import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import {
  // ADULT_AGE_TEXT,
  // CHILD_AGE_TEXT,
  // INFANT_AGE_TEXT,
  LOUNGE_TYPE,
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_ARRIVAL,
  PRODUCTID_DEPARTURE,
  PRODUCTID_PRIORITYPASS,
  PRODUCTID_DIGICELBLACKCARD,
  SINGLE_BOOKING_MAX_COUNT_DEPARTURE,
} from "../config/commonConstants";
import OverlayTooltip from "../components/commonComponents/OverlayTooltip";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChild,
  faPeopleRoof,
  faPersonBreastfeeding,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import {
  useLocation,
  useNavigate,
  Link,
  useOutletContext,
} from "react-router-dom";
import SoldOutDataModal from "../components/commonComponents/SoldOutDataModal";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import {
  dateFormatForDisplay,
  getAirlineNames,
  getFlightNumbers,
  getMarketLoungenamesWithAirport,
  checkLoggedInUserIsPriorityPassOrDigicelBlackCard,
  getSubDistributorId,
  getAdultDisplayAge,
  getChildDisplayAge,
  getInfantDisplayAge,
  HandleAPIError,
  scrollToDiv,
  divRef,
  checkCartItemUse,
  getProductId,
  log,
  maxSeatsChecker,
  getDistributorSubDistributorProfile,
} from "../components/commonComponents/commonFunctions";
import { cancelCartItem, reserveCartItem } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import {
  setflightArrivalData,
  setflightDepartureData,
} from "../redux/flightScheduleRedux";
import {
  cartBookingDetailUpdate,
  cartFetchSuccess,
  deleteProduct,
} from "../redux/cartRedux";
import {
  showError,
  showConfirm,
  showInfo,
} from "../components/commonComponents/Swal";
import { cartData as CD } from "../redux/cartRedux";
import { logindetails as LD } from "../redux/signInRedux";
import useToggle from "../hooks/useToggle";
import { VIPER_Response_Codes } from "../config/viper-response-codes";
import FlightDetailsComponent from "../components/commonComponents/FlightDetailsComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function BookingForm(props) {
  const initialBookingDetails = {
    lounge: "",
    flightDate: null,
    airlineName: "",
    airlineId: "",
    flightNumber: "",
    flightTime_hour: "",
    errors: "",
  };

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector(CD);
  const distributorProfile = getDistributorSubDistributorProfile();
  const infantallowed = distributorProfile?.infantallowed;
  let productid = location?.state?.productid;
  const standBy = location?.state?.standBy;
  const currentCartItemId = location?.state?.cartItemId;
  const adultCountForEdit = location?.state?.adultCount;
  const childCountForEdit = location?.state?.childCount;
  const infantCountForEdit = location?.state?.infantCount;  
  const showMaxPaxError = location?.state?.showMaxPaxError;
  

  let currentBookingDetail = cartData?.find((c) => c.key === currentCartItemId) ?.value?.bookingDetail;

  // this condition is for manual booking form handle when back button clicked on manual booking form
  currentBookingDetail = currentBookingDetail !== undefined ? currentBookingDetail : location?.state?.bookingDetail;

  const flightDetailsForEdit = cartData?.find((c) => c.key === currentCartItemId)?.value?.flightDetails;
  const airlineIdForEdit = cartData?.find((c) => c.key === currentCartItemId)?.value?.airlineId;
  const flightDetailsForStandBy = location?.state?.flightDetails;
  const airlineIdForStandBy = location?.state?.airlineId;
  const editScheduleId = location?.state;

  const standByBookingDetail = location?.state?.currentstandByBookingDetail;
  const currentPassengerInfo = cartData?.find((c) => c.key === currentCartItemId)?.value?.data?.passengerInfo ?? "";

  const selectedMarket = useSelector(currentMarket);
  const { t } = useTranslation(["common", "journeydetails"]);
  const [loading, setLoading] = useState(false);
  const [submitclicked, setSubmitclicked] = useState(false);
  const sendBroadcastMessage = useOutletContext();
  const [airlineId, setAirlineId] = useState([]);
  const [visibleSoldOut, setvisibleSoldOut] = useState(false);
  const [clubType, setClubType] = useState([]);
  const [date, setDate] = useState([]);
  const bookingType = ["Arrival Details", "Departure Details"];
  const hideSoldOutModal = () => setvisibleSoldOut(false);
  const showSoldOutModal = () => setvisibleSoldOut(true);
  const [formValidity, setformValidity] = useState("");
  const [arrivalscheduleid, setArrivalscheduleid] = useState(null);
  const [departurescheduleid, setDeparturescheduleid] = useState(null);
  const [cartItemId, setCartItemId] = useState();
  const [flightDetails, setFlightDetails] = useState([]);
  const [tmparrivalscheduleid, setTmpArrivalscheduleid] = useState(null);
  const [tmpdeparturescheduleid, setTmpDeparturescheduleid] = useState(null);
  const loginDetails = useSelector(LD);
  const [isVisibleStandByModal, setIsVisibleStandByModal] = useToggle();

  const standByCancelHandler = () => {
    setIsVisibleStandByModal();
  };

  const validationSchema = Yup.object().shape({
    bookingDetail: Yup.array().of(
      Yup.object().shape({
        lounge: Yup.object()
          .test(
            "arrival-lounge",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalLounge.required")
              : t("journeydetails:errors.departureLounge.required"),
            function (value) {
              return this.path !== "bookingDetail[0].lounge" || value;
            }
          )
          .test(
            "deaprture-lounge",
            t("journeydetails:errors.departureLounge.required"),
            function (value) {
              return this.path !== "bookingDetail[1].lounge" || value;
            }
          ),
        flightDate: Yup.date()
          .nullable()
          .test(
            "arrival-flightDate",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightDate.required")
              : t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightDate" || value;
            }
          )
          .test(
            "deaprture-flightDate",
            t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightDate" || value;
            }
          ),
        airlineName: Yup.object()
          .test(
            "arrival-airlineName",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalAirlineName.required")
              : t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              return this.path !== "bookingDetail[0].airlineName" || value;
            }
          )
          .test(
            "deaprture-airlineName",
            t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              return this.path !== "bookingDetail[1].airlineName" || value;
            }
          ),
        flightNumber: Yup.object()
          .test(
            "arrival-flightNumber",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightNumber.required")
              : t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightNumber" || value;
            }
          )
          .test(
            "deaprture-flightNumber",
            t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightNumber" || value;
            }
          ),
      })
    ),
    adultCount: Yup.object()
      .required(t("journeydetails:errors.flightNumber.required"))
      .test(
        "infatn-count-validate",
        t("journeydetails:errors.adult.count"),
        (adultCount) => {
          let count =
            parseInt(adultCount.value) +
            parseInt(formik.values.childCount.value);
          if (count === 0) {
            return false;
          }
          return true;
        }
      ),
    childCount: Yup.object().test(
      "child-count-validate",
      t(""),
      (childCount) => {
        let count =
          parseInt(formik.values.adultCount.value) + parseInt(childCount.value);
        if (count === 0) {
          return false;
        }
        return true;
      }
    ),
    // infantCount: Yup.object().required(t("feedback:errors.adultCount.required")),
  });

  const initial = {
    // ... other fields
    bookingDetail: [],
    adultCount:
      currentCartItemId !== 0
        ? { value: adultCountForEdit, label: adultCountForEdit }
        : { value: "1", label: "1" },
    childCount:
      currentCartItemId !== 0
        ? { value: childCountForEdit, label: childCountForEdit }
        : { value: "0", label: "0" },
    infantCount:
      currentCartItemId !== 0
        ? { value: infantCountForEdit, label: infantCountForEdit }
        : { value: "0", label: "0" },
  };

  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => {
      setSubmitclicked(true);
      handleSubmit(formik.values);
    },
  });
  const handleArrivalDepartureScheduleIdChange = async (
    scheduleid,
    i,
    productid
  ) => {
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      if (i === 0) {
        await setArrivalscheduleid(scheduleid);
        await setTmpArrivalscheduleid(scheduleid); // set tmp for schedule id
        if (
          editScheduleId?.departurescheduleid &&
          editScheduleId?.departurescheduleid !== null
        ) {
          setDeparturescheduleid(editScheduleId?.departurescheduleid);
        } else if (editScheduleId?.cartItemId === 0) {
          setDeparturescheduleid(tmpdeparturescheduleid);
        } else {
          setDeparturescheduleid(tmpdeparturescheduleid);
        }
        // editScheduleId?.departurescheduleid !== null ? setDeparturescheduleid(editScheduleId?.departurescheduleid) : setDeparturescheduleid(tmpdeparturescheduleid);
      } else {
        await setDeparturescheduleid(scheduleid);
        await setTmpDeparturescheduleid(scheduleid); // set  tmp for schedule id
        if (
          editScheduleId?.arrivalscheduleid &&
          editScheduleId?.arrivalscheduleid !== null
        ) {
          setArrivalscheduleid(editScheduleId?.arrivalscheduleid);
        } else if (editScheduleId?.cartItemId === 0) {
          setArrivalscheduleid(tmparrivalscheduleid);
        } else {
          setArrivalscheduleid(tmparrivalscheduleid);
        }
        // editScheduleId?.arrivalscheduleid !== null ? setArrivalscheduleid(editScheduleId?.arrivalscheduleid) : setArrivalscheduleid(tmparrivalscheduleid);
      }
    } else if (productid === PRODUCTID_ARRIVAL && i === 0) {
      await setArrivalscheduleid(scheduleid);
    } else if (
      (productid === PRODUCTID_DEPARTURE ||
        productid === PRODUCTID_PRIORITYPASS ||
        productid === PRODUCTID_DIGICELBLACKCARD) &&
      i === 0
    ) {
      await setDeparturescheduleid(scheduleid);
    }
  };

  const standByOkHandler = () => {
    log(
      "standby",
      { sessionid: loginDetails?.sessionid },
      "redirect-from-regular-to-standby",
      null
    );
    const state = {
      cartItemId: 0, // hardcoded
      productid: getProductId(productid),
      arrivalscheduleid: arrivalscheduleid,
      departurescheduleid: departurescheduleid,
      adultCount: formik.values.adultCount,
      childCount: formik.values.childCount,
      infantCount: formik.values.infantCount,
      currentBookingDetail: { ...formik.values.bookingDetail },
      flightDetails: { ...flightDetails },
      airlineId: airlineId,
    };

    if (currentCartItemId && currentCartItemId !== 0) {
      const data = { cartitemid: currentCartItemId };
      cancelCartItem(data)
        .then((res) => {
          if (res?.status === 0) {
            dispatch(deleteProduct(currentCartItemId));
          }
        })
        .catch((err) => {
          console.log("Error " + err);
        });
    }
    navigate(
      productid === PRODUCTID_ARRIVALBUNDLE
        ? "/standby-bundle"
        : productid === PRODUCTID_ARRIVAL
        ? "/standby-arrival"
        : "/standby-departure",
      { state: state }
    );
    setIsVisibleStandByModal();
  };

  const [maxAdultCount, setMaxAdultCount] = useState([]);
  const [maxChildCount, setMaxChildCount] = useState([]);
  const [maxInfantCount, setMaxInfantCount] = useState([]);
  const [maxAllowed, setMaxAllowed] = useState();

  const getMaxSeats = () => {
    let adultMaxCount = 0;
    let childMaxCount = 0;
    let infantMaxCount = 0;
    let maxCount = 0;

    if (
      productid === PRODUCTID_PRIORITYPASS ||
      productid === PRODUCTID_DEPARTURE ||
      productid === PRODUCTID_DIGICELBLACKCARD
    ) {
      adultMaxCount = distributorProfile.departuremaxseats;
      childMaxCount = distributorProfile.departuremaxseats;
      infantMaxCount = distributorProfile.departuremaxseats;
      maxCount = distributorProfile.departuremaxseats;

      if (
        productid === PRODUCTID_PRIORITYPASS ||
        productid === PRODUCTID_DIGICELBLACKCARD
      ) {
        childMaxCount = 0;
      }
    } else {
      adultMaxCount = distributorProfile.arrivalmaxseats;
      childMaxCount = distributorProfile.arrivalmaxseats;
      infantMaxCount = distributorProfile.arrivalmaxseats;
      maxCount = distributorProfile.arrivalmaxseats;
    }

    let objMaxSeats = {
      adult: adultMaxCount,
      child: childMaxCount,
      infant: infantMaxCount,
      max: maxCount,
    };

    return objMaxSeats;
  };

  const setMaxSeats = () => {
    const objMaxSeats = getMaxSeats();

    const updatedAdultCount = Array.from(
      { length: objMaxSeats.adult + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    const updatedChildCount = Array.from(
      { length: objMaxSeats.child + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    const updatedInfantCount = Array.from(
      { length: objMaxSeats.infant + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    setMaxAdultCount(updatedAdultCount);
    setMaxChildCount(updatedChildCount);
    setMaxInfantCount(updatedInfantCount);
    setMaxAllowed(objMaxSeats.max);
  };

  const maxChecker = async (value, type) => {
    let returnValue = true;
    let tmpAdultCount = 0;
    let tmpChildCount = 0;

    if (type === "child") {
      tmpAdultCount = parseInt(formik.values.adultCount.value);
      tmpChildCount = parseInt(value);
    } else if (type === "adult") {
      tmpAdultCount = parseInt(value);
      tmpChildCount = parseInt(formik.values.childCount.value);
    }

    let tmpPaxCount = tmpAdultCount + tmpChildCount;

    if (tmpPaxCount > maxAllowed) {
      returnValue = false;
      await showError(
        t("error"),
        t("common:maxSeats", { maxAllowed: maxAllowed })
      );

      if (type === "child") {
        formik.setFieldValue("childCount", { value: "0", label: "0" });
      } else if (type === "adult") {
        formik.setFieldValue("adultCount", { value: "0", label: "0" });
      }
    } else {
      formik.setFieldValue("adultCount", {
        value: tmpAdultCount.toString(),
        label: tmpAdultCount.toString(),
      });
      formik.setFieldValue("childCount", {
        value: tmpChildCount.toString(),
        label: tmpChildCount.toString(),
      });
    }
    return returnValue;
  };

  const urlMaxChecker = async () => {
    if (showMaxPaxError === true) {
      // await showError(t("error"), t('common:maxSeats', { maxAllowed: urlMaxCount }));
      formik.setFieldValue("adultCount", {
        value: adultCountForEdit,
        label: adultCountForEdit,
      });
      formik.setFieldValue("childCount", {
        value: childCountForEdit,
        label: childCountForEdit,
      });
    }
  };

  useEffect(() => {
    setMaxSeats();
  }, []);

  useEffect(() => {
    if (loginDetails?.sessionid) {
      checkCartItemUse(cartData, currentCartItemId, navigate, false);
    }
  }, [cartData, currentCartItemId, navigate, loginDetails]);

  useEffect(() => {
    urlMaxChecker();
  }, [showMaxPaxError]);

  useEffect(() => {}, [
    formik.values.adultCount,
    formik.values.infantCount,
    formik.values.childCount,
  ]);

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );

  useEffect(() => {}, [formik.values.bookingDetail]);

  useEffect(() => {
    if (currentCartItemId !== 0 || currentCartItemId === undefined) {
      if (standBy) {
        setArrivalscheduleid(editScheduleId?.arrivalscheduleid);
        setDeparturescheduleid(editScheduleId?.departurescheduleid);
      }

      standBy ? setFlightDetails({ ...flightDetailsForStandBy }) : setFlightDetails({ ...flightDetailsForEdit });
      standBy ? setAirlineId({ ...airlineIdForStandBy }) : setAirlineId({ ...airlineIdForEdit });
      
      productid === PRODUCTID_ARRIVAL &&
        (standBy
          ? formik.setFieldValue("bookingDetail", [...standByBookingDetail])
          : formik.setFieldValue("bookingDetail", [currentBookingDetail]));

      productid === PRODUCTID_DEPARTURE &&
        (standBy
          ? formik.setFieldValue("bookingDetail", [...standByBookingDetail])
          : formik.setFieldValue("bookingDetail", [currentBookingDetail]));
      productid === PRODUCTID_PRIORITYPASS &&
        (standBy
          ? formik.setFieldValue("bookingDetail", [...standByBookingDetail])
          : formik.setFieldValue("bookingDetail", [currentBookingDetail]));
      productid === PRODUCTID_DIGICELBLACKCARD &&
        (standBy
          ? formik.setFieldValue("bookingDetail", [...standByBookingDetail])
          : formik.setFieldValue("bookingDetail", [currentBookingDetail]));
      productid === PRODUCTID_ARRIVALBUNDLE &&
        (standBy
          ? formik.setFieldValue("bookingDetail", [...standByBookingDetail])
          : formik.setFieldValue("bookingDetail", [
              { ...currentBookingDetail[0] },
              { ...currentBookingDetail[1] },
            ]));
    } else {
      productid === PRODUCTID_ARRIVAL &&
        formik.setFieldValue("bookingDetail", [{ ...initialBookingDetails }]);
      productid === PRODUCTID_DEPARTURE &&
        formik.setFieldValue("bookingDetail", [{ ...initialBookingDetails }]);
      productid === PRODUCTID_PRIORITYPASS &&
        formik.setFieldValue("bookingDetail", [{ ...initialBookingDetails }]);
      productid === PRODUCTID_DIGICELBLACKCARD &&
        formik.setFieldValue("bookingDetail", [{ ...initialBookingDetails }]);
      productid === PRODUCTID_ARRIVALBUNDLE &&
        formik.setFieldValue("bookingDetail", [
          { ...initialBookingDetails },
          { ...initialBookingDetails },
        ]);
    }
    setClubType(marketLoungenameswithairport);
  }, [standByBookingDetail]);

  const getDirection = (i) => {
    const Direction =
      productid === PRODUCTID_ARRIVALBUNDLE
        ? LOUNGE_TYPE[i]?.value
        : productid === PRODUCTID_ARRIVAL
        ? LOUNGE_TYPE[0]?.value
        : LOUNGE_TYPE[1]?.value;
    return Direction;
  };

  const compareDates = () => {
    const flag =
      new Date(
        dateFormatForDisplay(
          formik.values.bookingDetail[0].flightDate,
          "MM/dd/yyyy"
        )
      ) <
      new Date(
        dateFormatForDisplay(
          formik.values.bookingDetail[1].flightDate,
          "MM/dd/yyyy"
        )
      )
        ? true
        : false;
    return flag;
  };

  const handleSubmit = async () => {
    const data = {
      cartitemid: currentCartItemId ? currentCartItemId : 0,
      productid: productid,
      ticketsrequested:
        parseInt(formik.values.adultCount.value) +
        parseInt(formik.values.childCount.value),
      adulttickets: parseInt(formik.values.adultCount.value),
      childtickets: parseInt(formik.values.childCount.value),
      paymenttype: "GUESTCARD",
      distributorid: "",
    };

    let error = false;

    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      if (!compareDates()) {
        const confirmed = await showConfirm(t("confirm"), t("pastDateError"));
        if (confirmed.isConfirmed) {
          error = false;
        } else {
          error = true;
        }
      }
    }

    if (!error) {
      if (productid === PRODUCTID_ARRIVALBUNDLE) {
        dispatch(
          setflightArrivalData(
            formik.values.bookingDetail[0]
              ? { ...formik.values.bookingDetail[0] }
              :{}
          )
        );
        dispatch(
          setflightDepartureData(
            formik.values.bookingDetail[1]
              ? { ...formik.values.bookingDetail[1] }
              :{}
          )
        );
      }

      if (productid === PRODUCTID_ARRIVAL) {
        dispatch(
          setflightArrivalData(
            formik.values.bookingDetail[0]
              ? { ...formik.values.bookingDetail[0] }
              :{}
          )
        );
        dispatch(setflightDepartureData({}));
      }

      if (productid === PRODUCTID_DEPARTURE) {
        dispatch(setflightArrivalData({}));
        dispatch(
          setflightDepartureData(
            formik.values.bookingDetail[0]
              ? { ...formik.values.bookingDetail[0] }
              :{}
          )
        );
      }

      const subDistributorId = getSubDistributorId();

      try {
        setLoading(true);
        let tmpArrivalScheduleId, tmpDepartureScheduleId;
        let tmpBookingDetails;

        if (productid === PRODUCTID_ARRIVALBUNDLE) {
          tmpArrivalScheduleId = arrivalscheduleid;
          tmpDepartureScheduleId = departurescheduleid;
          tmpBookingDetails = formik.values.bookingDetail;
        } else if (productid === PRODUCTID_ARRIVAL) {
          tmpArrivalScheduleId =
            productid === PRODUCTID_ARRIVAL
              ? arrivalscheduleid
              : arrivalscheduleid !== 0
              ? arrivalscheduleid
              : departurescheduleid;
          tmpDepartureScheduleId = 0;
          tmpBookingDetails = formik.values.bookingDetail[0];
        } else {
          productid =
            productid === PRODUCTID_PRIORITYPASS ||
            productid === PRODUCTID_DIGICELBLACKCARD
              ? PRODUCTID_DEPARTURE
              : productid;

          tmpArrivalScheduleId = 0;
          tmpDepartureScheduleId =
            currentCartItemId !== 0
              ? departurescheduleid !== 0
                ? departurescheduleid
                : arrivalscheduleid
              : departurescheduleid;
          tmpBookingDetails = formik.values.bookingDetail[0];
        }

        const res = await reserveCartItem(dispatch, {
          ...data,
          productid: productid,
          arrivalscheduleid: tmpArrivalScheduleId,
          departurescheduleid: tmpDepartureScheduleId,
          distributorid: subDistributorId,
        });

        setCartItemId(res?.data?.cartitemid);

        if (res.status === 0) {
          const peopleCountData = {
            bookingDetail: { ...tmpBookingDetails },
            adultCount: formik.values.adultCount.value,
            childCount: formik.values.childCount.value,
            infantCount:
              location?.state?.productid === PRODUCTID_DEPARTURE
                ? 0
                : formik.values.infantCount.value,

            //  "infantCount": (productid === PRODUCTID_ARRIVAL || productid === PRODUCTID_ARRIVALBUNDLE) ? formik.values.infantCount.value : 0,
            infantCountNumber: formik.values.infantCount?.value,
            productid: productid,
            arrivalscheduleid: tmpArrivalScheduleId,
            departurescheduleid: tmpDepartureScheduleId,
            cartItemId: res?.data?.cartitemid,
            currentPassengerInfo: currentPassengerInfo,
          };

          if (currentCartItemId !== 0 && currentCartItemId !== undefined) {
            dispatch(
              cartBookingDetailUpdate({
                bookingDetail: { ...tmpBookingDetails },
                flightDetails: { ...flightDetails },
                airlineId: airlineId,
                currentCartItem: res?.data,
                cartItemId: currentCartItemId,
                adultCount: formik.values.adultCount?.value,
                childCount: formik.values.childCount?.value,
                infantCount: formik.values.infantCount?.value,
                data: {
                  passengerInfo: currentPassengerInfo,
                  ...(checkLoggedInUserIsPriorityPassOrDigicelBlackCard() && {
                    prioritypass: "Y",
                  }),
                },
              })
            );
          } else {
            dispatch(
              cartFetchSuccess({
                key: res?.data?.cartitemid,
                value: {
                  bookingDetail: { ...tmpBookingDetails },
                  flightDetails: { ...flightDetails },
                  airlineId: airlineId,
                  currentCartItem: res?.data,
                  adultCount: formik.values.adultCount?.value,
                  childCount: formik.values.childCount?.value,
                  infantCount: formik.values.infantCount?.value,
                  data: {
                    passengerInfo: currentPassengerInfo,
                    ...(checkLoggedInUserIsPriorityPassOrDigicelBlackCard() && {
                      prioritypass: "Y",
                    }),
                  },
                },
              })
            );
          }

          setLoading(false);
          navigate("/registration", { state: peopleCountData });
        } else {
          if (
            VIPER_Response_Codes.STANDBY_BOOKING_STATUS_CODE?.find(
              (code) => code === res.status
            )
          ) {
            setLoading(false);
            setIsVisibleStandByModal();
          } else {
            setLoading(false);
            showError(t("error"), res.statusMessage);
          }
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };
  const getDirectionTranslationKey = () => {
    let translationKey = "";
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      translationKey = "arrivalDeparture";
    } else if (productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else {
      // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }
    return translationKey;
  };
  const setFlightDetailsForEdit = (data) => {
    setFlightDetails(data);
    setArrivalscheduleid(editScheduleId?.arrivalscheduleid);
    setDeparturescheduleid(editScheduleId?.departurescheduleid);
  };

  return (
    <>
      <section>
        <Modal
          show={isVisibleStandByModal}
          onHide={standByCancelHandler}
          size="lg"
          style={{ zIndex: "9996" }}
          centered
        >
          <Modal.Header closeButton><h3>{t("standByHeading")}</h3></Modal.Header>
          <Modal.Body>{t("standByMessage")}</Modal.Body>
          <Modal.Footer>
            <CommonButton
              classname="exit"
              title={t("noThanks")}
              onclick={standByCancelHandler}
            />
            <CommonButton
              classname="btn btn-danger"
              title={t("yesThanks")}
              onclick={standByOkHandler}
            />
          </Modal.Footer>
        </Modal>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} />
          {visibleSoldOut && (
            <SoldOutDataModal
              message={""}
              cancelModal={hideSoldOutModal}
              direction={getDirection(0)}
            />
          )}
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">
              {t("bookingFormHeading", {product: t(getDirectionTranslationKey()),
                 article : productid === PRODUCTID_DEPARTURE ? t("a") : t("an")
                 })}
            </span>
          </h3>
          <Row className="my-3 d-flex align-content-left">
            <Col md={12} className="d-flex justify-content-end">
              <CommonButton
                type="submit"
                classname="gradient-btn mx-2"
                title={t("checkSoldOutPeriod")}
                onclick={showSoldOutModal}
              />
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3">
            <Card.Body>
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <h6 className="field">{t("fillAllFields")}</h6>
                <ErrorMessages formik={formik} />
                <Row>
                  <h5 className="my-2 d-flex">
                    <FontAwesomeIcon
                      icon={faPeopleRoof}
                      size="sm"
                      className="me-2 icon-color"
                    />
                    {t("numberOfGuests")}
                    <span className="field">*</span>
                  </h5>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="icon-color"
                          size="lg"
                        />{" "}
                        {t("adult")} {getAdultDisplayAge()}
                      </Form.Label>
                      <Select
                        options={maxAdultCount}
                        defaultValue={maxAdultCount[1]}
                        value={formik.values.adultCount}
                        name="adultCount"
                        onChange={async (d) => {
                          await formik.setFieldValue("adultCount", d);
                          maxChecker(d.value, "adult");
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        className={
                          formik.touched.adultCount && formik.errors.adultCount
                            ? " error rounded-2"
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faChild}
                          size="lg"
                          className="mx-1 mt-1 icon-color"
                        />{" "}
                        {productid === PRODUCTID_PRIORITYPASS ||
                        productid === PRODUCTID_DIGICELBLACKCARD ? (
                          <OverlayTooltip
                            title={t("child") + getChildDisplayAge()}
                            msg={t("childAgePriorityMessage")}
                          ></OverlayTooltip>
                        ) : (
                          <OverlayTooltip
                            title={t("child") + getChildDisplayAge()}
                            msg={t("childAgeMessage")}
                          ></OverlayTooltip>
                        )}
                      </Form.Label>
                      <Select
                        options={maxChildCount}
                        defaultValue={maxAdultCount[1]}
                        value={formik.values.childCount}
                        name="childCount"
                        onChange={async (d) => {
                          await formik.setFieldValue("childCount", d);
                          maxChecker(d.value, "child");
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        className={
                          formik.touched.childCount && formik.errors.adultCount
                            ? " error rounded-2"
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                  {infantallowed === "Y" ? (
                    <Col md={"auto "} sm={12}>
                      <Form.Group>
                        <Form.Label className="d-flex">
                          <FontAwesomeIcon
                            icon={faPersonBreastfeeding}
                            size="lg"
                            className="mx-1 mt-1 icon-color"
                          />
                          <OverlayTooltip
                            title={t("infant") + getInfantDisplayAge()}
                            msg={t("infantAgeMessage")}
                          ></OverlayTooltip>
                        </Form.Label>
                        <Select
                          value={formik.values.infantCount}
                          name="infantCount"
                          onChange={(d) => {
                            formik.setFieldValue("infantCount", d);
                          }}
                          options={maxInfantCount}
                          onBlur={(d) => formik.handleBlur}
                          //   className={formik.touched.infantCount && formik.errors.infantCount ? " error rounded-2" : ""}
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
                {/* booking detail component  */}
                {formik.values.bookingDetail?.map((a, i) => (
                  <FlightDetailsComponent
                    setFlightDetailsForEdit={setFlightDetailsForEdit}
                    key={i}
                    isLoungeDisplay={true}
                    clubType={clubType}
                    productid={productid}
                    flightNames={getAirlineNames(flightDetails[i]?.airlines)}
                    flightNumbers={getFlightNumbers(
                      flightDetails[i]?.flights?.filter(
                        (f) => f.airlineid === airlineId[i]
                      )
                    )}
                    heading={bookingType[i]}
                    product
                    direction={getDirection(i)}
                    index={i}
                    lounge={formik?.values?.bookingDetail?.[i]?.lounge}
                    flightDate={formik?.values?.bookingDetail?.[i]?.flightDate}
                    airlineName={
                      formik?.values?.bookingDetail?.[i]?.airlineName
                    }
                    airlineId={formik?.values?.bookingDetail?.[i]?.airlineId}
                    flightNumber={
                      formik?.values?.bookingDetail?.[i]?.flightNumber
                    }
                    flightTime_hour={
                      formik?.values?.bookingDetail?.[i]?.flightTime_hour
                    }
                    scheduleId={handleArrivalDepartureScheduleIdChange}
                    formik={formik}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                    onChange={(key, val) => {
                      if (key === "flightDate") {
                        const event = dateFormatForDisplay(val, "MM/dd/yyyy");
                        formik.setFieldValue(
                          `bookingDetail.${i}.${key}`,
                          event
                        );
                      }
                      formik.setFieldValue(`bookingDetail.${i}.${key}`, val);
                    }}
                  />
                ))}
                <Row className="my-3">
                  <Col md={12}>
                    <Link
                      onClick={() =>
                        log(
                          "manual-booking",
                          { sessionid: loginDetails?.sessionid },
                          "redirect-from-regular-to-manual-booking",
                          null
                        )
                      }
                      to="/manual-booking"
                      state={{
                        productid: productid,
                        adultCount: formik.values.adultCount,
                        childCount: formik.values.childCount,
                        infantCount: formik.values.infantCount,
                        bookingDetail: formik.values.bookingDetail,
                      }}
                      className="link"
                    >
                      {t("sendManualBookingRequest")}
                    </Link>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className=" d-flex justify-content-end">
                    <CommonButton
                      type="submit"
                      size="md"
                      classname="gradient-btn mx-2"
                      title={t("checkAvailability")}
                      //   onsubmit={handleSubmit}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default BookingForm;
