import { Card, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { useEffect, useState } from "react";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { addBillPayment } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { createColumnHelper } from "@tanstack/react-table";
import { Table } from "../components/commonComponents/Table";
import { HandleAPIError, validExpiryOrNot, clearSession, divRef, getCurrentMonth, myDateFormat, renderErrorMessages, scrollToDiv, getDistributorSubDistributorProfile } from "../components/commonComponents/commonFunctions";
import { showError } from "../components/commonComponents/Swal";
import CreditCard from "../components/commonComponents/CreditCard";
import { processCreditCardPayment } from "../utils/Fac";
import { useFormik } from "formik";
import * as Yup from "yup";
import { EMAIL_POLICY, CARD_CVV_LENGTH, DEFAULT_CURRENCYCODE } from "../config/commonConstants";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function BillPayment() {
  const { t } = useTranslation(["common", "payment", "billingreport"]);
  const sendBroadcastMessage = useOutletContext();
  const distributorProfile = getDistributorSubDistributorProfile();
  const currencyCode = distributorProfile?.currencycode ? distributorProfile.currencycode : DEFAULT_CURRENCYCODE;
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const [billdetails, setBilldetails] = useState([]);
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);

  const amount = location.state?.billdetails?.billpayableamount;

  const formik = useFormik({
    initialValues: {
      amount: amount,
      name: "",
      email: "",
      cardType: "VISA",
      cardProfile: "",
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: (new Date()?.getFullYear()?.toString()),
      cvv: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("topup:errors.name.required")),
      amount: Yup.number().required(t("topup:errors.amount.required"))
        // .matches(/^[0-9]+$/, t("topup:errors.cardNumber.match"))
        .min(0.01, t("topup:errors.amount.valid"))
        .max(amount),
      cardNumber: Yup.string()
        .required(t("topup:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("topup:errors.cardNumber.match"))
        .length(16, t("topup:errors.cardNumber.length")),
      cvv: Yup.string().required(t("payment:errors.cvv.required"))
      .length(CARD_CVV_LENGTH, t("payment:errors.cvv.length")),
      email: Yup.string().required(t("topup:errors.email.required"))
        .matches(EMAIL_POLICY, t("topup:errors.email.valid")),
      cardMonth: Yup.string().test(
        'test-credit-card-expiration-date',
        t("payment:errors.cardMonth.match"),
        tmpcardmonth => {
          return validExpiryOrNot(formik.values.cardYear, tmpcardmonth);
        }
      ),
      cardYear: Yup.string().test(
        'test-credit-card-expiration-date',
        t(""),
        tmpcardyear => {
          return validExpiryOrNot(tmpcardyear, formik.values.cardMonth);  
         }
      ),
    }),
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik.values),
  });

  useEffect(()=>{
    formik.setFieldValue("cardMonth",formik.values.cardMonth);
    formik.setFieldValue("cardYear",formik.values.cardYear);
  },[formik.values.cardMonth,formik.values.cardYear])

  // const [cardholderDetails, setCardholderDetails] = useState({
  //   amount: 0,
  //   name: "",
  //   email: "",
  //   cardType: "VISA",
  //   cardProfile: { value: "GUESTCARD", label: "GUEST CARD" },
  //   cardNumber: "",
  //   cardMonth: getCurrentMonth(),
  //   cardYear: new Date()?.getFullYear()?.toString(),
  //   cvv: "",
  //   errors: {},
  // });

  const getBillPaymentRequest = async (objPayment) => {
    let request = {};
    request = {
      paymentrecord: {
        billpayments: [
          {
            billid: location.state?.billdetails?.sequenceid,
            amount: objPayment.creditcard?.amount,
          },
        ],
        charged: "Y",
        creditcard: {
          cardtype: formik.values.cardType,
          cardnumber: formik.values.cardNumber.slice(-4),
          currency: currencyCode,
          cardholder: formik.values.name,
          email: formik.values.email,
          amount: objPayment.creditcard.amount,
          authorizationnumber: objPayment?.creditcard?.authorizationnumber,
        },
      },
    };
    return request;
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("startdate", {
      cell: (info) => myDateFormat(info.getValue()),
      header: t("billingreport:startDate"),
    }),
    columnHelper.accessor("enddate", {
      cell: (info) => myDateFormat(info.getValue()),
      header: t("billingreport:endDate"),
    }),
    columnHelper.accessor("billdate", {
      cell: (info) => myDateFormat(info.getValue()),
      header: t("billingreport:billDate"),
    }),
    columnHelper.accessor("duedate", {
      cell: (info) => myDateFormat(info.getValue()),
      header: t("billingreport:dueDate"),
    }),
    columnHelper.accessor("billamount", {
      cell: (info) => (
        <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>
      ),
      header: <div style={{ textAlign: "right" }}>{t("billingreport:currentCharges")}</div>
    }),
    columnHelper.accessor("outstandingamount", {
      cell: (info) => (
        <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>
      ),
      header: <div style={{ textAlign: "right" }}>{t("billingreport:totalDue")}</div>
    }),
    columnHelper.accessor("billpayableamount", {
      cell: (info) => (
        <div style={{ textAlign: "right" }}>{info.getValue().toFixed(2)}</div>
      ),
      header: <div style={{ textAlign: "right" }}>{t("billingreport:billPayableAmount") + "**"}</div>
    }),
  ];

  useEffect(() => {
    setBilldetails([location.state.billdetails]);
  }, []);

  const handleSubmit = async (formik) => {
    let objPayment = {};
    try {
      setLoading(true);
      setSubmitOnClickDisable(true);
      objPayment = {
        creditcard: {
          cardnumber: formik.cardNumber.slice(-4),
          authorizationnumber: "123456",
          amount: formik.amount,
        },
      };
      const addBillPaymentLogRequest = await getBillPaymentRequest(objPayment);
      const paymentData = {
        source: "OBI-BILLING",
        amount: formik.amount,
        cardholderDetails: formik,
      };
      const responseCC = await processCreditCardPayment(paymentData, addBillPaymentLogRequest);
      if (responseCC && Object.keys(responseCC).length !== 0 && responseCC?.data?.authorizationnumber && responseCC?.data?.authorizationnumber !== "") {
        objPayment = {
          creditcard: {
            cardnumber: responseCC?.data?.cardnumber,
            authorizationnumber: responseCC?.data?.authorizationnumber,
            amount: formik.amount,
          }
        };
        const request = await getBillPaymentRequest(objPayment);
        const response = await addBillPayment(request);

        if (response.status === 0) {
          const successInfo = {
            messageHeading: t('payment:successInfoMessageHeading'),
            successMessage: t('payment:successInfoSuccessMessage',{currencyCode:currencyCode,amount:formik.amount.toFixed(2)}),
            jePromotion: true,
            jemPromotion: true
          }
          // closeSession();
          clearSession(sendBroadcastMessage);
          navigate("/common-success", { state: successInfo });
        } else {
          showError(t("error"), response.statusMessage);
          setSubmitOnClickDisable(false);
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setSubmitOnClickDisable(false);
      setLoading(false);
    }
  };

  return (
    <>
      <section>
        <Container className="my-4">
          <Loader
            loading={loading}
            message={t("billingreport:loaderMessage")}
          />
          <div className="my-3">
            <h3 className="d-flex justify-content-center heading heading">
              <span className="heading">{t("billingreport:billPayment")}</span>
            </h3>
          </div>
          <div className="my-3 px-1 d-flex justify-content-end">
            <Link to="/billingReport" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t("back")} />
            </Link>
            <ExitButton />
          </div>
          <Card className="" ref={divRef}>
            <Card.Body>
              <Row>
                <Col md={12}><p className="field">{t("billingreport:amountCurrencyDeclaration", {currencyCode:currencyCode})}</p></Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Table
                    columns={columns}
                    data={billdetails}
                    noDataMessage={t("noDataAvailable")}
                    showPaginationControls={false}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-start">
                <Col md={4}>
                  <h6 className="field">{t("fillAllFields")}</h6>
                </Col>
              </Row>
              <ErrorMessages formik={formik} />
              <Row>
                <Col md={12}>
                  <h6 className="my-1 my-bg-info">
                    {t("billingreport:billPaymentNotes")}
                  </h6>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8}>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => scrollToDiv(e, formik)}
                  >
                    <div className="mt-4">
                      {<h4 className=" px-1 mb-3 ">{t("payment:amount")}</h4>}
                      <Card className="bg-light bg-opacity-10 rounded-3 px-0 mb-2">
                        <Card.Body>
                          <Row className="g-3">
                            <Col md={"auto"}>
                              <Form.Label className="d-flex" htmlFor="amount">
                                {t("amount")} ({currencyCode}){" "}
                                <span className="field">*</span>
                              </Form.Label>
                              <Form.Control
                                name="amount"
                                id="amount"
                                required
                                type="number"
                                placeholder=""
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.touched.amount && formik.errors.amount
                                    ? "error"
                                    : ""
                                }
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                      <CreditCard
                        isSubmitDisabled={
                          submitOnClickDisable ? submitOnClickDisable : false
                        }
                        formik={formik}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                        onChange={(key, val) => {
                          formik.setFieldValue(key, val);
                        }}
                        onBlur={(key, e) => {
                          formik.handleBlur(e);
                        }}
                      />
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}
export default BillPayment;
