import { Card, Col, Container, Row, Form } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { mapmembercard, processMemberCard } from "../redux/apiCalls";
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_NUMBER_MAXLENGTH, MEMBERCARD_PIN_MAXLENGTH, REGEX_ALPHABET, NAME_LENGTH,REGEX_NUMERIC } from "../config/commonConstants";
import md5 from "md5";
import { showError, showSuccess } from "../components/commonComponents/Swal";
import Password from "../components/commonComponents/Password";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { HandleAPIError, divRef, getMemberCardsArray, renderErrorMessages, scrollToDiv, translateConstantText } from "../components/commonComponents/commonFunctions";
import Select from "react-select";
import { memberCards as MC } from "../redux/memberCardRedux";
import { useSelector } from "react-redux";
import Loader from "../components/commonComponents/Loader";
import ChangeMemberPinModal from "../components/commonComponents/ChangeMemberPinModal";
import useToggle from "../hooks/useToggle";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

export default function MapMemberCardForm() {
  const location = useLocation();
  const navigate = useNavigate();
  // let stateData = location.state;
  const memberCardData = location?.state?.memberData || null;
  const memberCardDataMode = location?.state?.mode || null;

  const { t } = useTranslation(["common", "payment", "memberandcardinformation",]);
  const memberCards = useSelector(MC);

  const [MemberCardTypeOptions, setMemberCardTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisibleChangeMemberPin, setIsVisibleChangeMemberPin] = useToggle();
  const hideChangeMemberPinChangeMemberPin = () => setIsVisibleChangeMemberPin(false);
  const [resetMemberPinStatus, setResetMemberPinStatus] = useState(false);

  const validationSchema = Yup.object().shape({
    cardType: Yup.string().required(t("memberandcardinformation:errors.cardType.required", {membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")})),
    cardNumber: Yup.string().required(t("memberandcardinformation:errors.cardNumber.required", {membershipNumber: MEMBERCARD_CARD_NUMBER_LABEL})),
    pin: Yup.string().required(t('memberandcardinformation:errors.pin.required')).min(MEMBERCARD_PIN_MAXLENGTH).max(MEMBERCARD_PIN_MAXLENGTH),   
    memberName: Yup.string().required(t('memberandcardinformation:errors.memberName.required')),
  });

  const setMemberCardArray = async () => {
    const memberCardsArray = getMemberCardsArray(memberCards);
    setMemberCardTypeOptions(memberCardsArray);
  };

  useEffect(() => {
    setMemberCardArray();
  }, [memberCards]);

  const formik = useFormik({
    initialValues: {
      cardType: memberCardData?.cardid || "VIPVALUE",
      memberName: memberCardData?.membername || '',
      cardNumber: memberCardData?.memberid.substr(-16) || '',
      pin: ''
    },
    validationSchema,
    onSubmit: () => handleSubmit(formik.values),
  });

  const memberCardDetails = {
    memberCards: MemberCardTypeOptions.find((option) => option.value === formik.values.cardType),
    cardNumber: formik.values.cardNumber,
  }

  const handleSubmit = async (values) => {
    const data = {
      "action": memberCardData ? "UPDATE" : "ADD",
      "memberid": values.cardType + values.cardNumber,
      "membername": values.memberName,
      "pin": md5(values.pin),
    };

    const verifyMemberCardRequest = {
      "action": "VERIFYNAME",
      "memberid": values.cardType + values.cardNumber,
      "name": values.memberName,
      "pin": md5(values.pin),
    };

    const validateMemberCardRequest = {
      "action": "VALIDATE",
      "memberid": values.cardType + values.cardNumber,
      "allowexpired": "Y",
      "pin": md5(values.pin),
    };

    try {
      setLoading(true);
      const verifyMemberCardResponse = await processMemberCard(verifyMemberCardRequest);
      if (verifyMemberCardResponse?.status === 0) {
        const validateMemberCardResponse = await processMemberCard(validateMemberCardRequest);
        if (validateMemberCardResponse?.status === 0) {
          if (validateMemberCardResponse?.data?.reset === "Y") {
            setIsVisibleChangeMemberPin(true);
          } else {
            const response = await mapmembercard(data);
            if (response.status === 0) {
              await showSuccess(response?.statusMessage, t("memberandcardinformation:cardLinkSuccess")); 
              navigate("/linked-membercards");
            } else {
              await showError(t("error"), response?.statusMessage);
            }
          }
        } else {
          await showError(t("error"), validateMemberCardResponse?.statusMessage);
        }
      }
      else {
        await showError(t("error"), verifyMemberCardResponse?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePinSuccessAndSubmit = async (pin) => {
    formik.values.pin = pin; 
    formik.handleSubmit(formik.values); 
  };
  
  return (
    <Container className="my-3" ref={divRef}>
      <Loader
        loading={loading}
        message={t("memberandcardinformation:loaderMessage")}
      />
      <ChangeMemberPinModal
        visible={isVisibleChangeMemberPin}
        setResetMemberPinStatus={setResetMemberPinStatus}
        vipmembershipcardObject={memberCardDetails}
        cancelModal={hideChangeMemberPinChangeMemberPin}
        onSuccess={handlePinSuccessAndSubmit}
      />
      <h3 className="my-3  d-flex justify-content-center heading heading">
        <span className="heading">
          {t("memberandcardinformation:MapMemberHeading")}
        </span>
      </h3>
      <Row className="mb-3 ">
        <Col className="d-flex justify-content-end">
          <CommonButton
            classname="back btn"
            title={t("back")}
            size="md"
            type="button"
            onclick={() => navigate(-1)}
          />
        </Col>
      </Row>
      <Form onSubmit={(e) => scrollToDiv(e, formik)}>
        <Card className="d-flex justify-content-center mb-3 bg-light bg-opacity-10 rounded-3 ">
          <Card.Body>
            <Row className="d-flex justify-content-start">
              <Col md={4}>
                <h6 className="field">{t('fillAllFields')}</h6>
              </Col>
            </Row>
            <ErrorMessages formik={formik} />
            <Row className="d-flex justify-content-center">
              <Col md={4} sm={12}>
                <Form.Group className="my-2">
                  <Form.Label className="d-flex">
                    {t("memberandcardinformation:cardType", {
                      membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL"),
                    })}
                    {memberCardDataMode !== "Edit" && <span className="field">*</span> }       
                  </Form.Label>
                  {memberCardDataMode === "Edit" ? (
                    <Form.Label className="d-flex">
                      <b>{memberCardData?.cardname}</b>
                    </Form.Label>
                  ) : (
                    <Select
                      name="cardType"
                      id="MemberCardTypeOptions"
                      options={MemberCardTypeOptions}
                      value={MemberCardTypeOptions.find(
                        (option) => option.value === formik.values.cardType
                      )}
                      onBlur={formik.handleBlur}
                      onChange={(selectedOption) =>
                        formik.setFieldValue("cardType", selectedOption.value)
                      }
                      // isInvalid={formik.touched.cardType && formik.errors.cardType}
                      className={
                        formik.touched.cardType && formik.errors.cardType
                          ? "error w-100 rounded"
                          : "w-100 rounded"
                      }
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col md={4} sm={12} className="mb-3">
                <Form.Label className="d-flex" htmlFor="cardNumber">
                  {t("memberandcardinformation:cardNumber", {
                    membershipNumber: MEMBERCARD_CARD_NUMBER_LABEL,
                  })}
                  {memberCardDataMode !== "Edit" && <span className="field">*</span> } 
                </Form.Label>
                {memberCardDataMode === "Edit" ? (
                  <Form.Label className="d-flex">
                    <b>{memberCardData?.memberid.substr(-16)}</b>
                  </Form.Label>
                ) : (
                  <Form.Control
                    name="cardNumber"
                    type="text"
                    placeholder={t("memberandcardinformation:cardNumber", {
                      membershipNumber: MEMBERCARD_CARD_NUMBER_LABEL,
                    })}
                    value={formik.values.cardNumber}
                    maxLength={MEMBERCARD_NUMBER_MAXLENGTH}
                    onChange={(e) =>
                      formik.handleChange({
                        target: {
                          name: "cardNumber",
                          value: e.target.value.replace(REGEX_NUMERIC, ""),
                        },
                      })
                    }
                    onBlur={formik.handleBlur}
                    // isInvalid={formik.touched.cardNumber && formik.errors.cardNumber}
                    className={
                      formik.touched.cardNumber && formik.errors.cardNumber
                        ? "error"
                        : ""
                    }
                  />
                )}
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col md={4} sm={12} className="mb-3">
                <Form.Label htmlFor="validationCustomMemberName">
                  {t("memberandcardinformation:memberName")}
                  <span className="field">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="memberName"
                  maxLength={NAME_LENGTH}
                  placeholder={t(
                    "memberandcardinformation:memberNamePlaceholder"
                  )}
                  value={formik.values.memberName}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    e.target.value = e.target.value?.replace(
                      REGEX_ALPHABET,
                      ""
                    );
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  // isInvalid={formik.touched.memberName && formik.errors.memberName}
                  className={
                    formik.touched.memberName && formik.errors.memberName
                      ? "error"
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col md={4} sm={12} className="mb-3">
                <Form.Label htmlFor="validationCustomPin">
                  {t("memberandcardinformation:pin")}
                  <span className="field">*</span>
                </Form.Label>
                <Password
                  name="pin"
                  value={formik.values.pin}
                  minLength={MEMBERCARD_PIN_MAXLENGTH}
                  maxLength={MEMBERCARD_PIN_MAXLENGTH}
                  onChange={(e) =>
                    formik.handleChange({
                      target: {
                        name: "pin",
                        value: e.target.value.replace(REGEX_NUMERIC, ""),
                      },
                    })
                  }
                  placeholder={t("pinPlaceHolder")}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.pin && formik.errors.pin
                      ? "error rounded-2"
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <CommonButton
                  classname="gradient-btn"
                  title={t("save")}
                  size="md"
                  type="submit"
                  // onclick={() => addLinkCard()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </Container>
  );
}
