import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getProductName, getClubName, getAirportName, myDateFormat, dateFormatForDisplay } from "./commonFunctions";
import { useSelector } from "react-redux";
import { selectedMarket as currentMarket, availableMarkets as AM } from "../../redux/marketRedux";
import { PRODUCTID_ARRIVAL, PRODUCTID_DEPARTURE } from "../../config/commonConstants";

function BookingSummaryComponentCopy(props) {
  const { t } = useTranslation(["common", "bookingsummarycomponent"]);
  const selectedMarket = useSelector(currentMarket);
  const availableMarkets = useSelector(AM);
  const airports = selectedMarket?.airports;

  const renderBookingRecordHeader = () => {
    return (
      <>
        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={12} sm={12}>
            {/* <h4 className="my-2">{props?.orderDetail.heading}</h4> */}
            {props.orderDetail.productid ? (
              <h4 className="mb-1">
                <b>{t("bookingsummary:serviceCategory")}:</b>{" "}
                {getProductName(props?.orderDetail.productid)}
              </h4>
            ) : (
              ""
            )}
          </Col>
        </Row>
        {/* <Row className="d-flex align-content-center mb-1">
          {(props?.renderType === "CREDIT-BOOKING" || props?.renderType === "UPDATE-FLIGHT-DETAILS") ? (
            <Col md={6} sm={6}>
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:confirmationNumber")}:</b>{" "}
                {props?.orderDetail?.confirmationnumber}
              </h5>
            </Col>
          ) : null}
        </Row> */}
      </>
    );
  };

  const renderBookingSummaryComponent = (heading, bookingRecord) => {
    return (
      <>
        {/* {!(props.bookingRecord.productid === "ARRIVALBUNDLE" && props.productType === "DEPARTURELOUNGE") ?
        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={12} sm={12}>
            <h4 className="my-2">{props?.bookingRecord.heading}</h4>
            {props.bookingRecord.productid ?
              <h4 className="mb-1">
                <b>{t("bookingsummary:serviceCategory")}:</b> {getProductName(props?.bookingRecord.productid)}
              </h4> : ""}
          </Col>
        </Row> : null} */}

        <Row className="d-flex align-content-center mb-1">
          {(props?.renderType === "CREDIT-BOOKING" || props?.renderType === "UPDATE-FLIGHT-DETAILS") ?
            <Col md={6} sm={6}>
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:confirmationNumber")}:</b>{" "}
                {bookingRecord?.confirmationnumber}
              </h5>
            </Col>
            : null}
        </Row>

        {/* {props?.renderType === "STANDBY-PAYMENT" ?
          <Row className="d-flex justify-content-center align-content-center mb-1">
            <Col md={12} sm={12}>
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:tracking")}:</b> {props?.bookingRecord.confirmationnumber}
              </h5>
            </Col>
          </Row> : ""} */}

        <hr />
        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={12} sm={12}>
            <h5 className="mb-1">
              <b>{heading}</b>
            </h5>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={12} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:loungeService")}:</b> {getClubName(bookingRecord?.airportid)} {" / "} {getAirportName(bookingRecord?.airportid)}
            </h5>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center align-content-center mb-1">
          <Col md={4} sm={12}>
            {/* {props.renderType === "STANDBY-PAYMENT" ? (
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:flightDate")}:</b>{myDateFormat(bookingRecord?.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy hh:mm a")}
                </h5>
            ) : ( */}
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:flightDate")}: </b>{myDateFormat(bookingRecord?.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy hh:mm a")}
              </h5>
            {/* )} */}
          </Col>
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:airline")}:</b> {bookingRecord?.airline}
            </h5>
          </Col>
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:flightNumber")}: </b>{bookingRecord?.flightid}
            </h5>
          </Col>
        </Row>

        <Row className="d-flex align-content-center mb-1">
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:adultTicket")}:</b> {bookingRecord.adulttickets}
            </h5>
          </Col>
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:childTicket")}: </b>{bookingRecord.childtickets}
            </h5>
          </Col>
          <Col md={4} sm={12}>
            <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:infantTicket")}: </b>{bookingRecord.infanttickets}
            </h5>
          </Col>
        </Row>
        <Row className="d-flex align-content-center mb-1">
          <Col md={4} sm={12}>
            {/* <h5 className="mb-1">
              <b>{t("bookingsummarycomponent:guestName")}:</b> {`${bookingRecord.guests[0].firstname} ${bookingRecord.guests[0].lastname}`}
            </h5> */}
            {bookingRecord?.guests.map((departureRecord, index) => (
              <div key={index}>
                <h5 className="mb-1">
                  <b>{t("bookingsummarycomponent:guestName")}: </b> 
                  {`${departureRecord.firstname} ${departureRecord.lastname}`}
                </h5>
              </div>
            ))
            }
          </Col>
          {props.renderType === "UPDATE-FLIGHT-DETAILS" ?
            <Col md={4} sm={12}>
              <h5 className="mb-1">
                <b>{t("bookingsummarycomponent:bookingNumber")}:</b> {bookingRecord?.lookupkey}
              </h5>
            </Col> : ""}
        </Row>
      </>
    );
  };

  return (
    <>
      {renderBookingRecordHeader()}

      {props.orderDetail.arrivalbundles && props.orderDetail.arrivalbundles.map((record, key) => (
        <div key={"bundle_" + key}>
          {renderBookingSummaryComponent(t("arrivalDetails"), record.arrivalrecord)}
          {record.departurerecords.map((departureRecord, departureKey) => (
            <div key={"departure_" + departureKey}>
              {renderBookingSummaryComponent(t("departureDetails"), departureRecord)}
            </div>
          ))
          }
        </div>
      ))
      }

      {props.orderDetail.arrivalrecords && props.orderDetail.arrivalrecords.map((record, key) => (
        <div key={"arrival_" + key}>
          {renderBookingSummaryComponent(t("arrivalDetails"), record)}
        </div>
      ))
      }

      {props.orderDetail.departurerecords && props.orderDetail.departurerecords.map((record, key) => (
        <div key={"departure_" + key}>
          {renderBookingSummaryComponent(t("departureDetails"), record)}
        </div>
      ))
      }
    </>
  );
}

export default BookingSummaryComponentCopy;
