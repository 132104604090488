import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Col, Row, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LOGOS_URL, CUSTOMER_LOGIN, DIGICELBLACKCARD_LOGIN, PRIORITYPASS_LOGIN, MEMBERCARD_LABEL } from "../config/commonConstants";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket, selectedMarket, selectedMarketId as SM } from "../redux/marketRedux";
import useToggle from "../hooks/useToggle";
import ChangePasswordModal from "../components/commonComponents/ChangePasswordModal";
import { userDefault as UD, logindetails as LD, subDistributorProfile } from "../redux/signInRedux";
import { menuDefault, menuManage, menuItemManageUsers, menuReports, menuVIPMembershipCard, menuFeedback, menuGetUpdateFlightDetailsLink, menuItemLinkedMemberCard, menuItemDownloadUserMannual, menuItemTopup, menuItemMyBookings, menuItemLinkBooking, vipMenus, menuItemUpdateFlightDetailsForm, menuItemStandbyBundle, menuItemStandbyDeparture, menuItemStandbyArrival } from '../menuItems';
import Modal from "react-bootstrap/Modal";
import CommonButton from "./commonComponents/CommonButton";
import { logOut } from "../redux/apiCalls";
import { closeSession, get_affiliate_subaffiliate_data, show_distributor_logo, HandleAPIError, getDistributorSubDistributorProfile, checkLoggedInUserIsPriorityPassOrDigicelBlackCard, isObjectEmpty, changeUrlAccordingUser, translateConstantText, checkIfPromotionUser, getAffiliateLink } from "./commonComponents/commonFunctions";
import { affiliateDetails as AD } from "../redux/affiliatesRedux";
import { findAndConcatMenuItems } from "../components/commonComponents/commonFunctions";
import { clearLoggedInUserDetail } from "../redux/loggedInUserDetailRedux";
import Loader from "./commonComponents/Loader";
import { useCookies } from "react-cookie";
import { store } from "../store/store";
import SubscriberUpdateProfileModal from "../components/commonComponents/SubscriberUpdateProfileModal";

const Navigationbar = ({ sendBroadcastMessage }) => {
  const [cookies,setCookie,removeCookie] =useCookies(["affiliateData","promotionLoginData","affiliateDataBackup"])
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(["common", "navigationBar", "login"]);
  const selectedMarket = useSelector(currentMarket);
  const selectedMarketId = useSelector(SM);
  const distributorProfile = getDistributorSubDistributorProfile();
  const userDefault = useSelector(UD);
  const affiliateDetails = useSelector(AD);
  const loginDetails = useSelector(LD);
  const [menuItems, setMenuItems] = useState(menuDefault);
  let subscriber, default_user, showDistributorName;
  const dispatch = useDispatch();

  const [isVisibleChangePassword, setIsVisibleChangePassword] = useToggle();
  const [isVisibleUpdateProfile, setIsVisibleUpdateProfile] = useToggle();
  const [isVisibleLogoutModal, setIsVisibleLogoutModal] = useToggle();
  const [loading, setLoading] = useState(false);

  const logoutCancelHandler = () => {
    setIsVisibleLogoutModal();
  };

  const logoutOkHandler = async () => {
    setLoading(true)
    try {
      if(loginDetails?.sessionid){
        if (checkIfPromotionUser()) {
          let affiliateDataBackup = cookies?.affiliateDataBackup;
          if (affiliateDataBackup) {
            affiliateDataBackup.expiresAt = new Date(affiliateDataBackup.expiresAt)
            setCookie("affiliateData",affiliateDataBackup,{ expires: affiliateDataBackup.expiresAt, path: "/", sameSite:"none", secure:true})
            removeCookie("affiliateDataBackup", { path: "/" });
          }
          removeCookie("promotionLoginData", { path: "/" });
        }
          const response = await logOut();
          if (response?.status === 0) {
            dispatch(clearLoggedInUserDetail());
          }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
      closeSession(sendBroadcastMessage);
      setIsVisibleLogoutModal();
      const loginPageLink = (subscriber === "Y" ? "subscriber-login" : "/login");
      navigate(loginPageLink);
    }
  };

  useEffect(() => {
    const menu = generateUserMenu(userDefault, location.pathname, distributorProfile, navigate);
    setMenuItems(menu);

  //  cookies.affiliateid && location.pathname.split("/")[1] === "affiliate" && navigate("/");
  }, [userDefault, location.pathname, distributorProfile,cookies, selectedMarket]);

  const affiliateLink = getAffiliateLink();
  console.log("affiliateLink",affiliateLink);
  const displayname = userDefault?.displayname;
  let distributorname, partnerDistributorName = distributorProfile?.distributorname;
  default_user = !userDefault?.username ? 'Y' : (userDefault?.username === CUSTOMER_LOGIN.username ? 'Y' : 'N');
  subscriber = !userDefault?.subscriber ? "N" : userDefault?.subscriber;

  if (default_user === 'N') {
    default_user = !userDefault?.username ? 'Y' : (userDefault?.username === CUSTOMER_LOGIN.username ? 'Y' : 'N');//(userDefault?.username === PRIORITYPASS_LOGIN.username || userDefault?.username === DIGICELBLACKCARD_LOGIN.username ? 'Y' : 'N')
  }

  let affiliate_subaffiliate_data = get_affiliate_subaffiliate_data()
  showDistributorName = false;

  if (show_distributor_logo(location.pathname)) {
    if (default_user === "Y" && (affiliate_subaffiliate_data?.affiliateid !== "!" && affiliate_subaffiliate_data?.affiliateid !== "0")) {
      showDistributorName = true;
      distributorname = affiliateDetails?.affiliatename;
    }
  }

  if (partnerDistributorName && subscriber !== 'Y' && default_user !== "Y") {
    showDistributorName = true;
    distributorname = partnerDistributorName;
  }

  return (
    <>
      <Loader loading={loading} />
      <Modal
        show={isVisibleLogoutModal}
        onHide={logoutCancelHandler}
        size="md"
        style={{ zIndex: "9996" }}
        centered
      >
        <Modal.Header closeButton><h3>{t('confirmLogoutHeading')}</h3></Modal.Header>
        <Modal.Body>{t('logoutMessage')}</Modal.Body>
        <Modal.Footer>
          <CommonButton
            classname="exit"
            title={t('no')}
            onclick={logoutCancelHandler}
          />
          <CommonButton classname="btn btn-danger" title={t('yes')} onclick={logoutOkHandler} />
        </Modal.Footer>
      </Modal>

      <ChangePasswordModal
        visible={isVisibleChangePassword}
        cancelModal={setIsVisibleChangePassword}
        sendBroadcastMessage={sendBroadcastMessage}
      />

      <SubscriberUpdateProfileModal
        visible={isVisibleUpdateProfile}
        cancelModal={setIsVisibleUpdateProfile}
        userDefault={userDefault}
        sendBroadcastMessage={sendBroadcastMessage}
      />

      <Navbar
        className="navbarContainer"
        expand="lg"
        sticky="top"
        bg="light"
      >
        <Container fluid="xxl" className="">
          <Row className="d-flex  justify-content-start align-items-center">
            {showDistributorName &&
              <Col md={"auto"}>
                <p className="mb-md-0 text-center text-sm-start">
                  {affiliateLink && <Link to={affiliateLink} className="link" title={t("navigationbar:backToPartner")}>{distributorname}</Link>}
                  {!affiliateLink && distributorname}
                </p>
              </Col>
            }
            <Col md={"auto"} className="d-flex pe-0">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Brand href={changeUrlAccordingUser(location.pathname) ? "/distributor-booking-user" : "/" } className="ps-1 me-0 ps-md-0">
                <img
                  src={LOGOS_URL + selectedMarket?.image}
                  width={selectedMarketId === "JAM" ? "auto" : 100}
                  className="d-inline-block align-left"
                  alt={selectedMarket?.marketname}
                />
              </Navbar.Brand>
            </Col>
            <Col md={"auto"} className="d-flex justify-content-between">
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">{renderMenuItems(menuItems)}</Nav>
              </Navbar.Collapse>
            </Col>
            </Row>
          {(default_user === "N" && (userDefault?.username !== PRIORITYPASS_LOGIN.username && userDefault?.username !== DIGICELBLACKCARD_LOGIN.username)) &&
              <Row className="d-flex justify-content-start">
                <Col md={"auto"}>
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                      <NavDropdown
                        title={<span className="small">{displayname}</span>}
                        id="basic-nav-dropdown"
                        renderMenuOnMount={true}
                        align={"end"}
                      >
                        { checkIfPromotionUser() === false &&
                          <>
                            <NavDropdown.Item onClick={setIsVisibleChangePassword}>
                              {t('changePassword')}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                          </>
                        }
                        {userDefault.subscriber === "Y" && (
                          <>
                            <NavDropdown.Item onClick={setIsVisibleUpdateProfile}>
                              {t('updateProfileHeading')}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                          </>
                        )}
                        <NavDropdown.Item onClick={setIsVisibleLogoutModal}>
                          {t("navigationbar:logout")}
                        </NavDropdown.Item>

                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Col>
             </Row>
            }
            {/* </Row> */}
        </Container>
      </Navbar>
    </>
  );
};

const generateUserMenu = (userDefault, pathname, distributorProfile, navigate) => {
  const state = store.getState();
  let arrMenuItems = [];
  let tmpMenuItem;
  let manageuser, reportagent, groupbookingallowed, subscriber, default_user, resetpassword;

  manageuser = !userDefault?.manageuser ? "N" : userDefault?.manageuser;
  reportagent = !userDefault?.reportagent ? "N" : userDefault?.reportagent;
  groupbookingallowed = !userDefault?.groupbookingallowed ? "N" : userDefault?.groupbookingallowed;
  subscriber = !userDefault?.subscriber ? "N" : userDefault?.subscriber;
  default_user = !userDefault?.username ? 'Y' : (userDefault?.username === CUSTOMER_LOGIN.username ? 'Y' : 'N');
  resetpassword = userDefault?.resetpassword ? userDefault?.resetpassword : "N";
  let affiliate_subaffiliate_data = get_affiliate_subaffiliate_data()

  if (default_user === 'N') {
    default_user = !userDefault?.username ? 'Y' : (userDefault?.username === CUSTOMER_LOGIN.username ? 'Y' : 'N');//(userDefault?.username === PRIORITYPASS_LOGIN.username || userDefault?.username === DIGICELBLACKCARD_LOGIN.username ? 'Y' : 'N')
  }

  changeUrlAccordingUser(pathname) ? menuDefault[0].url = "/distributor-booking-user" : menuDefault[0].url = "/";

  // if(!isObjectEmpty(subDistributorProfile(state)) || pathname === "/distributor-booking-user"){
  //   if (Array.isArray(menuDefault) && menuDefault.length > 0) {
  //     menuDefault[0].url = "/distributor-booking-user";
  //   }
  // }
  // if(pathname === "/login" || pathname === "/subscriber-login" ){
  //   menuDefault[0].url = "/";
  // }

  arrMenuItems = [];
  tmpMenuItem = [];
  arrMenuItems = menuDefault; 
  if (default_user === "Y" && (affiliate_subaffiliate_data?.affiliateid === "!" || affiliate_subaffiliate_data?.affiliateid === "0")) {
    arrMenuItems = findAndConcatMenuItems(arrMenuItems, 'services', vipMenus);
  } else {
    let newMenuManage = {
      key: menuManage[0].key,
      title: menuManage[0].title,
      url: menuManage[0].url,
      submenu: [...menuManage[0].submenu],
    };

    if(affiliate_subaffiliate_data.affiliateid === "!" || affiliate_subaffiliate_data.affiliateid === "0" ){
      
      if(checkLoggedInUserIsPriorityPassOrDigicelBlackCard() !== true) {   
        if (resetpassword === "N") {
          if (checkIfPromotionUser() === false) {
            if (!arrMenuItems.find((item) => item.key === "manage")) {
              arrMenuItems = arrMenuItems.concat(newMenuManage);
            }

            if (arrMenuItems.find(item => item.key === 'manage')) {
              tmpMenuItem = arrMenuItems.find(item => item.key === 'manage');

                if (selectedMarket(state)?.arrivalbundleallowed !== "N") {   
                  if (!tmpMenuItem.submenu.find(item => item.key === 'standByBundle')) {
                    tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemStandbyBundle);
                  }
                } 
                if (selectedMarket(state)?.arrivalonlyallowed !== "N") {
                  if (!tmpMenuItem.submenu.find(item => item.key === 'standByArrival')) {
                    tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemStandbyArrival);
                  }
                } 
                if (selectedMarket(state)?.departureloungeallowed !== "N") {
                  if (!tmpMenuItem.submenu.find(item => item.key === 'standByDeparture')) {
                    tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemStandbyDeparture);
                  }
                }

              if (subscriber === "Y") {
                if (!tmpMenuItem.submenu.find(item => item.key === 'linkBooking')) {
                  tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemLinkBooking);
                }

                if (!tmpMenuItem.submenu.find(item => item.key === 'myBookings')) {
                  tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemMyBookings);
                }
              } else {
                if (!tmpMenuItem.submenu.find(item => item.key === 'downloadUserMannual')) {
                  tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemDownloadUserMannual);
                }

                if (!tmpMenuItem.submenu.find(item => item.key === 'updateFlightDetailsForm')) {
                  tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemUpdateFlightDetailsForm);
                }

                if (manageuser === "Y") {
                  if (!tmpMenuItem.submenu.find(item => item.key === 'manageUsers')) {
                    // tmpMenuItem.submenu = tmpMenuItem.submenu.slice(0, 1).concat(menuItemManageUsers, tmpMenuItem.submenu.slice(1));
                    tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemManageUsers);
                  }
                }

                if (distributorProfile?.prepaidallowed === "Y" || distributorProfile?.prepaidproductallowed === "Y") {
                  tmpMenuItem.submenu = findAndConcatMenuItems(tmpMenuItem.submenu, 'topup', menuItemTopup);
                }
              }
            }

            if (reportagent === "Y") {
              arrMenuItems = findAndConcatMenuItems(arrMenuItems, 'reports', menuReports);
            }
          }
        }
      }  
    }
  }

  if (resetpassword === "Y") {
    if (pathname !== "/feedback") {
      navigate("/force-password-change", { replace: true });
    }
  } else {
    if (selectedMarket(state)?.topupallowed === "Y") {
      if (checkLoggedInUserIsPriorityPassOrDigicelBlackCard() !== true) {
        if (checkIfPromotionUser() === false) {
          if (!arrMenuItems.find(item => item.key === 'vipMembershipCard')) {
            arrMenuItems = arrMenuItems.concat(menuVIPMembershipCard);
            tmpMenuItem = arrMenuItems.find(item => item.key === 'vipMembershipCard');

            if (subscriber === "Y") {
              if (!tmpMenuItem.submenu.find(item => item.key === "linkedMemberCard")) {
                tmpMenuItem.submenu = tmpMenuItem.submenu.concat(menuItemLinkedMemberCard);
              }
            }
          }
        }
      }
    }
  }

  if (!arrMenuItems.find(item => item.key === 'feedback')) {
    arrMenuItems = arrMenuItems.concat(menuFeedback);
  }

  if (pathname === "/login" || pathname === "/subscriber-login") {
    arrMenuItems = arrMenuItems.filter((item) => item.key !== "getUpdateFlightDetailsLink");
  } else {
    let show_update_booking_link;
    if (default_user === "Y") {
      show_update_booking_link = true;
    }

    if (show_update_booking_link === true) {
      if (!arrMenuItems.find((item) => item.key === "getUpdateFlightDetailsLink")) {
        arrMenuItems = arrMenuItems.concat(menuGetUpdateFlightDetailsLink);
      }
    }
  }
  return arrMenuItems;
}

const renderMenuItems = (menuItems) => {
  return menuItems.map((item, i) => {
    if (item.submenu) {
      return (
        <NavDropdown
          key={i}
          title={i18n.t("navigationbar:" + item.key, {menulabel: translateConstantText("MEMBERCARD_LABEL")})}
          id={item.key}
          renderMenuOnMount={true}
        >
          {renderSubMenu(item.submenu)}
        </NavDropdown>
      );
    } else {
      return (
        <Nav.Link key={i} href={item.url}>
          {i18n.t("navigationbar:" + item.key, {menulabel: translateConstantText("MEMBERCARD_LABEL")})}
        </Nav.Link>
      );
    }
  });
};

const renderSubMenu = (submenu) => {
  if (!submenu) return null;
  return submenu.map((item, i) => {
    if (item.submenu) {
      return (
        <NavDropdown
          key={i}
          title={i18n.t("navigationbar:" + item.key, {menulabel: translateConstantText("MEMBERCARD_LABEL")})}
          id={item.key}
          renderMenuOnMount={true}
        >
          {renderSubMenu(item.submenu)}
        </NavDropdown>
      );
    } else {
      return (
        <NavDropdown.Item
          key={i}
          href={item.url}
          className={item.submenu ? "" : "nav-link"}
          target={item.target}
        >
          {i18n.t("navigationbar:" + item.key, {menulabel: translateConstantText("MEMBERCARD_LABEL")})}
        </NavDropdown.Item>
      );
    }
  });
};

export default Navigationbar;