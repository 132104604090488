import { faUser, faPhone, faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ContactDetailsDisplay(props) {
  const { t } = useTranslation(["common"]);
  return (
    props?.contactDetails?.firstName && <div key={0}>
      <Row className="d-flex justify-content-center align-content-center mb-2">
        <Col md={12} sm={12}>
          <h5 className="mb-1">
            <b>{props?.contactType === "Secondary" ? t('secondaryContact') : t('primaryContact') }</b>
          </h5>
        </Col>
      </Row>

      <Row className="d-flex justify-content-start align-content-center mb-1">
        <Col md={4} sm={12}>
          <h5>
            <FontAwesomeIcon
              icon={faUser}
              className="mx-1 icon-color"
              size="sm"
            />{" "}
            <b>{t('name')}: </b>{props?.contactDetails?.title?.label}{" "}{props?.contactDetails?.firstName}{" "}{props?.contactDetails?.lastName}
          </h5>
        </Col>
        <Col md={4} sm={12}>
          <h5>
            <FontAwesomeIcon
              icon={faAt}
              size="sm"
              className="mx-1 icon-color"
            />
            <b>{t('email')}: </b>{props?.contactDetails?.email}
          </h5>
        </Col>
        <Col md={4} sm={12}>
          <h5>
            <FontAwesomeIcon
              icon={faPhone}
              className="mx-1 icon-color"
              size="sm"
            />
            <b>{t('contactNumber')}: </b>{props?.contactDetails?.phone}
          </h5>
        </Col>
      </Row>
    </div>
  );
}

export default ContactDetailsDisplay;
