import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import {
  PEOPLE_COUNT,
  //  ADULT_AGE_TEXT,
  // CHILD_AGE_TEXT,
  // INFANT_AGE_TEXT,
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_ARRIVAL,
  SALUTATION,
  EMAIL_POLICY,
  PRODUCTID_DEPARTURE,
  PRODUCTID_PRIORITYPASS,
  PRODUCTID_DIGICELBLACKCARD,
} from "../config/commonConstants";
import OverlayTooltip from "../components/commonComponents/OverlayTooltip";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChild,
  faPeopleRoof,
  faPersonBreastfeeding,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import ManualBookingFlightDetailsComponent from "../components/commonComponents/ManualBookingFlightDetailsComponent";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import {
  demographicInformation as DI,
  clearDemographicInformation,
} from "../redux/demographicInformationRedux";

import {
  autoLogin,
  dateFormatForDisplay,
  getMarketLoungenamesWithAirport,
  HandleAPIError,
  getAdultDisplayAge,
  getChildDisplayAge,
  getInfantDisplayAge,
  translateArrayKeyValue,
  scrollToDiv,
  divRef,
  secondaryContactDetailsSchemaToggle,
  getProductId,
  maxSeatsChecker,
  isObjectEmpty,
  getDistributorSubDistributorProfile,
  urlForRedirect,
} from "../components/commonComponents/commonFunctions";
import { addmanualbooking, getspecialoccassions } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { specialOccasions as SO } from "../redux/specialOccasionsRedux";
import AdultDetails from "../components/commonComponents/AdultDetails";
import ChildDetails from "../components/commonComponents/ChildDetails";
import ContactDetails from "../components/commonComponents/ContactDetails";
import GreetSign from "../components/commonComponents/GreetSign";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import {
  showError,
  showConfirm,
  showInfo,
} from "../components/commonComponents/Swal";
import { setDemographicInformation } from "../redux/demographicInformationRedux";

function ManualBookingForm(props) {
  const sendBroadcastMessage = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const productid = location?.state?.productid
    ? location?.state?.productid
    : PRODUCTID_DEPARTURE;
  const selectedMarket = useSelector(currentMarket);
  const demographicInformation = useSelector(DI);

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );
  const [isTitleChangeable, setIsTitleChangeable] = useState(true);

  const initialGreetSign = {
    name: "",
    occasion: "",
    occasionDetail: "",
    errors: {},
  };

  const initialBookingDetails = {
    lounge: location.state !== null ? (location.state.bookingDetail[0]?.lounge !== undefined ? location.state.bookingDetail[0]?.lounge : "") : "",
    flightDate: location.state !== null? (location.state.bookingDetail[0]?.flightDate !== null ? location.state.bookingDetail[0]?.flightDate : "") : "",
    airlineName: location.state !== null ? (location.state.bookingDetail[0]?.airlineName?.label !== undefined ? location.state.bookingDetail[0]?.airlineName?.label : "") : "",
    flightNumber: location.state !== null ? (location.state.bookingDetail[0]?.flightNumber?.label !== undefined ? location.state.bookingDetail[0]?.flightNumber?.label : "") : "",
    flightTime: "",
    errors: "",
  };

  const [salutation, setSalutation] = useState(
    translateArrayKeyValue(SALUTATION, "SALUTATION")
  );

  const initialAdult = {
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    errors: {},
  };

  const initialChild = {
    title: salutation.filter((x) => x.value === "MASTER")[0],
    firstName: "",
    lastName: "",
    dob: null,
    errors: {},
  };

  const initialPrimaryContact = {
    heading: "",
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    errors: {},
  };

  const initialSecondaryContact = {
    heading: "",
    title: salutation[0],
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    errors: {},
  };

  const { t } = useTranslation([
    "common",
    "registration",
    "greetSign",
    "common",
    "journeydetails",
    "standbybookingform",
  ]);
  const [loading, setLoading] = useState(false);
  const [greetingDetail, setGreetingDetail] = useState([initialGreetSign]);
  const bookingType = ["Arrival Details", "Departure Details"];
  const heading = [t("primaryContact"), t("secondaryContact")];
  const kidsType = [t("child"), t("infant")];
  const [selectedOccasion, setSelectedOccasion] = useState("");
  const specialOccasions = useSelector(SO);
  const [isEditable, setIsEditable] = useState(true);
  const distributorProfile = getDistributorSubDistributorProfile();
  const infantallowed = distributorProfile?.infantallowed;
  const [secondaryContactDetails, setSecondaryContactDetails] = useState(
    Yup.object().nullable()
  );

  const [maxAdultCount, setMaxAdultCount] = useState([]);
  const [maxChildCount, setMaxChildCount] = useState([]);
  const [maxInfantCount, setMaxInfantCount] = useState([]);
  const [maxAllowed, setMaxAllowed] = useState();

  const getMaxSeats = () => {
    let adultMaxCount = 0;
    let childMaxCount = 0;
    let infantMaxCount = 0;
    let maxCount = 0;

    if (
      productid === PRODUCTID_PRIORITYPASS ||
      productid === PRODUCTID_DEPARTURE ||
      productid === PRODUCTID_DIGICELBLACKCARD
    ) {
      adultMaxCount = distributorProfile.departuremaxseats;
      childMaxCount = distributorProfile.departuremaxseats;
      infantMaxCount = distributorProfile.departuremaxseats;
      maxCount = distributorProfile.departuremaxseats;

      if (
        productid === PRODUCTID_PRIORITYPASS ||
        productid === PRODUCTID_DIGICELBLACKCARD
      ) {
        childMaxCount = 0;
      }
    } else {
      adultMaxCount = distributorProfile.arrivalmaxseats;
      childMaxCount = distributorProfile.arrivalmaxseats;
      infantMaxCount = distributorProfile.arrivalmaxseats;
      maxCount = distributorProfile.arrivalmaxseats;
    }

    let objMaxSeats = {
      adult: adultMaxCount,
      child: childMaxCount,
      infant: infantMaxCount,
      max: maxCount,
    };

    return objMaxSeats;
  };

  const setMaxSeats = () => {
    const objMaxSeats = getMaxSeats();

    const updatedAdultCount = Array.from(
      { length: objMaxSeats.adult + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    const updatedChildCount = Array.from(
      { length: objMaxSeats.child + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    const updatedInfantCount = Array.from(
      { length: objMaxSeats.infant + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    setMaxAdultCount(updatedAdultCount);
    setMaxChildCount(updatedChildCount);
    setMaxInfantCount(updatedInfantCount);
    setMaxAllowed(objMaxSeats.max);
  };

  const validationSchema = Yup.object().shape({
    bookingDetail: Yup.array().of(
      Yup.object().shape({
        lounge: Yup.object()
          .test(
            "arrival-lounge",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalLounge.required")
              : t("journeydetails:errors.departureLounge.required"),
            function (value) {
              return this.path !== "bookingDetail[0].lounge" || value;
            }
          )
          .test(
            "deaprture-lounge",
            t("journeydetails:errors.departureLounge.required"),
            function (value) {
              return this.path !== "bookingDetail[1].lounge" || value;
            }
          ),
          flightDate: Yup.date()
          .nullable()
          .test(
            "arrival-flightDate",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightDate.required")
              : t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightDate" || value;
            }
          )
          .test(
            "deaprture-flightDate",
            t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightDate" || value;
            }
          ),
        airlineName: Yup.string()
          .test(
            "arrival-airlineName",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalAirlineName.required")
              : t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              return this.path !== "bookingDetail[0].airlineName" || value;
            }
          )
          .test(
            "deaprture-airlineName",
            t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              return this.path !== "bookingDetail[1].airlineName" || value;
            }
          ),
          flightNumber: Yup.string()
          .test(
            "arrival-flightNumber",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightNumber.required")
              : t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightNumber" || value;
            }
          )
          .test(
            "deaprture-flightNumber",
            t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightNumber" || value;
            }
          ),
        flightTime: Yup.string()
        .test(
          "arrival-flightTime",
          productid === PRODUCTID_ARRIVALBUNDLE ||
            productid === PRODUCTID_ARRIVAL
            ? t("journeydetails:errors.arrivalFlightTime.required")
            : t("journeydetails:errors.departureFlightTime.required"),
          function (value) {
            return this.path !== "bookingDetail[0].flightTime" || value;
          }
        )
        .test(
          "deaprture-flightTime",
          t("journeydetails:errors.departureFlightTime.required"),
          function (value) {
            return this.path !== "bookingDetail[1].flightTime" || value;
          }
        ),
      })
    ),
    adultCount: Yup.object()
      .required(t("manualbookingform:errors.flightNumber.required"))
      .test(
        "infatn-count-validate",
        t("manualbookingform:errors.adult.count"),
        (adultCount) => {
          let count =
            parseInt(adultCount.value) +
            parseInt(formik.values.childCount.value);
          if (count === 0) {
            return false;
          }
          return true;
        }
      ),
    childCount: Yup.object().test(
      "child-count-validate",
      t(""),
      (childCount) => {
        let count =
          parseInt(formik.values.adultCount.value) + parseInt(childCount.value);
        if (count === 0) {
          return false;
        }
        return true;
      }
    ),
    infantCount: Yup.object().required(
      t("feedback:errors.adultCount.required")
    ),
    adults: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required(
          t("manualbookingform:errors.adultFirstName.required")
        ),
        lastName: Yup.string().required(
          t("manualbookingform:errors.adultLastName.required")
        ),
        // email: Yup.string()
        //   .required(t("manualbookingform:errors.adultEmail.required"))
        //   .matches(
        //     EMAIL_POLICY,
        //     t("manualbookingform:errors.adultEmail.match")
        //   ),
      })
    ),
    childs: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required(
          t("manualbookingform:errors.childFirstName.required")
        ),
        lastName: Yup.string().required(
          t("manualbookingform:errors.childLastName.required")
        ),
        dob: Yup.string().required(
          t("manualbookingform:errors.childDob.required")
        ),
      })
    ),
    infants:
      getProductId(productid) !== PRODUCTID_DEPARTURE &&
      Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().required(
            t("manualbookingform:errors.infantFirstName.required")
          ),
          lastName: Yup.string().required(
            t("manualbookingform:errors.infantLastName.required")
          ),
          dob: Yup.string().required(
            t("manualbookingform:errors.infantDob.required")
          ),
        })
      ),
    primaryContactDetails: Yup.object().shape({
      firstName: Yup.string().required(
        t("manualbookingform:errors.primaryContactFirstName.required")
      ),
      lastName: Yup.string().required(
        t("manualbookingform:errors.primaryContactLastName.required")
      ),
      email: Yup.string()
        .required(t("manualbookingform:errors.primaryContactEmail.required"))
        .matches(
          EMAIL_POLICY,
          t("manualbookingform:errors.primaryContactEmail.match")
        ),
      confirmEmail: Yup.string()
        .required(
          t("manualbookingform:errors.primaryContactConfirmEmail.required")
        )
        .matches(
          EMAIL_POLICY,
          t("manualbookingform:errors.primaryContactConfirmEmail.match")
        )
        .test(
          "email-validate",
          t("manualbookingform:errors.primaryContactConfirmEmailEqual.equal"),
          (confirmEmail) => {
            let flag =
              confirmEmail === formik.values.primaryContactDetails.email
                ? true
                : false;
            if (!flag) {
              return false;
            }
            return true;
          }
        ),
      phone: Yup.string().required(
        t("manualbookingform:errors.primaryContactPhoneNumber.required")
      ),
    }),
    secondaryContactDetails: secondaryContactDetails,
    greetingDetails: Yup.array().of(
      Yup.object().shape({
        occasionDetail: Yup.string().test(
          "occasionDetail-validate",
          t("manualbookingform:errors.greetingDetailsOccasionDetail.required"),
          (occasionDetail) => {
            let flag =
              formik?.values?.greetingDetails[0]?.occasion?.value === "OTHER" &&
              formik?.values?.greetingDetails[0]?.occasionDetail === ""
                ? true
                : false;
            if (flag) {
              return false;
            }
            return true;
          }
        ),
      })
    ),
  });

  const initial = {
    bookingDetail: [],
    clubType: [],
    adultCount:
      location.state !== null
        ? {
            value: location.state.adultCount.value,
            label: location.state.adultCount.label,
          }
        : { value: 1, label: 1 },
    childCount:
      location.state !== null
        ? {
            value: location.state.childCount.value,
            label: location.state.childCount.label,
          }
        : { value: 0, label: 0 },
    infantCount:
      location.state !== null
        ? {
            value: location.state.infantCount.value,
            label: location.state.infantCount.label,
          }
        : { value: 0, label: 0 },
    adults: [],
    childs: [],
    infants: [],
    greetingDetails: [],
    primaryContactDetails: {
      primaryContactDetailsheading: "",
      title: salutation[0],
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",
    },
    secondaryContactDetails: {
      heading: "",
      title: salutation[0],
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",
    },
  };

  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  useEffect(() => {
    secondaryContactDetailsSchemaToggle(
      setSecondaryContactDetails,
      formik?.values?.secondaryContactDetails
    );
  }, [formik.values.secondaryContactDetails]);

  useEffect(() => {
    formik.setFieldValue("adults", formik.values.adults);
  }, [formik.values.adults]);

  function convertTimeToOutputFormat(timeString) {
    const [time, meridian] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    let formattedHours = hours;

    if (meridian === "PM" && hours !== 12) {
      formattedHours += 12;
    } else if (meridian === "AM" && hours === 12) {
      formattedHours = 0;
    }

    formattedHours = formattedHours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = "00";

    return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
  }

  useEffect(() => {
    setMaxSeats();
  }, []);

  useEffect(() => {
    if (isEditable) {
      formik.setFieldValue(
        "adultCount",
        location.state !== null
          ? location?.state?.adultCount
          : { value: 1, label: 1 }
      );
      formik.setFieldValue(
        "childCount",
        location.state !== null
          ? location?.state?.childCount
          : { value: 0, label: 0 }
      );
      formik.setFieldValue(
        "infantCount",
        location.state !== null
          ? location?.state?.infantCount
          : { value: 0, label: 0 }
      );
    }

    setIsEditable(false);
    //let tmpBookingDetails;
    let i = 0;
    const tmpAdults = [];
    const tmpChildren = [];
    const tmpInfants = [];
    const greet = [];
    //  let tmpStateBookingDetails;

    // if (productid === PRODUCTID_ARRIVALBUNDLE) {
    //   const initialBookingDetails = location.state.bookingDetail;
    //   tmpStateBookingDetails = initialBookingDetails?.map(
    //     (initialBookingDetail) => ({
    //       lounge: initialBookingDetail?.lounge,
    //       flightDate: initialBookingDetail?.flightDate,
    //       airlineName: initialBookingDetail?.airlineName?.label,
    //       airlineId: initialBookingDetail?.airlineId,
    //       flightNumber: initialBookingDetail?.flightNumber?.label,
    //       flightTime: initialBookingDetail?.flightTime_hour,
    //       errors: initialBookingDetail?.errors,
    //     })
    //   );
    //   tmpBookingDetails = [...tmpStateBookingDetails];
    // } else {
    //   // if (productid === PRODUCTID_ARRIVAL || productid === PRODUCTID_DEPARTURE) {
    //   tmpStateBookingDetails = {
    //     lounge: initialBookingDetails?.lounge,
    //     flightDate: initialBookingDetails?.flightDate,
    //     airlineName: initialBookingDetails?.airlineName,
    //     airlineId: initialBookingDetails?.airlineId,
    //     flightNumber: initialBookingDetails?.flightNumber,
    //     flightTime: initialBookingDetails?.flightTime,
    //     errors: initialBookingDetails?.errors,
    //   };
    //   tmpBookingDetails = [tmpStateBookingDetails];
    //   // formik.setFieldValue("clubType", location?.state?.clubType);
    // }

    // adults data filled
    if (isObjectEmpty(demographicInformation)) {
      for (i = 0; i < formik?.values?.adultCount.value; i++) {
        tmpAdults.push({ ...initialAdult });
      }
    } else {
      for (i = 0; i < formik?.values?.adultCount.value; i++) {
        const temp = demographicInformation?.adults?.find(
          (a, index) => index === i
        );
        console.log("adults....", temp);
        if (temp) tmpAdults.push({ ...temp });
        else tmpAdults.push({ ...initialAdult });
      }
    }

    // Children data filled
    if (isObjectEmpty(demographicInformation)) {
      for (i = 0; i < formik?.values?.childCount.value; i++) {
        tmpChildren.push({ ...initialChild });
      }
    } else {
      for (i = 0; i < formik?.values?.childCount.value; i++) {
        const temp = demographicInformation?.childs?.find(
          (a, index) => index === i
        );
        console.log("childs....", temp);
        if (temp) tmpChildren.push({ ...temp });
        else tmpChildren.push({ ...initialChild });
      }
    }

    // infant data filled
    if (isObjectEmpty(demographicInformation)) {
      if (getProductId(productid) !== PRODUCTID_DEPARTURE) {
        for (let i = 0; i < formik?.values?.infantCount.value; i++) {
          tmpInfants.push({ ...initialChild });
        }
      }
    } else {
      for (i = 0; i < formik?.values?.infantCount.value; i++) {
        const temp = demographicInformation?.infants?.find(
          (a, index) => index === i
        );
        console.log("infants....", temp);
        if (temp) tmpInfants.push({ ...temp });
        else tmpInfants.push({ ...initialChild });
      }
    }

    for (i = 0; i < 1; i++) {
      greet.push({ ...initialGreetSign });
    }

    formik.setFieldValue("adults", tmpAdults);
    formik.setFieldValue("childs", tmpChildren);
    formik.setFieldValue("infants", tmpInfants);
    formik.setFieldValue("greetingDetails", greet);

    if (
      isObjectEmpty(demographicInformation) ||
      (parseInt(formik.values.childCount.value) === 0 &&
        parseInt(formik.values.adultCount.value) === 0)
    ) {
      dispatch(clearDemographicInformation());
      formik.setFieldValue("primaryContactDetails", {
        ...initialPrimaryContact,
      });
    } else {
      if (
        formik.values.adultCount.value !== 0 &&
        !isObjectEmpty(demographicInformation.adult_primaryContactDetails)
      ) {
        formik.setFieldValue("primaryContactDetails", {
          ...demographicInformation.adult_primaryContactDetails,
        });
      } else if (
        formik.values.childCount.value !== 0 &&
        !isObjectEmpty(demographicInformation.child_primaryContactDetails)
      ) {
        formik.setFieldValue("primaryContactDetails", {
          ...demographicInformation.child_primaryContactDetails,
        });
      } else if (
        formik.values.adultCount.value === 0 &&
        formik.values.childCount.value !== 0 &&
        !isObjectEmpty(demographicInformation.child_primaryContactDetails)
      ) {
        formik.setFieldValue("primaryContactDetails", {
          ...demographicInformation.child_primaryContactDetails,
        });
      } else if (
        formik.values.adultCount.value !== 0 &&
        formik.values.childCount.value === 0 &&
        !isObjectEmpty(demographicInformation.adult_primaryContactDetails)
      ) {
        formik.setFieldValue("primaryContactDetails", {
          ...demographicInformation.adult_primaryContactDetails,
        });
      } else {
        formik.setFieldValue("primaryContactDetails", {
          ...initialPrimaryContact,
        });
      }
    }

    formik.setFieldValue("secondaryContactDetails", {
      ...initialSecondaryContact,
    });
    Object.values(specialOccasions)?.length === 0 && getspecialoccassions(dispatch);
    // formik.setFieldValue("bookingDetail", tmpBookingDetails);
    !location?.state?.productid &&
      autoLogin(dispatch, "", sendBroadcastMessage);
    formik.setFieldValue("clubType", marketLoungenameswithairport);
  }, [
    formik.values.adultCount,
    formik.values.infantCount,
    formik.values.childCount,
  ]);

  useEffect(() => {
    let tmpBookingDetails;
    let tmpStateBookingDetails;

    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      const initialBookingDetails = location.state.bookingDetail;
      tmpStateBookingDetails = initialBookingDetails?.map(
        (initialBookingDetail) => ({
          lounge: initialBookingDetail?.lounge,
          flightDate: initialBookingDetail?.flightDate,
          airlineName: initialBookingDetail?.airlineName?.label,
          airlineId: initialBookingDetail?.airlineId,
          flightNumber: initialBookingDetail?.flightNumber?.label,
          flightTime: initialBookingDetail?.flightTime_hour,
          errors: initialBookingDetail?.errors,
        })
      );
      tmpBookingDetails = [...tmpStateBookingDetails];
    } else {
      // if (productid === PRODUCTID_ARRIVAL || productid === PRODUCTID_DEPARTURE) {
      tmpStateBookingDetails = {
        lounge: initialBookingDetails?.lounge,
        flightDate: initialBookingDetails?.flightDate,
        airlineName: initialBookingDetails?.airlineName,
        airlineId: initialBookingDetails?.airlineId,
        flightNumber: initialBookingDetails?.flightNumber,
        flightTime: initialBookingDetails?.flightTime,
        errors: initialBookingDetails?.errors,
      };
      tmpBookingDetails = [tmpStateBookingDetails];
      // formik.setFieldValue("clubType", location?.state?.clubType);
    }
    formik.setFieldValue("bookingDetail", tmpBookingDetails);
  }, [productid]);

  const generateAddManualBookingRequest = async () => {
    const passengersArray = [];
    formik?.values?.adults.forEach((a) => {
      passengersArray.push({
        passengertype: "ADULT",
        title: a?.title.value,
        firstname: a?.firstName,
        lastname: a?.lastName,
        email: a?.email,
        dob: a?.dob ? dateFormatForDisplay(a?.dob, "yyyyMMdd") : "",
      });
    });

    formik?.values?.childs.forEach((a) => {
      passengersArray.push({
        passengertype: "CHILD",
        title: a?.title.value,
        firstname: a?.firstName,
        lastname: a?.lastName,
        email: a?.email,
        dob: dateFormatForDisplay(a?.dob, "yyyyMMdd"),
      });
    });

    formik?.values?.infants.forEach((a) => {
      passengersArray.push({
        passengertype: "INFANT",
        title: a?.title.value,
        firstname: a?.firstName,
        lastname: a?.lastName,
        email: a?.email,
        dob: dateFormatForDisplay(a?.dob, "yyyyMMdd"),
      });
    });

    let flights = [];
    flights[0] = {
      direction:
        productid === PRODUCTID_ARRIVAL || productid === PRODUCTID_ARRIVALBUNDLE
          ? "A"
          : "D",
      airportid: formik?.values?.bookingDetail[0]?.lounge.value,
      arrivaldate: dateFormatForDisplay(
        formik?.values?.bookingDetail[0]?.flightDate,
        "yyyyMMdd"
      ),
      arrivaltime: convertTimeToOutputFormat(
        formik?.values?.bookingDetail[0]?.flightTime
      ),
      airline: formik?.values?.bookingDetail[0]?.airlineName,
      flightid: formik?.values?.bookingDetail[0]?.flightNumber,
    };

    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      flights[1] = {
        direction: "D",
        airportid: formik?.values?.bookingDetail[1]?.lounge.value,
        arrivaldate: dateFormatForDisplay(
          formik?.values?.bookingDetail[1]?.flightDate,
          "yyyyMMdd"
        ),
        arrivaltime: convertTimeToOutputFormat(
          formik?.values?.bookingDetail[1]?.flightTime
        ),
        airline: formik?.values?.bookingDetail[1]?.airlineName,
        flightid: formik?.values?.bookingDetail[1]?.flightNumber,
      };
    }

    const addManualBookingRequest = {
      booking: {
        productid: getProductId(productid),
        adulttickets: formik?.values?.adultCount?.value,
        childtickets: formik?.values?.childCount?.value,
        infanttickets: formik?.values?.infantCount?.value,
        hotel: "",
        transportcompany: "",
        transportcontactname: "",
        transportcontactnumber: "",
        occassioncode: "",
        occassioncomment: "",
        paddlename: formik?.values?.greetingDetails[0].name,
        primarytitle: formik?.values?.primaryContactDetails?.title?.value,
        primaryfirstname: formik?.values?.primaryContactDetails?.firstName,
        primarylastname: formik?.values?.primaryContactDetails?.lastName,
        primaryemail: formik?.values?.primaryContactDetails?.email,
        primarydob: "",
        primaryphone: formik?.values?.primaryContactDetails?.phone,
        secondarytitle: formik?.values?.secondaryContactDetails?.title?.value,
        secondaryfirstname: formik?.values?.secondaryContactDetails?.firstName,
        secondarylastname: formik?.values?.secondaryContactDetails?.lastName,
        secondaryemail: formik?.values?.secondaryContactDetails?.email,
        secondarydob: "",
        secondaryphone: formik?.values?.secondaryContactDetails?.phone,
      },
      flights: flights,
      passengers: passengersArray,
      special_occassion: formik?.values?.greetingDetails[0].occasion.value,
      special_occassion_comment: formik?.values?.greetingDetails[0].occasionDetail,
      paddlename: formik?.values?.greetingDetails[0].name,
    };

    return addManualBookingRequest;
  };

  const handleSubmit = async () => {
    // setPrimaryContactDetails(primaryContactDetails);
    const addManualBookingRequest = await generateAddManualBookingRequest();
    try {
      setLoading(true);
      const response = await addmanualbooking(addManualBookingRequest);
      if (response.statusMessage === "Success" && response.status === 0) {
        const successInfo = {
          messageHeading: t("manualbookingform:successHeading"),
          // successMessage: t("manualbookingform:successMessage"),
          successMessage: t('manualbookingform:successMessage', { referenceNumber: response?.data?.bookingid, email: formik?.values?.primaryContactDetails?.email }),
          jePromotion: true,
          jemPromotion: true,
        };
        dispatch(clearDemographicInformation());
        navigate("/common-success", { state: successInfo });
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const getBookingDate = () => {
    let dates = [];
    formik.values.bookingDetail.forEach((item) => {
      dates.push(item.flightDate);
    });
    //const dates = ["2021-02-05", "2021-05-20", "2021-01-02"]
    const min = dates.reduce((acc, date) => {
      return acc && new Date(acc) < new Date(date) ? acc : date;
    }, "");
    //  const max = dates.reduce((acc, date) => { return acc && new Date(acc) > new Date(date) ? acc : date }, '')
    return min;
  };

  const getDirectionTranslationKey = () => {
    let translationKey = "";
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      translationKey = "arrivalDeparture";
    } else if (productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else {
      // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }
    return translationKey;
  };

  const maxChecker = async (value, type) => {
    let returnValue = true;
    let tmpAdultCount = 0;
    let tmpChildCount = 0;

    if (type === "child") {
      tmpAdultCount = parseInt(formik.values.adultCount.value);
      tmpChildCount = parseInt(value);
    } else if (type === "adult") {
      tmpAdultCount = parseInt(value);
      tmpChildCount = parseInt(formik.values.childCount.value);
    }

    let tmpPaxCount = tmpAdultCount + tmpChildCount;

    if (tmpPaxCount > maxAllowed) {
      returnValue = false;
      await showError(
        t("error"),
        t("common:maxSeats", { maxAllowed: maxAllowed })
      );

      if (type === "child") {
        formik.setFieldValue("childCount", { value: "0", label: "0" });
      } else if (type === "adult") {
        formik.setFieldValue("adultCount", { value: "0", label: "0" });
      }
    } else {
      formik.setFieldValue("adultCount", {
        value: tmpAdultCount.toString(),
        label: tmpAdultCount.toString(),
      });
      formik.setFieldValue("childCount", {
        value: tmpChildCount.toString(),
        label: tmpChildCount.toString(),
      });
    }

    return returnValue;
  };

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} />
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">
              {t("manualbookingform:reservationFormHeading")}
            </span>
          </h3>
          <Row className="my-3 d-flex align-content-left">
            <Col md={12} className="d-flex justify-content-end">
              <CommonButton
                classname="back mx-2"
                title={t("back")}
                onclick={() => {
                  dispatch(clearDemographicInformation());
                  navigate(urlForRedirect(productid), { state: { cartItemId: undefined, adultCount: formik.values?.adultCount?.value, childCount: formik.values?.childCount?.value, infantCount: formik.values?.infantCount?.value, productid: productid, bookingDetail: productid === PRODUCTID_ARRIVALBUNDLE ? formik?.values?.bookingDetail : formik?.values?.bookingDetail[0] } });
                }}
              />
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3">
            <Card.Body>
              <div>
                <h6 className="my-bg-info">
                  {t("manualbookingform:reservationMessage")}
                </h6>
              </div>
              <h4>
                {t("bookingFormHeading", {
                  product: t(getDirectionTranslationKey()),
                  article : productid === PRODUCTID_DEPARTURE ? t("a") : t("an")
                  })}
              </h4>
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <h6 className="field">{t("fillAllFields")}</h6>
                <ErrorMessages formik={formik} />
                <Row>
                  <h5 className="my-2 d-flex">
                    <FontAwesomeIcon
                      icon={faPeopleRoof}
                      size="sm"
                      className="me-2 icon-color"
                    />
                    {t("numberOfGuests")}
                    <span className="field">*</span>
                  </h5>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="icon-color"
                          size="lg"
                        />{" "}
                        {t("adult")} {getAdultDisplayAge()}
                      </Form.Label>
                      <Select
                        options={maxAdultCount}
                        defaultValue={maxAdultCount[1]}
                        value={formik.values.adultCount}
                        name="adultCount"
                        onChange={async (d) => {
                          await formik.setFieldValue("adultCount", d);
                          maxChecker(d.value, "adult");
                          dispatch(
                            setDemographicInformation({
                              adults: formik.values.adults,
                              childs: formik.values.childs,
                              infants: formik.values.infants,
                              adult_primaryContactDetails:
                                formik.values.primaryContactDetails,
                              child_primaryContactDetails: {},
                            })
                          );
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        className={
                          formik.touched.adultCount && formik.errors.adultCount
                            ? " error rounded-2"
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faChild}
                          size="lg"
                          className="mx-1 mt-1 icon-color"
                        />{" "}
                        <OverlayTooltip
                          title={t("child") + getChildDisplayAge()}
                          msg={t("childAgeMessage")}
                        ></OverlayTooltip>
                      </Form.Label>
                      <Select
                        value={formik.values.childCount}
                        name="childCount"
                        onChange={async (d) => {
                          await formik.setFieldValue("childCount", d);
                          maxChecker(d.value, "child");

                          dispatch(
                            setDemographicInformation({
                              adults: formik.values.adults,
                              childs: formik.values.childs,
                              infants: formik.values.infants,
                              adult_primaryContactDetails: {},
                              child_primaryContactDetails:
                                formik.values.primaryContactDetails,
                            })
                          );
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        className={
                          formik.touched.childCount && formik.errors.adultCount
                            ? " error rounded-2"
                            : ""
                        }
                        options={maxChildCount}
                        defaultValue={maxAdultCount[1]}
                      />
                    </Form.Group>
                  </Col>
                  {infantallowed === "Y" ? (
                    <Col md={"auto "} sm={12}>
                      <Form.Group>
                        <Form.Label className="d-flex">
                          <FontAwesomeIcon
                            icon={faPersonBreastfeeding}
                            size="lg"
                            className="mx-1 mt-1 icon-color"
                          />
                          <OverlayTooltip
                            title={t("infant") + getInfantDisplayAge()}
                            msg={t("infantAgeMessage")}
                          ></OverlayTooltip>
                        </Form.Label>
                        <Select
                          value={formik.values.infantCount}
                          name="infantCount"
                          onChange={(d) => {
                            formik.setFieldValue("infantCount", d);

                            dispatch(
                              setDemographicInformation({
                                adults: formik.values.adults,
                                childs: formik.values.childs,
                                infants: formik.values.infants,
                                adult_primaryContactDetails:
                                  formik.values.adultCount.value !== 0
                                    ? formik.values.primaryContactDetails
                                    : {},
                                child_primaryContactDetails:
                                  formik.values.adultCount.value === 0 &&
                                  formik.values.childCount.value !== 0
                                    ? formik.values.primaryContactDetails
                                    : {},
                                // primaryContactDetails: formik.values.primaryContactDetails,
                              })
                            );
                          }}
                          onBlur={(d) => formik.handleBlur}
                          options={PEOPLE_COUNT}
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
                {formik.values.bookingDetail?.map((a, i) => (
                  <ManualBookingFlightDetailsComponent
                    key={i}
                    clubType={formik.values.clubType}
                    productid={productid}
                    heading={bookingType[i]}
                    product
                    index={i}
                    lounge={formik?.values?.bookingDetail?.[i]?.lounge}
                    flightDate={formik?.values?.bookingDetail?.[i]?.flightDate}
                    airlineName={
                      formik?.values?.bookingDetail?.[i]?.airlineName
                    }
                    flightNumber={
                      formik?.values?.bookingDetail?.[i]?.flightNumber
                    }
                    flightTime={formik?.values?.bookingDetail?.[i]?.flightTime}
                    errors={a.errors}
                    formik={formik}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                    onChange={(key, val) => {
                      if (formik.values.bookingDetail[i].flightTime) {
                        let b = convertTimeToOutputFormat(a.flightTime);
                      }
                      formik.setFieldValue(`bookingDetail.${i}.${key}`, val);
                    }}
                  />
                ))}

                <Row className="d-flex justify-content-around align-content-center my-3">
                  <Col>
                    <h3>{t("registration:airlineDemographicInformation")}</h3>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-around align-content-center mb-2">
                  <Col>
                    <h5>{t("customerInformation")}</h5>
                  </Col>
                </Row>
                {/* adult component  */}
                <div className="mt-2">
                  {formik.values.adults?.map((a, i) => (
                    <AdultDetails
                      key={i}
                      isMandatory={i === 0 ? true : false}
                      title={a.title}
                      bookingDate={getBookingDate()}
                      formik={formik}
                      index={i}
                      onBlur={(key, e, title) => {
                        formik.handleBlur(e);
                        if (key === "title" && isTitleChangeable) {
                          setIsTitleChangeable(false);
                          formik.setFieldValue(
                            `primaryContactDetails.${key}`,
                            title
                          );
                        }
                        if (
                          key === "firstName" &&
                          formik.values.primaryContactDetails?.firstName === ""
                        ) {
                          formik.setFieldValue(
                            `primaryContactDetails.${key}`,
                            e.target.value
                          );
                        }
                        if (
                          key === "lastName" &&
                          formik.values.primaryContactDetails?.lastName === ""
                        ) {
                          formik.setFieldValue(
                            `primaryContactDetails.${key}`,
                            e.target.value
                          );
                        }
                        if (
                          key === "email" &&
                          formik.values.primaryContactDetails?.email === ""
                        ) {
                          formik.setFieldValue(
                            `primaryContactDetails.${key}`,
                            e.target.value
                          );
                        }
                        if (getProductId(productid) !== PRODUCTID_DEPARTURE) {
                          var ele_fname = formik?.values?.adults[0]?.firstName;
                          var ele_lname = formik?.values?.adults[0]?.lastName;
                          if (
                            parseInt(formik?.values?.adultCount?.value) +
                              parseInt(formik?.values?.childCount?.value) +
                              parseInt(formik?.values?.infantCount.value) >
                            1
                          ) {
                            formik.setFieldValue(
                              `greetingDetails.${0}.name`,
                              ele_fname + " " + ele_lname + " " + t("guests")
                            );
                          } else {
                            formik.setFieldValue(
                              `greetingDetails.${0}.name`,
                              ele_fname + " " + ele_lname
                            );
                          }
                        }
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(`adults.${i}.${key}`, val);
                      }}
                    />
                  ))}
                </div>
                {/* child component */}
                <div className="mt-4">
                  {formik.values.childs?.map((a, i) => (
                    <ChildDetails
                      key={i}
                      heading={kidsType[0]}
                      isMandatory={i === 0 ? true : false}
                      title={a.title}
                      type={"child"}
                      bookingDate={getBookingDate()}
                      formik={formik}
                      index={i}
                      onBlur={(key, e, title) => {
                        formik.handleBlur(e);
                        if (formik.values.adults.length === 0) {
                          if (key === "title" && isTitleChangeable) {
                            setIsTitleChangeable(false);
                            formik.setFieldValue(
                              `primaryContactDetails.${key}`,
                              title
                            );
                          }
                          if (
                            key === "firstName" &&
                            formik.values.primaryContactDetails?.firstName ===
                              ""
                          ) {
                            formik.setFieldValue(
                              `primaryContactDetails.${key}`,
                              e.target.value
                            );
                          }
                          if (
                            key === "lastName" &&
                            formik.values.primaryContactDetails?.lastName === ""
                          ) {
                            formik.setFieldValue(
                              `primaryContactDetails.${key}`,
                              e.target.value
                            );
                          }
                          if (
                            key === "email" &&
                            formik.values.primaryContactDetails?.email === ""
                          ) {
                            formik.setFieldValue(
                              `primaryContactDetails.${key}`,
                              e.target.value
                            );
                          }
                          if (getProductId(productid) !== PRODUCTID_DEPARTURE) {
                            var ele_fname =
                              formik?.values?.childs[0]?.firstName;
                            var ele_lname = formik?.values?.childs[0]?.lastName;
                            if (
                              parseInt(formik?.values?.adultCount?.value) +
                                parseInt(formik?.values?.childCount?.value) +
                                parseInt(formik?.values?.infantCount.value) >
                              1
                            ) {
                              formik.setFieldValue(
                                `greetingDetails.${0}.name`,
                                ele_fname + " " + ele_lname + " " + t("guests")
                              );
                            } else {
                              formik.setFieldValue(
                                `greetingDetails.${0}.name`,
                                ele_fname + " " + ele_lname
                              );
                            }
                          }
                        }
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(`childs.${i}.${key}`, val);
                      }}
                    />
                  ))}
                </div>
                {/*same  child component used for infant  */}
                <div className="mt-4">
                  {getProductId(productid) !== PRODUCTID_DEPARTURE &&
                    formik.values.infants?.map((a, i) => (
                      <ChildDetails
                        key={i}
                        type={"infant"}
                        heading={kidsType[1]}
                        isMandatory={i === 0 ? true : false}
                        title={a.title}
                        index={i}
                        formik={formik}
                        bookingDate={getBookingDate()}
                        onBlur={(key, e) => {
                          formik.handleBlur(e);
                        }}
                        onChange={(key, val) => {
                          formik.setFieldValue(`infants.${i}.${key}`, val);
                        }}
                      />
                    ))}
                </div>
                {/* primary and secondary contact component  */}
                <div className="mt-4">
                  <ContactDetails
                    key={0}
                    isMandatory={true}
                    type={"primary"}
                    heading={heading[0]}
                    title={formik.values.primaryContactDetails.title}
                    formik={formik}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    onChange={(key, val) => {
                      formik.setFieldValue(`primaryContactDetails.${key}`, val);
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />

                  <ContactDetails
                    key={1}
                    isMandatory={false}
                    heading={heading[1]}
                    title={formik.values.secondaryContactDetails.title}
                    type={"secondary"}
                    formik={formik}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    onChange={(key, val) => {
                      formik.setFieldValue(
                        `secondaryContactDetails.${key}`,
                        val
                      );
                    }}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />
                </div>
                {(productid === PRODUCTID_ARRIVAL ||
                  productid === PRODUCTID_ARRIVALBUNDLE) &&
                  greetingDetail?.map((a, i) => (
                    <GreetSign
                      key={i}
                      selectedOccasion={selectedOccasion}
                      formik={formik}
                      index={i}
                      onChange={(key, val) => {
                        formik.setFieldValue(
                          `greetingDetails.${i}.${key}`,
                          val
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                  ))}
                <Row className="mt-4 ">
                  <Col
                    md={12}
                    sm={12}
                    className="d-flex justify-content-around align-content-center"
                  >
                    <CommonButton
                      type="submit"
                      classname=" gradient-btn mb-2 mx-1"
                      title={t("registration:submitDetails")}
                      // onsubmit={handleSubmit}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default ManualBookingForm;
