import Card from "react-bootstrap/Card";
import PriceModal from "./PriceModal";
import PriorityPassPriceModal from "./PriorityPassPriceModal";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import CommonButton from "./CommonButton";
import useToggle from "../../hooks/useToggle";
import { BASE_URL, CUSTOMER_LOGIN, DIGICELBLACKCARD_LOGIN, LOGIN_TYPE, PRIORITYPASS_LOGIN, PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE, PRODUCTID_DIGICELBLACKCARD, PRODUCTID_PRIORITYPASS, ROUTENAME_ARRIVAL, ROUTENAME_ARRIVALBUNDLE, ROUTENAME_DEPARTURE, ROUTENAME_DIGICELBLACKCARD, ROUTENAME_PRIORITYPASS } from "../../config/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import { cartData as CD, clearCart } from "../../redux/cartRedux";
import { useEffect, useState } from "react";
import { autoLogin, isCurrentCartContainOtherThanRegularProduct, checkLoggedInUserIsPriorityPassOrDigicelBlackCard, getLoggedInUsername, isCurrentCartContainGiftItem } from "./commonFunctions";
import { loggedInUserDetails as LOGGED_USER } from "../../redux/loggedInUserDetailRedux";
import CryptoJS from 'crypto-js';
import { paxCountBookingDetails as PCD, paxCountClear } from "../../redux/increasePaxCountRedux";
import { clearVIPERCart } from "../../redux/apiCalls";
import {logindetails as LD} from "../../redux/signInRedux"
import Loader from "./Loader";

function ProductCard(props) {
  const [isVisiblePriceModal, setIsVisiblePriceModal] = useToggle();
  const [isVisiblePriorityPriceModal, setIsVisiblePriorityPriceModal] = useToggle();
  const [isNotRegularProduct, setIsNotRegularProduct] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const paxCountBookingDetails = useSelector(PCD);
  const sendBroadcastMessage = useOutletContext()

  const navigate = useNavigate();
  const loggedInUserDetails = useSelector(LOGGED_USER);
  let OldLoggedInUserDetails = "";
  let loginType = "";
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const [isLoading, setIsLoading] = useState(false);

  if (loggedInUserDetails) {
    OldLoggedInUserDetails = JSON.parse(CryptoJS.AES.decrypt(loggedInUserDetails, SECRET_KEY)?.toString(CryptoJS.enc.Utf8));
    loginType = (OldLoggedInUserDetails.username === CUSTOMER_LOGIN.username || OldLoggedInUserDetails.username === PRIORITYPASS_LOGIN.username || OldLoggedInUserDetails.username === DIGICELBLACKCARD_LOGIN.username) ? LOGIN_TYPE.regular : "";
  }

  const dispatch = useDispatch();
  const cartData = useSelector(CD);
  const loginDetail = useSelector(LD);

  const disableButtonHandler = () => {
    if (cartData?.length !== 0) {
      if (checkLoggedInUserIsPriorityPassOrDigicelBlackCard() && loginDetail?.username === PRIORITYPASS_LOGIN.username) {
        if (props.productid === PRODUCTID_PRIORITYPASS) {
          return false;
        }
        else
          return true;
      }
      else if (checkLoggedInUserIsPriorityPassOrDigicelBlackCard() && loginDetail?.username === DIGICELBLACKCARD_LOGIN.username) {
        if (props.productid === PRODUCTID_DIGICELBLACKCARD)
          return false;
        else
          return true;
      }
      else if(paxCountBookingDetails && Object.values(paxCountBookingDetails).length !== 0){
        return true
      }
      else {
        if (props.productid === PRODUCTID_ARRIVALBUNDLE)
          return false;
        else if (props.productid === PRODUCTID_ARRIVAL) {
          return false;
        }
        else if (props.productid === PRODUCTID_DEPARTURE) {
          return false;
        }
        else
          return true;
      }
    }
    else {
      return false;
    }
  }



  useEffect(() => {
    setIsNotRegularProduct(isCurrentCartContainOtherThanRegularProduct(cartData, getLoggedInUsername() === PRIORITYPASS_LOGIN.username ? LOGIN_TYPE.priority_pass : LOGIN_TYPE.digicel_black_card));
  }, [])


  useEffect(()=>{
    setIsDisabled(disableButtonHandler());
  },[cartData])

  const showPriceModal = () => {
    if (props.productid === PRODUCTID_PRIORITYPASS || props.productid === PRODUCTID_DIGICELBLACKCARD) {
      setIsVisiblePriorityPriceModal(true);
    } else {
      setIsVisiblePriceModal(true);
    }
  };



  const loginService = async () => {
    let response = "";
    props.productid === PRODUCTID_PRIORITYPASS || props.productid === PRODUCTID_DIGICELBLACKCARD ?
      await autoLogin(dispatch, props.productid === PRODUCTID_PRIORITYPASS ? LOGIN_TYPE.priority_pass : LOGIN_TYPE.digicel_black_card,sendBroadcastMessage).then((res) => {
        if (res.status === 0) {
          response = res;
        }
        else {
          navigate("/");
        }
      })
        .catch((e) => console.log(e))
        .finally(() => {
        })
      :
      await autoLogin(dispatch, loginType,sendBroadcastMessage).then((res) => {
        if (res.status === 0) {
          response = res;
        }
        else {
          navigate("/");
        }
      })
        .catch((e) => console.log(e?.message))
        .finally(() => {
        });
    return response;
  }

  const generateURLForRedirect = (cartData) => {
    // return (
    //   props.productid === PRODUCTID_ARRIVALBUNDLE ?
    //     (isNotRegularProduct ? "" : ROUTENAME_ARRIVALBUNDLE)
    //     :
    //     props.productid === PRODUCTID_ARRIVAL ?
    //       (isNotRegularProduct ? "" : ROUTENAME_ARRIVAL)
    //       :
    //       props.productid === PRODUCTID_PRIORITYPASS || props.productid === PRODUCTID_DIGICELBLACKCARD ?
    //         (isNotRegularProduct ?
    //           (props.productid === PRODUCTID_PRIORITYPASS ? ROUTENAME_PRIORITYPASS : ROUTENAME_DIGICELBLACKCARD)
    //           :
    //           (cartData.length === 0 || checkLoggedInUserIsPriorityPassOrDigicelBlackCard()
    //             ? (props.productid === PRODUCTID_PRIORITYPASS ? ROUTENAME_PRIORITYPASS : ROUTENAME_DIGICELBLACKCARD) : ""))
    //         : isNotRegularProduct ? "" : ROUTENAME_DEPARTURE);


    if (props.productid === PRODUCTID_ARRIVALBUNDLE) {
      if (isNotRegularProduct) {
        return "";
      } else {
        return BASE_URL + ROUTENAME_ARRIVALBUNDLE;
      }
    }
    else {
      if (props.productid === PRODUCTID_ARRIVAL) {
        if (isNotRegularProduct) {
          return "";
        } else {
          return BASE_URL + ROUTENAME_ARRIVAL;
        }
      } else {
        if (props.productid === PRODUCTID_PRIORITYPASS || props.productid === PRODUCTID_DIGICELBLACKCARD) {
          if (isNotRegularProduct) {
            if (props.productid === PRODUCTID_PRIORITYPASS) {
              return BASE_URL + ROUTENAME_PRIORITYPASS;
            } else {
              return BASE_URL + ROUTENAME_DIGICELBLACKCARD;
            }
          } else {
            if (cartData.length === 0 || checkLoggedInUserIsPriorityPassOrDigicelBlackCard()) {
              if (props.productid === PRODUCTID_PRIORITYPASS) {
                return BASE_URL + ROUTENAME_PRIORITYPASS;
              } else {
                return BASE_URL + ROUTENAME_DIGICELBLACKCARD;
              }
            } else {
              return "";
            }
          }
        } else {
          if (isNotRegularProduct) {
            return "";
          } else {
            return BASE_URL + ROUTENAME_DEPARTURE;
          }
        }
      }
    }
  };

  const productClickHandler = async () => {
    setIsLoading(true);
    if (paxCountBookingDetails && Object.values(paxCountBookingDetails).length !== 0) {
      // this is temp fix done , needs to update again 
      dispatch(paxCountClear());
      dispatch(clearCart());
      loginDetail?.sessionid && await clearVIPERCart();
    }
    // cartData.length === 0 && await loginService();
    const response = await loginService("","",sendBroadcastMessage);
    if (loggedInUserDetails) {
      if (response?.data?.distributorprofile?.distributorbookingallowed === "Y") {
        navigate("/distributor-booking-user", { replace: true });
      }
      else {
        navigate(generateURLForRedirect(cartData), { state: { cartItemId: 0, productid: props.productid } });
      }
    }
    else {
      navigate(generateURLForRedirect(cartData), { state: { cartItemId: 0, productid: props.productid } });
    }
    setIsLoading(false);
  };
  const handleChange = async () =>{
    if(!props.title){
      if(isCurrentCartContainGiftItem() || !isDisabled){
        await productClickHandler()
      }
    }
  } 

  return (
    <>
      <Loader loading={isLoading} />
      <PriceModal
        visible={isVisiblePriceModal}
        cancelModal={() => setIsVisiblePriceModal(false)}
        productid={props.productid}
      ></PriceModal>
      <PriorityPassPriceModal
        visible={isVisiblePriorityPriceModal}
        cancelModal={() => setIsVisiblePriorityPriceModal(false)}
        productid={props.productid === PRODUCTID_PRIORITYPASS || props.productid === PRODUCTID_DIGICELBLACKCARD ? PRODUCTID_PRIORITYPASS : ""}
      ></PriorityPassPriceModal>

      <Card className="cardBackground">
        {props.title &&
          <CommonButton
            type="submit"
            classname="my-2 mx-2 gradient-btn"
            size="md"
            title={props.title}
            onclick={async () => { await productClickHandler() }}
            disabled={isCurrentCartContainGiftItem() ? true : isDisabled}

          />
        }
        {/* </Link> */}

        <Card.Img
          className={`img-responsive bottom-block ${!props?.title && 'rounded-top'}`}
          variant="middle"
          alt={props.productid}
          src={props.image}
          onClick= {async () => { await handleChange() }} 
          style={props.title ? {cursor: ""} : {cursor: "pointer"}}
        />

        {props.desc &&
          <Card.Body>
            {props.desc && <Card.Text className="mb-0">{props.desc}</Card.Text>}
          </Card.Body>
        }
        {props.viewPrice && (
          <Link
            to="#"
            className="btn p-0 no-btn-border"
            onClick={showPriceModal}
          >
            <Card.Footer className="align-items-center text-center p-3">
              {props.viewPrice}
              {props.loungeDesc && props.loungeDesc}
            </Card.Footer>
          </Link>
        )}
      </Card>
    </>
  );
}

export default ProductCard;
