import { AxiosError } from "axios"
import { appAxios } from "./config/axios-config"
import { getLoginDetails, log } from "./components/commonComponents/commonFunctions";
import { showInfo } from "./components/commonComponents/Swal";
import { store } from "./store/store";

export const _post = async (url, data, headers = null) => {
  const endpoint = url.split('/').filter(Boolean).pop();
  const state = store.getState();
  const sessionid = getLoginDetails(state).sessionid;
  const endpoints = ["resetpassword", "addcommentcard", "getaffiliates", "getmarkets", "getpaymentgateway", "login", "initaffiliatelog", "linkbooking", "altlogin"];
  if(sessionid || endpoints.includes(endpoint) ){
    try {
      let response;
      console.log("request for ", url, data);
      log(endpoint,data,"request", null);
      if (headers) {
        response = await appAxios.post(`${url}`, data, { headers })
      } else {
        response = await appAxios.post(`${url}`, data)
      }
      console.log("response for ", url, response?.data);
      log(endpoint, response?.data,"response", null);
      return response?.data;

      // if (response.data.status === VIPER_Response_Co des.SUCCESS) {
      //   return response
      // } else {
      //   // throw new ResponseCodeError(response.data.responseCode)
      // }
    } catch (error) {
      log(endpoint, null,"error", error);
      throw HandleError(error)
    }
  }else{
    showInfo(endpoint,"No Sessionid Found")
    console.log("abh hua",endpoint)
  }
}

export const HandleError = (error) => {
  // if (error instanceof ResponseCodeError) throw error
  // if (error instanceof AxiosError) {
  //   switch (error?.response?.status) {
  //     case 400:
  //       return new BadRequestError(error.response.data)
  //     default:
  //       return new UnexpectedError(error.message)
  //   }
  // }
  return error;
}

