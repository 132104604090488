import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AppContext from "./components/commonComponents/AppContext";
import { init as initI18n } from "./i18n";
import router from "./config/routeConfig";
import { selectedMarket as currentMarket, availableMarkets as AM, selectedMarketLanguage } from "./redux/marketRedux";
import { useDispatch, useSelector } from "react-redux";
import { getMarkets, getMemberCardValues, getMemberCards, getPaymentGateway } from "./redux/apiCalls";
import { logout } from "./redux/signInRedux";
import { clearCart } from "./redux/cartRedux";
import { paymentGateway as PG } from "./redux/paymentGatewayRedux";
import { memberCardValues as MV, memberCards as MC } from "./redux/memberCardRedux";
import { authed as AU } from "./redux/signInRedux";
import { HandleAPIError, getLoginDetails } from "./components/commonComponents/commonFunctions";
import i18next from "i18next";
import { selectedMarketId as SM } from "./redux/marketRedux";
import { MIX_PANEL_PROJECT_TOKEN } from "./config/commonConstants";

import mixpanel from 'mixpanel-browser';

mixpanel.init(MIX_PANEL_PROJECT_TOKEN);

initI18n();
function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const selectedMarket = useSelector(currentMarket);
  const language = useSelector(selectedMarketLanguage);
  const availableMarkets = useSelector(AM);
  const paymentGateway = useSelector(PG);
  const selectedMarketId = useSelector(SM);
  let memberCardValues = useSelector(MV);
  let memberCards = useSelector(MC);
  let authed = useSelector(AU);

  var urlElement = window?.location?.pathname;

  const handleTabClosing = (event) => {
    event.preventDefault();
    dispatch(logout());
    dispatch(clearCart());
  }
  const alertUser = (event) => {
    event.preventDefault()
    event.returnValue = ''
  }

  // useEffect(() => {
  //   availableMarkets === "" && getMarkets(dispatch);
  // //  // window.addEventListener('unload', handleTabClosing);
  // //   return () => {
  // //     window.addEventListener('unload', handleTabClosing)
  // // }
  // useBeforeunload(() => handleTabClosing );
  // }, []);

  const getmemberCardValues = () => {
    let data = {};
    if (memberCardValues?.length === 0 || memberCards?.length === 0) {
      try {
        const loginDetail = getLoginDetails()
        if(loginDetail?.sessionid){
          getMemberCardValues(dispatch);
          getMemberCards(data, dispatch);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
      }
    }
  };

  useEffect(() => {
    !selectedMarketId && getMarkets(dispatch);
  }, [])

  useEffect(() => { 
    Object.values(paymentGateway)?.length === 0 && getPaymentGateway(dispatch);
    if (authed === true) {
      urlElement !== "/" && getmemberCardValues();
    }

    // window.addEventListener('unload', handleTabClosing);
    // return () => {

    //     window.removeEventListener('unload', handleTabClosing);
    // }
  }, [authed])

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language])

  const setUsernameAndPassword = (username, password) => {
    setUsername(username);
    setPassword(password);
  };

  const userSettings = {
    user_name: username,
    user_password: password,
    setUsernameAndPassword,
  };


  return (
    <div className="App">
      <AppContext.Provider value={userSettings}>
        <RouterProvider router={router}></RouterProvider>
      </AppContext.Provider>
    </div>
  );
}

export default App;