import { Card, Container, Row, Col, Form } from "react-bootstrap";
import { getBookingDetails, updateContactDetail } from "../redux/apiCalls";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { showError, showSuccess } from "../components/commonComponents/Swal";
import BookingSummaryComponent from "../components/commonComponents/BookingSummaryComponent";
import { useTranslation } from "react-i18next";
import Loader from "../components/commonComponents/Loader";
import { HandleAPIError, dateFormatForDisplay, divRef, myDateFormat, renderErrorMessages, scrollToDiv, secondaryContactDetailsSchemaToggle, translateArrayKeyValue } from "../components/commonComponents/commonFunctions";
import CommonButton from "../components/commonComponents/CommonButton";
import ContactDetails from "../components/commonComponents/ContactDetails";
import ExitButton from "../components/commonComponents/ExitButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { EMAIL_POLICY, PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE, SALUTATION } from "../config/commonConstants";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import ContactDetailsDisplay from "../components/commonComponents/ContactDetailsDisplay";
import { useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";

export default function UpdateContactDetails() {
  const { t } = useTranslation(["common", "mybookings"]);
  const [orderDetail, setOrderDetail] = useState([]);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [salutation] = useState(translateArrayKeyValue(SALUTATION, "SALUTATION"))
  const location = useLocation();
  const bookingDetails = location.state
  const [secondaryContactDetails, setSecondaryContactDetails] = useState(Yup.object().nullable());
  const [currentDate, setCurrentDate] = useState(Date());
  const [editable, setEditable] = useState(false);
  const selectedMarket = useSelector(currentMarket);

  const initial = {
    primaryContactDetails: {
      primaryContactDetailsheading: "",
      title: salutation[0],
      firstName: "",
      email: "",
      confirmEmail: "",
      phone: "",

    },
    secondaryContactDetails: {
      heading: "",
      title: salutation[0],
      firstName: "",
      email: "",
      confirmEmail: "",
      phone: "",

    },
  };

  const validationSchema = Yup.object().shape({
    primaryContactDetails:
      Yup.object().shape({
        firstName: Yup.string().required(t("mybookings:errors.updateContactFullName.required")),
        email: Yup.string()
          .required(t("mybookings:errors.primaryContactEmail.required"))
          .matches(EMAIL_POLICY, t("mybookings:errors.primaryContactEmail.match")),
        confirmEmail: Yup.string().required(t("mybookings:errors.primaryContactConfirmEmail.required"))
          .matches(EMAIL_POLICY, t("mybookings:errors.primaryContactConfirmEmail.match"))
          .test(
            'email-validate',
            t("mybookings:errors.primaryContactConfirmEmailEqual.equal"),
            confirmEmail => {
              let flag = confirmEmail === formik.values.primaryContactDetails.email ? true : false;
              if (!flag) {
                return false
              }
              return true
            }
          ),
        phone: Yup.string().required(t("mybookings:errors.primaryContactPhoneNumber.required")),
      }),
    secondaryContactDetails: secondaryContactDetails,
  });

  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  const fetchBookingDetails = async () => {
    try {
      setLoading(true);
      const data = {
        reservationid: bookingDetails.reservationid,
        productid: bookingDetails.productid,
        getschedule: "N",
      };
      const response = await getBookingDetails(data);
      if (response.status === 0) {
        let tmpOrderDetail = response.data.orderdetail;
        setOrderDetail(response.data.orderdetail);
        formik.setFieldValue(`primaryContactDetails.firstName`, response.data.primary.contactname)
        formik.setFieldValue(`primaryContactDetails.email`, response.data.primary.email)
        formik.setFieldValue(`primaryContactDetails.confirmEmail`, response.data.primary.email)
        formik.setFieldValue(`primaryContactDetails.phone`, response.data.primary.phone)
        formik.setFieldValue(`secondaryContactDetails.firstName`, response.data.secondary.contactname.trim())
        formik.setFieldValue(`secondaryContactDetails.email`, response.data.secondary.email.trim())
        formik.setFieldValue(`secondaryContactDetails.confirmEmail`, response.data.secondary.email.trim())
        formik.setFieldValue(`secondaryContactDetails.phone`, response.data.secondary.phone.trim())

        if (tmpOrderDetail.productid === PRODUCTID_ARRIVALBUNDLE) {
          tmpOrderDetail.arrivalbundles.forEach((arrivalbundle) => {
            setEditable((new Date(dateFormatForDisplay(currentDate, "MM/dd/yyyy")) < new Date(myDateFormat(arrivalbundle.arrivalrecord.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy"))) ? true : false);
         })
        } else if (tmpOrderDetail.productid === PRODUCTID_ARRIVAL) {
          tmpOrderDetail.arrivalrecords.forEach((arrivalrecord) => {
            setEditable((new Date(dateFormatForDisplay(currentDate, "MM/dd/yyyy")) < new Date(myDateFormat(arrivalrecord.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy"))) ? true : false);
          })
        }
        else if (tmpOrderDetail.productid === PRODUCTID_DEPARTURE) {
          tmpOrderDetail.departurerecords.forEach((departurerecord) => {
            setEditable((new Date(dateFormatForDisplay(currentDate, "MM/dd/yyyy")) < new Date(myDateFormat(departurerecord.flightdate, "yyyyMMdd HHmm", "MM/dd/yyyy"))) ? true : false);
          })
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookingDetails();
  }, []);

  useEffect(() => {
    secondaryContactDetailsSchemaToggle(setSecondaryContactDetails, formik?.values?.secondaryContactDetails, "UPDATE-CONTACT-DETAILS")
  }, [formik.values.secondaryContactDetails]);

  const updateContactDetails = async () => {
    try {
      setLoading(true)
      const request = {
        "productid": bookingDetails.productid,
        "confirmationnumber": bookingDetails.reservationid,
        "primarycontact": {
          "contactname": formik.values.primaryContactDetails.firstName,
          "email": formik.values.primaryContactDetails.email,
          "phone": formik.values.primaryContactDetails.phone
        },
        "secondarycontact": {
          "contactname": formik.values.secondaryContactDetails.firstName,
          "email": formik.values.secondaryContactDetails.email,
          "phone": formik.values.secondaryContactDetails.phone
        }
      }
      const response = await updateContactDetail(request)
      if (response?.status === 0) {
        await showSuccess(t("success"), t("mybookings:successMessage"))
        navigate("/my-bookings")
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    await updateContactDetails();
  }

  return (
    <section>
      <Loader loading={loading} />
      <Container className="my-4" ref={divRef}>
        <div className="my-3">
          <h3 className="d-flex justify-content-center heading heading">
            <span className="heading">{t("mybookings:updateContactDetailsHeading")}</span>
          </h3>
        </div>
        <div className="my-3 px-1 d-flex justify-content-end">
          <Link to="/my-bookings" className=" mx-1 text-decoration-none">
            <CommonButton classname="back" title={t("back")} />
          </Link>
          <ExitButton />
        </div>
        {loading ? (
          <LoadingSkeleton />
        ) :
          (
            <>
              <div className="my-3">
                <Card className="">
                  <Card.Body>
                    <Row className="d-flex justify-content-start">
                      <Col md={4}>
                        <h6 className="field">{t('fillAllFields')}</h6>
                    </Col>
                   </Row>
                  <ErrorMessages formik={formik} />
                    <BookingSummaryComponent
                      renderType={"UPDATE-CONTACT-DETAILS"}
                      orderDetail={orderDetail}
                      productid={orderDetail.productid}
                    />
                  </Card.Body>
                </Card>
              </div>
                <div className="my-3">
                  <Card className="p-4">
                    <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                      {editable === true &&
                        <ContactDetails
                          renderType={"updateContactDetails"}
                          key={0}
                          isMandatory={true}
                          type={"primary"}
                          heading={t("primaryContact")}
                          formik={formik}
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          onChange={(key, val) => {
                            formik.setFieldValue(`primaryContactDetails.${key}`, val);
                          }}
                          onBlur={(key, e) => {
                            formik.handleBlur(e);
                          }}
                        />
                      }
                      {editable === true &&
                        <ContactDetails
                          renderType={"updateContactDetails"}
                          key={1}
                          isMandatory={false}
                          heading={t("secondaryContact")}
                          type={"secondary"}
                          formik={formik}
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          onChange={(key, val) => {
                            formik.setFieldValue(`secondaryContactDetails.${key}`, val);
                          }}
                          onBlur={(key, e) => {
                            formik.handleBlur(e);
                          }}
                        />
                      }                         
                      {editable === true &&
                        <Row className="d-flex justify-content-end">
                          <Col md={3} className="d-flex justify-content-end">
                            <CommonButton
                              classname="gradient-btn mx-1"
                              title={t('mybookings:updateDetails')}
                              // onclick={handleSubmit} // Corrected the prop name from 'onclick' to 'onClick'
                              type="submit" // Change the type from 'submit' to 'button' to prevent accidental form submission
                            />
                          </Col>
                        </Row>
                      }
                      {editable === false &&
                        <>
                          <ContactDetailsDisplay contactType="Primary" contactDetails={formik?.values?.primaryContactDetails} />
                          <ContactDetailsDisplay contactType="Secondary" contactDetails={formik?.values?.secondaryContactDetails} />
                          <Row>
                            <Col>
                              <h5 className="my-bg-info fs-5 lh-base">
                                <HtmlToReactConverter value={t("noteEditableMessageContact", { skype: selectedMarket?.contact?.skype, email: selectedMarket?.contact?.email })} />
                              </h5>
                            </Col>
                          </Row>
                        </>
                      }
                    </Form>
                  </Card>
                </div>
            </>
          )}
      </Container>
    </section>

  )
}