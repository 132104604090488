import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import {
  CONTACT_NUMBER_MAXLENGTH,
  EMAIL_MAXLENGTH,
  FIRST_NAME_MAXLENGTH,
  LAST_NAME_MAXLENGTH,
  SALUTATION,
  REGEX_NUMERIC,
  REGEX_ALPHABET
} from "../../config/commonConstants";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { translateArrayKeyValue } from "./commonFunctions";
import HtmlToReactConverter from "./HtmlToReactConverter";
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import { useSelector } from "react-redux";

function ContactDetails(props) {
  const selectedMarket = useSelector(currentMarket);
  const { t } = useTranslation(["common"]);
  return (
    <div>
      <Row className="my-3">
        <Form.Label className="d-flex">
          <FontAwesomeIcon
            icon={faAddressCard}
            size="lg"
            className="me-2 icon-color"
          />{" "}
          {props.heading}
          {props.isMandatory === true && <span className="field">*</span>}
        </Form.Label>
        {props.renderType !== "updateContactDetails" ?
          <Col
            md="auto"
            controlid="validationCustom01"
            className="position-relative mb-4"
          >
            <Select
              name={props.type === "primary" ? `primaryContactDetails.title` : `secondaryContactDetails.title`}
              options={translateArrayKeyValue(SALUTATION, "SALUTATION")}
              className="my-0 select_width"
              value={props.formik?.values?.[props.type === "primary" ? "primaryContactDetails" : "secondaryContactDetails"]?.title}
              onChange={(d) => props.onChange("title", d)}
            />
          </Col>
          : null}
        <Col md="2" controlid="validationCustom01" className="mb-4">
          <Form.Control
            type="text"
            placeholder={props.renderType === "updateContactDetails" ? t('fullName'):t('firstName')}
            maxLength={FIRST_NAME_MAXLENGTH}
            minLength={1}
            onBlur={(e) => props.onBlur("firstName", e)}
            name={props.type === "primary" ? `primaryContactDetails.firstName` : `secondaryContactDetails.firstName`}
            onChange={(e) => props.onChange("firstName", e.target.value)}
            value={props.formik?.values?.[props.type === "primary" ? "primaryContactDetails" : "secondaryContactDetails"]?.firstName?.replace(REGEX_ALPHABET,"")}
            className={props?.formik?.touched[`${props.type}ContactDetails`]?.["firstName"] && props?.formik?.errors[`${props.type}ContactDetails`]?.["firstName"] ? "error rounded-2" : ""}
          />
        </Col>
        {props.renderType !== "updateContactDetails" ?
          <Col md="2" controlid="validationCustom02" className="mb-4">
            <Form.Control
              type="text"
              placeholder={t('lastName')}
              maxLength={LAST_NAME_MAXLENGTH}
              minLength={1}
              onBlur={(e) => props.onBlur("lastName", e)}
              name={props.type === "primary" ? `primaryContactDetails.lastName` : `secondaryContactDetails.lastName`}
              onChange={(e) => props.onChange("lastName", e.target.value)}
              value={props.formik?.values?.[props.type === "primary" ? "primaryContactDetails" : "secondaryContactDetails"]?.lastName?.replace(REGEX_ALPHABET,"")}
              className={props?.formik?.touched[`${props.type}ContactDetails`]?.["lastName"] && props?.formik?.errors[`${props.type}ContactDetails`]?.["lastName"] ? "error rounded-2" : ""}
            />
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please Enter Last Name.
          </Form.Control.Feedback> */}
          </Col>
          : null}

        <Col
          md="3"
          controlid="validationCustomUsername"
          className="position-relative mb-4"
        >
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              type="email"
              placeholder={t('emailAddress')}
              aria-describedby="inputGroupPrepend"
              maxLength={EMAIL_MAXLENGTH}
              minLength={1}
              onBlur={(e) => props.onBlur("email", e)}
              name={props.type === "primary" ? `primaryContactDetails.email` : `secondaryContactDetails.email`}
              onChange={(e) => props.onChange("email", e.target.value)}
              value={props.formik?.values?.[props.type === "primary" ? "primaryContactDetails" : "secondaryContactDetails"]?.email}
              className={props?.formik?.touched[`${props.type}ContactDetails`]?.["email"] && props?.formik?.errors[`${props.type}ContactDetails`]?.["email"] ? "error rounded-2" : ""}
            />
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please choose a Email Address.
            </Form.Control.Feedback> */}
          </InputGroup>
        </Col>
        <Col
          md="3"
          controlid="validationCustomUsername"
          className="position-relative mb-4"
        >
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              type="email"
              placeholder={t('confirmEmailAddress')}
              aria-describedby="inputGroupPrepend"
              maxLength={EMAIL_MAXLENGTH}
              minLength={1}
              onBlur={(e) => props.onBlur("confirmEmail", e)}
              name={props.type === "primary" ? `primaryContactDetails.confirmEmail` : `secondaryContactDetails.confirmEmail`}
              onChange={(e) => props.onChange("confirmEmail", e.target.value)}
              value={props.formik?.values?.[props.type === "primary" ? "primaryContactDetails" : "secondaryContactDetails"]?.confirmEmail}
              className={props?.formik?.touched[`${props.type}ContactDetails`]?.["confirmEmail"] && props?.formik?.errors[`${props.type}ContactDetails`]?.["confirmEmail"] ? "error rounded-2" : ""}
            />
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please choose a Email Address.
            </Form.Control.Feedback> */}
          </InputGroup>
        </Col>
        <Col md="2" controlid="validationCustom02">
          <Form.Control
            type="text"
            placeholder={t('contactNumber')}
            maxLength={CONTACT_NUMBER_MAXLENGTH}
            isInvalid={props?.errors && props?.errors["phone"]}
            onBlur={(e) => props.onBlur("phone", e)}
            name={props.type === "primary" ? `primaryContactDetails.phone` : `secondaryContactDetails.phone`}
            onChange={(e) => {
              e.target.value = e.target.value?.replace(REGEX_NUMERIC, "");
              props.onChange("phone", e.target.value);
            }}
            value={props.formik?.values?.[props.type === "primary" ? "primaryContactDetails" : "secondaryContactDetails"]?.phone}
            className={props?.formik?.touched[`${props.type}ContactDetails`]?.["phone"] && props?.formik?.errors[`${props.type}ContactDetails`]?.["phone"] ? "error rounded-2" : ""}
          />
          {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please Enter Valid Mobile Number.
          </Form.Control.Feedback> */}
        </Col>
      </Row>
    </div>
  );
}

export default ContactDetails;
