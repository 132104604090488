import { Col, Form, InputGroup, Row } from "react-bootstrap";
import {
  NAME_LENGTH, PRIORITY_PASS_CARD_NUMBER_MAXLENGTH, REGEX_ALPHABET,REGEX_NUMERIC
} from "../../config/commonConstants";
import { getMonthDropdownList, getCCExpiryYearDropdownList } from "./commonFunctions";
import { useTranslation } from "react-i18next";

function PriorityPassCard(props) {
  const { t } = useTranslation(["common", "payment"]);

  return (
    <div>
      {props.ispriorityPass && <h5 className="py-1">{t('payment:priorityCardHeading')}</h5>}

      <Row className="g-3">
        {props.ispriorityPass &&
          <Col md="5">
            <Form.Label className="d-flex" htmlFor="validationCustomCardType">
              {t("pleaseSelectCardType")}<span className="field">*</span>
            </Form.Label>
            <Form.Select aria-label="Default select example"
              id="validationCustomCardType"

              name={`priorityPassDetails.cardType`}
              value={props.formik?.values?.priorityPassDetails?.cardType}

              onChange={(e) => props.onChange("cardType", e.target.value)}
              className="w-50">
              <option value="Priority Pass" selected>{t('payment:prioritypass')}</option>
              <option value="Diners Club">{t('payment:dinerclub')}</option>
            </Form.Select>
          </Col>
        }
        <Col
          md="4"
        >
          <Form.Label className="d-flex" htmlFor="validationCustomCardUserName">
            {t('payment:nameTitle')}<span className="field">*</span>
          </Form.Label>
          <Form.Control
            id="validationCustomCardUserName"
            type="text"
            placeholder={t('cardHolderName')}
            maxLength={NAME_LENGTH}
            minLength={1}
            onBlur={(e) => props.onBlur("name", e)}
            name={`priorityPassDetails.name`}
            onChange={(e) => {
              e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
              props.onChange("name", e.target.value)
            }
          }
            value={props.formik?.values?.priorityPassDetails?.name}
            className={props?.formik?.touched["priorityPassDetails"]?.["name"] && props?.formik?.errors["priorityPassDetails"]?.["name"] ? "error rounded-2" : ""}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Col >
      </Row>

      <Row className="my-3">
        <Col md="5">
          <Form.Label className="d-flex" htmlFor="validationCustomCardNumber">
            {t('payment:cardNumberTitle')} <span className="field">*</span>
          </Form.Label>
          <Form.Control
            id="validationCustomCardNumber"
            type="text"
            placeholder={t('priorityPassCardNumber')}
            minLength={PRIORITY_PASS_CARD_NUMBER_MAXLENGTH}
            maxLength={PRIORITY_PASS_CARD_NUMBER_MAXLENGTH}
            onBlur={(e) => props.onBlur("cardNumber", e)}
            name={`priorityPassDetails.cardNumber`}
            onChange={(e) => {
                e.target.value = e.target.value?.replace(REGEX_NUMERIC, "");
                props.onChange("cardNumber", e.target.value)
              }
            }
            value={props.formik?.values?.priorityPassDetails?.cardNumber}
            className={props?.formik?.touched["priorityPassDetails"]?.["cardNumber"] && props?.formik?.errors["priorityPassDetails"]?.["cardNumber"] ? "error rounded-2" : ""}
          // value={cardNumber}
          //  onChange={cardnumberChange}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

        </Col >
        <Col
          md="3"
        >
          <Form.Label className="d-flex" htmlFor="validationCustomCardMonthAndYear">
            {t('payment:expirtDatePriorityPass')} <span className="field">*</span>
          </Form.Label>
          <InputGroup className="my-group-input">
            <Form.Select aria-label="Default select example"
              id="validationCustomCardMonthAndYear"
              name={`priorityPassDetails.cardMonth`}
              onBlur={(e) => props.onBlur("cardMonth", e)}
              value={props.formik?.values?.priorityPassDetails?.cardMonth}
              onChange={(e) => props.onChange("cardMonth", e.target.value)}
              className={props?.formik?.touched["priorityPassDetails"]?.["cardMonth"] && props?.formik?.errors["priorityPassDetails"]?.["cardMonth"] ? "error rounded-2 mx-1" : ""}
            >
              {getMonthDropdownList()}
            </Form.Select>

            <Form.Select aria-label="Default select example"
              id="validationCustomCardMonthAndYeardsd"
              onChange={(e) => props.onChange("cardYear", e.target.value)}
              onBlur={(e) => props.onBlur("cardYear", e)}
              name={`priorityPassDetails.cardYear`}
              value={props.formik?.values?.priorityPassDetails?.cardYear}
              className={props?.formik?.touched["priorityPassDetails"]?.["cardYear"] && props?.formik?.errors["priorityPassDetails"]?.["cardMonth"] ? "error rounded-2" : ""}

            >
              {getCCExpiryYearDropdownList()}
            </Form.Select>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {t('enterCardExpiryMonthYear')}
            </Form.Control.Feedback>
          </InputGroup>
        </Col >
      </Row>
    </div>
  );
}

export default PriorityPassCard;
