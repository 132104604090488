/* eslint-disable no-fallthrough */
import { faCalendarDays, faClock, faHotel, faPlaneDeparture, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Loader from './Loader';
import Select from "react-select";
import range from "lodash/range";
import { FLIGHT_YEARS } from "../../config/commonConstants";
import {
  PRODUCTID_ARRIVAL,
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_DEPARTURE,
  PRODUCTID_DIGICELBLACKCARD,
  PRODUCTID_PRIORITYPASS,
  TRAVEL_CHECK_MONTHS,
} from "../../config/commonConstants";
import DatepickerComponent from "./Date-Picker";
import { t } from "i18next";
import { HandleAPIError, dateDifferenceInMonth, dateFormatForDisplay, getAirlineNames, getFlightNumbers, getLoginDetails, isObjectEmpty, myDateFormat } from "./commonFunctions";
import { getSchedule } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { showConfirm, showError } from "./Swal";
import HtmlToReactConverter from "./HtmlToReactConverter";
import { useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import { logindetails as SLD } from "../../redux/standByRedux";
import { logindetails as LD } from "../../redux/signInRedux";
import { getYear } from "date-fns";

function FlightDetailsComponent(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [flightDate, setFlightDate] = useState("");
  const [lounge, setLounge] = useState({});
  const [clubType, setClubType] = useState({});
  const [flightDetails, setFlightDetails] = useState([]);
  const [airlineName, setAirlineName] = useState("");
  const [airlineId, setAirlineId] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [flightTime, setFlightTime] = useState("");
  const [scheduleId, setScheduleId] = useState(null);
  const [flightNames, setFlightNames] = useState();
  const [flightNumbers, setFlightNumbers] = useState();
  const selectedMarket = useSelector(currentMarket);
  const standByLoginDetails = useSelector(SLD);
  const loginDetails = useSelector(LD)

  const getDirectionTranslationKey = () => {
    let translationKey = "departure";
    if (props.productid === PRODUCTID_ARRIVALBUNDLE) {
      if (props.index === 0) {
        translationKey = "arrival";
      } else {
        translationKey = "departure";
      }
    } else if (props.productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else { // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }

    return translationKey;
  }

  const validateFlightMonth = async (val) => {
    const currentDate = Date();
    const months = dateDifferenceInMonth(currentDate, val);
    let flightDateMinimum = TRAVEL_CHECK_MONTHS;
    if (months >= flightDateMinimum) {
      const confirmed = await showConfirm(t('confirm'), t('travelCheckMonthMessage', { travelCheckMonth: flightDateMinimum }))
      if (confirmed.isConfirmed) {
        setFlightDate(val);
        props.onChange("flightDate", val)
      }
      else {
        props.onChange("flightDate", null)
      }
    } else {
      setFlightDate(val);
      props.onChange("flightDate", val)
    }
  }


  var productid;
  const getDirection = (i, productid) => {
    let Direction = "D";
    if (productid === PRODUCTID_ARRIVAL) {
      Direction = "A";
    } else if (productid === PRODUCTID_ARRIVALBUNDLE) {
      i === 0 ? (Direction = "A") : (Direction = "D");
    }
    return Direction;
  };

  const fetchSchedule = async () => {
    if (flightDate === "") return;
    productid = props.productid;
    if (props.productid === PRODUCTID_PRIORITYPASS || props.productid === PRODUCTID_DIGICELBLACKCARD) {
      productid = PRODUCTID_DEPARTURE;
    }
    let Direction = getDirection(props.index, productid);
    const getScheduleRequestData = {
      direction: Direction,
      airportid: lounge?.value ? lounge?.value : lounge,
      traveldate: dateFormatForDisplay(flightDate, "yyyyMMdd"),
    };
    // const event = dateFormatForDisplay(val, "MM/dd/yyyy");
    // a["flightDate"] = event;
    if (isObjectEmpty(lounge)) {
      showError(t("error"), t('selectYourLounge'));
    } else {
      try {
        setLoading(true);
        const response = await getSchedule(dispatch, getScheduleRequestData)
        if (response?.status === 0) {
          setFlightDetails(response?.data);
          setFlightNames(getAirlineNames(response?.data?.airlines))
          props.setFlightDetailsForEdit(response?.data);
        } else {
          showError(t("error"), response?.statusMessage);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  }

  function clearData(location) {
    // eslint-disable-next-line default-case
    switch (location) {
      case "lounge": props.formik.setFieldValue(`bookingDetail.${props.index}.airlineName`, "");
        props.formik.setFieldValue(`bookingDetail.${props.index}.flightNumber`, "");
        props.formik.setFieldValue(`bookingDetail.${props.index}.airlineId`, "");
        props.formik.setFieldValue(`bookingDetail.${props.index}.flightTime_hour`, "");
        setScheduleId("");//new Date()
      //  case "flightDate": setAirlineName("");
      case "flightDate": 
        props.formik.setFieldValue(`bookingDetail.${props.index}.airlineName`, "");
        setFlightNumbers([]);
      // case "airlineName": setFlightNumber("");
      //   setAirlineId("");
      //   setFlightTime("");
      //   setScheduleId("");
      case "airlineName": props.formik.setFieldValue(`bookingDetail.${props.index}.flightNumber`, "");
        props.formik.setFieldValue(`bookingDetail.${props.index}.airlineId`, "");
        props.formik.setFieldValue(`bookingDetail.${props.index}.flightTime_hour`, "");
        setScheduleId("");
    }
  }
  const [standByDep,setStandByDep] = useState(false);
  useEffect(()=>{
    if(!isObjectEmpty(standByLoginDetails)){
      setStandByDep(!standByDep);
    }
  },[standByLoginDetails])

  useEffect(() => {
    getLoginDetails()?.sessionid && fetchSchedule();
  },[flightDate, lounge , loginDetails, standByDep]); //scheduleId, flightTime, props.scheduleId

  useEffect(() => {
    props.scheduleId(scheduleId, props?.index, props?.productid);
  }, [scheduleId]);

  useEffect(() => {
    setClubType(props?.clubType)
    props?.lounge && setLounge(props.lounge)
    props?.flightDate && setFlightDate(props.flightDate)
    props?.flightNames && setFlightNames(props.flightNames)
    props?.airlineId && setAirlineId(props.airlineId)
    props?.flightNumbers && setFlightNumbers(props.flightNumbers)
    props?.airlineName && setAirlineName(props.airlineName)
    props?.flightNumber && setFlightNumber(props.flightNumber)
    props?.flightTime_hour && setFlightTime(props.flightTime_hour)

  }, [clubType]);

  return (
    <div>
      <Loader loading={loading} message={t('loaderMessage')} />
      {props?.editable === false ?
        (
          <Row>
            <Col>
              <h5 className="my-bg-info fs-5 lh-base">
                <HtmlToReactConverter value={t("noteEditableMessage", { skype: selectedMarket?.contact?.skype, email: selectedMarket?.contact?.email })} />
              </h5>
            </Col>
          </Row>
        ) : (
          <Row className="my-3">
            <h4 className="my-3">{t('enterBookingInformation', { direction: t(getDirectionTranslationKey()) })} </h4>
            {props.isLoungeDisplay &&
              <div>
                <Form.Label className="d-flex">
                  <FontAwesomeIcon
                    icon={faHotel}
                    size="lg"
                    className="me-2 icon-color"
                  />
                  {t('selectLounge')} <span className="field">*</span>
                </Form.Label>
                <Col md={5} sm={12}>
                  <Form.Group className="">
                    <Select
                      onChange={(e) => {
                        clearData("lounge");
                        props.onChange("lounge", e);
                        setLounge(e);
                      }}
                      options={clubType}
                      name={`bookingDetail.${props.index}.lounge`}
                      onBlur={(e) => props.onBlur("lounge", e)}
                      value={props.formik?.values?.bookingDetail?.[props.index]?.lounge}
                      className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["lounge"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["lounge"] ? "error rounded-2" : ""}

                    />
                  </Form.Group>
                </Col>
              </div>
            }
          </Row>)}
      {props?.editable === false ? null : (
        <Row>
          {props.isLoungeDisplay && <div>
            <h6 className="my-bg-info">
              {t('journeydetails:bookingInformationMessage')}
            </h6>
          </div>}
          {props.productid === PRODUCTID_ARRIVAL && (
            <div>
              <h4 className="">{t('journeydetails:arrivalDetails')}</h4>
              <h6 className="my-bg-info">
                {t('journeydetails:importantNotice')}
              </h6>
            </div>
          )}
          {props.productid === PRODUCTID_DEPARTURE && (
            <h4 className="">{t('journeydetails:departureDetails')}</h4>
          )}
          {props.productid === PRODUCTID_ARRIVALBUNDLE && (
            <h4 className="">{props?.heading}</h4>
          )}

          <Col md={"auto"} sm={12}>
            <Form.Group className="my-2 my-md-1">
              <Form.Label className="d-flex mb-1">
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  size="lg"
                  className="my-0 me-1 icon-color"
                />
                {t('flightDate')}
                <span className="field">*</span>
              </Form.Label>
              <DatepickerComponent
                onchange={async (val) => {
                  await validateFlightMonth(val);
                  clearData("flightDate");
                  // props.onChange("flightDate", val);
                }
                } //props.onChange("flightDate", e);
                // onChangeProps={true}

                variableName="flightDate"
                minDate={new Date()}
                range={range(getYear(new Date()), getYear(new Date()) + FLIGHT_YEARS, 1)}

                name={`bookingDetail.${props.index}.flightDate`}

                onBlur={(e) => props.onBlur("flightDate", e)}
                value={props.formik?.values?.bookingDetail?.[props.index]?.flightDate}
                className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["flightDate"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["flightDate"] ? "error rounded-2" : ""}
              />
            </Form.Group>
          </Col>
          <Col md={4} sm={12}>
            <Form.Group className="my-2 my-md-1">
              <Form.Label className="d-flex mb-1">
                <FontAwesomeIcon
                  icon={faPlaneDeparture}
                  size="lg"
                  className="me-1 icon-color"
                />
                {t('nameOfAirline')}
                <span className="field">*</span>
              </Form.Label>
              <Select
                onChange={async (e) => {
                  clearData("airlineName");
                  setAirlineName(e);
                  setAirlineId(e.value);
                  props.onChange("airlineId", e.value);
                  props.onChange("airlineName", e);
                }}
                options={flightNames}
                name={`bookingDetail.${props.index}.airlineName`}
              //  onBlur={(e) => props.onBlur("airlineName", e)}
                value={props.formik?.values?.bookingDetail?.[props.index]?.airlineName} lounge
                className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["airlineName"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["airlineName"] ? "error rounded-2" : ""}

              />
            </Form.Group>
          </Col>
          <Col md={"auto"} sm={12}>
            <Form.Group className="my-2 my-md-1">
              <Form.Label className="d-flex">
                <h6 className="mb-0">{t('airlineID')}</h6>
              </Form.Label>
              <Form.Control
                type="text"
                value={props.formik?.values?.bookingDetail?.[props.index]?.airlineId}
                className=""
                placeholder={t('airlineID')}
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={2} sm={12}>
            <Form.Group className="my-2 my-md-1">
              <Form.Label className="d-flex">
                <h6 className="mb-0">
                  {t('flightNumber')} <span className="field" >*</span>
                </h6>
              </Form.Label>
              <Select
                onChange={(e) => {
                  setFlightNumber(e);
                  let scheduleId = flightDetails?.flightschedule?.find(f => f.flightId === e.value)?.scheduleId;
                  setScheduleId(scheduleId);
                  let flightTime = flightDetails?.flights?.find(f => f.flightid === e.value)?.flighttime;
                  setFlightTime(myDateFormat(flightTime, "yyyy-MM-dd hh:mm a", "hh:mm a"));
                  props.onChange("flightTime_hour", myDateFormat(flightTime, "yyyy-MM-dd hh:mm a", "hh:mm a"))
                  props.onChange("flightNumber", e)
                }}
                onBlur={(e) => props.onBlur("flightNumber", e)}
                options={getFlightNumbers(flightDetails?.flights?.filter(f => f.airlineid === airlineId))}
                //  onChange={(d) => props.onChange("flightNumber", d)}

                name={`bookingDetail.${props.index}.flightNumber`}
                // onBlur={(e) => props.onBlur("flightNumber", e)}
                value={props.formik?.values?.bookingDetail?.[props.index]?.flightNumber} lounge
                className={props?.formik?.touched["bookingDetail"]?.[props.index]?.["flightNumber"] && props?.formik?.errors["bookingDetail"]?.[props.index]?.["flightNumber"] ? "error rounded-2" : ""}

              />
            </Form.Group>
          </Col>
          <Col md={2} sm={12} className="d-flex flex-column">
            <Form.Group className="my-2 my-md-1">
              <Form.Label className="d-flex">
                <FontAwesomeIcon
                  icon={faClock}
                  size="lg"
                  className="mx-2 icon-color"
                />
                <h6 className="mb-0">{t('flightTime')} </h6>
              </Form.Label>
              <div className="d-flex flex-row justify-content-center">
                <Form.Control
                  type="text"
                  className="mx-1"
                  placeholder=""
                  disabled
                  onChange={(e) =>
                    props.onChange("flightTime_hour", e.target.value)
                  }
                  name={`bookingDetail.${props.index}.flightTime_hour`}
                  onBlur={(e) => props.onBlur("flightTime_hour", e)}
                  value={props.formik?.values?.bookingDetail?.[props.index]?.flightTime_hour}
                //  className={props?.formik?.touched["adults"]?.[props.index]?.["lastName"] && props?.formik?.errors["adults"]?.[props.index]?.["lastName"] ? "error rounded-2" : ""}

                />
              </div>
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default FlightDetailsComponent;
