import { store } from "../store/store";
import { showError } from "../components/commonComponents/Swal";
import { HandleAPIError, addlog, formatCreditCardExpiryFAC } from "../components/commonComponents/commonFunctions";
import { getOrderId, processCard } from "../redux/apiCalls";
import i18n from "i18next";

export const facProcess3DSPayment = async (HtmlData) => {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    const paymentGatewayDetail = state?.paymentGatewayRedux.paymentGateway;

    let redirecturl2 = paymentGatewayDetail?.redirecturl2;
    //  https://nigeria.reliablesoftjm.com/VIPERWS/powertranzcallback

    let width = 900;
    let height = 600;

    let left = window.outerWidth / 2 + window.screenX - ( width / 2)
    let top = window.outerHeight / 2 + window.screenY - ( height / 2)
    
    let paymentWindow = window.open("", "_blank", "width=" + width + ",height="+height + ", top=" + top + ", left=" + left);

    if (!paymentWindow) {
      resolve({
        success: false,
        statusMessage:
        i18n.t("browserPopingError"),
        data: {},
      });
    }

    paymentWindow.document.write(
      `<div style="margin: auto; width: 50%; border: 1px solid #EEE; padding: 10px; border-radius: 10px;right: 50%; bottom: 50%;transform: translate(50%,50%);position: absolute;"><p style="text-align: center;"><span style="font-weight: bold; font-size: 1.2em;">${i18n.t("waitMessage")} </span><br/>${i18n.t("paymentProcessingError")}</p>`
    );
    paymentWindow.document.write(HtmlData);

    let paymentInterval = setInterval(function () {
      if (paymentWindow.closed) {
        clearInterval(paymentInterval);

        resolve({
          success: false,
          statusMessage: i18n.t("windowClosedError"),
          data: {},
        });
      }
    }, 2000);
    
    window.addEventListener("message",function (ev) {
        let postData = ev.data;
        let responseData = {};
        if (postData.originUrl === redirecturl2) {
          if (postData.ResponseCode === 1) {
            clearInterval(paymentInterval);

            responseData = {
              responsecode: postData.ResponseCode,
              authorizationnumber: postData.AuthCode,
              originalresponsecode: postData.OriginalResponseCode,
              cardnumber: postData.PaddedCardNo,
              referencenumber: postData.ReferenceNo,
              reasoncode: postData.ReasonCode,
              reasoncodedescription: postData.ReasonCodeDesc,
            };
            paymentWindow.close();

            window.removeEventListener("message", function (ev) {});
            resolve({
              success: true,
              statusMessage: "Success",
              data: responseData,
            });
          } else {
            clearInterval(paymentInterval);
            window.removeEventListener("message", function (ev) {});

            paymentWindow.close();

            responseData = {
              responsecode: postData.ResponseCode,
              authorizationnumber: postData.AuthCode,
              originalresponsecode: postData.OriginalResponseCode,
              cardnumber: postData.PaddedCardNo,
              referencenumber: postData.ReferenceNo,
              reasoncode: postData.ReasonCode,
              reasoncodedescription: postData.ReasonCodeDesc,
            };
            
            resolve({
              success: false,
              statusMessage: responseData.reasoncodedescription,
              data: responseData,
            });
          }
        }
      },
      false
    );
  });
};

export const processCreditCardPayment = async (paymentData, addLogRequest) => {
  try {
    let data = {
      source: paymentData.source,
      amount: paymentData.amount,
    };
    const orderIdResponse = await getOrderId(data);
    
    if (!orderIdResponse || orderIdResponse.data.orderid === "" || orderIdResponse.data.orderid === null || orderIdResponse.status !== 0) {
      orderIdResponse.statusMessage && showError(i18n.t("error"), orderIdResponse.statusMessage);
    } else {
      let orderId = orderIdResponse?.data?.orderid;

      addlog(paymentData.source, orderId, addLogRequest);

      const processCardRequest = {
        orderid: orderId,
        actiontype: "CHARGECARD",
        creditcard: {
          cardtype: paymentData.cardholderDetails.cardType,
          cardnumber: paymentData.cardholderDetails.cardNumber,
          cardholder: paymentData.cardholderDetails.name,
          expirydate: formatCreditCardExpiryFAC(paymentData.cardholderDetails.cardMonth, paymentData.cardholderDetails.cardYear),
          cvv: paymentData?.cardholderDetails.cvv,
          amount: paymentData.amount,
        },
      };
      const processCardResponse = await processCard(processCardRequest);

      if (processCardResponse?.status === 0) {
        let facResponse;
        if (processCardResponse.data?.html) {
          facResponse = await facProcess3DSPayment(processCardResponse?.data.html);
        } else {
          facResponse = processCardResponse;
          facResponse.data.orderid = orderId;
        }
        if (facResponse.data?.authorizationnumber && facResponse.data.authorizationnumber !== "") {
          return facResponse;
        } else {
          await showError(i18n.t("error"), facResponse?.statusMessage);  
        }
      }
      else {
        await showError(i18n.t("error"), processCardResponse?.statusMessage);
        return processCardResponse;
      }
    }
  } catch (e) {
    HandleAPIError(e);
  }
}