import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  giftCartData: [],
  giftRedeemData: [],
  currentgiftCartItemId: "",
  error: false,
}

const giftCartSlice = createSlice({
  name: "giftCart",
  initialState: initialState,
  reducers: {
    giftCartFetchSuccess: (state, action) => {
     
      const data = action?.payload;
    //  console.log("editKey..........",data.giftCartItemId);
      if (state.giftCartData.find(item => item.giftCartItemId === data.giftCartItemId)) {
        state.giftCartData.splice(state.giftCartData.findIndex((item) => item.giftCartItemId === data.giftCartItemId), 1, data);
        // state.giftCartData.push(data);
        state.currentgiftCartItemId = action?.payload?.giftCartItemId;
      }
      else {
        state.giftCartData.push(action.payload);
        state.currentgiftCartItemId = action?.payload?.giftCartItemId;
      }
    },
    giftRedeemFetchSuccess: (state, action) => {
      state.giftRedeemData.push(action.payload);
    },
    giftCartUpdateData: (state, action) => {
      const data = action?.payload;
      state.giftCartData.forEach(c => {
        if (c.key === data?.passengerInfo?.cartitemid) {
          c.value = { ...c?.value, data }
        }
      });
    },

    giftRedeemUpdateData: (state, action) => {
      const data = action?.payload;
      state.giftRedeemData.forEach(c => {
        if (c.key === data?.passengerInfo?.cartitemid) {
          c.value = { ...c?.value, data }
        }
      });
    },

    giftRedeemBookingDetailUpdate: (state, action) => {
      const data = action?.payload;
      state.giftRedeemData.find(c => c.key === data.cartItemId).value = data;
    },
    giftCartDeleteItem: (state, action) => {
      state.giftCartData.splice(state.giftCartData.findIndex((item) => item.key === action.payload), 1);
    },
    giftCartFetchFailure: (state) => {
      state.error = true;
    },
    giftCartclear: state => {
      return initialState;
    },
  },
});

export const { giftCartFetchSuccess, giftCartUpdateData, giftCartFetchFailure, giftCartclear, giftCartDeleteItem, giftRedeemFetchSuccess, giftRedeemBookingDetailUpdate, giftRedeemUpdateData } = giftCartSlice.actions;
export const giftCartData = (state) => state.giftCartRedux?.giftCartData;
export const giftRedeemData = (state) => state.giftCartRedux?.giftRedeemData;
export const currentgiftCartItemId = (state) => state.giftCartRedux?.currentgiftCartItemId;

export default giftCartSlice.reducer;