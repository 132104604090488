import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import { useSelector } from "react-redux";
import { dateFormatForDisplay } from "./commonFunctions";

function DatepickerComponent(props) {
  const [date, setDate] = useState();
  const selectedMarket = useSelector(currentMarket);
  const locale = selectedMarket?.language;

  const DateChange = (value) => {
    const dt = value ? dateFormatForDisplay(value, "MM/dd/yyyy") : "";
    setDate(dt);
    props.onchange(dt);
  };

  return (
    <DatePicker
      locale={locale} //"en-EN"
      onChange={ (d) => {
        // DateChange(d)
          props.onChangeProps ? (props.onchange(props.variableName, (d ? dateFormatForDisplay(d, "MM/dd/yyyy") : ""))) : DateChange(d)
        }
      }
      value={props.value}
      className={props.className}
      format="MM/dd/yyyy"
      // clearIcon={props.showClearIcon ? "Clear" : null}
      {...(!props.showClearIcon && { clearIcon:null})} 
      minDate={props.minDate}
      maxDate={props.maxDate}
      onKeyDown={(e) => {
          e.preventDefault();
      }}
    />
  );
}

export default DatepickerComponent;
