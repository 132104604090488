import { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import MemberAndCardInformation from "../components/commonComponents/MemberAndCardInformation";
import CreditCard from "../components/commonComponents/CreditCard";
import CommonButton from "../components/commonComponents/CommonButton";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { addMemberCard, processdiscountvoucher } from "../redux/apiCalls";
import { showError } from "../components/commonComponents/Swal";
import { HandleAPIError, validExpiryOrNot, clearSession, divRef, externalLogin, getCurrentMonth, getLoginDetails, renderErrorMessages, scrollToDiv } from "../components/commonComponents/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { memberCardValues as MV } from "../redux/memberCardRedux";
import { processCreditCardPayment } from "../utils/Fac";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import MemberCardTermsAndConditionsModal from "../components/commonComponents/MemberCardTermsAndConditionsModal";
import Loader from "../components/commonComponents/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CARD_CVV_LENGTH, EMAIL_POLICY, DEFAULT_CURRENCYCODE } from "../config/commonConstants";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { logindetails as LD } from "../redux/signInRedux";

function PurchaseMembership() {
  const { t } = useTranslation(["common"]);
  const sendBroadcastMessage = useOutletContext()
  const memberCardValues = useSelector(MV);
  const [memberShipCardData, setMemberShipCardData] = useState("");
  const [amount, setAmount] = useState(0);
  const [cardValues, setCardValues] = useState([]);
  const [voucherDiscount, setVoucherDiscount] = useState(0);
  const [voucherOptions, setVoucherOptions] = useState([]);
  // const [voucherNumber, setVoucherNumber] = useState(null);
  const [processDiscountStatus, setProcessDiscountStatus] = useState(0);
  const loginDetails = useSelector(LD)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);
  const location = useLocation();
  const [isFirstComplete,setIsFirstComplete] = useState(false);

  var { voucherNumber } = useParams();
  if (processDiscountStatus !== 0) {
    voucherNumber = null;
  }

  // setVoucherNumber(vouchernumber);
  const initialCardholderDetails = {
    amount: 0,
    name: "",
    email: "",
    cardType: "VISA",
    cardProfile: "",
    cardNumber: "",
    cardMonth: getCurrentMonth(),
    cardYear: new Date()?.getFullYear()?.toString(),
    cvv: "",
    errors: {},
  };
  const [cardholderDetails, setCardholderDetails] = useState({ ...initialCardholderDetails });
  const initialMembershipCard = {
    name: "",
    email: "",
    confirmEmail: "",
    phone: "",
    cardValue: { key: memberCardValues[0] ? memberCardValues[0].cardid : "", value: memberCardValues[0] ? memberCardValues[0]?.cardprice : "", label: memberCardValues[0] ? memberCardValues[0]?.description : "" },
    cardValueLabel: memberCardValues[0] ? memberCardValues[0]?.valuedescription : "",
    cardValuedata: memberCardValues[0],
    errors: {},
  };


  useEffect(()=>{
    externalLogin(location,sendBroadcastMessage,navigate,dispatch,setIsFirstComplete)
  },[])

  const [memberShipCards, setMemberShipCards] = useState([{ ...initialMembershipCard }]);


  const formik = useFormik({
    initialValues: {
      amount: amount,
      name: "",
      email: "",
      cardType: "VISA",
      cardProfile: "",
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: (new Date()?.getFullYear()?.toString()),
      cvv: "",
      memberShipCards: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("topup:errors.name.required")),
      cardNumber: Yup.string()
        .required(t("topup:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("topup:errors.cardNumber.match"))
        .length(16, t("topup:errors.cardNumber.length")),
      cvv: Yup.string().required(t("topup:errors.cvv.required"))
        .length(CARD_CVV_LENGTH, t("topup:errors.cvv.length")),
      email: Yup.string().required(t("topup:errors.email.required"))
        .matches(EMAIL_POLICY, t("topup:errors.email.valid")),
      cardMonth: Yup.string().test(
        'test-credit-card-expiration-date',
        t("payment:errors.cardMonth.match"),
        tmpcardmonth => {
          return validExpiryOrNot(formik.values.cardYear, tmpcardmonth);
        }
       
      ),
      cardYear: Yup.string().test(
        'test-credit-card-expiration-date',
        t(""),
        tmpcardyear => {
          return validExpiryOrNot(tmpcardyear, formik.values.cardMonth);
        }
      ),
      // cardMonth: Yup.string().test(
      //   'test-credit-card-expiration-date',
      //   t("payment:errors.cardMonth.match"),
      //   expirationDate => {
      //     if (!expirationDate) {
      //       return false
      //     }
      //     var d = new Date(formik.values.cardYear, (expirationDate).toString(1, 2), 1, 0, 0, 0, 0);
      //     let flag = (new Date(dateFormatForDisplay(d, "MM/dd/yyyy")) > new Date(dateFormatForDisplay(new Date(), "MM/dd/yyyy"))) ? true : false;
      //    console.log("seect date is gretaer than current...........", new Date(dateFormatForDisplay(d, "MM/dd/yyyy")), new Date(dateFormatForDisplay(new Date(), "MM/dd/yyyy")), flag);
      //     if (!flag) {
      //       return false
      //     }
      //     return true
      //   }
      // ),
      // cardYear: Yup.string().test(
      //   'test-credit-card-expiration-date',
      //   t(""),
      //   expirationDate => {
      // console.log("expirationDate...........", expirationDate);
      // console.log("month value...........",  formik.values.cardMonth);
      //     var d = new Date(expirationDate, (formik.values.cardMonth).toString(1, 2), 1, 0, 0, 0, 0);
      //     let flag = (new Date(dateFormatForDisplay(d, "MM/dd/yyyy")) > new Date(dateFormatForDisplay(new Date(), "MM/dd/yyyy"))) ? true : false;
      //     console.log("seect date is gretaer than current............", new Date(dateFormatForDisplay(d, "MM/dd/yyyy")), new Date(dateFormatForDisplay(new Date(), "MM/dd/yyyy")), flag);
      //     if (!flag) {
      //       return false
      //     }
      //     return true
      //   }
      // ),
      memberShipCards: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(t("topup:errors.memberName.required")),
          email: Yup.string().required(t("topup:errors.memberEmail.required"))
            .matches(EMAIL_POLICY, t("topup:errors.memberEmail.valid")),
          confirmEmail: Yup.string().required(t("topup:errors.memberShipCards.memberConfirmEmail.required"))
            .matches(EMAIL_POLICY, t("topup:errors.memberShipCards.memberConfirmEmail.valid")),
          phone: Yup.string().required(t("topup:errors.memberCardPhone.required")),
        })
      ),
    }),
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik.values, "CREDITCARD"),
  });


  useEffect(() => {
    formik.setFieldValue("memberShipCards", [{
      name: "",
      email: "",
      confirmEmail: "",
      phone: "",
      cardValue: { key: memberCardValues[0] ? memberCardValues[0].cardid : "", value: memberCardValues[0] ? memberCardValues[0]?.cardprice : "", label: memberCardValues[0] ? memberCardValues[0]?.description : "" },
      cardValueLabel: memberCardValues[0] ? memberCardValues[0]?.valuedescription : "",
      cardValuedata: memberCardValues[0],
      errors: {},
    }]);
  }, [memberCardValues]);

  const calculateTotalAmount = () => {
    let sum = 0;
    formik.values.memberShipCards.map(c => {
      sum += parseInt(c?.cardValue?.value);
    });
    if (voucherNumber) {
      sum -= voucherDiscount;
    }
    return sum;
  };

  const addCard = () => {
    const newCards = [...formik.values.memberShipCards, { ...initialMembershipCard }];
    //  setMemberShipCards(newCards);
    formik.setFieldValue("memberShipCards", newCards);
  };

  const removeCard = (cardIndex) => {
    const newCards = formik.values.memberShipCards.filter((card, index) => index !== cardIndex);
    // memberShipCards.splice(memberShipCards.findIndex((item, index) => index === cardIndex), 1);
    // setMemberShipCards([...newCards]);
    formik.setFieldValue("memberShipCards", newCards);
  };

  const getmemberCardValues = () => {
    if (memberCardValues.length !== 0) {
      setMemberShipCardData(memberCardValues);
      // const cardValues = [];
      setCardValues(memberCardValues?.map(v => ({
        key: v?.cardid,
        value: v?.cardprice,
        label: v?.description,
      })));
      setLoading(false)
    }
    else {
      // setCardValues([]);
    }
  };

  useEffect(()=>{
    // console.log("formik_cardholderDetails.cardMonth",formik_cardholderDetails.cardMonth);
    // console.log("formik_cardholderDetails.cardMonth",formik_cardholderDetails.cardYear);
    formik.setFieldValue("cardMonth",formik.values.cardMonth);
    formik.setFieldValue("cardYear",formik.values.cardYear);
  },[formik.values.cardMonth,formik.values.cardYear])


  useEffect(() => {
    let sum = calculateTotalAmount();
    setAmount(sum ? sum : 0);

  }, [formik.values.memberShipCards, amount, memberCardValues])

  useEffect(() => {
    getmemberCardValues(); //memberCardValues.length === 0 &&
    console.log("voucherNumber", voucherNumber)
  }, [memberCardValues]);

  useEffect(() => {
    if(isFirstComplete){
      if (voucherNumber) {
        getLoginDetails()?.sessionid && processDiscountVoucher(); // memberCardValues.length !== 0 && 
      }
    }
  }, [cardValues, loginDetails,isFirstComplete])

  const generateAddMemberCardRequest = (objPayment) => {
    let requestData = {};
    let membercards = [];

    formik.values.memberShipCards.map(card => {
      membercards.push({
        name: card.name,
        email: card.email,
        phone: card.phone,
        cardid: card?.cardValuedata?.cardid,
        cardprice: card?.cardValuedata?.cardprice,
        cardvalue: card?.cardValuedata?.cardvalue
      });
    });

    requestData = {
      "paymentgateway": "FAC",
      "membercards": membercards,
      "creditcard": {
        "cardnumber": formik.values.cardNumber.slice(-4),
        "cardholder": formik.values.name,
        "currency": DEFAULT_CURRENCYCODE,
        "cardtype": formik.values.cardType,
        "amount": amount,
        "email": formik.values.email,
        "authorizationnumber": objPayment?.creditcard?.authorizationnumber,
      },
    };

    if (voucherNumber) {
      requestData.discountvoucher = {
        "vouchernumber": voucherNumber,
        "amount": voucherDiscount
      }
    }
    return requestData;
  }

  const holdFundsForVoucher = async () => {
    try {
      const voucherData = {
        "action": "HOLDFUNDS",
        "vouchernumber": voucherNumber,
        "amount": voucherDiscount
      }
      const respone = await processdiscountvoucher(voucherData);
      return respone;
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const releaseFundsForVoucher = async () => {
    try {
      const voucherData = {
        "action": "RELEASEFUNDS",
        "vouchernumber": voucherNumber,
        "amount": voucherDiscount
      }
      const respone = await processdiscountvoucher(voucherData);
      return respone;
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let doSubmit = true;
      if (voucherNumber) {
        const holdFundsForVoucherResponse = await holdFundsForVoucher();
        if (holdFundsForVoucherResponse.status === 0) {
        } else {
          doSubmit = false;
          showError(t("error", holdFundsForVoucherResponse?.statusMessage));
        }
      }

      if (doSubmit) {
        setLoading(true);
        setSubmitOnClickDisable(true);

        let objPayment = {}
        objPayment = {
          creditcard: {
            cardnumber: formik.values?.cardNumber.slice(-4),
            authorizationnumber: "123456",
            amount: amount,
          },
        };
        let AddMemberCardLogRequest = generateAddMemberCardRequest(objPayment);
        const paymentData = {
          "source": "OBI-ADDMEMBERCARD",
          "amount": amount,
          "cardholderDetails": formik.values
        };
        const responseCC = await processCreditCardPayment(paymentData, AddMemberCardLogRequest);
        if (responseCC && Object.keys(responseCC).length !== 0 && responseCC?.data?.authorizationnumber && responseCC?.data?.authorizationnumber !== "") {
          objPayment = {
            creditcard: {
              cardnumber: responseCC?.data?.cardnumber,
              authorizationnumber: responseCC?.data?.authorizationnumber,
              amount: responseCC?.data?.amount
            }
          };
          let AddMemberCardRequest = generateAddMemberCardRequest(objPayment);
          const response = await addMemberCard(AddMemberCardRequest);
          if (response.status === 0) {
            setLoading(false);
            const successInfo = {
              messageHeading: t("purchasemembership:purchaseMembership"),
              successMessage: t('purchasemembership:successMessage', { currencyCode: DEFAULT_CURRENCYCODE, amount: amount.toFixed(2), receiptid: response?.data?.receiptid, email: formik.values.email })
            }
            clearSession(sendBroadcastMessage);
            navigate("/common-success", { state: successInfo });
          }
          else {
            setLoading(false);
            showError(t("error"), response.statusMessage);
            setSubmitOnClickDisable(false);
          }
        } else {
          releaseFundsForVoucher();
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setSubmitOnClickDisable(false);
      setLoading(false);
    }
  };

  const processDiscountVoucher = async () => {
    try {
      setLoading(true);
      const voucherData = {
        "action": "VALIDATE",
        "vouchernumber": voucherNumber
      };
      const validateVoucherResponse = await processdiscountvoucher(voucherData);
      if (validateVoucherResponse?.status === 0) {
        let arrCard = cardValues.filter((card) => card.key === validateVoucherResponse.data.cardid);//card.key === validateVoucherResponse.data.cardid
        setVoucherDiscount(validateVoucherResponse.data.amount);
        setVoucherOptions(arrCard);
        let newArray = [...formik.values.memberShipCards]

        newArray[0] = {
          name: "",
          email: "",
          confirmEmail: "",
          phone: "",
          cardValue: { key: arrCard[0].key, value: arrCard[0]?.value, label: arrCard[0]?.label },
          cardValueLabel: memberCardValues[3] ? memberCardValues[3]?.valuedescription : "",
          cardValuedata: memberCardValues[3],
          errors: {},
        }
        console.log("newArray", newArray)
        formik.setFieldValue("memberShipCards", newArray)
      } else {
        voucherNumber = null;
        // setVoucherNumber(null)
        setProcessDiscountStatus(validateVoucherResponse?.status)
        showError(t("error"), validateVoucherResponse?.statusMessage);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("loaderMessage")} />
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t('purchasemembership:purchaseMembership')}</span>
          </h3>
         
          <Row className="justify-content-end my-4">
            <Col md={6} className="d-flex justify-content-end mb-2 px-3">
              <CommonButton
                classname="gradient-btn mx-2"
                size="md"
                title={t('purchasemembership:addCard')}
                onclick={addCard}
              />
              <ExitButton />
            </Col>
          </Row>
        
          <Form>
            <h6 className="field px-3 mb-3">
              {t('fillAllFields')}
            </h6>
            <h6 className="field px-3">
              <ErrorMessages formik={formik} />
            </h6>
            <Row className="justify-content-start my-3">
              <Col md={6} className="d-flex justify-content-start mb-2">
                <h4 className=" my-2 px-3 ">{t('purchasemembership:memberCardInformation')}</h4>
              </Col>
              <Col md="12">
                {formik.values.memberShipCards.map((card, index) => (
                  <MemberAndCardInformation
                    voucherNumber={voucherNumber}
                    voucherOptions={voucherOptions}
                    key={index}
                    index={index}
                    cardIndex={index}
                    onRemove={() => removeCard(index)}
                    formik={formik}
                    cardValues={cardValues ? cardValues : []}
                    errors={card.errors}
                    onChange={(key, val) => {
                      //  card[key] = val;
                      formik.setFieldValue(`memberShipCards.${index}.${key}`, val);
                      if (key === "cardValue") {
                        let sum = calculateTotalAmount();
                        let currentCard = memberShipCardData.find(c => c.description === val?.label);
                        formik.setFieldValue(`memberShipCards.${index}.cardValuedata`, currentCard);
                        formik.setFieldValue(`memberShipCards.${index}.cardValueLabel`, currentCard?.valuedescription);
                      }
                      if (card.errors[key]) card.errors[key] = "";
                      setMemberShipCards([...memberShipCards]);
                    }
                    }
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />
                ))}
                <Card className="rounded-3 p-4 mb-4 mx-2">
                  <Row>
                    <Col md={3}>
                      <Form.Label
                        className="d-flex "
                        htmlFor="validationCustomPrice"
                      >
                        {t("amount")} ({DEFAULT_CURRENCYCODE})
                      </Form.Label>
                      <Form.Control
                        required
                        disabled
                        id="validationPrice"
                        type="text"
                        placeholder=""
                        value={amount.toFixed(2)}
                        className="mb-3"
                      />
                    </Col>
                  </Row>
                </Card>

                <h4 className=" p-3 ">{t('purchasemembership:paymentDetails')}</h4>
                <CreditCard
                  isTermsAndConditionRequired={true}
                  modalTermsAndConditions={MemberCardTermsAndConditionsModal}
                  formik={formik}
                  isSubmitDisabled={submitOnClickDisable ? submitOnClickDisable : false}
                  onClick={(e) =>
                    scrollToDiv(e, formik)
                    // formik.handleSubmit();
                  }
                  onChange={(key, val) => {
                    formik.setFieldValue(key, val);
                  }}
                  onBlur={(key, e) => {
                    formik.handleBlur(e);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </>
  );
}

export default PurchaseMembership;