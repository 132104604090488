import Modal from "react-bootstrap/Modal";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Password from "./Password";
import CommonButton from "./CommonButton";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { changePassword } from "../../redux/apiCalls";
import md5 from "md5";
import { showError, showSuccess } from "./Swal";
import {
  HandleAPIError,
  decryptData,
  divRef,
  loginFunction,
  passwordValidationErrorExpresssion,
  passwordValidationErrorMessages,
  renderErrorMessages,
  scrollToDiv,
  updatePassword,
} from "./commonFunctions";
import { ref, object, string } from "yup";
import { useFormik } from "formik";
import { PASSWORD_MAXLENGTH, PASSWORD_POLICY } from "../../config/commonConstants";
import ErrorMessages from "./ErrorMessages";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logindetails as LD } from "../../redux/signInRedux";

export default function ChangePasswordModal(props) {
  const [render,setRender] = useState(false)
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginDetails = useSelector(LD);

  const cancelHandler = () => {
    formik.resetForm();
    setRender(!render)
    props.cancelModal();
  };

  const formReset = () => {
    formik.resetForm();
  };


  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["common", "changepassword"]);

  const handleSubmit = async (formik) => {
    let isFormValid = true;
    if (isFormValid) {
      let loginDetail = {};
      if (loginDetails?.sessionid) {
        loginDetail = await decryptData();
      }
      const data = {
        oldpassword: md5(formik.oldPassword),
        newpassword: md5(formik.newPassword),
      };
      setLoading(true);
      try {
        if (loginDetail?.password === data.oldpassword) {
          if (md5(formik.confirmPassword) === data.newpassword) {
            const response = await changePassword(data);
            if (response?.status === 0) {
              await updatePassword(loginDetail,data);
              await showSuccess(response?.statusMessage,t("changepassword:passwordChanged"));
              props.cancelModal();
              formReset();
            }
          } else {
            showError(t("error"), t("changepassword:passwordMismatch"));
          }
        }
        else {
          showError(t("error"), t("changepassword:invalidPassword"));
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const validationSchema = object().shape({
    oldPassword: string().required(
      t("changepassword:errors.oldPassword.required")
    ),
    newPassword: string().test("newPassword",passwordValidationErrorMessages(),passwordValidationErrorExpresssion())
      .required(t("changepassword:errors.newPassword.required"))
      .min(PASSWORD_POLICY.minLength, t("changepassword:errors.newPassword.lengthRequired",{minLength:PASSWORD_POLICY.minLength})),
    confirmPassword: string()
      .required(t("changepassword:errors.confirmPassword.required"))
      .oneOf(
        [ref("newPassword")],
        t("changepassword:errors.confirmPassword.notMatched")
      ),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  useEffect(()=>{
    props.visible && loginFunction(navigate,location,props.sendBroadcastMessage,dispatch,true);
  },[props.visible])

  useEffect(()=>{
    formik.setErrors({}, false);
    formik.setTouched({}, false);
  },[render])
  return (
    <>
      <Modal
        show={props.visible}
        onHide={cancelHandler}
        size="md"
        style={{ zIndex: "9998" }}
        centered
      >
        <Modal.Header closeButton><h3>{t("changePassword")}</h3></Modal.Header>
        <Loader loading={loading} message={t("changepassword:Processing")} />
        <Form onSubmit={(e)=> scrollToDiv(e,formik)}  ref={divRef} style={{whiteSpace: "pre-line"}}>
          <Modal.Body className="mb-3">
            <Row className="d-flex justify-content-center">
              <Col md={10}>
                <Card className="bg-light bg-opacity-10 rounded-3 ">
                  <Card.Body>
                    <Row className="d-flex justify-content-start">
                      <Col md={12}>
                        <h6 className="field">{t('fillAllFields')}</h6>
                      </Col>
                    </Row>
                    <ErrorMessages formik={formik} />
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label htmlFor="validationCustomOldPassword">
                          {t("currentPassword")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          name="oldPassword"
                          placeholder={t("currentPassword")}
                          id="validationCustomOldPassword"
                          onBlur={formik.handleBlur}
                          maxLength={PASSWORD_MAXLENGTH}
                          value={formik.values.oldPassword}
                          onChange={formik.handleChange}
                          className={
                            formik.touched.oldPassword &&
                            formik.errors.oldPassword
                              ? "error rounded"
                              : ""
                          }
                        />
                        {formik.touched.oldPassword}
                        
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label htmlFor="validationCustomNewPassword">
                          {t("newPassword")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          name="newPassword"
                          id="validationCustomNewPassword"
                          placeholder={t("newPassword")}
                          value={formik.values.newPassword}
                          maxLength={PASSWORD_MAXLENGTH}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.newPassword &&
                            formik.errors.newPassword
                              ? "error rounded"
                              : ""
                          }
                        />
                        {formik.touched.newPassword}

                       
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Label htmlFor="validationCustomConfirmPassword">
                          {t("confirmPassword")}<span className="field">*</span>
                        </Form.Label>
                        <Password
                          name="confirmPassword"
                          id="validationCustomConfirmPassword"
                          placeholder={t("confirmPassword")}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          maxLength={PASSWORD_MAXLENGTH}
                          className={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                              ? "error rounded"
                              : ""
                          }
                        />
                        {formik.touched.confirmPassword}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <CommonButton
              classname="exit"
              title={t("close")}
              onclick={cancelHandler}
              type="button"
            />
            <CommonButton
              classname="gradient-btn mx-1"
              title={t("changePassword")}
              type="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
