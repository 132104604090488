import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Card, Container, Form, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import CreditCard from "../components/commonComponents/CreditCard";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { CARD_CVV_LENGTH, EMAIL_MAXLENGTH, EMAIL_POLICY, FIRST_NAME_MAXLENGTH, GIFT_FIRST_NAME_MAXLENGTH, GIFT_LAST_NAME_MAXLENGTH, LAST_NAME_MAXLENGTH, REGEX_ALPHABET } from "../config/commonConstants";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as Yup from "yup";
import { HandleAPIError, validExpiryOrNot, clearSession, dateFormatForDisplay, divRef, getCurrentMonth, renderErrorMessages, scrollToDiv, getDistributorSubDistributorProfile } from "../components/commonComponents/commonFunctions";
import Loader from "../components/commonComponents/Loader";
import { useSelector } from "react-redux";
import { showError } from "../components/commonComponents/Swal";
import { processCreditCardPayment } from "../utils/Fac";
import { addGift } from "../redux/apiCalls";
import { giftCartData as GCD } from "../redux/giftCartRedux";
import { selectedMarketCurrency } from "../redux/marketRedux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";


function GiftPayment() {
  const [validated, setValidated] = useState(false);
  const sendBroadcastMessage = useOutletContext()
  const { t } = useTranslation(['common', 'giftpayment']);
  const currencyCode = useSelector(selectedMarketCurrency);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const distributorProfile = getDistributorSubDistributorProfile();
  const distributorid = distributorProfile?.distributorid;
  const giftCartData = useSelector(GCD);
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);
  const populateConfirmationEmail = () => {
    return (giftCartData.length > 0 ? giftCartData[0]?.recipient_email : "");
  }

  useEffect(() => {
    let totalAmount = 0;
    giftCartData.map((e) => {
      totalAmount = totalAmount + e.amount;
    });
    setAmount(totalAmount);
  }, [giftCartData]);



  const initialCardholderDetails = {
    "amount": 0,
    "name": "",
    "email": "",
    "cardType": "VISA",
    "cardProfile": "",
    "cardNumber": "",
    "cardMonth": getCurrentMonth(),
    "cardYear": (new Date()?.getFullYear()?.toString()),
    "cvv": "",
    "errors": {}
  };

  const [cardholderDetails, setCardholderDetails] = useState({ ...initialCardholderDetails });

  const validationSchema = Yup.object().shape({
    buyerFirstName: Yup.string().required(t("giftpayment:errors.buyerFirstName.required")),
    buyerLastName: Yup.string().required(t("giftpayment:errors.buyerLastName.required")),
    buyerEmail: Yup.string()
    .matches(EMAIL_POLICY, t("giftpayment:errors.buyerEmail.match"))
      .required(t("giftpayment:errors.buyerEmail.required")),
    buyerConfirmEmail: Yup.string().required(t("giftpayment:errors.buyerConfirmEmail.required"))
      .matches(EMAIL_POLICY, t("giftpayment:errors.buyerConfirmEmail.match"))
      .test(
        'email-validate',
        t("giftpayment:errors.buyerConfirmEmail.equal"),
        confirmEmail => {
          let flag = confirmEmail === formik.values.buyerEmail ? true : false;
          if (!flag) {
            return false
          }
          return true
        }
      ),
      
    name: Yup.string().required(t("topup:errors.name.required")),
    cardNumber: Yup.string()
      .required(t("giftpayment:errors.cardNumber.required"))
      .matches(/^[0-9]+$/, t("giftpayment:errors.cardNumber.match"))
      .length(16, t("giftpayment:errors.cardNumber.length")),
    cvv: Yup.string().required(t("giftpayment:errors.cvv.required"))
    .length(CARD_CVV_LENGTH, t("giftpayment:errors.cvv.length")),
    email: Yup.string().required(t("giftpayment:errors.email.required"))
      .matches(EMAIL_POLICY, t("giftpayment:errors.email.valid")),
    cardMonth: Yup.string().test(
      'test-credit-card-expiration-date',
      t("giftpayment:errors.cardMonth.match"),
      tmpcardmonth => {
        return validExpiryOrNot(formik.values.cardYear, tmpcardmonth);
      }
    ),
    cardYear: Yup.string().test(
      'test-credit-card-expiration-date',
      t(""),
      tmpcardyear => {
        return validExpiryOrNot(tmpcardyear, formik.values.cardMonth);  
       }
    ),
  });

  const formik = useFormik({
    initialValues: {
      buyerFirstName: "",
      buyerLastName: "",
      buyerEmail: "",
      buyerConfirmEmail: "",
      amount: 0,
      name: "",
      email: "",
      cardType: "VISA",
      cardProfile: "",
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: (new Date()?.getFullYear()?.toString()),
      cvv: "",
      errors: {}
    },
    validationSchema,
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => { handleSubmit(formik.values) },
  });

  useEffect(()=>{
    formik.setFieldValue("cardMonth",formik.values.cardMonth);
    formik.setFieldValue("cardYear",formik.values.cardYear);
  },[formik.values.cardMonth,formik.values.cardYear])

  const generateAddGiftCardRequest = (objPayment) => {
    let requestData = {};
    let cartItems = [];

    giftCartData.map(card => {
      cartItems.push({
        "packageid": parseInt(card.key) + 1,
        "image": "card" + card.key + ".png",
        "productid": card.productid,
        "airportid": card.loungeType,
        "arrivalairportid": card.loungeType,
        "departureairportid": card.loungeType,
        "adulttickets": card.adult_count.value,
        "childtickets": card.child_count.value,
        // "infanttickets": card.infant_count.value,
        "deliverydate": dateFormatForDisplay(card?.delivery_date, "yyyyMMdd"),
        "contactname": card.recipient_firstname_title + " " + card.recipient_firstname + " " + card.recipient_lastname,
        "contactemail": card.recipient_email,
        "sendername": card.purchase_firstname + " " + card.purchase_lastname, //card.purchase_firstname_title + 
        "message": card.message,
        "value": card.amount
      });
    });

    requestData = {
      "giftcart": {
        "firstname": formik.values.buyerFirstName,
        "lastname": formik.values.buyerLastName,
        "email": formik.values.buyerEmail,
        "distributorid": distributorid,
        "gifttype": "C",
        "cartitems": cartItems,
        "payment": {
          "paymenttype": "CREDITCARD",
          "creditcard": {
            "cardnumber": formik.values.cardNumber.slice(-4),
            "cardholder": formik.values.name,
            "cardtype": formik.values.cardType,
            "currency": currencyCode,
            "amount": amount,
            "authorizationnumber": objPayment?.creditcard?.authorizationnumber,
          },
          "charged": "Y"
        }
      }
    }

    return requestData;
  }

  const handleSubmit = async () => {
    let isFormValid = true; // Add a variable to track form validity
    let request = {};
    if (isFormValid) {
      try {
        setLoading(true);
        setSubmitOnClickDisable(true);
        let objPayment = {}
        objPayment = {
          creditcard: {
            cardnumber: formik.values?.cardNumber.slice(-4),
            authorizationnumber: "123456",
            amount: amount,
          },
        }
        const giftCardLogRequest = generateAddGiftCardRequest(objPayment);

        const paymentData = {
          "source": "OBI-GIFTCARD",
          "amount": amount,
          "cardholderDetails": formik.values
        };

        const responseCC = await processCreditCardPayment(paymentData, giftCardLogRequest);
        if (responseCC && Object.keys(responseCC).length !== 0 && responseCC?.data?.authorizationnumber && responseCC?.data?.authorizationnumber !== "") {
          objPayment = {
            creditcard: {
              cardnumber: responseCC?.data?.cardnumber,
              authorizationnumber: responseCC?.data?.authorizationnumber,
              amount: amount,
            }
          };
          request = generateAddGiftCardRequest(objPayment);
          const response = await addGift(request);

          if (response.status === 0) {
            const successInfo = {
              confirmationnumber: response?.data?.confirmationnumber,
              currencyCode: currencyCode,
              amount: amount.toFixed(2),
              confirmationEmail: request.giftcart.email,
              userConfirmationEmail: "",
              successResponse: response?.data,
              cartData: giftCartData,
              userDeafultEmail: "",
            };
            //  closeSession();
            clearSession(sendBroadcastMessage);
            navigate("/gift-payment-success", { state: successInfo });
          }
          else {
            setLoading(false);
            showError(t("error"), response.statusMessage);
            setSubmitOnClickDisable(false);
          }
        }
      } catch (e) {
        HandleAPIError(e);
        return null;
      } finally {
        setSubmitOnClickDisable(false);
        setLoading(false);
      }

    }
  };

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("loaderMessage")} />
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t("giftpayment:payment")}</span>
          </h3>

          <div className="my-3  d-flex justify-content-end">
            <Link to="/giftSummary" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t("back")} />
            </Link>
            <ExitButton />
          </div>
          <Card className="px-0">
            <Card.Body>
              <Row className="d-flex justify-content-start">
                <Col md={4}>
                  <h6 className="field">{t("fillAllFields")}</h6>
                </Col>
              </Row>
              <ErrorMessages formik={formik} />
              <Row className="justify-content-center">
                <Col md={8}>
                  <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                    <h4 className="px-1 mb-3 ">
                      {t("giftpayment:billingInformation")}
                    </h4>
                    <Card className="bg-light  bg-opacity-10 rounded-3 px-0 mb-2">
                      <Card.Body>
                        <Row className="g-3">
                          <Col md={6} className="">
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationBuyerFirstName"
                            >
                              {t("giftpayment:buyerFirstName")}
                              <span className="field">*</span>
                            </Form.Label>
                            <InputGroup className="d-flex">
                              <Form.Control
                                type="text"
                                id="buyerFirstName"
                                name="buyerFirstName"
                                placeholder={t("giftpayment:buyerFirstName")}
                                aria-describedby="inputGroupPrepend"
                                value={formik.values.buyerFirstName.replace(REGEX_ALPHABET,"")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.touched.buyerFirstName &&
                                  formik.errors.buyerFirstName
                                    ? "error"
                                    : ""
                                }
                                maxLength={GIFT_FIRST_NAME_MAXLENGTH}
                              />
                            </InputGroup>
                          </Col>
                          <Col md={6} className="">
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationBuyerLastName"
                            >
                              {t("giftpayment:buyerLastName")}
                              <span className="field">*</span>
                            </Form.Label>
                            <InputGroup className="d-flex">
                              <Form.Control
                                type="text"
                                id="buyerLastName"
                                name="buyerLastName"
                                placeholder={t("giftpayment:buyerLastName")}
                                aria-describedby="inputGroupPrepend"
                                value={formik.values.buyerLastName.replace(REGEX_ALPHABET,"")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.touched.buyerLastName &&
                                  formik.errors.buyerLastName
                                    ? "error"
                                    : ""
                                }
                                maxLength={GIFT_LAST_NAME_MAXLENGTH}
                              />
                            </InputGroup>
                          </Col>
                          <Col md={6}>
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationCustomemail"
                            >
                              {t("giftpayment:buyerEmail")}
                              <span className="field">*</span>
                            </Form.Label>
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                @
                              </InputGroup.Text>
                              <Form.Control
                                type="email"
                                id="buyerEmail"
                                name="buyerEmail"
                                placeholder={t("emailAddress")}
                                aria-describedby="inputGroupPrepend"
                                value={formik.values.buyerEmail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.touched.buyerEmail &&
                                  formik.errors.buyerEmail
                                    ? "error"
                                    : ""
                                }
                                maxLength={EMAIL_MAXLENGTH}
                                minLength={1}
                              />
                              <Form.Control.Feedback>
                                Looks good!
                              </Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                {t("chooseEmailAddress")}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                          <Col md={6}>
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationCustomConfirmemail"
                            >
                              {t("giftpayment:buyerConfirmEmail")}
                              <span className="field">*</span>
                            </Form.Label>
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                @
                              </InputGroup.Text>
                              <Form.Control
                                type="email"
                                id="buyerConfirmEmail"
                                name="buyerConfirmEmail"
                                placeholder={t("emailAddress")}
                                aria-describedby="inputGroupPrepend"
                                value={formik.values.buyerConfirmEmail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.touched.buyerConfirmEmail &&
                                  formik.errors.buyerConfirmEmail
                                    ? "error"
                                    : ""
                                }
                                maxLength={EMAIL_MAXLENGTH}
                                minLength={1}
                              />
                              <Form.Control.Feedback>
                                Looks good!
                              </Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                {t("chooseEmailAddress")}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col md="auto">
                            <Form.Label
                              className="d-flex"
                              htmlFor="validationCustom04"
                            >
                              {t("amount")} ({currencyCode}){" "}
                            </Form.Label>
                            <Form.Control
                              id="validationCustom04"
                              disabled
                              type="text"
                              placeholder=""
                              value={amount.toFixed(2)}
                              className=""
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    {/* <CreditCard /> */}
                    <CreditCard
                      // cardProfileValue=""
                      // name={cardholderDetails.name}
                      // email={cardholderDetails.email}
                      // cardType={cardholderDetails.cardType}
                      // cardNumber={cardholderDetails.cardNumber}
                      // cardMonth={cardholderDetails.cardMonth}
                      // cardYear={cardholderDetails.cardYear}
                      // cvv={cardholderDetails.cvv}
                      // errors={cardholderDetails.errors}
                      // isSubmitDisabled={submitOnClickDisable ? submitOnClickDisable : false}
                      // onClick={(event) => {
                      //   handleSubmit(event);
                      // }}
                      // onChange={(key, val) => {
                      //   cardholderDetails[key] = val;
                      //   if (cardholderDetails.errors[key]) {
                      //     cardholderDetails.errors[key] = "";
                      //   }
                      //   setCardholderDetails({ ...cardholderDetails });
                      // }}

                      formik={formik}
                      isSubmitDisabled={
                        submitOnClickDisable ? submitOnClickDisable : false
                      }
                      onClick={(e) => {
                        scrollToDiv(e, formik);
                        // formik.handleSubmit();
                      }}
                      onChange={(key, val) => {
                        formik.setFieldValue(key, val);
                      }}
                      onBlur={(key, e) => {
                        formik.handleBlur(e);
                      }}
                    />
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default GiftPayment;