import { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { topUpMemberCard, verifyMemberCards } from "../redux/apiCalls";
import { showError } from "../components/commonComponents/Swal";
import { HandleAPIError, validExpiryOrNot, clearSession, divRef, getCurrentMonth, renderErrorMessages, scrollToDiv, isCardNumberUnique, translateConstantText, getDistributorSubDistributorProfile } from "../components/commonComponents/commonFunctions";
import { useSelector } from "react-redux";
import { memberCardValues as MV, memberCards as MC } from "../redux/memberCardRedux";
import { processCreditCardPayment } from "../utils/Fac";
import { useNavigate, useOutletContext } from "react-router-dom";
import TopUpMemberCardInformation from "../components/commonComponents/TopUpMemberCardInformation";
import CreditCard from "../components/commonComponents/CreditCard";
import Loader from "../components/commonComponents/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CARD_CVV_LENGTH, EMAIL_POLICY, DEFAULT_CURRENCYCODE } from "../config/commonConstants";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function TopUpMembership() {
  const { t } = useTranslation(["common", "topup"]);
  const sendBroadcastMessage = useOutletContext()
  const memberCardValues = useSelector(MV);
  let membershipCardsFetchedData = useSelector(MC);
  membershipCardsFetchedData = membershipCardsFetchedData ? membershipCardsFetchedData : [];
  const distributorProfile = getDistributorSubDistributorProfile();
  const distributorid = distributorProfile?.distributorid;
  const [memberCardValuesData, setmemberCardValuesData] = useState("");
  const [memberShipCardsData, setmemberShipCardsData] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);

  const initialMembershipCard = {
    "name": "",
    "cardNumber": "",
    "cardType": { ...(membershipCardsFetchedData?.find(c => c?.showvalue === "Y")) ? { value: membershipCardsFetchedData?.find(c => c?.showvalue === "Y")?.cardid } : "", ...(membershipCardsFetchedData?.find(c => c?.showvalue === "Y")) ? { label: membershipCardsFetchedData?.find(c => c?.showvalue === "Y")?.cardname } : "" },
    "cardValue": { value: memberCardValues[0] ? memberCardValues[0]?.cardprice : "", label: memberCardValues[0] ? memberCardValues[0]?.description : "" },
    "cardValueLabel": memberCardValues[0] ? memberCardValues[0]?.valuedescription : "",
    "cardValuedata": memberCardValues[0],
    "errors": {},
  };

  const [memberShipCards, setMemberShipCards] = useState([{ ...initialMembershipCard }]);
  const [amount, setAmount] = useState(0);
  const [cardValues, setCardValues] = useState("");
  const [cardTypes, setCardTypes] = useState("");

  const navigate = useNavigate();

  const initialCardholderDetails = {
    "amount": 0,
    "name": "",
    "email": "",
    "cardType": "VISA",
    "cardProfile": "",
    "cardNumber": "",
    "cardMonth": getCurrentMonth(),
    "cardYear": (new Date()?.getFullYear()?.toString()),
    "cvv": "",
    "errors": {}
  };
  const [cardholderDetails, setCardholderDetails] = useState({ ...initialCardholderDetails });


  const validationSchema = Yup.object().shape({
    memberShipCards: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t("topup:errors.memberName.required")),
        cardNumber: Yup.string()
          .required(t("topup:errors.memberShipCards.cardNumber.required", { membershipCardNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
          .matches(/^[0-9]+$/, t("topup:errors.memberShipCards.cardNumber.match", { membershipCardNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
          .length(16, t("topup:errors.memberShipCards.cardNumber.length", { membershipCardNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
          .test('unique', ' ', function (value) {
            const res = isCardNumberUnique(memberShipCards)
            return res
          }),
      })
    ),
    name: Yup.string().required(t("topup:errors.name.required")),
    cardNumber: Yup.string()
      .required(t("topup:errors.cardNumber.required"))
      .matches(/^[0-9]+$/, t("topup:errors.cardNumber.match"))
      .length(16, t("topup:errors.cardNumber.length")),
    cvv: Yup.string().required(t("topup:errors.cvv.required"))
      .length(CARD_CVV_LENGTH, t("topup:errors.cvv.length")),
    email: Yup.string().required(t("topup:errors.email.required"))
      .matches(EMAIL_POLICY, t("topup:errors.email.valid")),
    cardMonth: Yup.string().test(
      'test-credit-card-expiration-date',
      t("payment:errors.cardMonth.match"),
      tmpcardmonth => {
        return validExpiryOrNot(formik.values.cardYear, tmpcardmonth);
      }
    ),
    cardYear: Yup.string().test(
      'test-credit-card-expiration-date',
      t(""),
      tmpcardyear => {
        return validExpiryOrNot(tmpcardyear, formik.values.cardMonth); 
      }
    ),
  })

  const formik = useFormik({
    initialValues: {
      amount: amount,
      name: "",
      email: "",
      cardType: "VISA",
      cardProfile: "",
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: (new Date()?.getFullYear()?.toString()),
      cvv: "",
      memberShipCards: [],
    },
    validationSchema,
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik.values, "CREDITCARD"),
  });

  
  useEffect(()=>{
    // console.log("formik_cardholderDetails.cardMonth",formik_cardholderDetails.cardMonth);
    // console.log("formik_cardholderDetails.cardMonth",formik_cardholderDetails.cardYear);
    formik.setFieldValue("cardMonth",formik.values.cardMonth);
    formik.setFieldValue("cardYear",formik.values.cardYear);
    console.log("formik",formik.touched,formik.errors);
  },[formik.values.cardMonth,formik.values.cardYear,formik.touched,formik.errors])

  useEffect(() => {
    formik.setFieldValue("memberShipCards", [{
      "name": "",
      "cardNumber": "",
      "cardType": { ...(membershipCardsFetchedData?.find(c => c?.showvalue === "Y")) ? { value: membershipCardsFetchedData?.find(c => c?.showvalue === "Y")?.cardid } : "", ...(membershipCardsFetchedData?.find(c => c?.showvalue === "Y")) ? { label: membershipCardsFetchedData?.find(c => c?.showvalue === "Y")?.cardname } : "" },
      "cardValue": { value: memberCardValues[0] ? memberCardValues[0]?.cardprice : "", label: memberCardValues[0] ? memberCardValues[0]?.description : "" },
      "cardValueLabel": memberCardValues[0] ? memberCardValues[0]?.valuedescription : "",
      "cardValuedata": memberCardValues[0],
      "errors": {},
    }]);
    
  }, [memberCardValues, membershipCardsFetchedData]);

  useEffect(()=>{
    setMemberShipCards(formik.values.memberShipCards);
  },[formik.values.memberShipCards])

  const calculateTotalAmount = () => {
    let sum = 0;
    formik.values.memberShipCards.map(c => {
      sum += parseInt(c.cardValue?.value);
    });
    return sum;
  };

  const checkForDuplicateCardNumber = (cardNumber) => {
    let counter = 0;
    let flag = false;
    formik.values.memberShipCards.map(c => c?.cardNumber === cardNumber && counter++);
    if (counter > 1)
      flag = true;
    return flag;
  };

  const addCard = () => {
    const newCards = [...formik.values.memberShipCards, { ...initialMembershipCard }];
    formik.setFieldValue("memberShipCards", newCards);
    setMemberShipCards(newCards)
  };

  const removeCard = (cardIndex) => {
    const newCards = formik.values.memberShipCards.filter((card, index) => index !== cardIndex);
    formik.setFieldValue("memberShipCards", newCards);
    setMemberShipCards(newCards)
  };

  const getmemberCardValues = async () => {
    if (memberCardValues.length !== 0) {
      setmemberCardValuesData(memberCardValues);
      const cardValuesTemp = [];
      memberCardValues?.map(v => {
        if (v?.showvalue === "Y") {
          cardValuesTemp.push({
            value: v?.cardprice,
            label: v?.description,
          });
        }
      });
      setCardValues(cardValuesTemp);

      setmemberShipCardsData(membershipCardsFetchedData);
      const cardsTemp = [];
      membershipCardsFetchedData?.map(c => {
        if (c.showvalue === "Y") {
          cardsTemp.push({
            value: c?.cardid,
            label: c?.cardname,
          });
        }
      });
      setCardTypes(cardsTemp);
    }
    else {
      setCardValues([]);
      setCardTypes([]);
    }
  };

  const handleTopUpMembershipCard = async (request) => {
    try {
      setLoading(true);
      const memberData = [];
      formik.values.memberShipCards.map(c => {
        memberData.push({
          memberid: c.cardType.value + c.cardNumber,
          membername: c.name,
        });
      });
      const verifyMemberRequest = {
        cards: memberData
      }
      const verifyMemberResponse = await verifyMemberCards(verifyMemberRequest);
      if (verifyMemberResponse.status === 0) {
        let isValidMembers = true;
        let errors = [];
        verifyMemberResponse?.data?.cards?.forEach(c => {
          if (c?.validated === "N") {
            isValidMembers = false;
            errors.push(t("common:invalidCard", { cardNumber: c.memberid.substr(-16) }));
          }
        });

        if (isValidMembers) {
          const paymentData = {
            "source": "OBI-TOPUPMEMBERCARD",
            "amount": amount,
            "cardholderDetails": formik.values
          };
          const ccResponse = await processCreditCardPayment(paymentData, request);
          return ccResponse;
        } else {
          const errorMessage = errors.join("<br />");
          showError(t("error"), errorMessage);
        }
      }
      else {
        await showError(t("error"), verifyMemberResponse?.statusMessage);
      }

    } catch (e) {
      HandleAPIError(e);
      return null;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getmemberCardValues();
    let sum = calculateTotalAmount();
    setAmount(sum ? sum : 0);
  }, [formik.values.memberShipCards, amount, memberCardValues, membershipCardsFetchedData])

  const generateTopUpMemberCardRequest = (objPayment) => {
    let requestData = {};
    let membercards = [];

    formik.values.memberShipCards.map(card => {
      membercards.push({
        memberid: card.cardType.value + card.cardNumber,
        cardid: card?.cardValuedata?.cardid,
        cardprice: card?.cardValuedata?.cardprice,
        cardvalue: card?.cardValuedata?.cardvalue
      });
    });

    requestData = {
      "topup": {
        "paymentgateway": "FAC",
        "distributorid": distributorid,
        "membercards": membercards,
        "creditcard": {
          "cardnumber": formik.values.cardNumber.slice(-4),
          "cardholder": formik.values.name,
          "currency": DEFAULT_CURRENCYCODE,
          "cardtype": formik.values.cardType,
          "amount": amount,
          "email": formik.values.email,
          "authorizationnumber": objPayment?.creditcard?.authorizationnumber,
        },
        "charged": "Y"
      }
    }

    return requestData;
  }

  const handleSubmit = async () => {
    let error = false;
    let request = {};
    let tmpCardNumberArr = [];
    if (!error) {
      try {
        setLoading(true);
        setSubmitOnClickDisable(true);
        let objPayment = {}

        objPayment = {
          creditcard: {
            cardnumber: formik.values?.cardNumber.slice(-4),
            authorizationnumber: "123456",
            amount: amount,
          },
        }
        let topUpMemberCardLogRequest = generateTopUpMemberCardRequest(objPayment);
        const responseCC = await handleTopUpMembershipCard(topUpMemberCardLogRequest);
        if (responseCC && Object.keys(responseCC).length !== 0 && responseCC?.data?.authorizationnumber && responseCC?.data?.authorizationnumber !== "") {
          objPayment = {
            creditcard: {
              cardnumber: responseCC?.data?.cardnumber,
              authorizationnumber: responseCC?.data?.authorizationnumber,
              amount: amount,
            }
          };
          request = generateTopUpMemberCardRequest(objPayment);
          const response = await topUpMemberCard(request);

          if (response.status === 0) {
            const successInfo = {
              messageHeading: t("topup:topMembershipSuccess"),
              successMessage: t("topup:successMessage", { currencyCode: DEFAULT_CURRENCYCODE, amount: amount.toFixed(2), receiptid: response?.data?.receiptid, email: formik.values.email }),
              jePromotion: true,
              jemPromotion: true
            }
            clearSession(sendBroadcastMessage);
            navigate("/common-success", { state: successInfo });
          }
          else {
            setLoading(false);
            showError(t("error"), response.statusMessage);
            setSubmitOnClickDisable(false);
          }
        }
      } catch (e) {
        HandleAPIError(e);
        return null;
      } finally {
        setLoading(false);
        setSubmitOnClickDisable(false);
      }
    }
  };

  function findDuplicates(arr) {
    const duplicateElements = {};
    const result = [];
  
    for (const item of arr) {
      if (duplicateElements[item]) {
        if (duplicateElements[item] === 1) {
          result.push(item);
        }
        duplicateElements[item]++;
      } else {
        duplicateElements[item] = 1;
      }
    }
  
    return result;
  }

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("loaderMessage")} />
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t('purchasemembership:topUpMembership')}</span>
          </h3>

          <Row className="justify-content-end my-3">
            <Col md={6} className="d-flex justify-content-end mb-2 px-3">
              <CommonButton
                classname="gradient-btn mx-2"
                size="md"
                title={t('purchasemembership:addCard')}
                onclick={addCard}
                disabled={!isCardNumberUnique(memberShipCards)}
              />
              <ExitButton />
            </Col>
          </Row>

          <Form >
            <h6 className="field px-3 mb-3">
              {t('fillAllFields')}
            </h6>
            <h6 className="field px-3 mb-3">
              <ErrorMessages formik={formik} />
            </h6>

            <Row className="justify-content-start my-3">
              <Col md={6} className="d-flex justify-content-start mb-2">
                <h4 className=" my-2 px-3 ">{t('purchasemembership:selectTopupPlan')}</h4>
              </Col>
              <Col md="12">
                {formik.values.memberShipCards.map((card, index) => (
                  <TopUpMemberCardInformation
                    key={index}
                    index={index}
                    cardIndex={index}
                    onRemove={() => removeCard(index)}
                    cardValues={cardValues}
                    cardTypes={cardTypes}
                    formik={formik}
                    onChange={(key, val) => {
                      formik.setFieldValue(`memberShipCards.${index}.${key}`, val);
                      if (key === "cardValue") {
                        let sum = calculateTotalAmount();
                        let currentCard = memberCardValuesData.find(c => c.description === val?.label);
                        formik.setFieldValue(`memberShipCards.${index}.cardValuedata`, currentCard);
                        formik.setFieldValue(`memberShipCards.${index}.cardValueLabel`, currentCard?.valuedescription);
                      }else if (key === "cardNumber") {
                        setMemberShipCards(formik.values.memberShipCards);
                      }
                    }}
                    onBlur={async(key, e) => {
                      formik.handleBlur(e);
                    }}
                  />
                ))}
                <Card className="rounded-3 p-4 mb-2 mx-2">
                  <Row>
                    <Col md={3}>
                      <Form.Label
                        className="d-flex"
                        htmlFor="validationCustomPrice"
                      >
                        {t("amount")} ({DEFAULT_CURRENCYCODE})
                      </Form.Label>
                      <Form.Control
                        required
                        disabled
                        id="validationPrice"
                        type="text"
                        placeholder=""
                        value={amount.toFixed(2)}
                        className="mb-3"
                      />
                    </Col>
                  </Row>
                </Card>

                <h4 className=" p-3 ">{t('purchasemembership:paymentDetails')}</h4>
                <CreditCard
                  formik={formik}
                  isSubmitDisabled={submitOnClickDisable ? submitOnClickDisable : false}
                  onClick={(e) => {
                    scrollToDiv(e, formik)
                    // formik.handleSubmit();
                  }}
                  onChange={(key, val) => {
                    formik.setFieldValue(key, val);
                  }}
                  onBlur={(key, e) => {
                    formik.handleBlur(e);
                  }}
                />
              </Col>

            </Row>
          </Form>
        </Container>
      </section>
    </>
  );
}

export default TopUpMembership;