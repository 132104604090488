import Footer from "../components/Footer";
import Header from "../components/Header";
import Navigationbar from "../components/Navigationbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import { useEffect,useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AFFILIATE_LINK, BC_SESSION, RESTRICTED_LOGIN_LINK } from "../config/commonConstants";
import { resetSignInRedux } from "../redux/signInRedux";
import { checkPromotionCookieExpiry, checkSession, loginFunction } from "../components/commonComponents/commonFunctions";
import useBroadcastChannel from "../hooks/useBroadcastChannel";
import { showInfo } from "../components/commonComponents/Swal";
import { sessionStartTime as SST } from "../redux/signInRedux"
import { useCookies } from "react-cookie";

const MasterLayout = () => {
  const navigate = useNavigate();
  const sendBroadcastMessage = useBroadcastChannel(BC_SESSION, showInfo,navigate);
  const sessionStartTime = useSelector(SST);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const checkInterval = setInterval(() => {
      checkSession(dispatch,sendBroadcastMessage,navigate);
      checkPromotionCookieExpiry()
    }, 5000);


    
    return () => {
      clearInterval(checkInterval);
    };
  }, [dispatch,sendBroadcastMessage,navigate]);

  // Call the function with the pathname
  useLayoutEffect(() => {
    if (!RESTRICTED_LOGIN_LINK.some(linkRegex => location.pathname.match(linkRegex))){
      loginFunction(navigate,location,sendBroadcastMessage,dispatch);
    }
    sessionStartTime === undefined && dispatch(resetSignInRedux());
  },[location.pathname]);
 
  return (
    <div>
      <ScrollToTop />
      <Header sendBroadcastMessage={sendBroadcastMessage} />
      <Navigationbar sendBroadcastMessage={sendBroadcastMessage} />
      <Outlet context={sendBroadcastMessage} />
      <Footer />
    </div>
  );
};

export default MasterLayout;