import { Card, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import { useEffect, useState } from "react";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from 'react-i18next';
import { getOrders } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { faCalendarDays, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ORDER_REPORT_FILTERBY } from "../config/commonConstants";
import Select from "react-select";
import DatepickerComponent from "../components/commonComponents/DatepickerComponent";
import { Table } from "../components/commonComponents/Table";
import {
  createColumnHelper,
} from "@tanstack/react-table"
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { HandleAPIError, dateFormatForDisplay, myDateFormat, omit } from "../components/commonComponents/commonFunctions";
import { CSVLink } from "react-csv";

function OrderReports() {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'orderreport']);
  const location = useLocation();
  const stateVariable = location?.state;
  const [orderdetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterBy, setFilterBy] = useState(ORDER_REPORT_FILTERBY[0]);
  const [orderdetailsexport, setOrderdetailsExport] = useState([]);

  const fetchOldData = async () => {
    if (stateVariable) {
      let tmpStartDate = myDateFormat(stateVariable?.startDate, "MM/dd/yyyy", "yyyyMMdd");
      let tmpEndDate = myDateFormat(stateVariable?.endDate, "MM/dd/yyyy", "yyyyMMdd");
      let tmpFilterBy = stateVariable?.filterBy.value;

      setFilterBy(stateVariable?.filterBy);
      _getOrderApi(tmpStartDate, tmpEndDate, tmpFilterBy);
      setStartDate(dateFormatForDisplay(stateVariable?.startDate, "MM/dd/yyyy"));
      setEndDate(dateFormatForDisplay(stateVariable?.endDate, "MM/dd/yyyy"));
    } else {
      setStartDate(dateFormatForDisplay(new Date(), "MM/dd/yyyy"));
      setEndDate(dateFormatForDisplay(new Date(), "MM/dd/yyyy"));
    }
  }

  useEffect(() => {
    fetchOldData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let tmpStartDate, tmpEndDate, tmpFilterBy;
    tmpStartDate = myDateFormat(startDate, "MM/dd/yyyy", "yyyyMMdd");
    tmpEndDate = myDateFormat(endDate, "MM/dd/yyyy", "yyyyMMdd");
    tmpFilterBy = filterBy.value;
    _getOrderApi(tmpStartDate, tmpEndDate, tmpFilterBy);

  }

  const _getOrderApi = async (tmpStartDate, tmpEndDate, tmpFilterBy) => {
    const data = {
      "startdate": tmpStartDate,
      "enddate": tmpEndDate,
      "entrydatefilter": tmpFilterBy,
    };

    try {
      setLoading(true);
      const response = await getOrders(data)
      if (response?.status === 0) {
        setFormSubmitted(true);
        setOrderDetails(response.data.orders);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const navigateToOrderDetails = (data) => {
    navigate("/order-details", { state: { data, startDate, endDate, filterBy } });
  }

  const exportorderdetails = () => {
    const tmp = (orderdetails.map((l) => {
      return omit(l, ['sequenceid', 'pdfexists'])
    }));

    setOrderdetailsExport(tmp);
    return orderdetailsexport;
  }

  const columnHelper = createColumnHelper();

  const COLUMNS = [
    columnHelper.accessor("reservationid", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('orderreport:reservationID')
    }),
    columnHelper.accessor("product", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('orderreport:Product'),
      className: "right"
    }),
    columnHelper.accessor("contactname", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('orderreport:contactName')
    }),
    columnHelper.accessor("contactphone", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('orderreport:contactPhone')
    }),
    columnHelper.accessor("contactemail", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('orderreport:email')
    }),
    columnHelper.accessor("view", {
      cell: (info) => (
        <div style={{ textAlign: "center" }}>
          <FontAwesomeIcon
            className="ms/1 icon-color pointer"
            icon={faEye}
            size="lg"
            onClick={() => navigateToOrderDetails(info.row.original)}
          />
        </div>
      ),
      header: "",
      enableSorting: false
    }),
  ]

  const csv_headers = [
    { key: "reservationid", label: t('orderreport:csvReservation') },
    { key: "product", label: t('orderreport:Product') },
    { key: "contactname", label: t('orderreport:contactName') },
    { key: "contactphone", label: t('orderreport:contactPhone') },
    { key: "contactemail", label: t('orderreport:email') },
  ];

  return (
    <>
      <section>
        <Container className="my-4">
          <Loader loading={loading} message={t('orderreport:loaderMessage')} />
          <div className="my-3">
            <h3 className="d-flex justify-content-center heading heading">
              <span className="heading">{t('orderreport:orderReport')}</span>
            </h3>
          </div>

          <div className="my-3 px-1 d-flex justify-content-end">
            <Link to="/" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t('back')} />
            </Link>

            <ExitButton />
          </div>

          <Card className="">
            <Card.Body>
              <Row className="d-flex justify-content-start">
                <Col md={4}>
                  <h6 className="field">{t("fillAllFields")}</h6>
                </Col>
              </Row>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="my-2 ">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faFilter}
                          size="lg"
                          className="my-0 me-1 icon-color"
                        />
                        {t('orderreport:filterBy')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        options={ORDER_REPORT_FILTERBY}
                        className="my-0"
                        value={filterBy}
                        onChange={(v) =>
                          setFilterBy(v)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="my-2 ">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          size="lg"
                          className="my-0 me-1 icon-color"
                        />
                        {t('orderreport:startDate')}
                        <span className="field">*</span>
                      </Form.Label>
                      <DatepickerComponent
                        onChangeProps={false}
                        maxDate={filterBy?.value === "Y" ? new Date() : ""}
                        onchange={(v) => { setStartDate(v) }}
                        value={startDate}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="my-2 ">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          size="lg"
                          className="my-0 me-1 icon-color"
                        />
                        {t('orderreport:endDate')}
                        <span className="field">*</span>
                      </Form.Label>
                      <DatepickerComponent
                        onChangeProps={false}
                        minDate={ new Date(startDate)}
                        maxDate={filterBy?.value === "Y" ? new Date() : ""}
                        onchange={(v) => { setEndDate(v) }}
                        value={endDate}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={"auto"} className="d-flex justify-content-start" style={{ height: "38px", marginTop: "40px" }}>
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t('submit')}
                    />
                  </Col>
                </Row>
              </Form>
              {formSubmitted && (
                <Row>
                  <Col md={12}>
                    <Table columns={COLUMNS} data={orderdetails} noDataMessage={t("noDataAvailable")} />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
          {orderdetails.length > 0 &&
            <Row className="my-3 d-flex justify-content-end">
              <Col md={"auto"} className="d-flex justify-content-end">
                <CSVLink filename="order_reports.csv"
                asyncOnClick={true}
                onClick={exportorderdetails}
                headers={csv_headers}
                data={orderdetailsexport} 
                >
                <CommonButton
                  type="button"
                  classname="gradient-btn"
                  title={t('export')}
                />
              </CSVLink>

              </Col>
            </Row>
          }
        </Container>
      </section>
    </>
  );
}

export default OrderReports;
