import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Card, Container, Form, Tabs, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import CreditCard from "../components/commonComponents/CreditCard";
import ManualAuthorization from "../components/commonComponents/ManualAuthorization";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../components/commonComponents/CommonButton";
import MagnaCard from "../components/commonComponents/MagnaCard";
import VipMembershipCard from "../components/commonComponents/VipMembershipCard";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { showError } from "../components/commonComponents/Swal";
import { cartData } from "../redux/cartRedux";
import MappedMemberCardModal from "../components/commonComponents/MappedMemberCardModal";
import { useDispatch, useSelector } from "react-redux";
import {
  processMagnaTransaction, confirmCart,
  getMemberCards,
  processMemberCard,
  getBalance,
  manageFunds, getAuthorizedlogin
} from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import {
  dateFormatForDisplay,
  getProductsIdForCurrentCart, get_affiliate_subaffiliate_data, getSubDistributorId, is_member_card_payment_method_allowed, check_magna_allowed,
  is_manual_payment_method_allowed, clearSession, convertAmountToUSD, convertAmountToUSDFloat, scrollToDiv, divRef, validExpiryOrNot, translateConstantText, getAdultValidationsForDob, getChildValidationsForDob, getInfantValidationsForDob, getDistributorSubDistributorProfile
} from "../components/commonComponents/commonFunctions";
import { logindetails as LD,userDefault as UD  } from "../redux/signInRedux";
import { selectedMarketCurrency } from "../redux/marketRedux";
import { getCurrentDateTime } from "../../src/components/commonComponents/commonFunctions";
import {
  HandleAPIError,
  getCurrentMonth,
} from "../components/commonComponents/commonFunctions";
import { processCreditCardPayment } from "../utils/Fac";
import {
  DIGICELBLACKCARD_LOGIN, EMAIL_MAXLENGTH, PRIORITYPASS_LOGIN, PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_DEPARTURE, DEFAULT_CURRENCYCODE, EMAIL_POLICY, MEMBERCARD_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_CARD_NUMBER_LABEL, CARD_CVV_LENGTH, MEMBERCARD_PIN_MAXLENGTH
} from "../config/commonConstants";
import md5 from "md5";
import ChangeMemberPinModal from "../components/commonComponents/ChangeMemberPinModal";
import useToggle from "../hooks/useToggle";
import PartnerReferenceComponent from "../components/commonComponents/PartnerReferenceComponent";
import { paxCountBookingDetails as PCD, paxCountClear } from "../redux/increasePaxCountRedux";
import Select from "react-select";
import ManualAuthorizationCreditCard from "../components/commonComponents/ManualAuthotrizationCreditCard";
import ResetMemberPinModal from "../components/commonComponents/ResetMemberPinModal";
import { VIPER_Response_Codes } from "../config/viper-response-codes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOutletContext } from "react-router-dom";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";

function Payment() {
  const currencyCode = useSelector(selectedMarketCurrency);
  const sendBroadcastMessage = useOutletContext()
  const cartCompleteData = useSelector(cartData);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [productsId, setProductsId] = useState([]);
  const [responseGetMemberCards, setResponseGetMemberCards] = useState([]);
  const [vipMemberCardsList, setVipMemberCardsList] = useState([]);
  const [defaultTab, setDefaultTab] = useState();
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const distributorProfile = getDistributorSubDistributorProfile();
  const userDefault = useSelector(UD);
  const paxCountBookingDetails = useSelector(PCD);
  const [isVisibleChangeMemberPin, setIsVisibleChangeMemberPin] = useToggle();
  const [isVisibleResetMemberPin, setIsVisibleResetMemberPin] = useState(false);
  const [visibleReferenceBookingModal, setVisibleReferenceBookingModal] = useState(false);
  const [resetMemberPinStatus, setResetMemberPinStatus] = useState(true);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [isVisibleMappedMemberCardModal, setIsVisibleMappedMemberCardModal] = useToggle();
  const [prepaidArray, setPrepaidArray] = useState([]);
  const [barterArray, setBarterArray] = useState([]);
  const [linkCardStatus, setLinkCardStatus] = useState(false);
  const [convertMd5, setConvertMd5] = useState(false);
  const [showManualAuthPayment, setShowMannalAuthPayment] = useState(false);
  const [submitOnClickDisable, setSubmitOnClickDisable] = useState(false);
  const [partnerReferenceNumber, setPartnerReferenceNumber] = useState("");
  const [partnerReferenceNumberForCreditCard, setPartnerReferenceNumberForCreditCard] = useState("");
  const { t } = useTranslation(["common", "payment", "magnacard", "vipmembershipcard", "feedback"]);

  const [partnerReferenceNumberStatus, setPartnerReferenceNumberStatus] = useState(distributorProfile?.referencenumberallowed === "Y" ? false : true);

  const handlPartnerReferenceNumberStatus = (value) => {
    setPartnerReferenceNumberStatus(value);
  }

  const [partnerReferenceNumberStatusForCreditCard, setPartnerReferenceNumberStatusForCreditCard] = useState(distributorProfile?.referencenumberallowed === "Y" ? false : true);

  const handlPartnerReferenceNumberStatusForCreditCard = (value) => {
    setPartnerReferenceNumberStatusForCreditCard(value);
  }


  const focusOnReferenceInfoDivForInvoice = () => {
    const referenceNumberInputField1 = document.getElementById('reference-number1');
    if (referenceNumberInputField1) {
      referenceNumberInputField1.focus();
    }
  }

  const focusOnReferenceInfoDivForCreditCard = () => {
    const referenceNumberInputField2 = document.getElementById('reference-number2');
    if (referenceNumberInputField2) {
      referenceNumberInputField2.focus();
    }
  }

  const loginDetail = useSelector(LD)
  const [status, setStatus] = useState(null);

  let totalWholesaleAmount = 0;
  cartCompleteData.forEach((e) => {
    totalWholesaleAmount = totalWholesaleAmount + e.value?.currentCartItem?.wholesale;
  });

  let totalRetailAmount = 0;
  cartCompleteData.forEach((e) => {
    totalRetailAmount = totalRetailAmount + e.value?.currentCartItem?.retail;
  });

  const populateConfirmationEmail = () => {
    return (cartCompleteData.length > 0 ? cartCompleteData[0]?.value?.data?.passengerInfo?.primaryContactDetails?.email : "");
  }

  const getAmount = (paymentType, cardProfile) => {
    if (paymentType === "CREDITCARD")
      return cardProfile === "GUESTCARD" ? totalRetailAmount : totalWholesaleAmount;
    else if (paymentType === "INVOICE")
      return totalWholesaleAmount;
    else if (paymentType === "VIPMEMBERSHIPCARD")
      return totalRetailAmount;
    else if (paymentType === "PREPAID")
      return totalWholesaleAmount;
    else if (paymentType === "BARTER")
      return totalRetailAmount;
    else if (paymentType === "MAGNACARD")
      return totalRetailAmount;
    else if (paymentType === "MANUALAUTHORIZATION")
      return totalRetailAmount;
    else
      return totalWholesaleAmount;
  }


  const handleMagnaRedeem = async () => {
    try {
      setLoading(true);
      const redeemMagnaPointsReponse = await processMagnaTransaction({
        memberid: formik_magnacard.values.magnaEmail,
        pin: formik_magnacard.values.magnaPassword,
        amount: formik_magnacard.values.amount,
        action: "REDEEMPOINTS",
      });
      if (redeemMagnaPointsReponse !== null) {
        return redeemMagnaPointsReponse;
      } else {
        showError(t("error"), redeemMagnaPointsReponse.statusMessage);
        return null;
      }
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleSelectedRowData = (rowData) => {
    setLinkcardObject({
      // cardHolderName: rowData.membername,
      // cardNumber: rowData.memberid,
      // cardType: rowData.cardname,
      balance: rowData.balance,
      showValue: rowData.showvalue,
      expiryDate: rowData.expirydate,
    });
    formik_vipmembershipcard.setValues({
      ...formik_vipmembershipcard.values,
      memberCards: vipMemberCardsList.find((item) => item.label === rowData.cardname),// rowData.cardname,
      cardNumber: rowData.memberid.slice(-16),
      cardHolderName: rowData.membername,
      pin: rowData.pin,
    })
    if (linkcardObject) {
      setLinkCardStatus(true);
    }

    setVipmembershipcardObject({
      amount: totalRetailAmount,
      memberCards: { value: rowData.cardid, label: rowData.cardname, showValue: rowData.showvalue, },
      cardNumber: (rowData.memberid).replace(/\D/g, ''),
      email: "",
      cardHolderName: rowData.membername,
      pin: rowData.pin,
    })
  };

  const handleGetBalance = async (buckettype) => {
    const bucketID = buckettype.toString().toUpperCase();
    try {
      setLoading(true);
      let getBalanceReponse = await getBalance({
        buckettype: bucketID,
        distributorid: distributorProfile?.distributorid
      });

      if (getBalanceReponse !== null) {
        let allowedField = buckettype + "allowed";
        let productAllowedField = buckettype + "productallowed";

        getBalanceReponse?.data?.buckets.map(tmpBucket => {
          return tmpBucket.cartamount = 0
        })

        cartCompleteData.forEach(cartItem => {
          let productId = cartItem.value?.currentCartItem?.productid;
          let cartItemValue = 0;
          let myBucketId = "";

          if (distributorProfile?.[productAllowedField] === "Y") {
            myBucketId = bucketID + productId;
          } else {
            myBucketId = bucketID;
          }

          if (buckettype === "prepaid") {
            cartItemValue = cartItem.value?.currentCartItem?.wholesale;
          } else { // barter bucket
            cartItemValue = cartItem.value?.currentCartItem?.retail;
          }

          const foundBucket = getBalanceReponse?.data?.buckets?.find((e) => e?.bucketid === myBucketId);

          if (foundBucket) {
            const updatedCartAmount = foundBucket.cartamount + cartItemValue;
            const updatedBucket = { ...foundBucket, cartamount: updatedCartAmount };
            const updatedBuckets = getBalanceReponse.data.buckets.map((e) =>
              e.bucketid === myBucketId ? updatedBucket : e
            );
            getBalanceReponse.data.buckets = updatedBuckets;
          }
        });

        if (buckettype === "prepaid") {
          if (distributorProfile?.[productAllowedField] === "Y") {
            // if prepaidproductallowed is Y, use all buckets
            setPrepaidArray(getBalanceReponse?.data?.buckets.filter(bucket => bucket.bucketid !== bucketID))
          } else {
            // if prepaidproductallowed is N, use main bucket only
            setPrepaidArray(getBalanceReponse?.data?.buckets.filter(bucket => bucket.bucketid === bucketID))
          }
        } else { // barter bucket
          if (distributorProfile?.[productAllowedField] === "Y") {
            setBarterArray(getBalanceReponse?.data?.buckets.filter(bucket => bucket.bucketid !== bucketID))
          } else {
            setBarterArray(getBalanceReponse?.data?.buckets.filter(bucket => bucket.bucketid === bucketID))
          }
        }
      } else {
        showError(t("error"), getBalanceReponse.statusMessage);
        return null;
      }
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleManageFunds = async (buckettype) => {
    try {
      setLoading(true);
      let bucketData = [];
      if (buckettype === "PREPAID") {
        prepaidArray.forEach(e => {
          bucketData.push({
            transactionid: 0,
            bucketid: e?.bucketid,
            amount: currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(e?.cartamount) : e?.cartamount
          });
        });
      } else {
        barterArray.forEach(e => {
          bucketData.push({
            transactionid: 0,
            bucketid: e?.bucketid,
            amount: currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(e?.cartamount) : e?.cartamount
          });
        });
      }
      const manageFundsReponse = await manageFunds({
        action: "HOLDFUNDS",
        distributorid: distributorProfile?.distributorid,
        buckets: bucketData
      });
      if (manageFundsReponse !== null) {
        return manageFundsReponse;
      } else {
        showError(t("error"), manageFundsReponse.statusMessage);
        return null;
      }
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleVIPMembershipVerifyName = async (newPin = "") => {
    
    let pin = "";
    if (newPin === "") {
      if (linkCardStatus === true && convertMd5 === true) {
        pin = md5(formik_vipmembershipcard?.values?.pin)
      }
      else {
        pin = linkCardStatus ? formik_vipmembershipcard?.values?.pin : md5(formik_vipmembershipcard?.values?.pin);
      }
    } else {
      pin = md5(newPin)
    }
    try {
      setStatus(false);
      setLoading(true);
      const validateMemberCardReponse = await processMemberCard({
        "action": "VALIDATE",
        "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
        // "name": vipmembershipcardObject?.cardHolderName,
        "pin": pin,
        "allowexpired": "N"
      });
      if (validateMemberCardReponse?.status === 0) {
        if (validateMemberCardReponse?.data?.reset === "Y") {
          // reset modal displayed
          setIsVisibleChangeMemberPin();
        } else {
          const verifynameMemberCardReponse = await processMemberCard({
            "action": "VERIFYNAME",
            "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
            "name": formik_vipmembershipcard?.values?.cardHolderName,
            "pin": pin,
          });
          if (verifynameMemberCardReponse?.status === 0) {
            setStatus(verifynameMemberCardReponse?.status);
            setAvailableBalance(verifynameMemberCardReponse?.data?.amount);
            return verifynameMemberCardReponse;
          } else {
            showError(t("error"), verifynameMemberCardReponse?.statusMessage);
            setStatus(null); // Reset the status if there is an error
            return null;
          }
        }
      } else if (linkCardStatus && validateMemberCardReponse?.status === VIPER_Response_Codes.MAPPED_MEMBERCARD_INVALID_PIN) {
        setIsVisibleResetMemberPin(true);
        setStatus(null); // Reset the status if there is an error
        return null;
      } else {
        showError(t("error"), validateMemberCardReponse?.statusMessage);
        setStatus(null); // Reset the status if there is an error
        return null;
      }
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleVIPMembershipHoldFunds = async () => {

    try {
      let pin = "";
      if (linkCardStatus === true && convertMd5 === true) {
        pin = md5(formik_vipmembershipcard?.values?.pin)
      }
      else {
        pin = linkCardStatus ? formik_vipmembershipcard?.values?.pin : md5(formik_vipmembershipcard?.values?.pin);
      }
      // setLoading(true);
      const processMemberCardHoldFundReponse = await processMemberCard({
        "action": "HOLDFUNDS",
        "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
        "pin": pin,
        "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(formik_vipmembershipcard?.values?.amount) : formik_vipmembershipcard?.values?.amount
      });
      if (processMemberCardHoldFundReponse !== null) {
        return processMemberCardHoldFundReponse;
      } else {
        showError(t("error"), processMemberCardHoldFundReponse.statusMessage);
        return null;
      }
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      // setLoading(false);
    }
  };

  const handleVIPMembershipReleaseFunds = async () => {
    try {
      // setLoading(true);
      const processMemberCardReleaseFundReponse = await processMemberCard({
        "action": "RELEASEFUNDS",
        "memberid": formik_vipmembershipcard?.values?.memberCards?.value + formik_vipmembershipcard?.values?.cardNumber,
        "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(formik_vipmembershipcard?.values?.amount) : formik_vipmembershipcard?.values?.amount
      });

      if (processMemberCardReleaseFundReponse !== null) {
        return processMemberCardReleaseFundReponse;
      } else {
        showError(t("error"), processMemberCardReleaseFundReponse.statusMessage);
        return null;
      }
    } catch (error) {
      showError(t("error"), error.message);
      return null;
    } finally {
      // setLoading(false);
    }
  };

  const formik_cardholderDetails = useFormik({
    initialValues: {
      amount: totalRetailAmount,
      confirmationEmail: populateConfirmationEmail(),
      copytoEmail: "",
      name: "",
      email: populateConfirmationEmail(),
      cardType: "VISA",
      cardProfile: { value: "GUESTCARD", label: "GUEST CARD" },
      cardNumber: "",
      cardMonth: getCurrentMonth(),
      cardYear: new Date()?.getFullYear()?.toString(),
      cvv: "",
    },
    validationSchema: Yup.object().shape({
      confirmationEmail: Yup.string()
        .required(t("payment:errors.confirmationEmail.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
      copytoEmail: Yup.string()
        .matches(EMAIL_POLICY, t("payment:errors.emailCopyTo.valid")),
      name: Yup.string().required(t("payment:errors.name.required")),
      cardNumber: Yup.string()
        .required(t("payment:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("payment:errors.cardNumber.match"))
        .length(16, t("payment:errors.cardNumber.length")),
      cvv: Yup.string().required(t("payment:errors.cvv.required"))
          .length(CARD_CVV_LENGTH, t("payment:errors.cvv.length")),
      email: Yup.string().required(t("payment:errors.email.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),

      cardMonth: Yup.string().test(
        'test-credit-card-expiration-date',
        t("payment:errors.cardMonth.match"),
        tmpcardmonth => {
          return validExpiryOrNot(formik_cardholderDetails.values.cardYear, tmpcardmonth);
        }
      ),
      cardYear: Yup.string().test(
        'test-credit-card-expiration-date',
        t(""),
        tmpcardyear => {
          return validExpiryOrNot(tmpcardyear, formik_cardholderDetails.values.cardMonth);     
        }
      ),
    }),
    initialTouched : {cardMonth : true, cardYear : true},
    onSubmit: () => handleSubmit(formik_cardholderDetails.values, "CREDITCARD"),
  });

  const formik_vipmembershipcard = useFormik({
    initialValues: {
      amount: totalRetailAmount,
      memberCards: "",
      cardNumber: "",
      email: "",
      cardHolderName: "",
      pin: "",
    },
    validationSchema: Yup.object().shape({
      // confirmationEmail: Yup.string().email(t("payment:errors.confirmationEmail.valid")).required(t("payment:errors.confirmationEmail.required")),
      cardHolderName: Yup.string().required(t("payment:errors.memberShip.memberName.length")),
      cardNumber: Yup.string()
        .required(t("payment:errors.memberShip.cardNumber.required", {membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")}))
        .matches(/^[0-9]+$/, t("payment:errors.memberShip.cardNumber.match", {membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")}))
        .length(16, t("payment:errors.memberShip.cardNumber.length", {membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")})),
      pin: Yup.string().required(t("payment:errors.pin.required")).min(MEMBERCARD_PIN_MAXLENGTH),
      memberCards: Yup.mixed().required(t("payment:errors.memberCards.required",{membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")})),
      //   email: Yup.string().email(t("payment:errors.email.valid")).required(t("payment:errors.email.required")),
    }),
    onSubmit: paymentType => handleSubmit(formik_vipmembershipcard.values, "VIPMEMBERSHIPCARD"),
  });

  const formik_magnacard = useFormik({
    initialValues: {
      amount: totalRetailAmount,
      confirmationEmail: populateConfirmationEmail(),
      copytoEmail: "",
      magnaEmail: "",
      magnaPassword: "",
    },
    validationSchema: Yup.object().shape({
      confirmationEmail: Yup.string()
        .required(t("payment:errors.confirmationEmail.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
        copytoEmail: Yup.string()
        .matches(EMAIL_POLICY, t("payment:errors.emailCopyTo.valid")),
      magnaEmail: Yup.string()
        .required(t("payment:errors.magnaEmail.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),

      cardHolderName: Yup.string().required(t("payment:errors.name.required")),
      cardNumber: Yup.string()
        .required(t("payment:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("payment:errors.cardNumber.match"))
        .length(16, t("payment:errors.cardNumber.length")),
      magnaPassword: Yup.string().required(t("payment:errors.magnaPassword.required")),
      //   email: Yup.string().email(t("payment:errors.email.valid")).required(t("payment:errors.email.required")),
    }),
    onSubmit: paymentType => {
      console.log("payment type", paymentType);
      handleSubmit(formik_magnacard.values, "MAGNACARD")
    },
  });

  const [cardholderDetails, setCardholderDetails] = useState({
    amount: totalRetailAmount,
    confirmationEmail: populateConfirmationEmail(),
    copytoEmail: "",
    name: "",
    email: "",
    cardType: "VISA",
    cardProfile: { value: "GUESTCARD", label: "GUEST CARD" },
    cardNumber: "",
    cardMonth: getCurrentMonth(),
    cardYear: new Date()?.getFullYear()?.toString(),
    cvv: "",
    errors: {},
  });

  const [magnacardObject, setMagnacardObject] = useState({
    amount: totalRetailAmount,
    confirmationEmail: populateConfirmationEmail(),
    copytoEmail: "",
    magnaEmail: "",
    magnaPassword: "",
    errors: {},
  });

  const [vipmembershipcardObject, setVipmembershipcardObject] = useState({
    amount: totalRetailAmount,
    memberCards: "",
    cardNumber: "",
    email: "",
    cardHolderName: "",
    pin: "",
    errors: {},
  });

  const [linkcardObject, setLinkcardObject] = useState({
    balance: 0,
    memberCards: "",
    cardNumber: "",
    cardType: "",
    email: "",
    cardHolderName: "",
    pin: "",
    showValue: "",
    expiryDate: "",
    errors: {},
  });

  const formik_manualAuthorization = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required(t("payment:errors.username.required")),
      password: Yup.string().required(t("payment:errors.password.required")),
    }),
    onSubmit: paymentType => {
      console.log("payment type", paymentType);
      handleSubmit(formik_manualAuthorization.values, "")
    },
  });

  const formik_manualAuthorization_Creditcard = useFormik({
    initialValues: {
      amount: totalRetailAmount,
      confirmationEmail: populateConfirmationEmail(),
      copytoEmail: "",
      name: "",
      email: "",
      cardType: "VISA",
      cardNumber: "",
      authorizationNumber: "",
    },
    validationSchema: Yup.object().shape({
      confirmationEmail: Yup.string()
        .required(t("payment:errors.confirmationEmail.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
      copytoEmail: Yup.string()
        .matches(EMAIL_POLICY, t("payment:errors.emailCopyTo.valid")),
      name: Yup.string().required(t("payment:errors.name.required")),
      cardNumber: Yup.string()
        .required(t("payment:errors.cardNumber.required"))
        .matches(/^[0-9]+$/, t("payment:errors.cardNumber.match"))
        .length(4, t("payment:errors.cardNumber.length")),
      authorizationNumber: Yup.string().required(t("payment:errors.mannualAuthorizationNumber.required")),
      email: Yup.string()
        .required(t("payment:errors.email.required"))
        .matches(EMAIL_POLICY, t("payment:errors.email.valid")),
    }),
    onSubmit: paymentType => {
      console.log("payment type", paymentType);
      handleSubmit(formik_manualAuthorization_Creditcard.values, "MANUALAUTHORIZATION")
    },
  });

  const [manualAuthorizationObject, setManualAuthorizationObject] = useState({
    username: "",
    password: "",
    // errors: {},

    amount: totalRetailAmount,
    confirmationEmail: populateConfirmationEmail(),
    copytoEmail: "",
    name: "",
    email: "",
    cardType: "VISA",
    cardNumber: "",
    authorizationNumber: "",
    errors: {},
  });

  const getManualAuthorizationApi = async (username, password) => {
    const data = {
      "username": username,
      "password": md5(password),
    };
    try {
      setLoading(true);
      const response = await getAuthorizedlogin(data);
      if (response?.status === 0) {
        setShowMannalAuthPayment(true)
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [cardholderDetails, setCardholderDetails] = useState([initialCardholderDetails]);
  // const [formValidity, setformValidity] = useState("");
  const dispatch = useDispatch();
  // const location = useLocation();
  const getMemberCardAvailableForPayment = async (productsid) => {
    let getmembercardsRequest = {}
    productsid?.forEach(id => {
      if (id === PRODUCTID_ARRIVAL)
        getmembercardsRequest = { ...getmembercardsRequest, "arrivalonly": "Y" }
      if (id === PRODUCTID_DEPARTURE)
        getmembercardsRequest = { ...getmembercardsRequest, "departurelounge": "Y" }
      if (id === PRODUCTID_ARRIVALBUNDLE)
        getmembercardsRequest = { ...getmembercardsRequest, "arrivalbundle": "Y" }
    });
    console.log("getmembercardsRequest", getmembercardsRequest);
    const responseGetMemberCards = await getMemberCards(getmembercardsRequest);
    console.log("responseGetMemberCards", responseGetMemberCards);
    setResponseGetMemberCards(responseGetMemberCards?.data);
    let memberCardsArray = [];
    responseGetMemberCards?.data?.map(e => memberCardsArray.push({ value: e?.cardid, label: e?.cardname, showValue: e?.showvalue }));
    setVipMemberCardsList(memberCardsArray);
  }

  useEffect(() => {
    const allowedPaymentTypesDetail = generatePaymentMenu(distributorProfile, userDefault);
    setPaymentTypes(allowedPaymentTypesDetail);
    const products = getProductsIdForCurrentCart(cartCompleteData);
    setProductsId(products);
  }, []);

  const passwordHandler = (password) => {
    setMagnacardObject[0]?.magnaPassword(password);
  };

  const getPaxCountBookingData = () => {
    let data = [];
    if (paxCountBookingDetails?.productid === PRODUCTID_ARRIVALBUNDLE)
      data = [paxCountBookingDetails?.arrivalbundles[0]?.arrivalrecord, paxCountBookingDetails?.arrivalbundles[0]?.departurerecords[0]];
    else if (paxCountBookingDetails?.productid === PRODUCTID_ARRIVAL)
      data[0] = paxCountBookingDetails?.arrivalrecords[0];
    else
      data[1] = paxCountBookingDetails?.departurerecords[0];
    return data;
  };

  const generateConfirmCartRequest = (paymentType, objPayment) => {
    let request = {};
    const cartArray = [];
    console.log("paymentType", paymentType);

    let affiliate_subaffiliate_data = get_affiliate_subaffiliate_data();
    let tmpCartItemAmount = 0;

    cartCompleteData.map((e) => {
      const passengersArray = [];
      e?.value?.data?.passengerInfo?.adults.forEach((a) => {
        passengersArray.push({
          "passengertype": "ADULT",
          "title": a?.title.value,
          "firstname": a?.firstName,
          "lastname": a?.lastName,
          "email": a?.email,
          "phone": e?.value?.data?.passengerInfo?.primaryContactDetails?.phone,
          "dob": a?.dob ? dateFormatForDisplay(a?.dob, "yyyyMMdd") : "",
        });
      });

      e?.value?.data?.passengerInfo?.childs.forEach((a) => {
        passengersArray.push({
          "passengertype": "CHILD",
          "title": a?.title.value,
          "firstname": a?.firstName,
          "lastname": a?.lastName,
          "email": a?.email,
          "phone": e?.value?.data?.passengerInfo?.primaryContactDetails?.phone,
          "dob": dateFormatForDisplay(a?.dob, "yyyyMMdd"),
        });
      });

      e?.value?.data?.passengerInfo?.infant.forEach((a) => {
        passengersArray.push({
          "passengertype": "INFANT",
          "title": a?.title.value,
          "firstname": a?.firstName,
          "lastname": a?.lastName,
          "email": a?.email,
          "phone": e?.value?.data?.passengerInfo?.primaryContactDetails?.phone,
          "dob": dateFormatForDisplay(a?.dob, "yyyyMMdd"),
        });
      });

      if (paymentType === "INVOICE" || paymentType === "PREPAID") {
        tmpCartItemAmount = e?.value?.currentCartItem?.wholesale;
      } else {
        tmpCartItemAmount = formik_cardholderDetails?.values?.cardProfile?.value !== "GUESTCARD" ? e?.value?.currentCartItem?.wholesale : e?.value?.currentCartItem?.retail;
      }
      if (paymentType === "MAGNACARD" || paymentType === "VIPMEMBERSHIPCARD" || paymentType === "PREPAID" || paymentType === "BARTER") {
        if (currencyCode !== DEFAULT_CURRENCYCODE) {
          tmpCartItemAmount = convertAmountToUSDFloat(tmpCartItemAmount);
        }
      }

      let groupbooking = distributorProfile?.groupbookingallowed || "N";
      let groupid = groupbooking === "Y" ? distributorProfile?.groupid : "NA";

      cartArray.push({
        "cartitemid": e?.value?.currentCartItem?.cartitemid,
        "productid": e?.value?.currentCartItem?.productid,
        "referencenumber": partnerReferenceNumber,
        "groupid": groupid,
        "groupbooking": groupbooking,
        "arrivalscheduleid": e?.value?.currentCartItem?.arrivalscheduleid,
        "departurescheduleid": e?.value?.currentCartItem?.departurescheduleid,
        "adulttickets": e?.value?.data?.passengerInfo?.adults?.length,
        "childtickets": e?.value?.data?.passengerInfo?.childs?.length,
        "infanttickets": e?.value?.data?.passengerInfo?.infant?.length,
        "optional": {
          "specialoccasion": e?.value?.data?.passengerInfo?.greetingDetail[0]?.occasion?.value,
          "occasioncomment": e?.value?.data?.passengerInfo?.greetingDetail[0]?.occasionDetail,
          "paddlename": e?.value?.data?.passengerInfo?.greetingDetail[0]?.name
        },
        "passengers": passengersArray,
        "primarycontact": Object.values(paxCountBookingDetails)?.length === 0 ? {
          "title": e?.value?.data?.passengerInfo?.primaryContactDetails?.title.value,
          "firstname": e?.value?.data?.passengerInfo?.primaryContactDetails?.firstName,
          "lastname": e?.value?.data?.passengerInfo?.primaryContactDetails?.lastName,
          "email": e?.value?.data?.passengerInfo?.primaryContactDetails?.email,
          "phone": e?.value?.data?.passengerInfo?.primaryContactDetails?.phone,
        } : {
          "title": "",
          "firstname": paxCountBookingDetails?.primary?.contactname,
          "lastname": paxCountBookingDetails?.primary?.contactname,
          "email": paxCountBookingDetails?.primary?.email,
          "phone": paxCountBookingDetails?.primary?.phone,
        },
        "secondarycontact": Object.values(paxCountBookingDetails)?.length === 0 ? {
          "title": e?.value?.data?.passengerInfo?.secondaryContactDetails?.title.value,
          "firstname": e?.value?.data?.passengerInfo?.secondaryContactDetails?.firstName,
          "lastname": e?.value?.data?.passengerInfo?.secondaryContactDetails?.lastName,
          "email": e?.value?.data?.passengerInfo?.secondaryContactDetails?.email,
          "phone": e?.value?.data?.passengerInfo?.secondaryContactDetails?.phone,
        } : {
          "title": "",
          "firstname": paxCountBookingDetails?.secondary?.contactname,
          "lastname": paxCountBookingDetails?.secondary?.contactname,
          "email": paxCountBookingDetails?.secondary?.email,
          "phone": paxCountBookingDetails?.secondary?.phone,
        },
        "amount": tmpCartItemAmount,
      });
    });

    request = {
      ...((Object.values(paxCountBookingDetails)?.length !== 0) && { "productid": paxCountBookingDetails?.productid }),
      ...((Object.values(paxCountBookingDetails)?.length !== 0) && { "confirmationnumber": getPaxCountBookingData()[0]?.confirmationnumber ? getPaxCountBookingData()[0]?.confirmationnumber : getPaxCountBookingData()[1]?.confirmationnumber }),
      ...((Object.values(paxCountBookingDetails)?.length !== 0) && { "arrivalid": getPaxCountBookingData()[0]?.reservationid ? getPaxCountBookingData()[0]?.reservationid : 0 }),
      ...((Object.values(paxCountBookingDetails)?.length !== 0) && { "departureid": getPaxCountBookingData()[1]?.reservationid ? getPaxCountBookingData()[1]?.reservationid : 0 }),
      "distributorid": getSubDistributorId(),
      "sendconfirmation": {
        "sendto": formik_cardholderDetails?.values?.confirmationEmail,
        "copyto": formik_cardholderDetails?.values?.copytoEmail,
      },
      "cart": cartArray,
      "payment": generatePaymentRequestData(paymentType, objPayment),
      ...((paymentType !== "VIPMEMBERSHIPCARD" && paymentType !== "PREPAID") && { "affiliateid": affiliate_subaffiliate_data.affiliateid }),
      ...((paymentType !== "VIPMEMBERSHIPCARD" && paymentType !== "PREPAID") && { "subaffiliateid": affiliate_subaffiliate_data.subaffiliateid }),
      ...((paymentType !== "VIPMEMBERSHIPCARD" && paymentType !== "PREPAID") && { "httpreferrer": "" }),
      ...((paymentType !== "VIPMEMBERSHIPCARD" && paymentType !== "PREPAID") && { "referrerid": "" }),
      ...((loginDetail?.username === PRIORITYPASS_LOGIN.username || loginDetail?.username === DIGICELBLACKCARD_LOGIN.username) && {
        "loungepartner": {
          "distributorid": distributorProfile?.distributorid,
          "cardholder": cartCompleteData[0]?.value?.data?.passengerInfo?.prioritypass?.name,
          "cardnumber": cartCompleteData[0]?.value?.data?.passengerInfo?.prioritypass?.cardNumber,
          "expirydate": cartCompleteData[0]?.value?.data?.passengerInfo?.prioritypass?.cardYear + cartCompleteData[0]?.value?.data?.passengerInfo?.prioritypass?.cardMonth + "01",
        }
      }),
    };

    return request;
  };

  const generatePaymentRequestData = (paymentType, objPayment) => {
    let paymentRequestData = {};

    if (paymentType === "INVOICE") {
      paymentRequestData = {
        "paymenttype": paymentType,
        "invoice": {
          "invoicedate": dateFormatForDisplay(new Date(), "yyyyMMdd"),
          "currency": currencyCode,
          // amount: totalWholesaleAmount,
          "amount": getAmount(paymentType),
        },
      };
    } else if (paymentType === "CREDITCARD") {
      paymentRequestData = {
        "paymenttype": paymentTypes.cardType === "both" ? formik_cardholderDetails?.values?.cardProfile?.value : paymentTypes.cardprofile[0].value,
        "charged": "Y",
        "creditcard": {
          "cardtype": formik_cardholderDetails?.values.cardType,
          "cardnumber": formik_cardholderDetails?.values.cardNumber.slice(-4),
          "cardholder": formik_cardholderDetails?.values.name,
          "email": formik_cardholderDetails?.values.email,
          "currency": currencyCode,
          "amount": getAmount(paymentType, formik_cardholderDetails?.values?.cardProfile?.value),
          "authorizationnumber": objPayment?.creditcard?.authorizationnumber,
        },
      };
    } else if (paymentType === "MAGNACARD") {
      paymentRequestData = {
        "paymenttype": "MAGNACARD",
        "charged": "N",
        "magnacard": {
          "memberid": formik_magnacard.values.magnaEmail,
          "token": 0, // hardcoded
          "usedpoints": 0, // hardcoded
          "remainingpoints": 0, // hardcoded
          "paymentdate": getCurrentDateTime(),
          "currency": DEFAULT_CURRENCYCODE,
          "chargedamount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(formik_magnacard.values.amount) : formik_magnacard.values.amount
        }
      };
    } else if (paymentType === "VIPMEMBERSHIPCARD") {
      paymentRequestData = {
        "paymenttype": "MEMBERCARD",
        "charged": "Y",
        "membercard": {
          "cardtype": formik_vipmembershipcard?.values?.memberCards?.value,
          "cardnumber": formik_vipmembershipcard?.values?.cardNumber,
          "cardholder": formik_vipmembershipcard?.values?.cardHolderName,
          //   "amount": vipmembershipcardObject?.amount,
          "currency": DEFAULT_CURRENCYCODE,
          "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(getAmount(paymentType)) : getAmount(paymentType),
        },
      };
    } else if (paymentType === "PREPAID") {
      paymentRequestData = {
        "paymenttype": "ACCOUNT",
        "account": {
          "buckets": objPayment,
          "currency": DEFAULT_CURRENCYCODE,
          "paymentdate": dateFormatForDisplay(new Date(), "yyyyMMdd"),
          //  "amount": totalWholesaleAmount
          "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(getAmount(paymentType)) : getAmount(paymentType),
        },
      };
    } else if (paymentType === "BARTER") {
      paymentRequestData = {
        "paymenttype": "ACCOUNT",
        "account": {
          "buckets": objPayment,
          "currency": DEFAULT_CURRENCYCODE,
          "paymentdate": dateFormatForDisplay(new Date(), "yyyyMMdd"),
          "amount": currencyCode !== DEFAULT_CURRENCYCODE ? convertAmountToUSDFloat(getAmount(paymentType)) : getAmount(paymentType),
        },
      };
    } else if (paymentType === "MANUALAUTHORIZATION") {
      paymentRequestData = {
        "paymenttype": "GUESTCARD",
        "charged": "Y",
        "creditcard": {
          "cardtype": "VISA",
          "cardnumber": formik_manualAuthorization_Creditcard?.values?.cardNumber,
          "cardholder": formik_manualAuthorization_Creditcard?.values?.name,
          "email": formik_manualAuthorization_Creditcard?.values?.email,
          "currency": currencyCode,
          "amount": getAmount(paymentType),
          "authorizationnumber": formik_manualAuthorization_Creditcard?.values?.authorizationNumber,
        },
      };
    }

    return paymentRequestData;
  };

  const handleSelect = async (key) => {
    if (key === "prepaid") {
      const handleGetBalanceReponse = await handleGetBalance(key);
    } else if (key === "barter") {
      const handleGetBalanceReponse = await handleGetBalance(key);
    } else if (key === "vipmembership") {
      getMemberCardAvailableForPayment(productsId);
    }
  }

  const getOBISource = () => {
    let source = "OBI-MAIN";
    if (loginDetail?.username === PRIORITYPASS_LOGIN.username) {
      source = "OBI-PRIORITYPASS";
    } else if (loginDetail?.username === DIGICELBLACKCARD_LOGIN.username) {
      source = "OBI-DIGICELBLACK";
    } else {
      source = "OBI-MAIN";
    }
    return source;
  }

  const handleSubmit = async (values, paymentType) => {
    let request = {};
    let error = false;
    if (paymentType === "CREDITCARD") {
    } else if (paymentType === "MAGNACARD") {
      setMagnacardObject({ ...magnacardObject });
    } else if (paymentType === "VIPMEMBERSHIPCARD") {
      setVipmembershipcardObject({ ...vipmembershipcardObject });
    }
    if (distributorProfile?.referencenumberallowed === "Y") {
      if (partnerReferenceNumber === "") {
        showError(t("error"), t("partnerReferenceError"));
        error = true;
      }
    }

    if (error) {
      console.log(error);
    } else {
      setSubmitOnClickDisable(true);
      console.log("paymentType 2", paymentType);
      let objPayment = {};
      let callConfirmCart = false;
      if (paymentType === "MAGNACARD") {
        const responseMagna = await handleMagnaRedeem();
        objPayment = {
          magnacard: {
            orderid: responseMagna.data.orderid,
            points: responseMagna.data.points,
            value: responseMagna.data.value,
          },
        };

        if (responseMagna.status === 0) {
          callConfirmCart = true;
        } else {
          showError(responseMagna.statusMessage);
          setSubmitOnClickDisable(false);
        }
      } else if (paymentType === "INVOICE") {
        objPayment = {};
        callConfirmCart = true;
      } else if (paymentType === "CREDITCARD") {
        try {
          setLoading(true);
          objPayment = {
            creditcard: {
              cardnumber: formik_cardholderDetails?.values?.cardnumber,
              authorizationnumber: 123456,
              amount: getAmount(paymentType, formik_cardholderDetails?.values?.cardProfile?.value),
              //  amount: cardholderDetails?.cardProfile?.value === "GUESTCARD" ? totalRetailAmount : totalWholesaleAmount,
            },
          };

          let logRequest = generateConfirmCartRequest(paymentType, objPayment);
          const paymentData = {
            "source": getOBISource(),
            "amount": getAmount(paymentType, formik_cardholderDetails?.values?.cardProfile?.value),
            "cardholderDetails": formik_cardholderDetails?.values,
          };

          const responseCC = await processCreditCardPayment(paymentData, logRequest);
          console.log("responseCC", responseCC);
          objPayment = {
            creditcard: {
              cardnumber: responseCC?.data.cardnumber,
              authorizationnumber: responseCC?.data.authorizationnumber,
              amount: responseCC?.data.amount,
            },
          };

          if (objPayment && Object.keys(objPayment).length !== 0 && objPayment.creditcard.authorizationnumber && objPayment.creditcard.authorizationnumber != "") {
            callConfirmCart = true;
          } else {
            showError(t("error"), responseCC?.statusMessage);
            setSubmitOnClickDisable(false);
          }
        } catch (e) {
          HandleAPIError(e);
        } finally {
          setSubmitOnClickDisable(false);
          setLoading(false);
        }
      } else if (paymentType === "VIPMEMBERSHIPCARD") {
        const processMemberCardReponse = await handleVIPMembershipVerifyName();
        if (processMemberCardReponse.status === 0) {
          if (processMemberCardReponse?.data?.reset === "Y") {
            // reset modal displayed
            setIsVisibleChangeMemberPin();
            setAvailableBalance(processMemberCardReponse?.data?.amount);
          } else {
            const processMemberCardHoldFundReponse = await handleVIPMembershipHoldFunds();
            if (processMemberCardHoldFundReponse.status === 0) {
              callConfirmCart = true;
              objPayment = {};
            }
          }
        } else {
          showError(t("error"), processMemberCardReponse.statusMessage);
          setSubmitOnClickDisable(false);
        }
      } else if (paymentType === "PREPAID") {
        const handleManageFundsReponse = await handleManageFunds("PREPAID");

        if (handleManageFundsReponse.status === 0) {
          callConfirmCart = true;
          let bucketData = [];
          handleManageFundsReponse?.data?.buckets?.forEach(e => {
            bucketData.push({
              transactionid: e.transactionid,
              bucketid: e?.bucketid,
              amount: e?.amount
            });
          });
          objPayment = bucketData;
        } else {
          showError(t("error"), handleManageFundsReponse.statusMessage);
          setSubmitOnClickDisable(false);
        }
      } else if (paymentType === "BARTER") {
        const handleManageFundsReponse = await handleManageFunds("BARTER");

        if (handleManageFundsReponse.status === 0) {
          callConfirmCart = true;
          let bucketData = [];
          handleManageFundsReponse?.data?.buckets?.forEach(e => {
            bucketData.push({
              transactionid: e.transactionid,
              bucketid: e?.bucketid,
              amount: e?.amount
            });
          });
          objPayment = bucketData;
        } else {
          showError(t("error"), handleManageFundsReponse.statusMessage);
          setSubmitOnClickDisable(false);
        }
      } else if (paymentType === "MANUALAUTHORIZATION") {
        objPayment = {
          creditcard: {
            cardnumber: formik_manualAuthorization_Creditcard?.values?.cardnumber,
            authorizationnumber: formik_manualAuthorization_Creditcard?.values?.authorizationNumber,
            amount: getAmount(paymentType),
          },
        };
        callConfirmCart = true;
      }

      if (callConfirmCart === true) {
        request = generateConfirmCartRequest(paymentType, objPayment);
        try {
          setLoading(true);
          const res = await confirmCart(dispatch, request);
          if (res.status === 0) {
            let successCurrencyCode = currencyCode;
            let successAmount = getAmount(paymentType, formik_cardholderDetails?.values?.cardProfile?.value);

            if (paymentType === "MAGNACARD" || paymentType === "VIPMEMBERSHIPCARD" || paymentType === "PREPAID" || paymentType === "BARTER") {
              successCurrencyCode = DEFAULT_CURRENCYCODE;
              if (currencyCode !== DEFAULT_CURRENCYCODE) {
                successAmount = convertAmountToUSDFloat(successAmount);
              }
            }

            const successInfo = {
              currencyCode: successCurrencyCode,
              amount: successAmount,
              confirmationEmail: formik_cardholderDetails?.values?.confirmationEmail,
              userConfirmationEmail: populateConfirmationEmail(),
              successResponse: res?.data?.response,
              cartData: cartCompleteData,
              userDeafultEmail: userDefault?.email,
              selectedPaymentType: paymentType,
              isPaxCountUpdate: Object.values(paxCountBookingDetails)?.length !== 0 ? true : false
            };
            navigate("/success", { state: successInfo });
          } else {
            // showError(t("error"), res.statusMessage);
            if (paymentType === "VIPMEMBERSHIPCARD") {
              const processMemberCardReleaseFundReponse = await handleVIPMembershipReleaseFunds();
              console.log("processMemberCardReleaseFundReponse", processMemberCardReleaseFundReponse);
            }
            navigate("/failure")
          }
        } catch (error) {
          console.log("error", error);
        } finally {
          setSubmitOnClickDisable(false);
          // await closeSession();
          dispatch(paxCountClear());
          clearSession(sendBroadcastMessage);
          setLoading(false);
        }
      }
    }
  };
  const handlePinSuccessAndVerify = async (newPin) => {
    formik_vipmembershipcard.setFieldValue("pin",newPin);
    await handleVIPMembershipVerifyName(newPin);
  };

  useEffect(()=>{
    // console.log("formik_cardholderDetails.cardMonth",formik_cardholderDetails.cardMonth);
    // console.log("formik_cardholderDetails.cardMonth",formik_cardholderDetails.cardYear);
    formik_cardholderDetails.setFieldValue("amount",totalRetailAmount);
    formik_magnacard.setFieldValue("amount",totalRetailAmount);
    formik_vipmembershipcard.setFieldValue("amount",totalRetailAmount);
    formik_manualAuthorization_Creditcard.setFieldValue("amount",totalRetailAmount);
  },[cartCompleteData,formik_cardholderDetails.values.cardMonth,formik_cardholderDetails.values.cardYear])

  const navigateToBookingSummary = () =>{
    let passngerInfo;
    cartCompleteData.forEach((data) => {
      passngerInfo = data?.value?.data?.passengerInfo === "" ? false : true;
    })
    if(passngerInfo){
      if((getAdultValidationsForDob() === false) || (getChildValidationsForDob() === false) || (getInfantValidationsForDob() === false)){
        navigate("/bookingSummary");
      }
    } else {
      navigate("/bookingSummary");
    }
  }

  useEffect(() => {
    navigateToBookingSummary()
  }, []);

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("loaderMessage")} />
          <ChangeMemberPinModal
            setConvertMd5={setConvertMd5}
            visible={isVisibleChangeMemberPin}
            linkCardStatus={linkCardStatus}
            setResetMemberPinStatus={setResetMemberPinStatus}
            message={"this is change password modal"}
            vipmembershipcardObject={formik_vipmembershipcard?.values}
            cancelModal={async () => {
              setIsVisibleChangeMemberPin(false);
              setIsVisibleResetMemberPin(false);
            }}
            onSuccess={handlePinSuccessAndVerify}
          />
          <ResetMemberPinModal
            setConvertMd5={setConvertMd5}
            visible={isVisibleResetMemberPin}
            setResetMemberPinStatus={setResetMemberPinStatus}
            vipmembershipcardObject={formik_vipmembershipcard?.values}
            // setIsVisibleChangeMemberPin={setIsVisibleChangeMemberPin}
            setIsVisibleChangeMemberPin={async () => {
              setIsVisibleChangeMemberPin(true)
              setIsVisibleResetMemberPin(false)
            }}
            cancelModal={async () => {
              setIsVisibleResetMemberPin(false);
              // await handleVIPMembershipVerifyName();
            }}
            setVipmembershipcardPin={async (newPin) => {
              formik_vipmembershipcard.setFieldValue("pin", newPin);
            }}
            onSuccess={handlePinSuccessAndVerify}
            setStatus={setStatus}
          />
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t("payment:paymentHeading")}</span>
          </h3>
          <div className="my-3  d-flex justify-content-end">
            <Link to="/bookingSummary" className=" mx-1 text-decoration-none">
              <CommonButton classname="back" title={t("back")} />
            </Link>
            <ExitButton />
          </div>
          <Card className="">

            <Card.Body>
            <h6 className="field mb-3">
            {t('fillAllFields')}
          </h6>
        
              <Tabs
                defaultActiveKey={defaultTab}
                id="justify-tab-example"
                className=""
                onSelect={(e) => handleSelect(e)}
              >
                {paymentTypes?.allowedPaymentTypes?.find(
                  (p) => p === "invoice"
                ) && (
                    <Tab eventKey="invoice" title={t("payment:invoice")}>
                      {loading ? <LoadingSkeleton /> :
                      <Row className="justify-content-center">
                        <Col md={9}>
                          <Form
                            noValidate
                            validated={validated}
                          // onSubmit={()=>formik_cardholderDetails.handleSubmit("INVOICE")}
                          >
                            <Row>
                              <Col md={5}>
                                <Form.Label
                                  className="d-flex"
                                  htmlFor="validationCustomPrice"
                                >
                                  {t("amount")} ({currencyCode})
                                </Form.Label>
                                <Form.Control
                                  required
                                  disabled
                                  id="validationPrice"
                                  type="text"
                                  placeholder=""
                                  value={totalWholesaleAmount.toFixed(2)}
                                  className="mb-3"
                                />
                              </Col>
                            </Row>
                            {distributorProfile?.referencenumberallowed === "Y" && <Row>
                              <Col md={5} className="mb-4 d-flex"  >
                                <PartnerReferenceComponent
                                  id="reference-number1"
                                  paymentType="invoice"
                                  partnerReferenceNumber={partnerReferenceNumber}
                                  handlPartnerReferenceNumberStatus={handlPartnerReferenceNumberStatus}
                                  focusOnReferenceInfoDivForInvoice={focusOnReferenceInfoDivForInvoice}
                                  onchange={(val) => {
                                    setPartnerReferenceNumber(val);
                                    val.length === 0 && handlPartnerReferenceNumberStatus(false);
                                  }}
                                  onblur={(val) => {
                                    //   val === "" && handlPartnerReferenceNumberStatus(false);
                                  }}
                                />
                              </Col>
                            </Row>}
                            <Row>
                              <Col md={5}>
                                <CommonButton
                                  classname=" gradient-btn  w-100"
                                  title={t("submit")}
                                  size="md"
                                  type="submit"
                                  disabled={submitOnClickDisable ? submitOnClickDisable : !partnerReferenceNumberStatus}
                                  onclick={(event) => {
                                    setSelectedPaymentType("INVOICE");
                                    handleSubmit(event, "INVOICE");
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                      }
                    </Tab>
                  )}
                {paymentTypes?.allowedPaymentTypes?.find(
                  (p) => p === "creditcard"
                ) && (
                    <Tab eventKey="creditcard" title={t("payment:creditCard")}>
                      {loading ? <LoadingSkeleton /> :
                      <Row className="justify-content-center">
                        <Col md={8}>
                          <Form>
                            <ErrorMessages formik={formik_cardholderDetails} />
                            <div className="mt-4">
                              {
                                <h4 className=" px-1 mb-3 ">
                                  {t("payment:confirmationDetails")}
                                </h4>
                              }
                              <Card className="bg-light bg-opacity-10 rounded-3 px-0 mb-2">
                                <Card.Body>
                                  <Row className="g-3">
                                    <Col md={6}>
                                      <Form.Label
                                        className="d-flex"
                                        htmlFor="validationCustomemail"
                                      >
                                        {t("payment:sendConfirmationEmailTo")}:
                                        <span className="field">*</span>
                                      </Form.Label>
                                      <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">
                                          @
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="email"
                                          name="confirmationEmail"
                                          id="validationCustomemail"
                                          placeholder={t('emailAddress')}
                                          value={formik_cardholderDetails.values.confirmationEmail}
                                          onBlur={formik_cardholderDetails.handleBlur}
                                          onChange={formik_cardholderDetails.handleChange}
                                          className={formik_cardholderDetails.touched.confirmationEmail && formik_cardholderDetails.errors.confirmationEmail ? "error" : ""}
                                          aria-describedby="inputGroupPrepend"
                                          maxLength={EMAIL_MAXLENGTH}
                                          minLength={1}
                                        />
                                        <Form.Control.Feedback>
                                          {t("payment:looksGood")}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          {t("chooseEmailAddress")}.
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Col>
                                    <Col md={6}>
                                      <Form.Label htmlFor="validationCustomcopyemail">
                                        {t("payment:copyTo")} (
                                        {t("payment:optional")})
                                      </Form.Label>
                                      <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">
                                          @
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="email"
                                          name="copytoEmail"
                                          id="validationCustomcopyemail"
                                          placeholder={t('emailAddress')}
                                          aria-describedby="inputGroupPrepend"
                                          value={formik_cardholderDetails.values.copytoEmail}
                                          onBlur={formik_cardholderDetails.handleBlur}
                                          onChange={formik_cardholderDetails.handleChange}
                                          maxLength={EMAIL_MAXLENGTH}
                                          minLength={1}
                                          className={formik_cardholderDetails.touched.copytoEmail && formik_cardholderDetails.errors.copytoEmail ? "error" : ""}

                                        />
                                        <Form.Control.Feedback>
                                          {t("payment:looksGood")}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          {t("chooseEmailAddress")}.
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Col>
                                    {distributorProfile?.referencenumberallowed === "Y" &&
                                      <Col md={6} className="d-flex">
                                        <Form.Group className="">
                                          <Form.Label className="mt-5" htmlFor="validationCustomcopyemail">
                                          </Form.Label>
                                          {/* {partnerReferenceNumberForCreditCard && <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            className="me-2 icon-color"
                                            size="xl"
                                          />} */}
                                        </Form.Group>
                                        <PartnerReferenceComponent
                                          id="reference-number2"
                                          paymentType="creditcard"
                                          partnerReferenceNumber={partnerReferenceNumberForCreditCard}
                                          handlPartnerReferenceNumberStatusForCreditCard={handlPartnerReferenceNumberStatusForCreditCard}
                                          focusOnReferenceInfoDivForCreditCard={focusOnReferenceInfoDivForCreditCard}
                                          onchange={(val) => {
                                            setPartnerReferenceNumberForCreditCard(val);
                                            setPartnerReferenceNumber(val);
                                            val.length === 0 && handlPartnerReferenceNumberStatusForCreditCard(false);
                                          }}

                                        />
                                      </Col>
                                    }
                                    {paymentTypes.cardType === "both" && paymentTypes?.cardprofile.length > 1 && <Col md="6">
                                      <Form.Label className="d-flex" htmlFor="validationCustomCardType">
                                        {t('cardProfile')} <span className="field">*</span>
                                      </Form.Label>
                                      <Select
                                        options={paymentTypes?.cardprofile}
                                        className="my-0"
                                        autosize={true}
                                        value={formik_cardholderDetails.values.cardProfile}
                                        id="payment_cardProfile"
                                        onBlur={(d) => formik_cardholderDetails.handleBlur}
                                        name="cardProfile"
                                        onChange={(d) => {
                                          formik_cardholderDetails.setFieldValue("cardProfile", d)
                                          formik_cardholderDetails.setFieldValue("amount", getAmount("CREDITCARD", d.value))
                                        }
                                        }
                                        getOptionValue={(v) => v.value}
                                        getOptionLabel={(v) => v.label}
                                      />
                                    </Col >}
                                    <Col md={"auto"}>
                                      <Form.Label
                                        className="d-flex"
                                        htmlFor="validationCustomPrice"
                                      >
                                        {t("amount")} ({currencyCode})
                                      </Form.Label>
                                      <Form.Control

                                        disabled
                                        id="validationCustomPrice"
                                        type="text"
                                        placeholder=""
                                        value={(formik_cardholderDetails.values.amount).toFixed(2)}
                                        className=""
                                      />
                                      <Form.Control.Feedback>
                                        {t("payment:looksGood")}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                              <CreditCard
                                formik={formik_cardholderDetails}
                                isSubmitDisabled={submitOnClickDisable ? submitOnClickDisable : partnerReferenceNumberStatusForCreditCard ? false : true}
                                onClick={(e) => {
                                  scrollToDiv(e, formik_cardholderDetails)
                                  // formik_cardholderDetails.handleSubmit();
                                }}
                                onChange={(key, val) => {
                                  formik_cardholderDetails.setFieldValue(key, val);
                                }}
                                onBlur={(key, e) => {
                                  formik_cardholderDetails.handleBlur(e);
                                  console.log("someValue", e.target.value);
                                }}
                              />
                            </div>
                          </Form>
                        </Col>
                      </Row>
                      }
                    </Tab>
                  )}
                {paymentTypes?.allowedPaymentTypes?.find(
                  (p) => p === "magnacard"
                ) && (
                    <Tab eventKey="magnacard" title={t("payment:magnaCard")}>
                      {loading ? <LoadingSkeleton /> :
                      <Row className="justify-content-center">
                        <Col md={8}>
                          <Form
                          >
                            <ErrorMessages formik={formik_magnacard} />
                            <div className="mt-4">
                              <MagnaCard
                                // amount={magnacardObject.amount.toFixed(2)}
                                // confirmationEmail={
                                //   magnacardObject.confirmationEmail
                                // }
                                // copytoEmail={magnacardObject.copytoEmail}
                                // magnaEmail={magnacardObject.magnaEmail}
                                // passwordChange={passwordHandler}
                                // magnaPassword={magnacardObject.magnaPassword}
                                // submitOnClickDisable={submitOnClickDisable}
                                // errors={magnacardObject.errors}
                                formik={formik_magnacard}
                                isSubmitDisabled={submitOnClickDisable ? submitOnClickDisable : partnerReferenceNumberStatusForCreditCard ? false : true}
                                onClick={(e) =>{
                                  setSelectedPaymentType("MAGNACARD");
                                  scrollToDiv(e, formik_magnacard)
                                  handleSubmit(e, "MAGNACARD");
                                  // formik_magnacard.handleSubmit()
                                }}
                                onChange={(key, val) => {
                                  formik_magnacard.setFieldValue(key, val);
                                }}
                              // onClick={(event) => {
                              //   setSelectedPaymentType("MAGNACARD");
                              //   handleSubmit(event, "MAGNACARD");
                              // }}
                              // onChange={(key, val) => {
                              //   magnacardObject[key] = val;

                              //   if (magnacardObject.errors[key]) {
                              //     magnacardObject.errors[key] = "";
                              //   }

                              //   setMagnacardObject({ ...magnacardObject });
                              //   // console.log(magnacardObject)
                              //   // console.log(magnacardObject.magnaEmail);
                              // }}
                              />

                              {/* ))} */}
                            </div>
                          </Form>
                        </Col>
                      </Row>
                      }
                    </Tab>
                  )}
                {paymentTypes?.allowedPaymentTypes?.find(
                  (p) => p === "vipmembership"
                ) && (
                    <Tab
                      eventKey="vipmembership"
                      title={t("payment:vipMembershipCard",{ vipMembershipLabel: translateConstantText("MEMBERCARD_LABEL") })}
                    >
                      {loading ? <LoadingSkeleton /> :
                      <>
                      {userDefault?.subscriber === "Y" &&
                        <MappedMemberCardModal
                          visible={isVisibleMappedMemberCardModal}
                          message={"this is change password modal"}
                          cancelModal={setIsVisibleMappedMemberCardModal}
                          productsId={productsId}
                          onRowSelect={handleSelectedRowData}
                          setStatus={setStatus}
                        />
                      }
{/* {linkCardStatus === true ? (
                        <LinkCardLabel
                          status={status}
                          setStatus={setStatus}
                          amount={vipmembershipcardObject.amount.toFixed(2)}
                          Balance={linkcardObject.balance.toFixed(2)}
                          CardType={linkcardObject.cardType}
                          cardHolderName={linkcardObject.cardHolderName}
                          cardNumber={linkcardObject.cardNumber.replace(/\D/g, "")}
                          expiryDate={linkcardObject.expiryDate}
                          showValue={linkcardObject.showValue}
                          resetMemberPinStatus={resetMemberPinStatus}

                          SelectedCard={() => {
                            setIsVisibleMappedMemberCardModal(true);
                          }}
                          onClick={(event) => {
                            setSelectedPaymentType("VIPMEMBERSHIPCARD");
                            handleSubmit(event, "VIPMEMBERSHIPCARD");
                          }}
                          onSubmitButtonClick={async (event) => {
                            event?.preventDefault();
                            const processMemberCardResponse = await handleVIPMembershipVerifyName();
                            if (processMemberCardResponse?.status === 0) {
                              setSelectedPaymentType("VIPMEMBERSHIPCARD");
                              handleSubmit(event, "VIPMEMBERSHIPCARD");
                            }
                          }}
                          onVerifyButtonClick={async (event) => {
                            event?.preventDefault();
                            const processMemberCardResponse = await handleVIPMembershipVerifyName();
                          }}
                          onChange={(key, val) => {
                            vipmembershipcardObject[key] = val;
                            if (vipmembershipcardObject.errors[key]) {
                              vipmembershipcardObject.errors[key] = "";
                            }
                            setVipmembershipcardObject({ ...vipmembershipcardObject });
                          }}
                        />
                      ) : ( */}
                      <Form>
                        <ErrorMessages formik={formik_vipmembershipcard} />
                        <VipMembershipCard
                          linkCardStatus={linkCardStatus}
                          vipMemberCardsList={vipMemberCardsList}
                          responseGetMemberCards={responseGetMemberCards}
                          passwordChange={passwordHandler}
                          // amount={vipmembershipcardObject.amount}
                          // memberCards={vipmembershipcardObject.memberCards}
                          // cardNumber={vipmembershipcardObject.cardNumber}
                          // email={vipmembershipcardObject.email}
                          // cardHolderName={vipmembershipcardObject.cardHolderName}
                          // pin={vipmembershipcardObject.pin}
                          // errors={vipmembershipcardObject.errors}
                          formik={formik_vipmembershipcard}
                          isSubmitDisabled={submitOnClickDisable ? submitOnClickDisable : partnerReferenceNumberStatusForCreditCard ? false : true}
                          onClick={(e) => {
                            scrollToDiv(e, formik_vipmembershipcard)
                            // formik_vipmembershipcard.handleSubmit();
                          }}
                          onChange={(key, val) => {
                            formik_vipmembershipcard.setFieldValue(key, val);
                          }}
                          resetMemberPinStatus={resetMemberPinStatus}
                          availableBalance={availableBalance}
                          submitOnClickDisable={submitOnClickDisable}
                          status={status}
                          setStatus={setStatus}
                          Balance={linkcardObject.balance}
                          CardType={linkcardObject.cardType}
                          expiryDate={linkcardObject.expiryDate}
                          showValue={linkcardObject.showValue}

                          SelectedCard={() => {
                            setIsVisibleMappedMemberCardModal(true);
                          }}

                          // onClick={(event) => {
                          //   setSelectedPaymentType("VIPMEMBERSHIPCARD");
                          //   handleSubmit(event, "VIPMEMBERSHIPCARD");
                          // }}

                          unsetLinkMemberCard={() => {
                            setStatus(null)
                            setLinkCardStatus(false);
                            // setVipmembershipcardObject({
                            //   amount: totalRetailAmount,
                            //   memberCards: "",
                            //   cardNumber: "",
                            //   email: "",
                            //   cardHolderName: "",
                            //   pin: "",
                            //   errors: {},
                            // });
                            formik_vipmembershipcard.resetForm();
                          }}

                          onSubmitButtonClick={async (event) => {
                            const processMemberCardResponse = await handleVIPMembershipVerifyName();
                            console.log("processMemberCardResponse", processMemberCardResponse);
                            if (processMemberCardResponse?.status === 0) {
                              setSelectedPaymentType("VIPMEMBERSHIPCARD");
                              handleSubmit(event, "VIPMEMBERSHIPCARD");
                            }
                          }}

                          onVerifyButtonClick={async (event) => {
                            if (formik_vipmembershipcard.values.cardHolderName === "" || formik_vipmembershipcard.values.pin === "") {
                              scrollToDiv(event, formik_vipmembershipcard)
                              // formik_vipmembershipcard.handleSubmit();
                              return;
                            }
                            else {
                              event?.preventDefault();
                              await handleVIPMembershipVerifyName();
                            }
                          }
                          }

                        // onChange={(key, val) => {
                        //   vipmembershipcardObject[key] = val;
                        //   if (vipmembershipcardObject.errors[key]) {
                        //     vipmembershipcardObject.errors[key] = "";
                        //   }
                        //   setVipmembershipcardObject({ ...vipmembershipcardObject });
                        // }}
                        />
                      </Form>
                      </>
                      }
                    </Tab>
                  )}
                {paymentTypes?.allowedPaymentTypes?.find(
                  (p) => p === "prepaid"
                ) && (
                    <Tab
                      eventKey="prepaid"
                      title={t("payment:prepaidAccount")}
                    >
                      {loading ? <LoadingSkeleton /> :
                      <>
                      <Row className="g-3">
                        <Col md={12}>
                          <table className="prepaid">
                            <tbody>
                              <tr>
                                <th>{t("payment:accountType")}</th>
                                <th className="text-end">{t("payment:accountBalance")} ({t("usd")})</th>
                                <th className="text-end">{t("payment:cartAmount")} ({t("usd")})</th>
                                {
                                  currencyCode !== DEFAULT_CURRENCYCODE &&
                                  <th className="text-end">{t("payment:cartAmount")} {currencyCode}</th>
                                }
                              </tr>
                              {prepaidArray.map((e, i) =>
                                <tr key={i}>
                                  <td>{distributorProfile?.buckets?.find((b) => b?.bucketid === e.bucketid)?.description}</td>
                                  <td className="text-end">{e?.amount.toFixed(2)}</td>
                                  <td className="text-end">{convertAmountToUSD(e?.cartamount)}</td>
                                  {
                                    currencyCode !== DEFAULT_CURRENCYCODE &&
                                    <td className="text-end">{e?.cartamount.toFixed(2)}</td>
                                  }
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={2}>
                          <CommonButton
                            classname="gradient-btn w-100"
                            title={t("submit")}
                            size="md"
                            disabled={submitOnClickDisable}
                            onclick={(event) => {
                              setSelectedPaymentType("PREPAID");
                              handleSubmit(event, "PREPAID");
                            }}
                          />
                        </Col>
                      </Row>
                      </>
                      }
                    </Tab>
                  )}
                {paymentTypes?.allowedPaymentTypes?.find(
                  (p) => p === "barter"
                ) && (
                    <Tab
                      eventKey="barter"
                      title={t("payment:barterAccount")}
                    >
                     {loading ? <LoadingSkeleton /> :
                      <>
                      <Row className="g-3">
                        <Col md={12}>
                          <table className="prepaid">
                            <tbody>
                              <tr>
                                <th>{t("payment:accountType")}</th>
                                <th className="text-end">{t("payment:accountBalance")} ({t('usd')})</th>
                                <th className="text-end">{t("payment:cartAmount")} ({t('usd')})</th>
                                {
                                  currencyCode !== DEFAULT_CURRENCYCODE &&
                                  <th className="text-end">{t("payment:cartAmount")} {currencyCode}</th>
                                }
                              </tr>
                              {barterArray.map((e, i) =>
                                <tr key={i}>
                                  <td>{distributorProfile?.buckets?.find((b) => b?.bucketid === e.bucketid)?.description}</td>
                                  <td className="text-end">{e?.amount.toFixed(2)}</td>
                                  <td className="text-end">{convertAmountToUSD(e?.cartamount)}</td>
                                  {
                                    currencyCode !== DEFAULT_CURRENCYCODE &&
                                    <td className="text-end">{e?.cartamount.toFixed(2)}</td>
                                  }
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={2}>
                          <CommonButton
                            classname="gradient-btn w-100"
                            title={t("submit")}
                            size="md"
                            disabled={submitOnClickDisable}
                            onclick={(event) => {
                              setSelectedPaymentType("BARTER");
                              handleSubmit(event, "BARTER");
                            }}
                          />
                        </Col>
                      </Row>
                      </>  }
                    </Tab>
                  )}
                {paymentTypes?.allowedPaymentTypes?.find(
                  (p) => p === "manualauthorization"
                ) && (
                    <Tab
                      eventKey="manualauthorization"
                      title={t("payment:manualauthorization")}
                    >
                      {loading ? <LoadingSkeleton /> :
                      <Row className="justify-content-center">
                        <Col md={8}>
                          <Form autoComplete="new-password">
                            <ErrorMessages formik={formik_manualAuthorization} />
                            <div className="bg-danger bg-gradient bg-opacity-50 rounded-1"></div>
                            <div className="mt-4">
                              {showManualAuthPayment === false ? (
                                <Form onSubmit={handleSubmit}>
                                  <ManualAuthorization
                                    // username={manualAuthorizationObject.username}
                                    // password={manualAuthorizationObject.password}
                                    // errors={manualAuthorizationObject.errors}
                                    formik={formik_manualAuthorization}
                                    onClick={(e) => {
                                      if (formik_manualAuthorization.values.username === "" || formik_manualAuthorization.values.password === "") {
                                        scrollToDiv(e, formik_manualAuthorization)
                                        // formik_manualAuthorization.handleSubmit();
                                        return;
                                      }
                                      else {
                                        getManualAuthorizationApi(formik_manualAuthorization.values.username, formik_manualAuthorization.values.password);
                                      }
                                      scrollToDiv(e, formik_manualAuthorization)
                                      // formik_manualAuthorization.handleSubmit();
                                    }}
                                    onChange={(key, val) => {
                                      formik_manualAuthorization.setFieldValue(key, val);
                                    }}
                                  // onClick={async (event) => {
                                  //   event?.preventDefault();
                                  //   getManualAuthorizationApi(manualAuthorizationObject.username, manualAuthorizationObject.password);
                                  // }}
                                  // onChange={(key, val) => {
                                  //   manualAuthorizationObject[key] = val;
                                  //   if (manualAuthorizationObject.errors[key]) {
                                  //     manualAuthorizationObject.errors[key] = "";
                                  //   }
                                  //   setManualAuthorizationObject({
                                  //     ...manualAuthorizationObject,
                                  //   });
                                  // }}
                                  />
                                </Form>
                              ) : null}
                              {showManualAuthPayment === true ? (
                                <Form>
                                  <ErrorMessages formik={formik_manualAuthorization_Creditcard} />
                                  <ManualAuthorizationCreditCard
                                    // confirmationEmail={manualAuthorizationObject.confirmationEmail}
                                    // copytoEmail={manualAuthorizationObject.copytoEmail}
                                    partnerReferenceNumber={partnerReferenceNumber}
                                    //  amount={manualAuthorizationObject.amount.toFixed(2)}
                                    distributorProfileReferencenumberallowed={
                                      distributorProfile?.referencenumberallowed
                                    }
                                    options={paymentTypes?.cardprofile}
                                    //  name={manualAuthorizationObject.name}
                                    //   email={manualAuthorizationObject.email}
                                    //  cardNumber={manualAuthorizationObject.cardNumber}
                                    //   authorizationNumber={manualAuthorizationObject.authorizationnumber}
                                    //   errors={manualAuthorizationObject.errors}
                                    formik={formik_manualAuthorization_Creditcard}
                                    onClick={(e) => {
                                      scrollToDiv(e, formik_manualAuthorization_Creditcard)
                                      // formik_manualAuthorization_Creditcard.handleSubmit();
                                    }}
                                    onChange={(key, val) => {
                                      formik_manualAuthorization_Creditcard.setFieldValue(key, val);
                                    }}
                                  // onClick={(event) => {
                                  //   handleSubmit(event, "MANUALAUTHORIZATION");
                                  // }}
                                  // onChange={(key, val) => {
                                  //   manualAuthorizationObject[key] = val;
                                  //   if (manualAuthorizationObject.errors[key]) {
                                  //     manualAuthorizationObject.errors[key] = "";
                                  //   }
                                  //   setManualAuthorizationObject({
                                  //     ...manualAuthorizationObject,
                                  //   });
                                  // }}
                                  />
                                </Form>

                              ) : null}
                            </div>
                          </Form>
                        </Col>
                      </Row>
                      }
                    </Tab>
                  )}
              </Tabs>
            
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}
const generatePaymentMenu = (distributorProfile, userDefault) => {
  let paymentType = "";
  let cardType = "both";
  let cardprofile = [];
  let allowedPaymentTypes = [];

  if (
    distributorProfile.invoiceonly === "Y" &&
    distributorProfile.groupbookingallowed === "Y"
  ) {
    paymentType = "invoice";
  }
  if (distributorProfile.invoiceonly === "Y") {
    paymentType = "invoice";
  } else if (distributorProfile.guestcardonly === "Y") {
    paymentType = "creditcard";
  } else if (distributorProfile.distributorcardonly === "Y") {
    paymentType = "creditcard";
  }

  if (allowedPaymentTypes.length === 0) {
    if (distributorProfile.invoiceallowed === "Y") {
      allowedPaymentTypes.push("invoice");
      paymentType = "invoice";
      if (
        distributorProfile.guestcardallowed === "Y" ||
        distributorProfile.distributorcardallowed === "Y"
      ) {
        paymentType = "both";
      }
      if (
        distributorProfile.prepaidallowed === "Y" ||
        distributorProfile.barterallowed === "Y"
      ) {
        paymentType = "both";
      }
    }

    if (
      distributorProfile.guestcardallowed === "Y" ||
      distributorProfile.distributorcardallowed === "Y"
    ) {
      allowedPaymentTypes.push("creditcard");
      if (
        distributorProfile.invoiceallowed === "Y" ||
        distributorProfile.prepaidallowed === "Y" ||
        distributorProfile.barterallowed === "Y"
      ) {
        paymentType = "both";
      } else {
        paymentType = "creditcard";
      }
    }
    if (distributorProfile.prepaidallowed === "Y") {
      allowedPaymentTypes.push("prepaid");
      // $bucketid = "PREPAID";
    }
    if (distributorProfile.barterallowed === "Y") {
      allowedPaymentTypes.push("barter");
      // $bucketid = "BARTER";
    }
    if (distributorProfile.invoiceonly === "Y") {
      paymentType = "invoice";
      allowedPaymentTypes.push("invoice");
    } else if (distributorProfile.guestcardonly === "Y") {
      paymentType = "creditcard";
      allowedPaymentTypes.push("creditcard");
    } else if (distributorProfile.distributorcardonly === "Y") {
      paymentType = "creditcard";
      allowedPaymentTypes.push("creditcard");
    }
  }

  if (allowedPaymentTypes.find((p) => p === "creditcard")) {
    if (distributorProfile.guestcardonly === "Y") {
      cardType = "guest";
    }
    if (distributorProfile.distributorcardonly === "Y") {
      cardType = "distributor";
    }
    if (
      distributorProfile.guestcardallowed === "Y" &&
      distributorProfile.distributorcardallowed === "Y"
    ) {
      cardType = "both";
    }
    if (
      distributorProfile.guestcardallowed === "Y" &&
      distributorProfile.distributorcardallowed === "N"
    ) {
      cardType = "guest";
    }
    if (
      distributorProfile.guestcardallowed === "N" &&
      distributorProfile.distributorcardallowed === "Y"
    ) {
      cardType = "distributor";
    }
  }

  if (cardType === "both") {
    cardprofile.push(
      { value: "GUESTCARD", label: "GUEST CARD" },
      { value: "DISTRIBUTORCARD", label: "DISTRIBUTOR CARD" }
    );
  } else if (cardType === "guest") {
    cardprofile.push({ value: "GUESTCARD", label: "GUEST CARD" });
  } else if (cardType === "distributor") {
    cardprofile.push({ value: "DISTRIBUTORCARD", label: "DISTRIBUTOR CARD" });
  }

  if (check_magna_allowed(userDefault)) {
    allowedPaymentTypes.push("magnacard");
  }

  if (is_member_card_payment_method_allowed(userDefault)) {
    allowedPaymentTypes.push("vipmembership");
  }

  if (is_manual_payment_method_allowed(userDefault)) {
    allowedPaymentTypes.push("manualauthorization");
  }
  return {
    allowedPaymentTypes: allowedPaymentTypes,
    cardType: cardType,
    cardprofile: cardprofile,
  };
};

export default Payment;
