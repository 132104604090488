import { Card, Row, Col, Container } from "react-bootstrap";
import { clearVIPERCart, getBookingKeyOrders, linkBooking, setMarketId } from "../redux/apiCalls";
import { useEffect, useState } from "react";
import { useParams, useNavigate, useOutletContext, useLocation} from "react-router-dom";
import { showError, showSuccess } from "../components/commonComponents/Swal";
import BookingSummaryComponent from "../components/commonComponents/BookingSummaryComponent";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import Loader from "../components/commonComponents/Loader";
import { HandleAPIError, externalLogin, getLanguageIdFromAirportName, getMarketIdFromAirportName, marketChangeBroadcast} from "../components/commonComponents/commonFunctions";
import { PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE } from "../config/commonConstants";
import CommonButton from "../components/commonComponents/CommonButton";
import { authed as AUTH, logindetails as LD } from "../redux/signInRedux";
import { Link } from "react-router-dom";
import ExitButton from "../components/commonComponents/ExitButton";
import { setSelectedMarket, selectedMarketId as SM } from "../redux/marketRedux";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";

function LinkBookingDetails() {
  const navigate = useNavigate()
  const { linkedusername, bookingKey, requestid } = useParams();
  const [arrivalRecords, setArrivalRecords] = useState([]);
  const [departureRecords, setDepartureRecords] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const auth = useSelector(AUTH);
  const dispatch = useDispatch();
  const loginDetails = useSelector(LD)
  const sendBroadcastMessage = useOutletContext()
  const selectedMarketId = useSelector(SM);
  const location = useLocation();

  const changeMarket = async (airportid, cartClear) => {
    let request = {};
    let marketid = getMarketIdFromAirportName(airportid);
    let languageid = getLanguageIdFromAirportName(airportid);
    request = {
      "marketid": marketid,
      "languageid": languageid
    }
    await marketChangeBroadcast(sendBroadcastMessage, marketid, cartClear);
    if (marketid === selectedMarketId) {
      // Do nothing
    } else {
      dispatch(setSelectedMarket(marketid));
      try {
        setLoading(true);
        await setMarketId(request);
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const getLinkBookingData = async () => {
    try {
      if (bookingKey) { // When user clicks the link from the email.
        const data = {
          "bookingkey": bookingKey,
        };
        const response = await getBookingKeyOrders(data);
        if (response?.status === 0) {
          setLoading(false);

          let tmpOrderDetail = response.data.orderdetail;
          setOrderDetail(tmpOrderDetail);

          if (tmpOrderDetail.productid === PRODUCTID_ARRIVALBUNDLE) {
            let arrivalrecords = [];
            let departurerecords = [];
            tmpOrderDetail.arrivalbundles.map(async (arrivalbundle, key) => (
              arrivalrecords = arrivalbundle.arrivalrecord,
              departurerecords = arrivalbundle.departurerecords,
              await changeMarket(arrivalrecords.airportid, false),
              setArrivalRecords([arrivalrecords]),
              setDepartureRecords(departurerecords)
            ))
          } else if (tmpOrderDetail.productid === PRODUCTID_ARRIVAL) {           
            tmpOrderDetail.arrivalrecords.map(
              async (arrivalrecord) => {
                await changeMarket(arrivalrecord.airportid, false);
                setArrivalRecords(arrivalrecord)
              }
            );
          }
          else if (tmpOrderDetail.productid === PRODUCTID_DEPARTURE) {
            tmpOrderDetail.departurerecords.map(
              async (departurerecord) => {
                await changeMarket(departurerecord.airportid, false);
                setDepartureRecords(departurerecord)
              }
            );
          }
        } else {
          showError(t("error"), response?.statusMessage);        
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const [isFirstComplete,setIsFirstComplete] = useState(false);
 
  useEffect(()=>{
    externalLogin(location,sendBroadcastMessage,navigate,dispatch,setIsFirstComplete)
  },[])


  useEffect(() => {
    if(isFirstComplete){
      loginDetails?.sessionid && getLinkBookingData();
    }
  }, [loginDetails,isFirstComplete]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let confirmationnumber = 0;
    if (orderDetail?.productid === PRODUCTID_ARRIVALBUNDLE) {
      confirmationnumber = orderDetail?.arrivalbundles[0]?.arrivalrecord ? orderDetail?.arrivalbundles[0]?.arrivalrecord?.confirmationnumber : orderDetail?.arrivalbundles[0]?.departurerecords[0]?.confirmationnumber
    }
    else if (orderDetail?.productid === PRODUCTID_ARRIVAL) {
      confirmationnumber = orderDetail?.arrivalrecords[0]?.confirmationnumber
    } else if (orderDetail?.productid === PRODUCTID_DEPARTURE) {
      confirmationnumber = orderDetail?.departurerecords[0]?.confirmationnumber
    }
    try {
      setLoading(true)
      const request = {
        "linkedusername": linkedusername,
        "bookingkey": bookingKey,
        "confirmationnumber": confirmationnumber,
        "requestid": requestid,
        "productid": orderDetail?.productid
      }
      const response = await linkBooking(request)
      if (response?.status === 0) {
        await showSuccess(t("success"), t("linkbooking:linkSuccessMessage"));
        navigate("/");
      }
      else {
        const res = await showError(t("error"), response?.statusMessage);
        if (res.isConfirmed || res.isDismissed) {
          navigate("/");
        }
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
     {loading ? (
        <> 
          <LoadingSkeleton />
          <Loader loading={loading} message={t("loaderMessage")} />
        </>     
      ) : (
      <Container className="my-3">
        
        <section className="d-flex justify-content-center align-content-center mt-3">
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t("linkbooking:linkBooking")}</span>
          </h3>
        </section>
        <div className="my-3 px-1 d-flex justify-content-end">
          <Link to="/" className=" mx-1 text-decoration-none">
            <CommonButton classname="back" title={t("back")} />
          </Link>
          <ExitButton />
        </div>
        {error === "" ?
          <section className="d-flex justify-content-center">
            <Container className="my-4 ">
              <Card className="">
                <Card.Body>
                  <BookingSummaryComponent
                    renderType={"UPDATE-FLIGHT-DETAILS"}
                    orderDetail={orderDetail}
                    productid={orderDetail.productid}
                  />
                  <Row className="d-flex justify-content-end">
                    <Col md={3} className="d-flex justify-content-end">
                      <CommonButton
                        classname="gradient-btn mx-1"
                        title={t('linkbooking:linkBookingButton')}
                        onclick={handleSubmit}
                        type="submit"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </section>
          :
          (
            <section>
              <Container className="my-4 text-center">
                <Card className="p-4">
                  <Row>
                    <Col md={12} className="d-flex justify-content-center">

                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12}>
                      <div className="mb-3 my-bg-info">
                        {error}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </section>
          )
        }
      </Container>
      )}
    </>
  );
}
export default LinkBookingDetails;
