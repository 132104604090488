import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import OverlayTooltip from "./OverlayTooltip";
import CommonButton from "./CommonButton";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { CONTACT_NUMBER_MAXLENGTH, EMAIL_MAXLENGTH, MEMBERCARD_CARD_VALUE_LABEL, NAME_LENGTH, REGEX_ALPHABET } from "../../config/commonConstants";
import { translateConstantText } from "./commonFunctions";

function MemberAndCardInformation(props) {
  const { onRemove, cardIndex } = props;
  const { t } = useTranslation(["common", "memberandcardinformation"]);
  return (
    <div>
      <Card className="rounded-3 px-0 mb-2 mx-2">
        {cardIndex !== 0 && (
          <Card.Header className=" d-flex justify-content-end">
            <CommonButton
              classname="gradient-btn d-flex justify-content-end"
              size="md"
              title={t('memberandcardinformation:removeCard')}
              onclick={onRemove}
            />
          </Card.Header>
        )}

        <Card.Body>
          <Row className="g-3 mb-3">
            <Col md="4">
              <Form.Label className="d-flex" >
                <OverlayTooltip
                  title={t('memberandcardinformation:memberName')}
                  for="memberinformation_membername"
                  msg={t('memberandcardinformation:memberNameTooltipPurchaseMembership')}
                  mandatory={true}
                ></OverlayTooltip>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t('memberandcardinformation:memberNamePlaceholder')}
                id="memberinformation_membername"
                value={props.formik?.values?.memberShipCards?.[props.index]?.name}
                onChange={(e) => {
                  e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
                  props.onChange("name", e.target.value)
                }}
                maxLength={NAME_LENGTH}
                onBlur={(e) => props.onBlur("name", e)}
                name={`memberShipCards.${props.index}.name`}
                className={props?.formik?.touched["memberShipCards"]?.[props.index]?.["name"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["name"] ? "error rounded-2" : ""}
              />
           
            </Col>
            <Col md="4">
              <Form.Label className="d-flex" htmlFor="memberinformation_email">
                {t('email')}<span className="field">*</span>
              </Form.Label>
              <InputGroup >
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>

                <Form.Control
                  type="email"
                  id="memberinformation_email"
                  placeholder={t('emailAddress')}
                  aria-describedby="inputGroupPrepend"
                  value={props.formik?.values?.memberShipCards?.[props.index]?.email}
                  maxLength={EMAIL_MAXLENGTH}
                  onChange={(e) => props.onChange("email", e.target.value)}
                  onBlur={(e) => props.onBlur("email", e)}
                  name={`memberShipCards.${props.index}.email`}
                  className={props?.formik?.touched["memberShipCards"]?.[props.index]?.["email"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["email"] ? "error rounded-2" : ""}
                />
              
              </InputGroup>
            </Col>
            <Col md="4">
              <Form.Label className="d-flex" htmlFor="memberinformation_confirm_email">
                {t('confirmEmail')} <span className="field">*</span>
              </Form.Label>
              <InputGroup >
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="email"
                  id="memberinformation_confirm_email"
                  placeholder={t('confirmEmailAddress')}
                  aria-describedby="inputGroupPrepend"
                  value={props.formik?.values?.memberShipCards?.[props.index]?.confirmEmail}
                  maxLength={EMAIL_MAXLENGTH}
                  onChange={(e) => props.onChange("confirmEmail", e.target.value)}
                  onBlur={(e) => props.onBlur("confirmEmail", e)}
                  name={`memberShipCards.${props.index}.confirmEmail`}
                  className={props?.formik?.touched["memberShipCards"]?.[props.index]?.["confirmEmail"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["confirmEmail"] ? "error rounded-2" : ""}
                />       
              </InputGroup>
            </Col>
          </Row>

          <Row className="g-3">
            <Col md="4">
              <Form.Label className="d-flex" htmlFor="memberinformation_phone">{t('phone')}  <span className="field">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder={t('contactNumber')}
                id="memberinformation_phone"
                value={props.formik?.values?.memberShipCards?.[props.index]?.phone}
                onChange={(e) => props.onChange("phone", e.target.value.replace(/[^0-9]/g, ""))}
                onBlur={(e) => props.onBlur("phone", e)}
                maxLength={CONTACT_NUMBER_MAXLENGTH}
                name={`memberShipCards.${props.index}.phone`}
                className={props?.formik?.touched["memberShipCards"]?.[props.index]?.["phone"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["phone"] ? "error rounded-2" : ""}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Col>

            <Col md="6">
              <Form.Label className="d-flex" htmlFor="memberinformation_cardvalue">
                {t('cardValue',{membership: translateConstantText("MEMBERCARD_CARD_VALUE_LABEL")})} <span className="field">*</span>
              </Form.Label>
              <Row className="d-flex ">
                <Col md="6">
                <Select
                  options={props?.voucherNumber ? (props.cardIndex===0 ? props.voucherOptions: props.cardValues ) : props.cardValues}
                  isOptionSelected={(option, selectValue) => selectValue.some(i => i.label === option.label)}
                  onChange={(d) => props.onChange("cardValue", d)}
                  name={`memberShipCards.${props.index}.cardValue`}
                  onBlur={(e) => props.onBlur("cardValue", e)}
                  value={props.formik?.values?.memberShipCards?.[props.index]?.cardValue}
                />       
              </Col>
              <Col md="4">
                <Form.Label className="d-flex" >
                  <span className="ms-1 mt-2"> {props?.formik?.values?.memberShipCards?.[props.index]?.cardValueLabel}</span>
                </Form.Label>
                </Col>
              </Row>
            </Col>
            </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default MemberAndCardInformation;
