import BillingReports from "../pages/BillingReports";
import BookingRegistration from "../pages/BookingRegistration";
import BookingForm from "../pages/BookingForm";
import Failure from "../pages/Failure";
import Success from "../pages/Success";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Payment from "../pages/Payment";
import PurchaseMembership from "../pages/PurchaseMembership";
import SubscriberRegistration from "../pages/SubscriberRegistration";
import GiftSummary from "../pages/GiftSummary";
import MemberCardProfile from "../pages/MemberCardProfile";
import Error404 from "../pages/Error404";
import GiftPayment from "../pages/GiftPayment";
import BookingSummary from "../pages/BookingSummary";
import GiftCard from "../pages/GiftCard";
import Feedback from "../pages/Feedback";
import NetworkError from "../pages/NetworkError";
import { createBrowserRouter } from "react-router-dom";
import MasterLayout from "../pages/MasterLayout";
import SuccessFeedback from "../pages/FeedbackSuccess";
import OrderReport from "../pages/OrderReports";
import StandByBookingList from "../pages/StandByBookingList";
import CommissionReports from "../pages/CommissionReports";
import PartnerRegistration from "../pages/PartnerRegistration";
import ManageUser from "../pages/ManageUser";
import SuccessCommon from "../pages/SuccessCommon";
import GetUpdateFlightDetailsLink from "../pages/GetUpdateFlightDetailsLink";
import StandByBookingForm from "../pages/StandByBookingForm";
import ForcePasswordChange from "../pages/ForcePasswordChange";
import BillPayment from "../pages/BillPayment";
import MemberCardForm from "../pages/MemberCardProfileForm";
import ChangeMemberPin from "../pages/ChangeMemberPin";
import ResetMemberPin from "../pages/ResetMemberPin";
import PurchaseMembershipSuccess from "../pages/PurchaseMembershipSuccess";
import UnlockMemberCard from "../pages/UnlockMemberCard";
import MappedMemberCardList from "../pages/MappedMemberCardList";
import MapMemberCardForm from "../pages/MapMemberCardForm";
import TopUpMembership from "../pages/TopUpMembership";
import Topup from "../pages/Topup";
import UpdateFlightDetails from "../pages/UpdateFlightDetails";
import CreditBooking from "../pages/CreditBooking";
import StandByPayment from "../pages/StandByPayment";
import StandByPaymentAuth from "../pages/StandByPaymentAuth";
import ManualBookingForm from "../pages/ManualBookingForm";
import GiftAuth from "../pages/GiftAuth";
import GiftRedeem from "../pages/GiftRedeem";
import GiftRedeemCustomerInformation from "../pages/GiftRedeemCustomerInformation";
import GiftRedeemSummary from "../pages/GiftRedeemSummary";
import DistributorBookingUser from "../pages/DistributorBookingUser";
import MyBookings from "../pages/MyBookings";
import UpdateContactDetails from "../pages/UpdateContactDetails";
import IncreasePaxCount from "../pages/IncreasePaxCount";
import UpdateFlightDetailsForm from "../pages/UpdateFlightDetailsForm";
import LinkBooking from "../pages/LinkBooking";
import GetLinkBookingDetailsLink from "../pages/LinkBookingDetails";
import OrderDetailsDisplay from "../pages/OrderDetailsDisplay";
import UnlockMemberCardLink from "../pages/UnlockMemberCardLink";
import GroupBookingForm from "../pages/GroupBookingForm";
import GiftRedeemSuccess from "../pages/GiftRedeemSuccess";
import GroupBookingSuccess from "../pages/GroupBookingSuccess";
import GiftPaymentSuccess from "../pages/GiftPaymentSuccess";
import VipBookingReffer from "../pages/VipBookingReffer";
import Promotion from "../pages/Promotion";

const routes = [
  {
    path: "/",
    element: <MasterLayout />,
    // role: [""],
    children: [
      {
        path: "/",
        element: <Home />,
        // role: [""],
      },
      {
        path: "/affiliate/:affiliateid/:subaffiliateid?",
        element: <Home />,
        // role: [""],
      },
      {
        path: "/network-error",
        element: <NetworkError />,
        // role: [""],
      },
      {
        path: "/product-arrival",
        element: <BookingForm />,
        // role: [""],
      },
      {
        path: "/product-departure",
        element: <BookingForm />
        // role: [""],
      },
      {
        path: "/product-bundle",
        element: <BookingForm />,
        // role: [""],
      },
      {
        path: "/product-priority-pass-departure",
        element: <BookingForm />,
        // role: [""],
      },
      {
        path: "/product-digicel-black-card-departure",
        element: <BookingForm />,
        // role: [""],
      },
      {
        path: "/registration",
        element: <BookingRegistration />,
        // role: [""],
      },
      {
        path: "/bookingSummary",
        element: <BookingSummary />,
        // role: [""],
      },
      {
        path: "/payment",
        element: <Payment />,
        // role: [""],
      },
      {
        path: "/login",
        element: <Login />,
        // role: [""],
      },
      {
        path: "/billingReport",
        element: <BillingReports />,
        // role: [""],
      },
      {
        path: "/billingReport/bill-payment",
        element: <BillPayment />,
        // role: [""],
      },
      {
        path: "/purchasemembership",
        element: <PurchaseMembership />,
        // role: [""],
      },
      {
        path: "/Success",
        element: <Success />,
        // role: [""],
      },
      {
        path: "/Failure",
        element: <Failure />,
        // role: [""],
      },
      {
        path: "/subscriber-registration",
        element: <SubscriberRegistration />,
        // role: [""],
      },
      {
        path: "/giftcard",
        element: <GiftCard />,
      },
      {
        path: "/giftSummary",
        element: <GiftSummary />,
        // role: [""],
      },
      {
        path: "/member-card/member-profile",
        element: <MemberCardProfile />,
        // role: [""],
      },
      {
        path: "*",
        element: <Error404 />,
        // role: [""],
      },
      {
        path: "/gift-payment",
        element: <GiftPayment />,
        // role: [""],
      },
      {
        path: "/feedback",
        element: <Feedback />,
        // role: [""],
      },
      {
        path: "/feedback-success",
        element: <SuccessFeedback />,
        // role: [""],
      },
      // {
      //   path: "/subscriber-registration-success",
      //   element: <SubscriberRegistartionSuccess />,
      //   // role: [""],
      // },
      {
        path: "/order-report",
        element: <OrderReport />,
        // role: [""],
      },
      {
        path: "/standby-list",
        element: <StandByBookingList />,
        // role: [""],
      },
      {
        path: "/standby-arrival",
        element: <StandByBookingForm />,
        // role: [""],
      },
      {
        path: "/standby-departure",
        element: <StandByBookingForm />,
        // role: [""],
      },
      {
        path: "/standby-bundle",
        element: <StandByBookingForm />,
        // role: [""],
      },
      {
        path: "/commission",
        element: <CommissionReports />,
        // role: [""],
      },
      {
        path: "/subscriber-login",
        element: <Login />,
        // role: [""],
      },
      {
        path: "/partner-registration",
        element: <PartnerRegistration />,
        // role: [""],
      },
      {
        path: "/distributor-users",
        element: <ManageUser />,
        // role: [""],
      },
      {
        path: "/common-success",
        element: <SuccessCommon />,
        // role: [""],
      },
      {
        path: "/booking/get-update-link",
        element: <GetUpdateFlightDetailsLink />,
        // role: [""],
      },
      {
        path: "/force-password-change",
        element: <ForcePasswordChange />,
        // role: [""],
      },
      {
        path: "/purchaseMembership-success",
        element: <PurchaseMembershipSuccess />,
        // role: [""],
      },
      {
        path: "/member-card/card-profile",
        element: <MemberCardForm />,
        // role: [""],
      },
      {
        path: "/member-card/change-pin",
        element: <ChangeMemberPin />,
        // role: [""],
      },
      {
        path: "/member-card/reset-pin",
        element: <ResetMemberPin />,
        // role: [""],
      },
      {
        path: "/member-card-unlock",
        element: <UnlockMemberCard />,
        // role: [""],
      },
      {
        path: "/linked-membercards",
        element: <MappedMemberCardList />,
        // role: [""],
      },
      {
        path: "/mapmembercard",
        element: <MapMemberCardForm />,
        // role: [""],
      },
      {
        path: "/topupmembership",
        element: <TopUpMembership />,
        // role: [""],
      },
      {
        path: "/topup",
        element: <Topup />,
        // role: [""],
      },
      {
        path: "/update-booking/:bookingKey",
        element: <UpdateFlightDetails />,
        // role: [""],
      },
      {
        path: "/credit-booking/:bookingKey",
        element: <CreditBooking />,
        // role: [""],
      },
      {
        path: "/standby",
        element: <StandByPayment />,
        // role: [""],
      },
      {
        path: "/stand-by",
        element: <StandByPaymentAuth />,
        // role: [""],
      },
      {
        path: "/manual-booking",
        element: <ManualBookingForm />,
      },
      {
        path: "/gift-auth",
        element: <GiftAuth />,
        // role: [""],
      },
      {
        path: "/gift-redeem",
        element: <GiftRedeem />,
        // role: [""],
      },
      {
        path: "/gift-redeem-user-info",
        element: <GiftRedeemCustomerInformation />,
        // role: [""],
      },
      {
        path: "/gift-redeem-summary",
        element: <GiftRedeemSummary />,
        // role: [""],
      },
      {
        path: "/distributor-booking-user",
        element: <DistributorBookingUser />,
      },
      {
        path: "/my-bookings",
        element: <MyBookings />,
      },
      {
        path: "/update-contact-details",
        element: <UpdateContactDetails />,
      },
      {
        path: "/update-booking",
        element: <UpdateFlightDetails />,
        // role: [""],
      },
      {
        path: "/increase-pax",
        element: <IncreasePaxCount />,
      },
      {
        path: "/update-booking-form",
        element: <UpdateFlightDetailsForm />,
        // role: [""],
      },
      {
        path: "/link-booking-queue",
        element: <LinkBooking />,
        // role: [""],
      },
      {
        path: "/link-booking/:linkedusername/:bookingKey/:requestid",
        element: <GetLinkBookingDetailsLink />,
        // role: [""],
      },
      {
        path: "/order-details",
        element: <OrderDetailsDisplay />,
        // role: [""],
      },
      {
        path: "/unlock-member-card/:loginKey",
        element: <UnlockMemberCardLink />,
        // role: [""],
      },
      {
        path: "/registration/:loginKey",
        element: <PartnerRegistration />,
        // role: [""],
      },
      {
        path: "/group-booking",
        element: <GroupBookingForm />,
        // role: [""],
      },
      {
        path: "/gift-redeem-success",
        element: <GiftRedeemSuccess />,
        // role: [""],
      },
      {
        path: "/group-booking-success",
        element: <GroupBookingSuccess />,
        // role: [""],
      },
      {
        path: "/gift-payment-success",
        element: <GiftPaymentSuccess />,
        // role: [""],
      },
      {
        path: "/referrer",
        element: <VipBookingReffer />,
        // role: [""],
      },
      {
        path: "/member-card-voucherdiscount/:voucherNumber",
        element: <PurchaseMembership />,
        // role: [""],
      },
      {
        path: "/market",
        element: <VipBookingReffer />,
        // role: [""],
      },
      {
        path: "/feedback/qrcode",
        element: <Feedback />,
        // role: [""],
      },
      {
        path: "/promotion/:bookingKey",
        element: <Promotion />,
        // role: [""],
      },
    ],
  },
];

// const routeDefinitions = createRoutesFromElements(
//   <Route element={<MasterLayout />} >
//       {routes?.map((route, index) => {
//               return (
//                 <Route exact path={route.path} element={route.element} />
//               );
//           })}
//   </Route>
// );

const router = createBrowserRouter(routes);
export default router;
