import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import CommonButton from '../components/commonComponents/CommonButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ExitButton from '../components/commonComponents/ExitButton';
import { useTranslation } from 'react-i18next';
import Loader from '../components/commonComponents/Loader';
import { sendUnlockNotification } from '../redux/apiCalls';
import { HandleAPIError, divRef, getMemberCardsArray, renderErrorMessages, scrollToDiv, translateConstantText } from '../components/commonComponents/commonFunctions';
import { showError, showSuccess } from '../components/commonComponents/Swal';
import { SHA256 } from 'crypto-js';
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_NUMBER_MAXLENGTH, REGEX_NUMERIC } from '../config/commonConstants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { memberCards as MC } from "../redux/memberCardRedux";
import { useSelector } from "react-redux";
import ErrorMessages from '../components/commonComponents/ErrorMessages';

function UnlockMemberCard() {
  const { t } = useTranslation(['common', 'memberandcardinformation']);
  const memberCards = useSelector(MC);
  const [memberCardTypeArr, setMemberCardTypeArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    cardType: Yup.object().required(t("memberandcardinformation:errors.cardType.required", { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })),
    cardNumber: Yup.string()
      .required(t("memberandcardinformation:errors.cardNumber.required", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
      .matches(/^[0-9]+$/, t("memberandcardinformation:errors.cardNumber.match", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
      .length(16, t("memberandcardinformation:errors.cardNumber.length", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })),
  });
  const formik = useFormik({
    initialValues: {
      cardType: "",
      cardNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => handleSubmit(formik.values)
  })
  const handleSubmit = async (formik) => {
    const data = {
      "unlockkey": SHA256(formik.cardType.value + formik.cardNumber).toString().toLowerCase(),
    };
    try {
      const response = await sendUnlockNotification(data);
      if (response?.status === 0) {
        await showSuccess(response.statusMessage, t('memberandcardinformation:unlockMemberCardSuccessMessage'));
        formik.cardType = "";
        formik.cardNumber = '';
      } else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }
  const setMemberCardArray = () => {
    const memberCardsArray = getMemberCardsArray(memberCards);
    setMemberCardTypeArr(memberCardsArray);
  };

  useEffect(() => {
    setMemberCardArray();
  }, [memberCards]);

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t('memberandcardinformation:loaderMessage')} />

          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">{t('memberandcardinformation:unlockMemberCardHeading')}</span>
          </h3>
          <Row className="my-3 d-flex justify-content-end align-content-right">
            <Col md={12} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3">
            <Card.Body>
                <Row className="d-flex justify-content-start">
                  <Col md={3}>
                    <h6 className="field">{t('fillAllFields')}</h6>
                  </Col>
                </Row>
              <ErrorMessages formik={formik} />
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <Row className="d-flex justify-content-center">
                  <Col md={3}  className="mb-3">
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                        {t('memberandcardinformation:cardType', { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        defaultValue={formik.values.cardType}
                        options={memberCardTypeArr}
                        name="cardType"
                        value={formik.values.cardType}
                        onChange={(d) => formik.setFieldValue('cardType', d)}
                        onBlur={(d) => formik.handleBlur}
                        className={formik.touched.cardType && formik.errors.cardType ? "error rounded-2" : ""}
                      />

                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col md={3} className="mb-3">
                    <Form.Label className="d-flex" htmlFor="cardNumber">
                      {t('memberandcardinformation:cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}
                      <span className="field">*</span>
                    </Form.Label>
                    <InputGroup className="d-flex">
                      <Form.Control
                        type="text"
                        id="cardNumber"
                        name="cardNumber"
                        placeholder={t('memberandcardinformation:cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}
                        value={formik.values.cardNumber.replace(REGEX_NUMERIC, "")}
                        maxLength={MEMBERCARD_NUMBER_MAXLENGTH}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={formik.touched.cardNumber && formik.errors.cardNumber ? "error" : ""}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      classname="gradient-btn"
                      size="md"
                      title={t('submit')}
                      disabled={formik.isSubmitting}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default UnlockMemberCard;
