import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import CommonButton from "./CommonButton";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { resetPassword } from "../../redux/apiCalls";
import { showSuccess, showError } from "./Swal";
import { HandleAPIError, divRef, renderErrorMessages, scrollToDiv } from "./commonFunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "./ErrorMessages";
import { NAME_LENGTH } from "../../config/commonConstants";

export default function ForgotPasswordModal(props) {
  const { t } = useTranslation(["common", "forgotpassword"]);
  const [loading, setLoading] = useState(false);
  const[render,setRender] = useState(false)
  // const [userName, setUserName] = useState({ value: "", error: "" });

  const cancelHandler = () => {
    props.cancelModal();
    formik.resetForm(); 
    setRender(!render)
  };

  const handleSubmit = async (formik) => {
    // event.preventDefault();
    let isFormValid = true; // Add a variable to track form validity
    if (isFormValid) {
 
    const data = {
      "username": formik.username,
    };
    setLoading(true);

    try {
      const response = await resetPassword(data, "N");
      if (response?.status === 0){
        showSuccess("",t('manageuser:resetPasswordMessage'));
        props.cancelModal();
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("subscriberregistration:errors.userName.required")),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema,
    onSubmit: async () => {
      await handleSubmit(formik.values)
      formik.resetForm();
     },
  });

  useEffect(()=>{
    formik.setErrors({}, false);
    formik.setTouched({}, false);
  },[render])

  return (
    <>
      <Modal
        show={props.visible}
        onHide={cancelHandler}
        size="md"
        style={{ zIndex: "9998" }}
        centered
      >
        <Modal.Header closeButton><h3>{t("forgotPassword")}</h3></Modal.Header>
        <Loader loading={loading} message={t("forgotpassword:processing")} />
        <Form onSubmit={(e) => scrollToDiv(e, formik)} ref={divRef}>
          <Modal.Body className="mb-3">
            <Row className="d-flex justify-content-start">
              <Col md={12}>
                <h6 className="field">{t("fillAllFields")}</h6>
              </Col>
            </Row>
            <ErrorMessages formik={formik} />
            <Row className="d-flex justify-content-center">
              <Col md={12}>
                <Row>
                  <Col md="3" className="mt-2">
                    <Form.Label>
                      {t("userName")}
                      <span className="field">*</span>
                    </Form.Label>
                  </Col>
                  <Col md="9" className="">
                    <Form.Control
                      type="text"
                      placeholder={t("forgotpassword:userName")}
                      id="username"
                      maxLength={NAME_LENGTH}
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched.username && formik.errors.username
                          ? "error"
                          : ""
                      }
                    />
                    {formik.touched.username}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col md="12" className="p-0">
                <CommonButton
                  classname="exit"
                  title={t("close")}
                  onclick={cancelHandler}
                />
                <CommonButton
                  classname="gradient-btn ms-2"
                  title={t("sendPassword")}
                  type="submit"
                />
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
