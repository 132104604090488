import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import {
  PEOPLE_COUNT,
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_ARRIVAL,
  PRODUCTID_DEPARTURE,
} from "../config/commonConstants";
import OverlayTooltip from "../components/commonComponents/OverlayTooltip";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faChild,
  faMessage,
  faPeopleRoof,
  faPersonBreastfeeding,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../redux/marketRedux";
import {
  dateFormatForDisplay,
  getAirlineNames,
  getFlightNumbers,
  getMarketLoungenamesWithAirport,
  getProductName,
  getClubName,
  getAirportName,
  divRef,
  scrollToDiv,
} from "../components/commonComponents/commonFunctions";
import { reserveCartItem } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import {
  setflightArrivalData,
  setflightDepartureData,
} from "../redux/flightScheduleRedux";
import { showError } from "../components/commonComponents/Swal";
import { giftRedeemData } from "../redux/giftCartRedux";
import {
  HandleAPIError,
  getAdultDisplayAge,
  getChildDisplayAge,
  getInfantDisplayAge,
} from "../components/commonComponents/commonFunctions";
import {
  giftRedeemBookingDetailUpdate,
  giftRedeemFetchSuccess,
} from "../redux/giftCartRedux";
import FlightDetailsComponent from "../components/commonComponents/FlightDetailsComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function GiftRedeem(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const giftBookingInfo = location?.state?.giftBookingInfo;
  const giftcartitemid = location?.state?.giftcartitemid;

  const selectedMarket = useSelector(currentMarket);

  const initialBookingDetails = {
    lounge: "",
    flightDate: null,
    airlineName: "",
    airlineId: "",
    flightNumber: "",
    flightTime_hour: "",
    errors: "",
  };

  let productid = location?.state?.productid;

  const currentcartitemid = location?.state?.cartitemid;
  const ascheduleID = location?.state?.arrivalscheduleid;
  const dscheduleID = location?.state?.departurescheduleid;

  const adultCountForEdit = location?.state?.adultCount;
  const childCountForEdit = location?.state?.childCount;
  const infantCountForEdit = location?.state?.infantCount;
  const currentBookingDetail = useSelector(giftRedeemData)?.find(
    (c) => c.key === currentcartitemid
  )?.value?.bookingDetail;
  const flightDetailsForEdit = useSelector(giftRedeemData)?.find(
    (c) => c.key === currentcartitemid
  )?.value?.flightDetails;
  const airlineIdForEdit = useSelector(giftRedeemData)?.find(
    (c) => c.key === currentcartitemid
  )?.value?.airlineId;

  const currentPassengerInfo =
    useSelector(giftRedeemData)?.find((c) => c.key === currentcartitemid)?.value
      ?.data?.passengerInfo ?? "";

  const { t } = useTranslation([
    "common",
    "journeydetails",
    "giftredeem",
    "standbybookinglist",
  ]);
  const [loading, setLoading] = useState(false);
  const [airlineId, setAirlineId] = useState([]);
  const [adultCount, setAdultCount] = useState({
    value:
      giftBookingInfo?.adulttickets > 0
        ? { value: "1", label: "1" }
        : { value: "0", label: "0" },
    error: "",
  });
  const [childCount, setChildCount] = useState({
    value:
      giftBookingInfo?.childtickets > 0
        ? { value: "1", label: "1" }
        : { value: "0", label: "0" },
    error: "",
  });
  const [infantCount, setInfantCount] = useState({
    value: { value: "0", label: "0" },
    error: "",
  });
  const [clubType, setClubType] = useState([]);
  const bookingType = ["Arrival Details", "Departure Details"];
  const [arrivalscheduleid, setArrivalscheduleid] = useState();
  const [departurescheduleid, setDeparturescheduleid] = useState();
  const [cartItemId, setCartItemId] = useState();
  const [flightDetails, setFlightDetails] = useState([]);
  const [tmparrivalscheduleid, setTmpArrivalscheduleid] = useState(null);
  const [tmpdeparturescheduleid, setTmpDeparturescheduleid] = useState(null);

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );

  const validationSchema = Yup.object().shape({
    bookingDetail: Yup.array().of(
      Yup.object().shape({
        // lounge: Yup.string()
        //   .test(
        //     "arrival-lounge",
        //     productid === PRODUCTID_ARRIVALBUNDLE ||
        //       productid === PRODUCTID_ARRIVAL
        //       ? t("journeydetails:errors.arrivalLounge.required")
        //       : t("journeydetails:errors.departureLounge.required"),
        //     function (value) {
        //       return this.path !== "bookingDetail[0].lounge" || value;
        //     }
        //   )
        //   .test(
        //     "deaprture-lounge",
        //     t("journeydetails:errors.departureLounge.required"),
        //     function (value) {
        //       return this.path !== "bookingDetail[1].lounge" || value;
        //     }
        //   ),
        flightDate: Yup.date()
          .nullable()
          .test(
            "arrival-flightDate",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightDate.required")
              : t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[0].flightDate" || value;
            }
          )
          .test(
            "deaprture-flightDate",
            t("journeydetails:errors.departureFlightDate.required"),
            function (value) {
              return this.path !== "bookingDetail[1].flightDate" || value;
            }
          ),
        airlineName: Yup.object()
          .test(
            "arrival-airlineName",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalAirlineName.required")
              : t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              //  console.log({ value });
              return this.path !== "bookingDetail[0].airlineName" || value;
            }
          )
          .test(
            "deaprture-airlineName",
            t("journeydetails:errors.deaptureAirlineName.required"),
            function (value) {
              //  console.log({ value });
              return this.path !== "bookingDetail[1].airlineName" || value;
            }
          ),
        flightNumber: Yup.object()
          .test(
            "arrival-flightNumber",
            productid === PRODUCTID_ARRIVALBUNDLE ||
              productid === PRODUCTID_ARRIVAL
              ? t("journeydetails:errors.arrivalFlightNumber.required")
              : t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              //   console.log({ value });
              return this.path !== "bookingDetail[0].flightNumber" || value;
            }
          )
          .test(
            "deaprture-flightNumber",
            t("journeydetails:errors.departureFlightNumber.required"),
            function (value) {
              //  console.log({ value });
              return this.path !== "bookingDetail[1].flightNumber" || value;
            }
          ),
      })
    ),
    adultCount: Yup.object()
      .required(t("journeydetails:errors.flightNumber.required"))
      .test(
        "infatn-count-validate",
        t("journeydetails:errors.adult.count"),
        (adultCount) => {
          let count =
            parseInt(adultCount.value) +
            parseInt(formik.values.childCount.value);
          if (count === 0) {
            return false;
          }
          return true;
        }
      ),
    childCount: Yup.object().test(
      "child-count-validate",
      t(""),
      (childCount) => {
        let count =
          parseInt(formik.values.adultCount.value) + parseInt(childCount.value);
        if (count === 0) {
          return false;
        }
        return true;
      }
    ),
    // infantCount: Yup.object().required(t("feedback:errors.adultCount.required")),
  });

  const initial = {
    // ... other fields
    bookingDetail: [],
    adultCount:
      currentcartitemid !== 0
        ? adultCountForEdit
        : giftBookingInfo?.adulttickets > 0
        ? { value: "1", label: "1" }
        : { value: "0", label: "0" },
    childCount:
      currentcartitemid !== 0
        ? childCountForEdit
        : giftBookingInfo?.childtickets > 0
        ? { value: "1", label: "1" }
        : { value: "0", label: "0" },
    infantCount:
      currentcartitemid !== 0 ? infantCountForEdit : { value: "0", label: "0" },
  };

  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  const handleArrivalDepartureScheduleIdChange = async (
    scheduleid,
    i,
    productid
  ) => {
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      if (i === 0) {
        setArrivalscheduleid(scheduleid);
        setTmpArrivalscheduleid(scheduleid); // tmp for schedule id
        if (
          location.state?.departurescheduleid &&
          location.state?.departurescheduleid !== null
        ) {
          setDeparturescheduleid(location.state?.departurescheduleid);
        } else if (location.state?.cartItemId === 0) {
          setDeparturescheduleid(tmpdeparturescheduleid);
        } else {
          setDeparturescheduleid(tmpdeparturescheduleid);
        }
        // await setDeparturescheduleid(tmpdeparturescheduleid);
      } else {
        setDeparturescheduleid(scheduleid);
        setTmpDeparturescheduleid(scheduleid); // tmp for schedule id
        if (
          location.state?.arrivalscheduleid &&
          location.state?.arrivalscheduleid !== null
        ) {
          setArrivalscheduleid(location.state?.arrivalscheduleid);
        } else if (location.state?.cartItemId === 0) {
          setArrivalscheduleid(tmparrivalscheduleid);
        } else {
          setArrivalscheduleid(tmparrivalscheduleid);
        }
        // await setArrivalscheduleid(tmparrivalscheduleid);
      }
    } else if (productid === PRODUCTID_ARRIVAL && i === 0) {
      setArrivalscheduleid(scheduleid);
    } else if (productid === PRODUCTID_DEPARTURE && i === 0) {
      setDeparturescheduleid(scheduleid);
    }
  };

  useEffect(() => {}, [currentcartitemid]);

  useEffect(() => {
    if (currentcartitemid !== 0) {
      setArrivalscheduleid(ascheduleID);
      setDeparturescheduleid(dscheduleID);
      setFlightDetails({ ...flightDetailsForEdit });
      setAirlineId({ ...airlineIdForEdit });
      productid === PRODUCTID_ARRIVAL &&
        formik.setFieldValue("bookingDetail", [currentBookingDetail]);
      productid === PRODUCTID_DEPARTURE &&
        formik.setFieldValue("bookingDetail", [currentBookingDetail]);
      productid === PRODUCTID_ARRIVALBUNDLE &&
        formik.setFieldValue("bookingDetail", [
          { ...currentBookingDetail[0] },
          { ...currentBookingDetail[1] },
        ]);
    } else {
      if (productid === PRODUCTID_ARRIVAL) {
        initialBookingDetails.lounge = giftBookingInfo?.arrivalairportid;
        formik.setFieldValue("bookingDetail", [{ ...initialBookingDetails }]);
      } else if (productid === PRODUCTID_DEPARTURE) {
        initialBookingDetails.lounge = giftBookingInfo?.departureairportid;
        formik.setFieldValue("bookingDetail", [{ ...initialBookingDetails }]);
      } else if (productid === PRODUCTID_ARRIVALBUNDLE) {
        const firstBookingDetails = { ...initialBookingDetails };
        const secondBookingDetails = { ...initialBookingDetails };
        firstBookingDetails.lounge = giftBookingInfo?.arrivalairportid;
        secondBookingDetails.lounge = giftBookingInfo?.departureairportid;
        formik.setFieldValue("bookingDetail", [firstBookingDetails, secondBookingDetails]);
      }
    }
    setClubType(marketLoungenameswithairport);
  }, []);

  const handleSubmit = async () => {
    const data = {
      cartitemid: location.state.cartitemid,
      productid: productid,
      ticketsrequested:
        parseInt(adultCount?.value?.value) + parseInt(childCount?.value?.value),
      adulttickets: parseInt(adultCount?.value?.value),
      childtickets: parseInt(childCount?.value?.value),
      paymenttype: "GUESTCARD",
      distributorid: "",
    };

    let error = false;
    if (!error) {
      if (productid === PRODUCTID_ARRIVALBUNDLE) {
        dispatch(
          setflightArrivalData(
            formik.values.bookingDetail[0]
              ? { ...formik.values.bookingDetail[0] }
              :{}
          )
        );
        dispatch(
          setflightDepartureData(
            formik.values.bookingDetail[1]
              ? { ...formik.values.bookingDetail[1] }
              :{}
          )
        );
      }

      if (productid === PRODUCTID_ARRIVAL) {
        dispatch(
          setflightArrivalData(
            formik.values.bookingDetail[0]
              ? { ...formik.values.bookingDetail[0] }
              :{}
          )
        );
        dispatch(setflightDepartureData({}));
      }

      if (productid === PRODUCTID_DEPARTURE) {
        dispatch(setflightArrivalData({}));
        dispatch(
          setflightDepartureData(
            formik.values.bookingDetail[0]
              ? { ...formik.values.bookingDetail[0] }
              :{}
          )
        );
      }

      try {
        setLoading(true);
        let tmpArrivalScheduleId, tmpDepartureScheduleId;
        let tmpBookingDetails;

        if (productid === PRODUCTID_ARRIVALBUNDLE) {
          tmpArrivalScheduleId = arrivalscheduleid;
          tmpDepartureScheduleId = departurescheduleid;
          tmpBookingDetails = formik.values.bookingDetail;
        } else if (productid === PRODUCTID_ARRIVAL) {
          tmpArrivalScheduleId =
            productid === PRODUCTID_ARRIVAL
              ? arrivalscheduleid
              : arrivalscheduleid !== 0
              ? arrivalscheduleid
              : departurescheduleid;
          tmpDepartureScheduleId = 0;
          tmpBookingDetails = formik.values.bookingDetail[0];
        } else {
          productid = PRODUCTID_DEPARTURE;
          tmpArrivalScheduleId = 0;
          // tmpDepartureScheduleId = currentcartitemid !== 0 ? (arrivalscheduleid !== 0 ? arrivalscheduleid : departurescheduleid) : arrivalscheduleid;
          tmpDepartureScheduleId =
            currentcartitemid !== 0
              ? departurescheduleid !== 0
                ? departurescheduleid
                : arrivalscheduleid
              : departurescheduleid;
          tmpBookingDetails = formik.values.bookingDetail[0];
        }

        const res = await reserveCartItem(dispatch, {
          ...data,
          productid: productid,
          arrivalscheduleid: tmpArrivalScheduleId,
          departurescheduleid: tmpDepartureScheduleId,
          // distributorid: subDistributorId
        });

        setCartItemId(res?.data?.cartitemid);

        if (res.status === 0) {
          const peopleCountData = {
            adultCount: formik.values.adultCount,
            childCount: formik.values.childCount,
            infantCount:
              productid === PRODUCTID_ARRIVAL ||
              productid === PRODUCTID_ARRIVALBUNDLE
                ? formik.values.infantCount
                : { value: "0", label: "0" },
            infantCountNumber: formik.values.infantCount.value,
            productid: productid,
            arrivalscheduleid: tmpArrivalScheduleId,
            departurescheduleid: tmpDepartureScheduleId,
            cartitemid: res?.data?.cartitemid,
            currentPassengerInfo: currentPassengerInfo,
            giftBookingInfo: giftBookingInfo,
            giftcartitemid: giftcartitemid,
          };

          if (currentcartitemid !== 0) {
            dispatch(
              giftRedeemBookingDetailUpdate({
                bookingDetail: { ...tmpBookingDetails },
                flightDetails: { ...flightDetails },
                airlineId: airlineId,
                currentCartItem: res?.data,
                cartItemId: currentcartitemid,
                adultCount: formik.values.adultCount,
                childCount: formik.values.childCount,
                infantCount: formik.values.infantCount,
                data: {
                  passengerInfo: currentPassengerInfo,
                  giftBookingInfo: giftBookingInfo,
                  giftcartitemid: giftcartitemid,
                },
              })
            );
          } else {
            dispatch(
              giftRedeemFetchSuccess({
                key: res?.data?.cartitemid,
                value: {
                  bookingDetail: { ...tmpBookingDetails },
                  flightDetails: { ...flightDetails },
                  airlineId: airlineId,
                  currentCartItem: res?.data,
                  adultCount: formik.values.adultCount,
                  childCount: formik.values.childCount,
                  infantCount: formik.values.infantCount,
                  data: {
                    passengerInfo: currentPassengerInfo,
                    giftBookingInfo: giftBookingInfo,
                    giftcartitemid: giftcartitemid,
                  },
                },
              })
            );
          }

          setLoading(false);
          navigate("/gift-redeem-user-info", { state: peopleCountData });
        } else {
          setLoading(false);
          showError(t("error"), res.statusMessage);
          // if (VIPER_Response_Codes.STANDBY_BOOKING_STATUS_CODE?.find(code => code === res.status)) {
          //   setLoading(false);
          // } else {
          // }
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const setFlightDetailsForEdit = (data) => {
    setFlightDetails(data);
    setArrivalscheduleid(ascheduleID);
    setDeparturescheduleid(dscheduleID);
  };

  return (
    <>
      <section>
        <Container className="my-4">
          <Loader loading={loading} />
          <h3 className="my-4 d-flex justify-content-center heading">
            <span className="heading">{t("giftredeem:giftRedeemHeading")}</span>
          </h3>

          <Row className="my-3 d-flex align-content-left">
            <Col md={12} className="d-flex justify-content-end">
              <CommonButton
                type="button"
                classname="back mx-1"
                title={t("back")}
                onclick={() => navigate(-1)}
              />
              <ExitButton />
            </Col>
          </Row>
          <Card className="py-3 px-4 my-4">
            <Row className="d-flex justify-content-center align-content-center mb-1">
              <Col md={12} sm={12}>
                <h5 className="mb-1">
                  <b>{t("giftredeem:product")}:</b>{" "}
                  {getProductName(giftBookingInfo?.productid)}
                </h5>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center align-content-center mb-1">
              <Col md={12} sm={12}>
                <h5 className="mb-1">
                  <b>{t("giftredeem:club")}:</b>{" "}
                  {getClubName(giftBookingInfo?.airportid)}
                </h5>
              </Col>
            </Row>

             {(giftBookingInfo?.productid === PRODUCTID_ARRIVAL || giftBookingInfo?.productid === PRODUCTID_ARRIVALBUNDLE) && (<Row className="d-flex justify-content-center align-content-center mb-1">
              <Col md={12} sm={12}>
                <h5 className="mb-1">
                  <b>{t("giftredeem:arrivalAirport")}:</b>{" "}
                  {getAirportName(giftBookingInfo?.arrivalairportid)}
                </h5>
              </Col>
            </Row>)}

            {(giftBookingInfo?.productid === PRODUCTID_DEPARTURE || giftBookingInfo?.productid === PRODUCTID_ARRIVALBUNDLE) && (<Row className="d-flex justify-content-center align-content-center mb-1">
              <Col md={12} sm={12}>
                <h5 className="mb-1">
                  <b>{t("giftredeem:departureAirport")}:</b>{" "}
                  {getAirportName(giftBookingInfo?.departureairportid)}
                </h5>
              </Col>
            </Row>)}

            <Row className="d-flex align-content-center mb-1">
              {giftBookingInfo?.adulttickets > 0 && (
                <Col md={4} sm={12}>
                  <h5 className="mb-1">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="icon-color"
                      size="sm"
                    />{" "}
                    <b>{t("giftredeem:adultTickets")}:</b>{" "}
                    {giftBookingInfo?.adulttickets}
                  </h5>
                </Col>
              )}
              {giftBookingInfo?.childtickets > 0 && (
                <Col md={4} sm={12}>
                  <h5 className="mb-1">
                    <FontAwesomeIcon
                      icon={faChild}
                      className="icon-color"
                      size="md"
                    />{" "}
                    <b>{t("giftredeem:childTickets")}: </b>
                    {giftBookingInfo?.childtickets}
                  </h5>
                </Col>
              )}
              {/* <Col md={4} sm={12}>
                <h5 className="mb-1">
                  <FontAwesomeIcon
                    icon={faPersonBreastfeeding}
                    className="icon-color"
                    size="sm"
                  />{" "}
                  <b>{t("giftredeem:infantTickets")}:</b>{giftBookingInfo.infanttickets}
                </h5>
              </Col> */}
            </Row>
            <Row className="d-flex justify-content-center align-content-center mb-1">
              <Col md={4} sm={12}>
                <h5 className="mb-1">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="icon-color"
                    size="sm"
                  />{" "}
                  <b>{t("giftredeem:adultTicketsRedeemed")}:</b>{" "}
                  {giftBookingInfo?.adultticketsredeemed}
                </h5>
              </Col>
              <Col md={4} sm={12}>
                <h5 className="mb-1">
                  <FontAwesomeIcon
                    icon={faChild}
                    className="icon-color"
                    size="sm"
                  />{" "}
                  <b>{t("giftredeem:childTicketsRedeemed")}:</b>{" "}
                  {giftBookingInfo?.childticketsredeemed}
                </h5>
              </Col>
              <Col md={4} sm={12}></Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center mb-1">
              <Col md={4} sm={12}>
                <h5 className="mb-1">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="icon-color"
                    size="sm"
                  />{" "}
                  <b>{t("giftredeem:contactName")}:</b>{" "}
                  {giftBookingInfo?.contactname}
                </h5>
              </Col>
              <Col md={8} sm={12}>
                <h5 className="mb-1">
                  <FontAwesomeIcon
                    icon={faAt}
                    size="sm"
                    className="icon-color"
                  />{" "}
                  <b>{t("giftredeem:contactEmail")}:</b>{" "}
                  {giftBookingInfo?.contactemail}
                </h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center mb-1">
              <Col md={12} sm={12}>
                <h5 className="mb-1">
                  <FontAwesomeIcon
                    icon={faMessage}
                    size="sm"
                    className="icon-color"
                  />{" "}
                  <b>{t("giftredeem:message")}:</b> {giftBookingInfo?.message}
                </h5>
              </Col>
            </Row>
          </Card>

          <Card className="my-3" ref={divRef}>
            <Card.Body>
              <h6 className="field">{t("fillAllFields")}</h6>
              <hr className="horizontalBar" />
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                {/* <div className="bg-danger bg-gradient bg-opacity-50 rounded-1">
                  {bookingDetail.map((c, key) => (
                    <div key={key}>
                      <h6>{c?.errors?.lounge}</h6>
                      <h6>{c?.errors?.flightDate}</h6>
                      <h6>{c?.errors?.airlineName}</h6>
                    </div>
                  ))}
                </div> */}
                <ErrorMessages formik={formik} />
                <Row>
                  <h5 className="my-3 d-flex">
                    <FontAwesomeIcon
                      icon={faPeopleRoof}
                      size="sm"
                      className="me-2 icon-color"
                    />
                    {t("numberOfGuests")}
                    <span className="field">*</span>
                  </h5>
                  {giftBookingInfo?.adulttickets > 0 && (
                    <Col md={"auto"} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <FontAwesomeIcon
                            icon={faUser}
                            className="icon-color"
                            size="lg"
                          />{" "}
                          {t("adult")} {getAdultDisplayAge()}
                        </Form.Label>
                        <Select
                          options={PEOPLE_COUNT.slice(
                            0,
                            giftBookingInfo?.adulttickets +
                              1 -
                              giftBookingInfo?.adultticketsredeemed
                          )}
                          defaultValue={PEOPLE_COUNT[1]}
                          //  value={adultCount.value}
                          // onChange={(value) =>
                          //   setAdultCount({
                          //     value: value,
                          //     error: "",
                          //   })
                          // }
                          value={formik.values.adultCount}
                          name="adultCount"
                          onChange={async (d) => {
                            await formik.setFieldValue("adultCount", d);
                          }}
                          onBlur={(e) => formik.handleBlur(e)}
                          className={
                            formik.touched.adultCount &&
                            formik.errors.adultCount
                              ? " error rounded-2"
                              : ""
                          }
                        />
                      </Form.Group>
                    </Col>
                  )}
                  {giftBookingInfo?.childtickets > 0 && (
                    <Col md={"auto"} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="d-flex">
                          <FontAwesomeIcon
                            icon={faChild}
                            size="lg"
                            className="mx-1 mt-1 icon-color"
                          />{" "}
                          <OverlayTooltip
                            title={t("child") + getChildDisplayAge()}
                            msg={t("childAgeMessage")}
                          ></OverlayTooltip>
                        </Form.Label>
                        <Select
                          value={formik.values.childCount}
                          options={PEOPLE_COUNT.slice(
                            0,
                            giftBookingInfo?.childtickets +
                              1 -
                              giftBookingInfo?.childticketsredeemed
                          )}
                          name="childCount"
                          defaultValue={PEOPLE_COUNT[1]}
                          onChange={async (d) => {
                            await formik.setFieldValue("childCount", d);
                          }}
                          onBlur={(e) => formik.handleBlur(e)}
                          className={
                            formik.touched.childCount &&
                            formik.errors.adultCount
                              ? " error rounded-2"
                              : ""
                          }
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={"auto "} sm={12}>
                    <Form.Group>
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faPersonBreastfeeding}
                          size="lg"
                          className="mx-1 mt-1 icon-color"
                        />
                        <OverlayTooltip
                          title={t("infant") + getInfantDisplayAge()}
                          msg={t("infantAgeMessage")}
                        ></OverlayTooltip>
                      </Form.Label>
                      <Select
                        options={PEOPLE_COUNT}
                        value={formik.values.infantCount}
                        name="infantCount"
                        onChange={(d) => {
                          formik.setFieldValue("infantCount", d);
                        }}
                        onBlur={(d) => formik.handleBlur}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* booking detail component  */}
                {formik.values.bookingDetail?.map((a, i) => (
                  <FlightDetailsComponent
                    setFlightDetailsForEdit={setFlightDetailsForEdit}
                    key={i}
                    isLoungeDisplay={false}
                    clubType={clubType}
                    productid={productid}
                    flightNames={getAirlineNames(flightDetails[i]?.airlines)}
                    flightNumbers={getFlightNumbers(
                      flightDetails[i]?.flights?.filter(
                        (f) => f.airlineid === airlineId[i]
                      )
                    )}
                    heading={bookingType[i]}
                    product
                    index={i}
                    lounge={formik?.values?.bookingDetail?.[i]?.lounge}
                    flightDate={formik?.values?.bookingDetail?.[i]?.flightDate}
                    airlineName={
                      formik?.values?.bookingDetail?.[i]?.airlineName
                    }
                    airlineId={formik?.values?.bookingDetail?.[i]?.airlineId}
                    flightNumber={
                      formik?.values?.bookingDetail?.[i]?.flightNumber
                    }
                    flightTime_hour={
                      formik?.values?.bookingDetail?.[i]?.flightTime_hour
                    }
                    scheduleId={handleArrivalDepartureScheduleIdChange}
                    errors={a.errors}
                    formik={formik}
                    onBlur={(key, e) => {
                      formik.handleBlur(e);
                    }}
                    onChange={(key, val) => {
                      if (key === "flightDate") {
                        const event = dateFormatForDisplay(val, "MM/dd/yyyy");
                        formik.setFieldValue(
                          `bookingDetail.${i}.${key}`,
                          event
                        );
                      }
                      formik.setFieldValue(`bookingDetail.${i}.${key}`, val);
                    }}
                  />
                ))}
                <Row className="my-3">
                  <Col className=" d-flex justify-content-end">
                    <CommonButton
                      type="submit"
                      size="md"
                      classname="gradient-btn mx-2"
                      title={t("checkAvailability")}
                      //  onsubmit={handleSubmit}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default GiftRedeem;
