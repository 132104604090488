import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
// import { ARR_PRODUCTS, } from "../config/commonConstants";
import CommonButton from "../components/commonComponents/CommonButton";
import InputGroup from "react-bootstrap/InputGroup";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDistributorBooking } from "../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import Loader from "../components/commonComponents/Loader";
import { showError } from "../components/commonComponents/Swal";
import { selectedMarket as currentMarket, selectedMarketId as SMID } from "../redux/marketRedux";
import { HandleAPIError, divRef, getProductDropdownAccordingMarket, scrollToDiv } from "../components/commonComponents/commonFunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function UpdateFlightDetailsForm() {
  const [Product, setProduct] = useState({ value: "", error: "" });
  const [ConfirmationNumber, setConfirmationNumber] = useState({ value: "", error: "" });
  const selectedMarket = useSelector(currentMarket);
  const { t } = useTranslation(["common", "getupdateflightdetailslink"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const selectedMarketId = useSelector(SMID);
  const ARR_PRODUCTS = getProductDropdownAccordingMarket(selectedMarketId)

  const handleSubmit = async (formik) => {
    // event.preventDefault();
    const data = {
      "productid": formik.product,
      "confirmationnumber": formik.confirmationNumber,
    };
    try {
      setLoading(true);
      const response = await getDistributorBooking(data);
      const type = "UPDATE-FLIGHT-DETAILS-FORM";
      if (response?.status === 0)
        navigate("/update-booking", { state: { response, type } });
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const validationSchema = Yup.object().shape({
    product: Yup.string().required(
      t("getupdateflightdetailslink:errors.product.required")
    ),
    confirmationNumber: Yup.string().required(
      t("getupdateflightdetailslink:errors.confirmationNumber.required")
    ),
  });

  const formik = useFormik({
    initialValues: {
      product: "",
      confirmationNumber: "",
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  useEffect(() => {
  }, []);

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t('getupdateflightdetailslink:loaderMessage')} />
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">{t('getupdateflightdetailslink:updateFlightDetails')}</span>
          </h3>
          <Row className="my-3 d-flex justify-content-around align-content-left">
            <Col md={6}></Col>
            <Col md={6} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3"  >
            <Card.Body>
              <h6 className="field mb-3">
                {t('fillAllFields')}
              </h6>
              <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                <ErrorMessages formik={formik} />
                <h5 className="d-flex justify-content-center">{t('getupdateflightdetailslink:updateBookingHeading')}</h5>
                <Row className="d-flex justify-content-center">
                  <Col md={3} sm={12}>
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                        {t('getupdateflightdetailslink:product')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        // className="my-0 mb-3"
                        name="product"
                        options={ARR_PRODUCTS}
                        // value={Product.label}
                        // onChange={(value) =>
                        //   setProduct({
                        //     value: value.value,
                        //     error: "",
                        //   })
                        // }
                        value={formik.values.product.Label}
                        onChange={(d) => {
                          formik.setFieldValue("product", d.value);
                        }}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.product && formik.errors.product
                            ? "error rounded-2 my-0 mb-3"
                            : "my-0 mb-3"
                        }
                      />
                      {formik.touched.product}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col
                    md={3}
                    className="mb-3"
                  >
                    <Form.Label className="d-flex" htmlFor="ConfirmationNumber">
                      {t('getupdateflightdetailslink:confirmationNumber')}<span className="field">*</span>
                    </Form.Label>
                    <InputGroup className="d-flex">
                      <Form.Control
                        type="text"
                        name="confirmationNumber"
                        id="ConfirmationNumber"
                        placeholder={t('getupdateflightdetailslink:confirmationNumber')}
                        // value={ConfirmationNumber.value}
                        aria-describedby="inputGroupPrepend"
                        // onChange={(e) =>
                        //   setConfirmationNumber({
                        //     value: e.target.value.replace(REGEX_NUMERIC, ""),
                        //     error: "",
                        //   })
                        // }
                        value={formik.values.confirmationNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.confirmationNumber &&
                            formik.errors.confirmationNumber
                            ? "error my-0 mb-3"
                            : "my-0 mb-3"
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t('submit')}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default UpdateFlightDetailsForm;
