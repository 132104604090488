import ProductCard from "../components/commonComponents/ProductCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/esm/Container";
import visamaster from "../images/visa-master-card.png";
import EGiftCard from "../components/commonComponents/EGiftCard";
import { PRODUCTID_PRIORITYPASS, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_ARRIVAL, PRODUCTID_DEPARTURE, AFFILIATE_COOKIE_EXPIRE_TIME, PRODUCTID_DIGICELBLACKCARD, CUSTOMER_LOGIN } from "../config/commonConstants";
import ArrivalProductImg from "../images/pic2_resized.jpg";
import DepartureProductImg from "../images/pic3_resized.jpg";
import BundleProductImg from "../images/pic1_resized.jpg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket, setSelectedMarket, selectedMarketId as SMID, availableMarkets as AM } from "../redux/marketRedux";
import { subDistributorProfile as SDP, logindetails as LD } from "../redux/signInRedux";
import { useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getAffiliates, initAffiliateLog, setMarketId } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { saveAffiliateDetails } from "../redux/affiliatesRedux";
import InvalidAffiliate from "./InvalidAffiliate";
import { userDefault as UD } from "../redux/signInRedux";
import { useNavigate } from "react-router-dom";
import { error as ERR } from "../redux/signInRedux";
import { showPriorityPassProduct, showDigicelBlackProduct, HandleAPIError, marketChangeBroadcast, getLoginDetails, externalLogin, getAffiliateExpiryTime } from "../components/commonComponents/commonFunctions";
import { cartData as CD } from "../redux/cartRedux";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import HomeLoadingSkeleton from "../components/commonComponents/HomeLoadingSkeleton";
import RenderProductCarousel from "../components/commonComponents/RenderProductCarousel";

const Home = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["affiliateData", "market","promotionLoginData", "affiliateDataBackup"])
  let { affiliateid, subaffiliateid } = useParams();
  const { t } = useTranslation(["common"]);
  const selectedMarket = useSelector(currentMarket);
  const selectedMarketId = useSelector(SMID);
  const subDistributorProfile = useSelector(SDP);
  const availableMarkets = useSelector(AM);
  const priceList = selectedMarket?.pricelist;
  const [loading, setLoading] = useState(false);
  const [showInvalidAffiliate, setShowInvalidAffiliate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDefault = useSelector(UD);
  const cartData = useSelector(CD);
  const location = useLocation();
  const sessionExpiredError = location.state;
  const sendBroadcastMessage = useOutletContext()
  const loginDetails = useSelector(LD)
  const resetpassword = userDefault?.resetpassword ? userDefault?.resetpassword : "N";
  let error = useSelector(ERR);

  const _getAffiliates = async (affiliateid) => {
    const request = { affiliateid: affiliateid };
    const response = await getAffiliates(request, dispatch);
    let tmp_affiliatedata = {};
    if (response?.data?.affiliates) {
      removeCookie("promotionLoginData", { path : "/" });
      let marketid = response?.data?.affiliates[0]?.marketid;
      let languageid = response?.data?.affiliates[0]?.languageid;
      await marketChangeBroadcast(sendBroadcastMessage, marketid, false);
      if (cookies?.market && cookies?.market !== marketid) {
        const request = {
          "marketid": cookies?.market,
          "languageid": availableMarkets?.data?.markets?.find((m) => m.marketid === cookies?.market).language
        };
        dispatch(setSelectedMarket(cookies?.market));
        getLoginDetails()?.sessionid && await setMarketId(request);
      } else {
        if (marketid === cookies?.market) {
          if (marketid === selectedMarketId) {
            // do nothing 
          } else {
            const request = {
              "marketid": marketid,
              "languageid": languageid,
            };
            dispatch(setSelectedMarket(marketid));
            try {
              setLoading(true);
              getLoginDetails()?.sessionid && await setMarketId(request);
              setCookie("market", response?.data?.affiliates[0]?.marketid, { maxAge: AFFILIATE_COOKIE_EXPIRE_TIME * 86400, path: "/", sameSite:"none", secure:true})
            } catch (e) {
              HandleAPIError(e);
            } finally {
              setLoading(false);
            }
          }
        } else {
          setCookie("market", marketid, { maxAge: AFFILIATE_COOKIE_EXPIRE_TIME * 86400, path: "/", sameSite:"none", secure:true})
          const request = {
              "marketid": marketid,
              "languageid": availableMarkets?.data?.markets?.find((m) => m.marketid === marketid).language,
            };
            console.log("market",marketid);
            selectedMarketId && dispatch(setSelectedMarket(marketid));

          getLoginDetails()?.sessionid && await setMarketId(request);
        }
      }
      tmp_affiliatedata = response?.data?.affiliates[0];
    }
    return tmp_affiliatedata;
  }
  
  const [isComplete, setIsComplete] = useState(false)

  const setAffliate = async () => {
    if (cookies?.affiliateDataBackup && cookies?.promotionLoginData === undefined) {
      let affiliateDataBackup = cookies?.affiliateDataBackup;
      affiliateDataBackup.expiresAt = new Date(affiliateDataBackup.expiresAt)
      setCookie("affiliateData",affiliateDataBackup,{ expires: affiliateDataBackup.expiresAt, path: "/", sameSite:"none", secure:true})
      removeCookie("affiliateDataBackup", { path: "/" });
    }

    let navigateToHome =false;

    affiliateid = parseInt(affiliateid);
    subaffiliateid = parseInt(subaffiliateid);

    let affiliateid_cookie = cookies?.affiliateData?.affiliateid;
    let subaffiliateid_cookie = cookies?.affiliateData?.subaffiliateid;

    if (affiliateid) {
      navigateToHome = true;
    } else {
      if (affiliateid_cookie) {
        affiliateid = parseInt(affiliateid_cookie);
        subaffiliateid = parseInt(subaffiliateid_cookie);
      }
    }
    
    console.log("affiliateid",affiliateid);
    if (affiliateid) {
      try {
        
        setLoading(true)
        //const response = await getAffiliates(dispatch);
        //const affiliatedata = response?.data?.affiliates?.find((affiliate) => affiliate.affiliateid === affiliateid) ?? {};

        const affiliatedata = await _getAffiliates(affiliateid);

        if (Object.keys(affiliatedata).length === 0) {
          setShowInvalidAffiliate(true);
        } else {
          !isComplete && externalLogin(location,sendBroadcastMessage,navigate,dispatch,setIsComplete,affiliateid)
            if(isComplete){
              if (affiliatedata.status === "I") {
                setShowInvalidAffiliate(true);
              } else {
                dispatch(saveAffiliateDetails(affiliatedata));
                var referrer = document.referrer;
                let request = {
                  "affiliate": {
                    "affiliateid": affiliateid,
                    "httpreferrer": referrer
                  }
                };
                await initAffiliateLog(request);
                if (!affiliateid_cookie || affiliateid_cookie !== affiliateid) {
                  const expiresAt = getAffiliateExpiryTime();
                  console.log("expiresAt",expiresAt);
                  const affiliateData =  {
                    "affiliateid":affiliateid,
                    "expiresAt":expiresAt
                  }
                    setCookie("affiliateData",affiliateData,{expires:expiresAt, path:"/", sameSite:"none", secure:true})
                    removeCookie("affiliateDataBackup",{path:"/"})
                    // setCookie("market",selectedMarketId,{maxAge:AFFILIATE_COOKIE_EXPIRE_TIME*86400,path:"/"})
                  if (subaffiliateid) {
                    setCookie("affiliateData",affiliateData,{maxAge:AFFILIATE_COOKIE_EXPIRE_TIME*86400,path:"/", sameSite:"none", secure:true})
                  }
                }
                navigateToHome && navigate("/");
              }
            }
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
    if (resetpassword === "Y") {
      navigate("/force-password-change", { replace: true });
    }
    
  }
  
  useEffect(() => {

    let default_user = !userDefault?.username ? 'Y' : (userDefault?.username === CUSTOMER_LOGIN.username ? 'Y' : 'N');
    default_user === "Y" && selectedMarketId && setAffliate()
    window.history.replaceState({}, document.title)
  }, [selectedMarketId,isComplete]);

  useEffect(() => {
  }, [selectedMarket])
  

  const getDirectionTranslationKey = (productid) => {
    let translationKey = "";
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      translationKey = "arrivalDeparture";
    } else if (productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else { // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }
    return translationKey;
  }

  return (
    <>
      <Loader loading={loading} message="" />
      {
        loading === true ? <HomeLoadingSkeleton /> :
      ( showInvalidAffiliate ?
        <InvalidAffiliate affiliateid={affiliateid} />
        :
        <>
          {sessionExpiredError && 
          <Container>
            <Card className="bg-danger bg-opacity-10 rounded-1 m-3 text-center p-2">
            <p className="text-danger ps-2 m-0 p-0" >{t("sessionTimeExpired")}</p>
            </Card>
          </Container>}
          <section>
            <Container className="my-4">
              {error !== null &&
                <div className="d-flex justify-content-center bg-danger bg-gradient bg-opacity-50 rounded-1">
                  <h5 className="my-3 d-flex justify-content-center">{error}</h5>
                </div>
              }
              <h3 className="my-3 d-flex justify-content-center">
                <span className="heading">{t("home:loungeServices")}</span>
              </h3>
              <h4 className="my-3 d-flex justify-content-center">
                {t("home:selectYourProduct")}
              </h4>
            </Container>
          </section>
          <section>
            <Container className="my-4">
              <Row className="d-flex justify-content-center">
                {priceList
                  ?.filter((item) => item?.productid === PRODUCTID_ARRIVALBUNDLE)
                  .map(
                    (value, index, elements) =>
                      !elements[index + 1] && (
                        selectedMarket?.arrivalbundleallowed === "Y" ? subDistributorProfile?.distributorprofile?.arrivalbundle === "N" ? "" : (
                          <Col className="col-md-4 d-flex mb-2 mb-md-0" key={index}>
                            <ProductCard
                              productid={PRODUCTID_ARRIVALBUNDLE}
                              title={t('bookProductTitle', { product: t(getDirectionTranslationKey(PRODUCTID_ARRIVALBUNDLE)) })}
                              image={BundleProductImg}
                              desc={value?.description}
                              viewPrice={t("home:viewPrices")}
                            />
                          </Col>
                        ) : (
                          ""
                        )
                      )
                  )
                }

                {priceList
                  ?.filter((item) => item?.productid === PRODUCTID_ARRIVAL)
                  .map((value, index, elements) =>
                    !elements[index + 1] && (
                      selectedMarket?.arrivalonlyallowed === "Y" ? subDistributorProfile?.distributorprofile?.arrivalonly === "N" ? "" : (
                        <Col className="col-md-4 d-flex mb-2 mb-md-0" key={index}>
                          <ProductCard
                            productid={PRODUCTID_ARRIVAL}
                            title={t('bookProductTitle', { product: t(getDirectionTranslationKey(PRODUCTID_ARRIVAL)) })}
                            image={ArrivalProductImg}
                            desc={value?.description}
                            viewPrice={t("home:viewPrices")}
                          />
                        </Col>
                      ) : (
                        ""
                      )
                    )
                  )
                }

                {priceList
                  ?.filter((item) => item?.productid === PRODUCTID_DEPARTURE)
                  .map((value, index, elements) =>
                    !elements[index + 1] && (
                      selectedMarket?.departureloungeallowed === "Y" ? subDistributorProfile?.distributorprofile?.departurelounge === "N" ? "" : (
                        <Col className="col-md-4 d-flex" key={index}>
                          <ProductCard
                            productid={PRODUCTID_DEPARTURE}
                            title={t('bookProductTitle', { product: t(getDirectionTranslationKey(PRODUCTID_DEPARTURE)) })}
                            image={DepartureProductImg}
                            desc={value?.description}
                            viewPrice={t("home:viewPrices")}
                          />
                        </Col>
                      ) : (
                        ""
                      )
                    )
                  )
                }
              </Row>
            </Container>
          </section>

          {/* Carousel component */}
          {(showPriorityPassProduct() || showDigicelBlackProduct()) ? <RenderProductCarousel priceList={priceList} /> : null}

          {selectedMarket?.giftcartallowed === "Y" ?
            <section>
              <Container className="my-4">
                <EGiftCard isDisable={cartData?.length === 0 ? false : true} />
              </Container>
            </section>
            : null
          }

          <section className="bg-light">
            <Container fluid="xxl">
              <Row>
                <Col md="12" className="my-2 p-2">
                  <p className="text-center">
                    {selectedMarket?.line1} <br /> {selectedMarket?.line2}
                  </p>
                  <div className="d-flex justify-content-center">
                    <img src={visamaster} width="300" alt="visa mastercard" />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </>)
      }
    </>
  );
};

export default Home;