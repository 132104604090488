import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartData: [],
  cartItemId: 0,
  error: false,
}

const cartSlice = createSlice({
  name: "getCart",
  initialState: initialState,
  reducers: {
    cartFetchSuccess: (state, action) => {
      state.cartData.push(action.payload);
      state.cartItemId = action?.payload?.key;
    },
    cartUpdateData: (state, action) => {
      const data = action?.payload;
      state.cartData.forEach(c => {
        if (c.key === data?.passengerInfo?.cartItemId) {
          c.value = { ...c?.value, data }
        }
      });
    },
    deleteProduct: (state, action) => {
      state.cartData.splice(state.cartData.findIndex((item) => item.key === action.payload), 1);
    },
    cartFetchFailure: (state) => {
      state.error = true;
    },
    clearCart: state => {
      return initialState;
    },
    cartBookingDetailUpdate: (state, action) => {
      const data = action?.payload;
      state.cartData.find(c => c.key === data.cartItemId).value = data;
    },
    resetCartData: (state) => {
      state.cartData = [];
      state.cartItemId = "";
    },
  },
});

export const { cartFetchSuccess, cartUpdateData, cartFetchFailure, clearCart, resetCartData, cartBookingDetailUpdate, deleteProduct } = cartSlice.actions;
export const currentCart = (state) => state.cartRedux?.cartData?.find((c) => c.key === state.cartRedux?.cartItemId) ?? {};
export const cartData = (state) => state.cartRedux?.cartData;

export default cartSlice.reducer;