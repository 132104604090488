import { Row, Col, Card, Container, Form } from "react-bootstrap";
import { getBookingDetails, reserveCartItem } from "../redux/apiCalls";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { showError } from "../components/commonComponents/Swal";
import BookingSummaryComponent from "../components/commonComponents/BookingSummaryComponent";
import { PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE } from "../config/commonConstants";
import { HandleAPIError, divRef, getAdultDisplayAge, getChildDisplayAge, getDistributorSubDistributorProfile, getInfantDisplayAge, maxSeatsChecker, scrollToDiv } from "../components/commonComponents/commonFunctions";
import { t } from "i18next";
import { selectedMarketCurrency } from "../redux/marketRedux";
import { useDispatch, useSelector } from "react-redux";
import { paxCountBookingDetailsFetchSuccess } from "../redux/increasePaxCountRedux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChild, faPeopleRoof, faPersonBreastfeeding, faUser } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import OverlayTooltip from "../components/commonComponents/OverlayTooltip";
import Loader from "../components/commonComponents/Loader";
import { cartBookingDetailUpdate, cartFetchSuccess } from "../redux/cartRedux";
import CommonButton from "../components/commonComponents/CommonButton";
import { cartData } from "../redux/cartRedux";
import ExitButton from "../components/commonComponents/ExitButton";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { selectedMarket as currentMarket } from "../redux/marketRedux";


function IncreasePaxCount() {
  const currencyCode = useSelector(selectedMarketCurrency);
  const selectedMarket = useSelector(currentMarket);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [departureRecords, setDepartureRecords] = useState([]);
  const [arrivalRecords, setArrivalRecords] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [primaryContactDetail, setPrimaryContactDetail] = useState([]);
  const [secondatyContactDetail, setSecondaryContactDetail] = useState([]);
  const [allowIncreasePax, setAllowIncreasePax] = useState(true);

  const [userData, setUserData] = useState([]);
  const [error, setError] = useState("");
  const getBookingRequestData = location?.state?.getBookingRequestData;
  const dispatch = useDispatch();
  const [adultCount, setAdultCount] = useState({ value: { value: "1", label: "1" }, error: "" });
  const [childCount, setChildCount] = useState({ value: { value: "0", label: "0" }, error: "" });
  const [infantCount, setInfantCount] = useState({ value: { value: "0", label: "0" }, error: "" });
  const [maxAdultCount, setMaxAdultCount] = useState([]);
  const [maxChildCount, setMaxChildCount] = useState([]);
  const [maxInfantCount, setMaxInfantCount] = useState([]);
  const [maxAllowed, setMaxAllowed] = useState();
  const [increasePaxAdultMax, setIncreasePaxAdultMax] = useState([]);
  const [increasePaxChildtMax, setIncreasePaxChildtMax] = useState([]);
  const productid = getBookingRequestData?.productid ? getBookingRequestData?.productid : location?.state?.productid;
  const distributorProfile = getDistributorSubDistributorProfile();
  const currentCartItemId = location?.state?.cartItemId;

  const ascheduleID = location?.state?.arrivalscheduleid;
  const dscheduleID = location?.state?.departurescheduleid;
  const adultCountForEdit = location?.state?.adultCount;
  const childCountForEdit = location?.state?.childCount;
  const infantCountForEdit = location?.state?.infantCount;
  const [arrivalscheduleid, setArrivalscheduleid] = useState();
  const [departurescheduleid, setDeparturescheduleid] = useState();
  const currentPassengerInfo = useSelector(cartData)?.find((c) => c.key === currentCartItemId)?.value?.data?.passengerInfo ?? "";

  const getMaxSeats = () => {
    let adultMaxCount = 0;
    let childMaxCount = 0;
    let infantMaxCount = 0;
    let maxCount = 0;
    if (productid === PRODUCTID_DEPARTURE) {
      adultMaxCount = distributorProfile.departuremaxseats;
      childMaxCount = distributorProfile.departuremaxseats;
      infantMaxCount = distributorProfile.departuremaxseats;
      maxCount = distributorProfile.departuremaxseats;
    } else {
      adultMaxCount = distributorProfile.arrivalmaxseats;
      childMaxCount = distributorProfile.arrivalmaxseats;
      infantMaxCount = distributorProfile.arrivalmaxseats;
      maxCount = distributorProfile.arrivalmaxseats;
    }

    let objMaxSeats = {
      adult: adultMaxCount,
      child: childMaxCount,
      infant: infantMaxCount,
      max: maxCount
    }

    return objMaxSeats;
  };

  const setMaxSeats = () => {
    const objMaxSeats = getMaxSeats();

    const updatedAdultCount = Array.from({ length: objMaxSeats.adult + 1 }, (_, i) => ({
      value: i.toString(),
      label: i.toString(),
    }));

    const updatedChildCount = Array.from({ length: objMaxSeats.child + 1 }, (_, i) => ({
      value: i.toString(),
      label: i.toString(),
    }));

    const updatedInfantCount = Array.from({ length: objMaxSeats.infant + 1 }, (_, i) => ({
      value: i.toString(),
      label: i.toString(),
    }));

    setMaxAdultCount(updatedAdultCount);
    setMaxChildCount(updatedChildCount);
    setMaxInfantCount(updatedInfantCount);
    setMaxAllowed(objMaxSeats.max);
  };

  const maxChecker = async (value, type) => {
    let returnValue = true;
    let tmpAdultCount = 0;
    let tmpChildCount = 0;

    if (type === "child") {
      tmpAdultCount = parseInt(formik.values.adultCount.value);
      tmpChildCount = parseInt(value);
    }
    else if (type === "adult") {
      tmpAdultCount = parseInt(value);
      tmpChildCount = parseInt(formik.values.childCount.value);
    }
    let increasepaxTmpCount = increasePaxAdultMax + increasePaxChildtMax
    let tmpPaxCount = increasepaxTmpCount + tmpAdultCount + tmpChildCount
    
    if (tmpPaxCount > maxAllowed) {
      returnValue = false;
      await showError(t("error"), t('common:maxSeats', { maxAllowed: maxAllowed }));

      if (type === "child") {
        formik.setFieldValue("childCount", {value:"0" , label:"0"});
      }
      else if (type === "adult") {
        formik.setFieldValue("adultCount", {value:"0" , label:"0"});
      }
    } else {
      formik.setFieldValue("adultCount", {value:tmpAdultCount.toString(), label:tmpAdultCount.toString()});
      formik.setFieldValue("childCount", {value:tmpChildCount.toString(), label:tmpChildCount.toString()});
    }
    
    return returnValue;

  }


  const validationSchema = Yup.object().shape({
    adultCount: Yup.object()
      .test(
        'infatn-count-validate',
        t("journeydetails:errors.adult.count"),
        adultCount => {
          let count = (parseInt(adultCount.value) + parseInt(formik.values.childCount.value));
          if (count === 0) {
            return false
          }
          return true
        }
      ),
    childCount: Yup.object().test(
      'child-count-validate',
      t(""),
      childCount => {
        let count = (parseInt(formik.values.adultCount.value) + parseInt(childCount.value));
        if (count === 0) {
          return false
        }
        return true
      }
    ),
    // infantCount: Yup.object().required(t("feedback:errors.adultCount.required")),
  });

  const initial = {
    adultCount: { value: 0, label: 0 },
    childCount: { value: 0, label: 0 },
    infantCount: { value: 0, label: 0 },
  }

  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values)
    },
  });

  useEffect(() => {
    if (currentCartItemId !== undefined) {
      setArrivalscheduleid(ascheduleID);
      setDeparturescheduleid(dscheduleID);
      formik.setFieldValue('adultCount', { value: adultCountForEdit, label: adultCountForEdit })
      formik.setFieldValue('childCount', { value: childCountForEdit, label: childCountForEdit })
      formik.setFieldValue('infantCount', { value: infantCountForEdit, label: infantCountForEdit })
    }
  }, [currentCartItemId]);

  useEffect(() => {
    setMaxSeats();
  }, [])

  useEffect(() => {
  }, [ arrivalRecords, departureRecords])

  useEffect(() => {
  }, [formik.values.adultCount, formik.values.infantCount, formik.values.childCount])

  const getPaxDetailsForBooking = async () => {
    try {
      const response = await getBookingDetails(getBookingRequestData);
      isIncreasePaxAllowed(response);
      if (response?.status === 0) {
        let tmpOrderDetail = response.data.orderdetail;
        setOrderDetail(tmpOrderDetail);
        setPrimaryContactDetail(response?.data?.primary);
        setSecondaryContactDetail(response?.data?.secondary);
        let data = "";
        if (tmpOrderDetail.productid === PRODUCTID_ARRIVALBUNDLE) {
          let arrivalrecords = [];
          let departurerecords = [];
          tmpOrderDetail.arrivalbundles.map((arrivalbundle, key) => (
            arrivalrecords = arrivalbundle.arrivalrecord,
            departurerecords = arrivalbundle.departurerecords,
            setIncreasePaxAdultMax(arrivalrecords?.adulttickets),
            setIncreasePaxChildtMax(arrivalrecords?.childtickets),
            setArrivalRecords([arrivalrecords]),
            setDepartureRecords(departurerecords)
          ))
        } else if (tmpOrderDetail.productid === PRODUCTID_ARRIVAL) {
          setIncreasePaxAdultMax(tmpOrderDetail.arrivalrecords[0]?.adulttickets);
          setIncreasePaxChildtMax(tmpOrderDetail.arrivalrecords[0]?.childtickets)
          setArrivalRecords(tmpOrderDetail.arrivalrecords)
        }
        else if (tmpOrderDetail.productid === PRODUCTID_DEPARTURE) {
          setIncreasePaxAdultMax(tmpOrderDetail.departurerecords[0]?.adulttickets);
          setIncreasePaxChildtMax(tmpOrderDetail.departurerecords[0]?.childtickets)
          setDepartureRecords(tmpOrderDetail.departurerecords)
        }
        dispatch(paxCountBookingDetailsFetchSuccess(tmpOrderDetail));
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const isIncreasePaxAllowed = async(response) => {
    const max_seat_allowed =  getMaxSeats();
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      if(response.data?.orderdetail?.arrivalbundles[0].arrivalrecord['ticketsrequested'] >= max_seat_allowed['max']){
        setAllowIncreasePax (false);
      }
    }else if(productid === PRODUCTID_ARRIVAL) {
      if(response.data?.orderdetail?.arrivalrecords[0]['ticketsrequested'] >= max_seat_allowed['max']){
        setAllowIncreasePax (false);
      }
    }else{
    if(response.data?.orderdetail?.departurerecords[0]['ticketsrequested'] >= max_seat_allowed['max']){
        setAllowIncreasePax (false);
      }
    }
  }

  useEffect(() => {
    getPaxDetailsForBooking();
  }, []);

  const handleSubmit = async () => {
    // event.preventDefault();
    const data = {
      "cartitemid": currentCartItemId ? currentCartItemId : 0,
      "productid": productid,
      "ticketsrequested": parseInt(formik.values.adultCount.value) + parseInt(formik.values.childCount.value),
      "adulttickets": parseInt(formik.values.adultCount.value),
      "childtickets": parseInt(formik.values.childCount.value),
      "paymenttype": "GUESTCARD",
      "distributorid": "",
    }
    let error = false;
    if (!error) {
      try {
        setLoading(true);
        let tmpArrivalScheduleId, tmpDepartureScheduleId;
        let tmpBookingDetails;

        let tmpArrivalDetails = {
          lounge: arrivalRecords[0]?.airportid,
          flightDate: arrivalRecords[0]?.flightdate.split(" ")[0],
          airlineName: arrivalRecords[0]?.airline,
          airlineId: arrivalRecords[0]?.airlineid,
          flightNumber: arrivalRecords[0]?.flightid,
          flightTime_hour: arrivalRecords[0]?.flightdate.split(" ")[1],
        };

        let tmpDepartureDetails = {
          lounge: departureRecords[0]?.airportid,
          flightDate: departureRecords[0]?.flightdate.split(" ")[0],
          airlineName: departureRecords[0]?.airline,
          airlineId: departureRecords[0]?.airlineid,
          flightNumber: departureRecords[0]?.flightid,
          flightTime_hour: departureRecords[0]?.flightdate.split(" ")[1],
        };

        let detailsArray = [];
        detailsArray.push(tmpArrivalDetails);
        detailsArray.push(tmpDepartureDetails);

        if (productid === PRODUCTID_ARRIVALBUNDLE) {
          tmpBookingDetails = detailsArray;
          if (currentCartItemId !== undefined) {
            tmpArrivalScheduleId = ascheduleID;
            tmpDepartureScheduleId = dscheduleID;
          }
          else {
            tmpArrivalScheduleId = arrivalRecords[0]?.scheduleid;
            tmpDepartureScheduleId = departureRecords[0]?.scheduleid;
          }
        } else if (productid === PRODUCTID_ARRIVAL) {
          tmpBookingDetails = { ...tmpArrivalDetails };
          if (currentCartItemId !== undefined) {
            tmpArrivalScheduleId = ascheduleID;
            tmpDepartureScheduleId = 0;
          }
          else {
            tmpArrivalScheduleId = arrivalRecords[0]?.scheduleid;
            tmpDepartureScheduleId = 0;
          }
        }
        else {
          tmpBookingDetails = { ...tmpDepartureDetails };
          if (currentCartItemId !== undefined) {
            tmpArrivalScheduleId = 0;
            tmpDepartureScheduleId = dscheduleID;
          }
          else {
            tmpArrivalScheduleId = 0;
            tmpDepartureScheduleId = departureRecords[0]?.scheduleid;
          }
        }

        const res = await reserveCartItem(dispatch, {
          ...data,
          productid: productid,
          arrivalscheduleid: tmpArrivalScheduleId,
          departurescheduleid: tmpDepartureScheduleId,
          distributorid: ""
        });

        if (res.status === 0) {
          if (currentCartItemId !== undefined) {
            dispatch(cartBookingDetailUpdate({
              bookingDetail: tmpBookingDetails,
              // flightDetails: { ...flightDetails },
              //  airlineId: airlineId,
              currentCartItem: res?.data,
              cartItemId: currentCartItemId,
              adultCount: formik.values.adultCount.value,
              childCount: formik.values.childCount.value,
              infantCount: formik.values.infantCount.value,
              data: {
                passengerInfo: currentPassengerInfo,
              },
              getBookingRequestData: getBookingRequestData,
            }));
          }
          else {
            dispatch(cartFetchSuccess({
              key: res?.data?.cartitemid, value:
              {
                bookingDetail: tmpBookingDetails,
                // flightDetails: { ...flightDetails },
                //  airlineId: airlineId,
                currentCartItem: res?.data,
                adultCount: formik.values.adultCount.value,
                childCount: formik.values.childCount.value,
                infantCount: formik.values.infantCount.value,
                data: {
                  passengerInfo: currentPassengerInfo,
                },
                getBookingRequestData: getBookingRequestData,
              }
            }));
          }

          const peopleCountData = {
            //  adultCount: formik.values.adultCount.value,
            //  childCount: formik.values.childCount.value,
            // infantCount: (productid === PRODUCTID_ARRIVAL || productid === PRODUCTID_ARRIVALBUNDLE) ? formik.values.infantCount.value : 0,
            //  infantCountNumber: formik.values.infantCount.value,
            productid: productid,
            //  arrivalscheduleid: tmpArrivalScheduleId,
            //  departurescheduleid: tmpDepartureScheduleId,
            cartItemId: res?.data?.cartitemid,
            //  currentPassengerInfo: currentPassengerInfo,
            // bookingDetail: tmpBookingDetails,
            // getBookingRequestData: getBookingRequestData,
            primaryContactDetail: primaryContactDetail,
            secondatyContactDetail: secondatyContactDetail
          };

          setLoading(false);

          navigate("/registration", { state: peopleCountData });
        }
        else {
          setLoading(false);
          showError(t("error"), res.statusMessage);
        }

      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Container className="my-4">
        <Loader loading={loading} />
        <div className="my-3">
        {/* <section className="d-flex justify-content-center align-content-center"> */}
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t('increasePaxHeading')}</span>
          </h3>
        {/* </section> */}
        </div>

        <div className="px-2 d-flex justify-content-end">
          <Link to="/my-bookings" className=" mx-2 text-decoration-none">
            <CommonButton classname="back" title={t("back")} />
          </Link>
          <ExitButton />
        </div>
        {loading ? (
          <LoadingSkeleton />
        ) :
        (error === "" ?
          <section className="d-flex justify-content-center">
            <Container className="my-4" ref={divRef}>
              <Card className="">
                <Card.Body>
                  <Row className="d-flex justify-content-start">
                    <Col md={4}>
                      <h6 className="field">{t('fillAllFields')}</h6>
                    </Col>
                  </Row>
                  <ErrorMessages formik={formik} />
                  <BookingSummaryComponent
                    renderType={"INCREASE-PAX-COUNT"}
                    orderDetail={orderDetail}
                    productid={orderDetail.productid}
                  />
                </Card.Body>
              </Card>
            </Container>
          </section>
          :
          (
            <section>
              <Container className="my-4 text-center">
                <Card className="p-4">
                  <Row>
                    <Col md={12} sm={12}>
                      <div className="mb-3 my-bg-info">
                        {error}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </section>
          ))
        }
        <section className="d-flex justify-content-center align-content-center mt-0">
          <Container >
            <Card>
              <Card.Body>
              { allowIncreasePax ? 
              <Form onSubmit={(e)=>scrollToDiv(e,formik)}>
                  <Row>
                    <h5 className="my-3 d-flex">
                      <FontAwesomeIcon
                        icon={faPeopleRoof}
                        size="sm"
                        className="me-2 icon-color"
                      />
                      {t("numberOfGuests")}
                      <span className="field">*</span>
                    </h5>
                    <Col md={"auto"} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <FontAwesomeIcon
                            icon={faUser}
                            className="icon-color"
                            size="lg"
                          />{" "}
                          {t("adult")} {getAdultDisplayAge()}
                        </Form.Label>

                        <Select
                          options={maxAdultCount}
                          defaultValue={maxAdultCount[1]}
                          value={formik.values.adultCount}
                          name="adultCount"
                          onChange={async (d) => {
                            await formik.setFieldValue('adultCount', d)
                            maxChecker(d.value, "adult")
                          }}
                          onBlur={(e) => formik.handleBlur(e)}
                          className={formik.touched.adultCount && formik.errors.adultCount ? " error rounded-2" : ""}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={"auto"} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="d-flex">
                          <FontAwesomeIcon
                            icon={faChild}
                            size="lg"
                            className="mx-1 mt-1 icon-color"
                          />{" "}
                          <OverlayTooltip
                            title={t("child") + getChildDisplayAge()}
                            msg={t("childAgeMessage")}
                          ></OverlayTooltip>
                        </Form.Label>
                        <Select
                          options={maxChildCount}
                          value={formik.values.childCount}
                          name="childCount"
                          onChange={async (d) => {
                            await formik.setFieldValue('childCount', d)
                            maxChecker(d.value, "child")

                          }}
                          onBlur={(e) => formik.handleBlur(e)}
                          className={formik.touched.childCount && formik.errors.adultCount ? " error rounded-2" : ""}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={"auto "} sm={12}>
                      <Form.Group>
                        <Form.Label className="d-flex">
                          <FontAwesomeIcon
                            icon={faPersonBreastfeeding}
                            size="lg"
                            className="mx-1 mt-1 icon-color"
                          />
                          <OverlayTooltip
                            title={t("infant") + getInfantDisplayAge()}
                            msg={t("infantAgeMessage")}
                          ></OverlayTooltip>
                        </Form.Label>
                        <Select
                          options={maxInfantCount}
                          value={formik.values.infantCount}
                          name="infantCount"
                          onChange={(d) => {
                            formik.setFieldValue('infantCount', d)
                          }}
                          onBlur={(d) => formik.handleBlur}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-1">
                    <Col className=" d-flex justify-content-end">
                      <CommonButton
                        type="submit"
                        size="md"
                        classname="gradient-btn"
                        title={t("checkAvailability")}
                      />
                    </Col>
                  </Row>
                </Form> :
                 <>
                 <Row>
                   <Col>
                     <h5 className="my-bg-info fs-5 lh-base">
                       <HtmlToReactConverter value={t("noteEditableIncreasePax", { skype: selectedMarket?.contact?.skype, email: selectedMarket?.contact?.email })} />
                     </h5>
                   </Col>
                 </Row>
                 </>
}
              </Card.Body>
            </Card>
          </Container>
        </section>
      </Container>
    </>
  );
}

export default IncreasePaxCount;