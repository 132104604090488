import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CommonButton from "../components/commonComponents/CommonButton";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "../components/commonComponents/Loader";
import { getDistributorList, getDistributorDetails } from "../redux/apiCalls";
import {subDistributorProfile as SDP, setSubDistributorProfile, logindetails as LD } from "../redux/signInRedux";
import { HandleAPIError, divRef, getDistributorSubDistributorProfile, renderErrorMessages, scrollToDiv, subDistributorProfileBroadcast } from "../components/commonComponents/commonFunctions";
import { showError } from "../components/commonComponents/Swal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSubDistributorProfileForLastLoggedInUser } from "../redux/loggedInUserDetailRedux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function DistributorBookingUser() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sendBroadcastMessage = useOutletContext()
  const [loading, setLoading] = useState(false);
  const distributorProfile = getDistributorSubDistributorProfile();
  const subDistributorProfile = useSelector(SDP)
  const [distributorList, setDistributorList] = useState([]);
  const loginDetails = useSelector(LD)
  const validationSchema = Yup.object().shape({
    distributorId: Yup.object().required(t("distributorbookinguser:errors.distributorId.required")),
  });
  const formik = useFormik({
    initialValues: {
      distributorId: {
        value: subDistributorProfile?.distributorprofile?.distributorid || distributorProfile?.distributorid  || "",
        label: subDistributorProfile?.distributorprofile?.distributorname || distributorProfile?.distributorname || ""
      }
    },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  const handleSubmit = async (formik) => {
    const isSubDistributor = await subDistributorProfileBroadcast(sendBroadcastMessage,formik.distributorId) 
    if(isSubDistributor){
      const data = {
        "distributorid": formik.distributorId.value,
      }
      try {
        const response = await getDistributorDetails(data);
        if (response?.status === 0) {
          dispatch(setSubDistributorProfile(response.data));
          dispatch(setSubDistributorProfileForLastLoggedInUser(response.data));
          navigate("/", { replace: true });
        }
        else {
          showError(t("error"), response?.statusMessage);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  }

  const _getDistributorList = async () => {
    const data = {
      "distributorid": distributorProfile.distributorid,
    }
    const response = await getDistributorList(data);
    const tmpDistributorList = [];

    response?.data?.distributors.map((v) => {
      tmpDistributorList.push({ value: v.distributorid, label: v.distributorname });
    });
    setDistributorList(tmpDistributorList);
  }

  useEffect(() => {
    loginDetails?.sessionid && _getDistributorList();
  }, [loginDetails]);

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} />
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">{t('distributorbookinguser:distributorLoungeServices')}</span>
          </h3>
          {/* <h6 className="field px-3">
            {t('fillAllFields')}
          </h6> */}
          <Card className="my-3"  >
            <Card.Body>
              <ErrorMessages formik={formik} />
              <Form onSubmit={(e)=>scrollToDiv(e,formik)}>
                <Row className="d-flex justify-content-center">
                  <Col md={3} sm={12}>
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                        {t('distributorbookinguser:selectDistributor')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        options={distributorList}
                        name="distributorId"
                        value={formik.values.distributorId}
                        onChange={ (v) => formik.setFieldValue('distributorId', v)}
                        onBlur={(v) => formik.handleBlur}
                        className={formik.touched.distributorId && formik.errors.distributorId ? " error my-0 mb-3" : "my-0 mb-3"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t('submit')}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default DistributorBookingUser;
