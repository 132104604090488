import { useEffect, useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPencil, faChampagneGlasses,
  faCircleInfo
} from "@fortawesome/free-solid-svg-icons";

import CommonButton from "../components/commonComponents/CommonButton";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { selectedMarket as currentMarket, availableMarkets as AM, selectedMarketCurrency } from "../redux/marketRedux";
import { DIGICELBLACKCARD_LOGIN, FLAG_URL, PRIORITYPASS_LOGIN, PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE, PRODUCTID_DIGICELBLACKCARD, PRODUCTID_PRIORITYPASS } from "../config/commonConstants";
import { useSelector, useDispatch } from "react-redux";
import { dateFormatForDisplay, getMarketRules, checkLoggedInUserIsPriorityPassOrDigicelBlackCard, getLoggedInUsername, urlForRedirect, myDateFormat, getMarketLoungenamesWithAirport, isObjectEmpty, HandleAPIError, isPaxCountUpdate, getAdultMinimumAge, getChildMinimumAge, dateDifferenceInYears, getAdultValidationsForDob, getChildValidationsForDob, getInfantValidationsForDob, getProductId } from "../components/commonComponents/commonFunctions";
import { cartData as CD, deleteProduct, currentCart as CURR_CART } from "../redux/cartRedux";
import { cancelCartItem } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import ContactDetailsDisplay from "../components/commonComponents/ContactDetailsDisplay";
import PriorityPassDetailsDisplay from "../components/commonComponents/PriorityPassDetailsDisplay";
import DeleteButton from "../components/commonComponents/DeleteButton";
import { paxCountBookingDetails as PCD } from "../redux/increasePaxCountRedux";
import { showError } from "../components/commonComponents/Swal";
import TermsAndConditionsModal from "../components/commonComponents/TermAndConditionModal";
import useToggle from "../hooks/useToggle";
import Switch from "react-switch";

export default function BookingSummary() {
  const { t } = useTranslation(["common", "bookingsummary"]);

  const currencyCode = useSelector(selectedMarketCurrency);

  const cartData = useSelector(CD);
  const currentCart = useSelector(CURR_CART);
  const [isChecked, setIsChecked] = useState(false);
  const [isTermAndConditionChecked, setIsTermAndConditionChecked] = useState(false);
  const [isVisibleTermsAndConditionModal, setIsVisibleTermsAndConditionModal] = useToggle();

  const paxCountBookingDetails = useSelector(PCD);

  const selectedMarket = useSelector(currentMarket);
  const dispatch = useDispatch();
  const country_name_with_flag = [];
  const availableMarkets = useSelector(AM);
  const Rules = selectedMarket?.rules;
  const [loading, setLoading] = useState(false);

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );

  // useEffect(() => {
  //   getAdultValidationsForDob();
  // }, []);

  const showWarningIfError = () => {
    if (!isPassengerDetailAvailableForAllCartItem() || !getAdultValidationsForDob() || !getChildValidationsForDob() || !getInfantValidationsForDob()) {
      HtmlToReactConverter(showError(t("error"), t('bookingsummary:passengerDetailNotAvailableSwalMessage')));
    } else if (!isChecked) {
      showError(t("error"), t('bookingsummary:checkboxWarning'));
    }
  };

  let amount = 0;
  cartData.forEach(e => {
    amount = amount + e.value?.currentCartItem?.retail
  });

  const isPassengerDetailAvailableForAllCartItem = () => {
    let flag = true;
    if (cartData.find(e => e?.value?.data?.passengerInfo === ""))
      flag = false;
    return flag;
  }

  const deleteProductHandler = async (productKey) => {
    try {
      const data = { "cartitemid": productKey };
      setLoading(true);
      const response = await cancelCartItem(data);
      if (response?.status === 0) {
        dispatch(deleteProduct(productKey));
      }
    } catch (error) {
      HandleAPIError(error);
    } finally {
      setLoading(false);
    }
  };

  availableMarkets?.data?.markets?.forEach((m) => {
    country_name_with_flag.push({
      value: m?.marketid,
      label: (
        <div style={{ width: "210px" }}>
          <img
            src={FLAG_URL + m?.image}
            width="30"
            className="d-inline-block align-left mx-1"
            alt={m?.marketname}
          />{" "}
          {m?.marketname}
        </div>
      ),
    });
  });

  const getDirectionTranslationKey = (index) => {
    let translationKey = "departure";
    if (index === 0) {
      translationKey = "arrival";
    } else {
      translationKey = "departure";
    }

    return translationKey;
  }

  const getDirectionTranslationKey2 = (productid) => {
    let translationKey = "departure";


    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      translationKey = "arrivalDeparture";
    } else if (productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else { // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }
    return translationKey;

  }


  var marketRulesArr = getMarketRules(Rules);
  // const cartData = useSelector(CD);
  return (
    <>
      <TermsAndConditionsModal
        visible={isVisibleTermsAndConditionModal}
        cancelModal={() => setIsVisibleTermsAndConditionModal(false)}
        onclick={setIsVisibleTermsAndConditionModal}
        checkLoggedInUserIsPriorityPassOrDigicelBlackCard={checkLoggedInUserIsPriorityPassOrDigicelBlackCard()}
      />
      {/* <Modal
        show={isVisibleDeleteModal}
        onHide={deleteCancelHandler}
        size="md"
        style={{ zIndex: "9996" }}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{t('deleteMessage')}</Modal.Body>
        <Modal.Footer>
          <CommonButton
            classname="exit"
            title={t('no')}
            onclick={deleteCancelHandler}
          />
          <CommonButton classname="btn btn-danger" title={t('yes')} onclick={deleteProductHandler} />
        </Modal.Footer>
      </Modal> */}
      <section>
        <Container className="mt-4">
          <Loader loading={loading} message={"Deleting"} />
          <h3 className="my-3 d-flex justify-content-center heading heading">
            <span className="heading">{t('bookingsummary:bookingSummary')}</span>
          </h3>
            <div className="px-1 d-flex justify-content-end">
              { cartData.length !== 0 &&
                <Link to="/registration"
                  state={{
                    "adultCount": (Object.values(paxCountBookingDetails).length !== 0) ? currentCart?.value?.adultCount : currentCart?.value?.adultCount,
                    "childCount": (Object.values(paxCountBookingDetails).length !== 0) ? currentCart?.value?.childCount : currentCart?.value?.childCount,
                    "infantCount": (Object.values(paxCountBookingDetails).length !== 0) ? currentCart?.value?.infantCount : currentCart?.value?.infantCount,
                    // "infantCountNumber": currentCart?.infantCount?.value?.value,
                    "productid": currentCart?.value?.currentCartItem?.productid,
                    "arrivalscheduleid": currentCart?.value?.currentCartItem?.arrivalscheduleid,
                    "departurescheduleid": currentCart?.value?.currentCartItem?.departurescheduleid,
                    "cartItemId": currentCart?.key,
                    "currentPassengerInfo": currentCart?.value?.data?.passengerInfo,
                    bookingDetail: Object.values(paxCountBookingDetails).length !== 0 ? currentCart?.value?.data?.passengerInfo?.bookingDetail : currentCart?.value?.bookingDetail,
                    ...((Object.values(paxCountBookingDetails).length !== 0) && { getBookingRequestData: currentCart?.value?.data?.passengerInfo?.getBookingRequestData }),

                }}
                className=" mx-1 text-decoration-none">
                <CommonButton classname="back" title={t('back')} />
              </Link>
            }
            <ExitButton />
          </div>
        </Container>
      </section>
      <section>
        <Container className="my-3">
          {
            cartData?.map((product, index) =>
              <Card key={index} className="">
                <Card.Body>
                  <Row className="d-flex justify-content-center align-content-center mb-1">
                    <Col md={12} sm={12}>
                      <div className=" bg-opacity-10 rounded-2 mb-0 d-flex justify-content-end">
                        {/* <h1>{product.key}{" "}{product?.value?.currentCartItem?.productid}{" "}</h1> */}
                        {/* <Link to={`/${product?.value?.currentCartItem?.productid === PRODUCTID_ARRIVALBUNDLE ? ROUTENAME_ARRIVALBUNDLE : product?.value?.currentCartItem?.productid === PRODUCTID_ARRIVAL ? ROUTENAME_ARRIVAL : checkLoggedInUserIsPriorityPassOrDigicelBlackCard() ? getLoggedInUsername() === PRIORITYPASS_LOGIN.username ? ROUTENAME_PRIORITYPASS : ROUTENAME_DIGICELBLACKCARD : ROUTENAME_DEPARTURE}`} */}

                        <Link to={Object.values(paxCountBookingDetails)?.length !== 0 ? "/increase-pax" : urlForRedirect(product?.value?.currentCartItem?.productid)}
                          state={{
                            cartItemId: product.key,
                            productid: checkLoggedInUserIsPriorityPassOrDigicelBlackCard() ? getLoggedInUsername() === PRIORITYPASS_LOGIN.username ? PRODUCTID_PRIORITYPASS : PRODUCTID_DIGICELBLACKCARD : product?.value?.currentCartItem?.productid,
                            arrivalscheduleid: product?.value?.currentCartItem?.arrivalscheduleid,
                            departurescheduleid: product?.value?.currentCartItem?.departurescheduleid,
                            adultCount: product?.value?.adultCount,
                            childCount: product?.value?.childCount,
                            infantCount: product?.value?.infantCount,
                            ...((Object.values(paxCountBookingDetails)?.length !== 0) && { getBookingRequestData: product?.value?.getBookingRequestData }),

                          }} className="">

                          <CommonButton
                            classname="btn-md edit-btn me-2"
                            title={t('edit')}
                            icon={
                              <FontAwesomeIcon
                                icon={faPencil}
                                className="mx-1"
                                size="sm"
                                position="right"
                              />
                            }
                          />

                        </Link>
                        <DeleteButton
                          heading={t('delete')}
                          message={t('deleteMessage')}
                          onClick={() => {
                            deleteProductHandler(product.key)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  {
                    (product?.value?.currentCartItem?.productid === PRODUCTID_ARRIVALBUNDLE
                      &&
                      [product?.value?.bookingDetail[0], product?.value?.bookingDetail[1]]?.map((item, index) =>
                        item !== "" &&
                        <div key={index}>
                          <Row className="d-flex justify-content-center align-content-center mb-1">
                            <Col md={12} sm={12}>
                              <h4 className="my-2">
                                <b>
                                  {t('summary', { product: t(getDirectionTranslationKey(index)) })}
                                </b>
                              </h4>
                              <h5 className="mb-1">
                                <b>{t('bookingsummary:serviceCategory')}: </b>  {Object.values(paxCountBookingDetails)?.length === 0 ? item?.lounge?.label : marketLoungenameswithairport.find(a => a?.value === item?.lounge)?.label}
                              </h5>
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-center align-content-center mb-1">
                            <Col md={5} sm={12}>
                              {Object.values(paxCountBookingDetails)?.length === 0 ? <h5 className="mb-1">
                                <b>{t('flightDateTime')}:</b>
                                {/* {dateFormatForDisplay(item?.flightDate, "MM/dd/yyyy")} */}
                                {Object.values(paxCountBookingDetails)?.length === 0 ? (dateFormatForDisplay(item?.flightDate, "MM/dd/yyyy")) : item?.flightDate}
                                {" "}
                                {item?.flightTime_hour}
                              </h5>
                                :
                                <h5><b>{t("flightDateTime")}:</b> {(myDateFormat(item?.flightDate, "yyyyMMdd", "MM/dd/yyyy"))}{" "}
                                  {(myDateFormat(item?.flightTime_hour, "HHmm", "HH:mm a"))}</h5>}
                            </Col>
                            <Col md={4} sm={12}>
                              <h5 className="mb-1">
                                <b>{t('airline')}:</b>  {Object.values(paxCountBookingDetails)?.length === 0 ? item?.airlineName?.label : item?.airlineName}
                              </h5>
                            </Col>
                            <Col md={3} sm={12}>
                              <h5 className="mb-1">
                                <b>{t('flightNumber')}:</b>  {Object.values(paxCountBookingDetails)?.length === 0 ? item?.flightNumber?.label : item?.flightNumber}
                              </h5>
                            </Col>
                          </Row>
                          {index === 1 && <Row className="d-flex justify-content-center align-content-center mb-1">
                            <Col md={12} sm={12}>
                              <h5 className="my-2">
                                <b>{t('bookingsummary:totalAmount')}:</b> {currencyCode} {" "} {Object.values(paxCountBookingDetails)?.length === 0 ? product?.value?.currentCartItem?.wholesale.toFixed(2) : product?.value?.currentCartItem?.wholesale.toFixed(2)}
                              </h5>
                            </Col>
                          </Row>}
                        </div>))
                  }
                  {
                    product?.value?.currentCartItem?.productid !== PRODUCTID_ARRIVALBUNDLE &&
                    < div key={index}>
                      <Row className="d-flex justify-content-center align-content-center mb-1">
                        <Col md={12} sm={12}>
                          <h4 className="my-2">
                            <b>
                              {t('summary', { product: t(getDirectionTranslationKey2(product?.value?.currentCartItem?.productid === PRODUCTID_ARRIVAL ? PRODUCTID_ARRIVAL : PRODUCTID_DEPARTURE)) })}
                            </b>
                          </h4>
                          <h5 className="mb-1">
                            <b>{t('bookingsummary:serviceCategory')}: </b>  {Object.values(paxCountBookingDetails)?.length === 0 ? product?.value?.bookingDetail?.lounge?.label : marketLoungenameswithairport.find(a => a?.value === product?.value?.bookingDetail?.lounge)?.label}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-center align-content-center mb-1">
                        <Col md={5} sm={12}>
                          <h5 className="mb-1">
                            <b>{t('flightDateTime')}: </b>
                            {/* {(dateFormatForDisplay(product?.value?.bookingDetail?.flightDate, "MM/dd/yyyy"))} */}
                            {product?.value?.bookingDetail?.flightDate && (myDateFormat(product?.value?.bookingDetail?.flightDate, Object.values(paxCountBookingDetails)?.length === 0 ? "MM/dd/yyyy" : "yyyyMMdd", "MM/dd/yyyy"))}

                            {" "}
                            {Object.values(paxCountBookingDetails).length === 0 ? product?.value?.bookingDetail?.flightTime_hour : myDateFormat(product?.value?.bookingDetail?.flightTime_hour, "HHmm", "HH:mm a")}
                          </h5>
                        </Col>
                        <Col md={4} sm={12}>
                          <h5 className="mb-1">
                            <b>{t('airline')}:</b>  {Object.values(paxCountBookingDetails)?.length === 0 ? product?.value?.bookingDetail?.airlineName?.label : product?.value?.bookingDetail?.airlineName}
                          </h5>
                        </Col>
                        <Col md={3} sm={12}>
                          <h5 className="mb-1">
                            <b>{t('flightNumber')}:</b>   {Object.values(paxCountBookingDetails)?.length === 0 ? product?.value?.bookingDetail?.flightNumber?.label : product?.value?.bookingDetail?.flightNumber}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-center align-content-center mb-1">
                        <Col md={12} sm={12}>
                          <h5 className="mb-1">
                            <b>{t('bookingsummary:totalAmount')}:</b> {currencyCode} {product?.value?.currentCartItem?.retail.toFixed(2)}
                          </h5>
                        </Col>
                      </Row>
                    </div>
                  }
                  <Row className="d-flex justify-content-center align-content-center mb-2">
                    <Col md={12} sm={12}>
                      <h5 className="my-2">
                        <b>{t('bookingsummary:otherInformation')}</b>
                      </h5>
                      <h6 className="mb-1">{t('bookingsummary:infantSeatsComplimentary')}</h6>
                    </Col>
                  </Row>
                  {
                    product?.value?.data?.passengerInfo !== "" ? <Row className="d-flex justify-content-start align-content-center mb-2">

                      {product?.value?.data?.passengerInfo?.adults?.map((item, index) =>
                        <div key={index}>
                          <Col md={"auto"} sm={12} key={index}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faUser}
                                className="mx-1 icon-color"
                                size="sm"
                              />{" "}
                              <b>{t('adult')}: </b>{item?.title?.label}{" "}{item?.firstName}{" "}{item?.lastName}
                            </h5>
                          </Col>
                        </div>
                      )}
                      {product?.value?.data?.passengerInfo?.childs?.map((item, index) =>
                        <div key={index}>
                          <Col md={"auto"} sm={12} key={index}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faUser}
                                className="mx-1 icon-color"
                                size="sm"
                              />{" "}
                              <b>{t('child')}: </b>{item?.title?.label}{" "}{item?.firstName}{" "}{item?.lastName}
                            </h5>
                          </Col>
                        </div>
                      )}
                      {product?.value?.data?.passengerInfo?.infant?.map((item, index) =>
                        <div key={index}>
                          <Col md={"auto"} sm={12} key={index}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faUser}
                                className="mx-1 icon-color"
                                size="sm"
                                
                              />{" "}
                              <b>{t('infant')}: </b>{item?.title?.label}{" "}{item?.firstName}{" "}{item?.lastName}
                            </h5>
                          </Col>
                        </div>

                      )}
                      
                    </Row> :
                      (<div className="bg-danger p-2 bg-gradient bg-opacity-50 rounded-1">
                        <HtmlToReactConverter value={t('bookingsummary:passengerDetailNotAvailableMessage')} />                       
                      </div>
                      )
                  }

                  {((getAdultValidationsForDob() === false) || (getChildValidationsForDob() === false) || (getProductId(product?.value?.currentCartItem?.productid) !== PRODUCTID_DEPARTURE && (getInfantValidationsForDob() === false))) && 
                    <div className="bg-danger p-2 bg-gradient bg-opacity-50 rounded-1">
                      <HtmlToReactConverter value={t('bookingsummary:passengerDobWrongMessage')} />  
                    </div>
                  }

                  {/* {(getChildValidationsForDob() === false) &&
                    <div className="bg-danger p-2 bg-gradient bg-opacity-50 rounded-1">
                      <HtmlToReactConverter value={t('bookingsummary:childDobWrongMessage')} />
                    </div>
                  }

                  {(getInfantValidationsForDob() === false) &&
                    <div className="bg-danger p-2 bg-gradient bg-opacity-50 rounded-1">
                      <HtmlToReactConverter value={t('bookingsummary:infantDobWrongMessage')} />
                    </div>
                  } */}

                  <ContactDetailsDisplay contactType="Primary" contactDetails={product?.value?.data?.passengerInfo?.primaryContactDetails} />
                  <ContactDetailsDisplay contactType="Secondary" contactDetails={product?.value?.data?.passengerInfo?.secondaryContactDetails} />
                  {Object.values(paxCountBookingDetails)?.length === 0 && 
                    (product?.value?.data?.passengerInfo?.productid === PRODUCTID_ARRIVAL || product?.value?.data?.passengerInfo?.productid === PRODUCTID_ARRIVALBUNDLE) && product?.value?.data?.passengerInfo?.greetingDetail?.map((item, index) =>
                      <div key={index}>
                        <Row className="d-flex justify-content-center align-content-center mb-2">
                          <Col md={12} sm={12}>
                            <h5 className="mb-1">
                              <b>{t('bookingsummary:otherInformation')}</b>
                            </h5>
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-start align-content-center mb-1">
                          <Col md={6} sm={12}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faUser}
                                className="mx-1 icon-color"
                                size="sm"
                              />{" "}
                              <b>{t('bookingsummary:WelcomeSign')}: </b>{item?.name}
                            </h5>
                          </Col>
                          {item?.occasion?.value && <Col md={6} sm={12}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faChampagneGlasses}
                                className="mx-1 icon-color"
                                size="sm"
                              />{" "}
                              <b>{t('occasion')}: </b>{item?.occasion?.value}
                            </h5>
                          </Col>}
                          {item?.occasionDetail && <Col md={12} sm={12}>
                            <h5>
                              <FontAwesomeIcon
                                icon={faCircleInfo}
                                size="sm"
                                className="mx-1 icon-color"
                              />
                              <b>{t('occasionDetail')}: </b>{item?.occasionDetail}
                            </h5>
                          </Col>
                          }
                        </Row>
                      </div>
                    )}

                  {product?.value?.data?.passengerInfo?.prioritypass ? <PriorityPassDetailsDisplay priorityPassDetails={product?.value?.data?.passengerInfo?.prioritypass} /> : ""}
                </Card.Body>
              </Card>
            )}
        </Container>
      </section >
      <section>
        <Container className="my-3">
          <Card className="">
            <Card.Body>
              {
                (cartData?.length === 0) &&
                <>
                  <Row className="my-2 d-flex justify-content-around align-content-center">
                    <Col>
                      <h4 className="my-bg-info text-center"><HtmlToReactConverter value={t('bookingsummary:emptyCartDataDescription')} /></h4>
                    </Col>
                  </Row>
                  <hr />
                </>
              }
              <Row className="my-2 d-flex justify-content-around align-content-center">
                <Col>
                  <h4>{t('bookingsummary:rulesRestrictions')}</h4>
                  <div className="mb-3 my-bg-info">
                    <ul className="mb-0">
                      <li>
                        <HtmlToReactConverter value={selectedMarket?.rules?.taxes} />
                      </li>
                    </ul>
                  </div>

                  <h4>{t('bookingsummary:cancellationPolicy')}</h4>
                  <div className="my-bg-info">
                    <ul style={{ textAlign: 'justify' }} className="mb-0">
                      {
                        marketRulesArr.map((m, i) => {
                          return <li key={i}><HtmlToReactConverter value={m} /></li>
                        })
                      }
                    </ul>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
      { (cartData?.length === 0) ? null :
        <>
          {
            isPaxCountUpdate() ? null :
              <section>
                <Container className="my-3">
                  <Card>
                    <Card.Body>
                      <Row>
                        <h4 className="">{t('bookingsummary:addAnotherBooking')}</h4>
                        {(availableMarkets?.data?.hide === "Y" && availableMarkets?.data?.hidemarkets === "Y") ? null :      
                          (<Col md={"auto"} sm={6} className="">
                            <h5>
                              <img
                                src={FLAG_URL + selectedMarket?.image}
                                width="40"
                                className="d-inline-block align-left mx-1"
                                alt={selectedMarket?.marketname}
                              />
                              {selectedMarket?.marketname}
                            </h5>
                          </Col>
                        )}
                      </Row>
                      {!checkLoggedInUserIsPriorityPassOrDigicelBlackCard() && <Row className="mb-2 d-flex">
                        {selectedMarket?.arrivalbundleallowed === "Y" ? (
                          <Col md={"auto"} sm={12} className="mb-2 mb-md-0 pe-md-0">
                            <Link to="/product-bundle" state={{ cartItemId: 0, productid: PRODUCTID_ARRIVALBUNDLE }} className=" me-1 text-decoration-none">
                              <CommonButton
                                classname=" gradient-btn "
                                title={t('bookProductTitle', { product: t(getDirectionTranslationKey2(PRODUCTID_ARRIVALBUNDLE)) })}
                              />
                            </Link>
                          </Col>
                        ) : ("")
                        }
                        {selectedMarket?.arrivalonlyallowed === "Y" ? (
                          <Col md={"auto"} sm={12} className="mb-2 mb-md-0 pe-md-0">
                            <Link to="/product-arrival" state={{ cartItemId: 0, productid: PRODUCTID_ARRIVAL }} className=" me-1 my-sm-auto text-decoration-none">
                              <CommonButton
                                classname=" gradient-btn "
                                title={t('bookProductTitle', { product: t(getDirectionTranslationKey2(PRODUCTID_ARRIVAL)) })}
                              />
                            </Link>
                          </Col>
                        ) : ("")
                        }
                        {selectedMarket?.departureloungeallowed === "Y" ? (
                          <Col md={"auto"} sm={12} className="">
                            <Link to="/product-departure" state={{ cartItemId: 0, productid: PRODUCTID_DEPARTURE }} className=" me-1 text-decoration-none">
                              <CommonButton
                                classname=" gradient-btn"
                                title={t('bookProductTitle', { product: t(getDirectionTranslationKey2(PRODUCTID_DEPARTURE)) })}
                              />
                            </Link>
                          </Col>
                        ) : ("")
                        }

                      </Row>}
                      <Row>
                        {checkLoggedInUserIsPriorityPassOrDigicelBlackCard() && getLoggedInUsername() === PRIORITYPASS_LOGIN.username ? (
                          <Col md={"auto"} sm={6} className="">
                            <Link to="/product-priority-pass-departure" state={{ cartItemId: 0, productid: PRODUCTID_PRIORITYPASS }} className=" me-1 text-decoration-none">
                              <CommonButton
                                classname=" gradient-btn"
                                title={t('priorityPassCard')}
                              />
                            </Link>
                          </Col>
                        ) : ("")
                        }
                      </Row>
                      <Row>
                        {checkLoggedInUserIsPriorityPassOrDigicelBlackCard() && getLoggedInUsername() === DIGICELBLACKCARD_LOGIN.username ? (
                          <Col md={"auto"} sm={6} className="">
                            <Link to="/product-digicel-black-card-departure" state={{ cartItemId: 0, productid: PRODUCTID_DIGICELBLACKCARD }} className=" me-1 text-decoration-none">
                              <CommonButton
                                classname=" gradient-btn"
                                title={t('digicelBlackCard')}
                              />
                            </Link>
                          </Col>
                        ) : ("")
                        }
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              </section>
          }

          {/* {checkLoggedInUserIsPriorityPassOrDigicelBlackCard() && */}
          <section>
            <Container className="my-3">
              <Card>
                <Card.Body>
                  <Row>
                    <Col md="12" >
                      <Form>
                        <div className="my-bg-info">
                          <Form.Label className="d-flex">
                          <Switch
                              checked={isChecked}
                              onChange={() => setIsChecked((prev) => !prev)}
                              className="react-switch me-2"
                              height={25}
                            />
                            <Link to="#" className=" text-decoration-none" onClick={setIsVisibleTermsAndConditionModal}> {t('iAgree')}</Link>
                          </Form.Label>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </section>
          {/* } */}
          <section>
            <Container className="mb-4">
              <Card>
                <Card.Body>
                  {/* {!isPassengerDetailAvailableForAllCartItem() &&
                    <Row className="my-3">
                      <Col md={12}>
                        <div className="bg-danger p-2 bg-gradient bg-opacity-50 rounded-1">
                          <HtmlToReactConverter value={t('bookingsummary:passengerDetailNotAvailableMessageCart')} />
                        </div>
                      </Col>
                    </Row>
                  } */}
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col xs={6} className="d-flex mb-2 mb-sm-0">
                      {/* {checkLoggedInUserIsPriorityPassOrDigicelBlackCard() ?
                        <Link to={isChecked ? "/payment" : ""} className="me-1 text-decoration-none" >
                          <CommonButton
                            classname="gradient-btn"
                            title={t('makePayment')}
                            // disabled={!isChecked}
                            onclick={!isChecked ? showWarning : null}
                          />
                        </Link>
                        : */}
                      <Link to={((isPassengerDetailAvailableForAllCartItem() && getAdultValidationsForDob() && getChildValidationsForDob() && getInfantValidationsForDob()) && isChecked) ? "/payment" : ""}
                        className="me-1 text-decoration-none" >
                        <CommonButton
                          classname="gradient-btn"
                          title={t('makePayment')}
                          // disabled={!isPassengerDetailAvailableForAllCartItem()}
                          onclick={() => showWarningIfError()}
                        />
                      </Link>
                      {/* } */}
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end ps-0">
                      <h5 className="m-0">{t('grandTotal')}: {currencyCode} {" "} {amount.toFixed(2)}</h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </section>
        </>
      }
    </>
  );
}