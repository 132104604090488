import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table"
import { getMarketLoungenamesWithAirport, getProductName, myDateFormat } from "./commonFunctions"
import { Table } from "./Table";
import CommonButton from "./CommonButton";
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import { useSelector } from "react-redux";


export default function PartnerReferenceNumberBookingModal(props) {
  const selectedMarket = useSelector(currentMarket);

  const cancelHandler = async () => {
    await props.cancelModal();
  };

  const marketLoungenameswithairport = getMarketLoungenamesWithAirport(
    selectedMarket?.airports
  );

  const getLoungeNameWithAirpot = (value) => {
    return marketLoungenameswithairport.find(a => a?.value === value)?.label;
  }

  const noButtonClickHandler = async () => {
    await props.noButtonClickHandler();
  };

  const { t } = useTranslation(["common", "partnerreferencenumberbookingmodal"]);

  const columnHelper = createColumnHelper()
  const columns = [
    columnHelper.accessor("reservationnumber", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('partnerreferencenumberbookingmodal:reservationNumber')
    }),
    columnHelper.accessor("airportid", {
      cell: info => <div style={{ textAlign: "left" }}>{getLoungeNameWithAirpot(info.getValue())}</div>,
      header: t('partnerreferencenumberbookingmodal:airpot')
    }),
    columnHelper.accessor("flightdate", {
      cell: info => myDateFormat(info.getValue(), "yyyyMMddHHmm", "dd/MM/yyyy"),
      //  cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('partnerreferencenumberbookingmodal:flightDate')
    }),
    columnHelper.accessor("reservationtype", {
      cell: info => <div style={{ textAlign: "left" }}>{getProductName(info.getValue()) }</div>,
      header: t('partnerreferencenumberbookingmodal:product')
    }),
    columnHelper.accessor("guestnames", {
      cell: info => <div style={{ textAlign: "left" }}>{info.getValue()}</div>,
      header: t('partnerreferencenumberbookingmodal:guestName')
    }),
  ]

  return (
    <>
      <Modal
        show={true}
        onHide={cancelHandler}
        size="lg"
        style={{ zIndex: "9998" }}
        centered
        scrollable={true}
      >
        <Modal.Header closeButton><h3>{t("partnerreferencenumberbookingmodal:referencenumberbooking")}</h3></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} sm={12}>
              <p className="me-auto">{t("partnerreferencenumberbookingmodal:information")}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <Table columns={columns} data={props.verifyResponseData} noDataMessage={t("noDataAvailable")} showPaginationControls={false} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md="12">
              <CommonButton
                classname="exit"
                title={t("no")}
                onclick={noButtonClickHandler}
              />
              <CommonButton
                classname="gradient-btn mx-2"
                title={t("yes")}
                onclick={cancelHandler}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
