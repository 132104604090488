import { useEffect, useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import Switch from "react-switch";
import CommonButton from "../components/commonComponents/CommonButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  REGISTRATION_PLACE, PARTNER_BUSSINESS_TYPES, ADDRESS_MAXLENGTH,
  IATA_NO_MAXLENGTH, FIRST_NAME_MAXLENGTH, LAST_NAME_MAXLENGTH, EMAIL_MAXLENGTH,
  CONTACT_NUMBER_MAXLENGTH, BUSINESSNAME_MAXLENGTH, EMAIL_POLICY, CAPTCHA_SITEKEY,REGEX_NUMERIC
} from "../config/commonConstants"
import { COUNTRY_NAME_ARRAY } from "../config/countryConstant";
import { addApplication, altLogin, confirmApplication } from "../redux/apiCalls";
import Loader from "../components/commonComponents/Loader";
import { showError } from "../components/commonComponents/Swal";
import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import OverlayTooltip from "../components/commonComponents/OverlayTooltip";
import { HandleAPIError, divRef, externalLogin, getLoginDetails, get_affiliate_subaffiliate_data,isExternalLink,renderErrorMessages, scrollToDiv } from "../components/commonComponents/commonFunctions";
import PartnerRegistrationTermsConditionsModal from "./PartnerRegistrationTermsConditionsModal";
import useToggle from "../hooks/useToggle";
import ExitButton from "../components/commonComponents/ExitButton";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import { mixed, object, string } from "yup";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { logindetails as LD } from "../redux/signInRedux";
import { selectedMarket as SM } from "../redux/marketRedux";
export default function PartnerRegistration() {

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["common", "partnerregistration"]);
  const [checked, setChecked] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [isProspect, setIsProspect] = useState(false);
  const [isVisibleTermsConditionsModal, setIsVisibleTermsConditionsModal] = useToggle();
  const [isAgreeToTermsConditions, setIsAgreeToTermsConditions] = useToggle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginKey } = useParams();
  const loginDetails = useSelector(LD);
  const [isFirstComplete,setIsFirstComplete] = useState(false);
  const location = useLocation();
  const sendBroadcastMessage = useOutletContext();
  const selectedMarket = useSelector(SM);

  function validationFunction (){
    const validationSchema = object().shape({
      businessName: string().required(
        t("partnerregistration:errors.businessName.required")
      ),
      businessType: mixed().required(
        t("partnerregistration:errors.businessType.required")
      ),
      street: string().required(t("partnerregistration:errors.street.required")),
      city: string().required(t("partnerregistration:errors.city.required")),
      stateProvince: string().required(
        t("partnerregistration:errors.stateProvince.required")
      ),
      countryName: mixed().required(
        t("partnerregistration:errors.countryName.required")
      ),
      firstName: string().required(
        t("partnerregistration:errors.firstName.required")
      ),
      lastName: string().required(
        t("partnerregistration:errors.lastName.required")
      ),
      emailAddress: string()
        .required(t("partnerregistration:errors.emailAddress.required"))
        .matches(EMAIL_POLICY, t("partnerregistration:errors.invalidEmail")),
      phone: string().required(t("partnerregistration:errors.phone.required")),
      registrationLocation: mixed().required(
        t("partnerregistration:errors.registrationLocation.required")
      ),
      chequeName: string().required(
        t("partnerregistration:errors.chequeName.required")
      ),
      mailingStreet: string().required(
        t("partnerregistration:errors.mailingStreet.required")
      ),
      mailingCity: string().required(
        t("partnerregistration:errors.mailingCity.required")
      ),
      mailingState: string().required(
        t("partnerregistration:errors.mailingState.required")
      ),
      mailingCountry: mixed().required(
        t("partnerregistration:errors.mailingCountry.required")
      ),
      websiteInfo: string().required(
        t("partnerregistration:errors.websiteInfo.required")
      ),
      contactName: string().required(
        t("partnerregistration:errors.contactName.required")
      ),
      mailingContactPhone: string().required(
        t("partnerregistration:errors.mailingContactPhone.required")
      ),
      contactEmail: string()
        .required(t("partnerregistration:errors.contactEmail.required"))
        .matches(EMAIL_POLICY, t("partnerregistration:errors.invalidEmail")),
      recaptcha: string().required(
        t("partnerregistration:errors.recaptcha.required")
      ),
    });
    return validationSchema
  }
  const formik = useFormik({
    initialValues: {
      businessName: "",
      businessType: null,
      street: "",
      city: "",
      stateProvince: "",
      countryName: null,
      firstName: "",
      lastName: "",
      emailAddress: "",
      phone: "",
      registrationLocation: null,
      chequeName: "",
      mailingStreet: "",
      mailingCity: "",
      mailingState: "",
      mailingCountry: null,
      websiteInfo: "",
      contactName: "",
      mailingContactPhone: "",
      contactEmail: "",
      recaptcha: "",
      iataNo: "",
      affiliate: true,
      partner: true,
    },
    validationSchema:validationFunction,
    onSubmit: () => handleSubmit(),
  });


  const getUserData = async (loginKey) => {
    try {
      setLoading(true);
      const apiData = {
        "loginkey": loginKey,
        "prospect": "Y"
      };
      const res = await altLogin(apiData); // loginData?.data?.distributorprofile?.prospect?.
      if (res.status === 0) {
        setIsProspect(true);
        const prospect = res?.data?.distributorprofile?.prospect;

        setLoginData({
          "username": res?.data?.username,
          "sessionid": res?.data?.sessionid
        });
        formik.setValues({
          ...formik.values,
          businessName: prospect?.distributorname,
          businessType: {value:prospect?.industryid , label: PARTNER_BUSSINESS_TYPES.find((x)=> x.value === prospect?.industryid).label},
          street: prospect?.address1,
          city: prospect?.address2,
          stateProvince: prospect?.address3,
          countryName: {value:prospect?.countrycode , label: COUNTRY_NAME_ARRAY.find((x)=> x.value === prospect?.countrycode).label},
          firstName: prospect?.firstname,
          lastName: prospect?.lastname,
          emailAddress: prospect?.email,
          phone: prospect?.phone,
          iataNo: prospect?.iatacode,
          registrationLocation: {value :prospect?.location,label: REGISTRATION_PLACE.find((x)=> x.value === prospect?.location).label},
          chequeName: prospect?.chequename,
          websiteInfo: prospect?.url,
          contactName: prospect?.contactname,
          mailingContactPhone: prospect?.contactphone,
          contactEmail: prospect?.contactemail,
          mailingStreet: prospect?.mailingaddress1,
          mailingCity: prospect?.mailingaddress2,
          mailingState: prospect?.mailingaddress3,
          mailingCountry: {value : prospect?.mailingcountrycode,label: COUNTRY_NAME_ARRAY.find((x)=> x.value === prospect?.mailingcountrycode).label},
          affiliate: prospect?.affiliate,
          partner: prospect?.partner,
        })
      } else {
        navigate("/");
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleChange = (nextChecked) => {
    setChecked(nextChecked);

    if (nextChecked === true) {
      // Save the input field values to respective state variables
      formik.setValues({
        ...formik.values,
        mailingState:formik.values.stateProvince,
        mailingStreet:formik.values.street,
        mailingCity:formik.values.city,
        mailingCountry:formik.values.countryName
      })
    }
  };

  const handleSubmit = async () => {
    if (formik.values.recaptcha) {
      const affiliateDetails = get_affiliate_subaffiliate_data();
      const data = {
        "affiliateid": isProspect ? affiliateDetails?.affiliateid : 0,
        "applicant": {
        "distributorname": formik.values.businessName,
        "firstname": formik.values.firstName,
        "lastname": formik.values.lastName,
        "email": formik.values.emailAddress,
        "phone": formik.values.phone,
        "affiliate": formik.values.affiliate ? "Y" : "N",
        "partner": formik.values.partner ? "Y" : "N",
        "chequename": formik.values.chequeName,
        "contactname": formik.values.contactName,
        "contactphone": formik.values.mailingContactPhone,
        "contactemail": formik.values.contactEmail,
        "location": formik.values.registrationLocation.value,
        "address1": formik.values.street,
        "address2": formik.values.stateProvince,
        "address3": formik.values.city,
        "url": "https://" + formik.values.websiteInfo,
        "countrycode": formik.values.countryName.value, 
        "mailingaddress1": formik.values.mailingStreet,
        "mailingaddress2": formik.values.mailingCity,
        "mailingaddress3": formik.values.mailingState,
        "mailingcountrycode": formik.values.mailingCountry.value,
        "iatacode": formik.values.iataNo,
        "industryid": formik.values.businessType.value,
        },
      };
      
      setLoading(true);
      const successInfo = {
        messageHeading: t('partnerregistration:successInfoMessageHeading'),
        successMessage: t('partnerregistration:successInfoSuccessMessage'),
        jePromotion: true
      }
      let response = {};
      try {
        if (isProspect) {
          response = await confirmApplication(loginData, data);
          response.data = response?.data?.distributor;
        } else {
          response = await addApplication(dispatch, data);
        }
        if (response?.status === 0) {
          navigate("/common-success", {state: { successinfo: successInfo, response: response, form: "partner-form"}});
        } else {
          showError(t("error"), response?.statusMessage);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }else {
      showError(t("error"), t('reCaptchaErrorMessage'));
    }
  };

  const getClubsArray=()=>{
    return selectedMarket?.airports?.map(lounge => lounge.lounge).join(' | ').toUpperCase();
  };

 
  useEffect(()=>{
    externalLogin(location,sendBroadcastMessage,navigate,dispatch,setIsFirstComplete)
  },[])

  useEffect(() => {
    if(isExternalLink(location)){
      if(isFirstComplete){
        if (getLoginDetails()?.sessionid) {
          if (loginKey !== undefined) {
            getUserData(loginKey);
          }
        }
      }
    }else{
      
      if (getLoginDetails()?.sessionid) {
        if (loginKey !== undefined) {
          getUserData(loginKey);
        }
      }
    }
  }, [loginDetails,isFirstComplete]);

  return (
    <>
      <PartnerRegistrationTermsConditionsModal
        visible={isVisibleTermsConditionsModal}
        onclick={setIsVisibleTermsConditionsModal}
      />

      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t('partnerregistration:loaderMessage')} />
          <h3 className="d-flex justify-content-center text-center">
            <span className="heading">              
              {isProspect ? t('partnerregistration:prospectRegistrationHeading') : t('partnerregistration:partnerRegistration',{ clubnames :getClubsArray() })}
            </span>
          </h3>
          <Row className="mt-3 mb-1 d-flex justify-content-around align-content-left">
            <Col md={6}></Col>
            <Col md={6} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center"  >
            <Col md="12">
              <Card className="bg-light bg-opacity-10 rounded-3 p-2 my-3">
                <Card.Body>
                  <Row className="d-flex justify-content-start">
                    <Col md={4}>
                      <h6 className="field">{t('fillAllFields')}</h6>
                    </Col>
                   </Row>
                  <ErrorMessages formik={formik} />
                  <Card.Text className="d-flex justify-content-center" ></Card.Text>
                  <Form onSubmit={(e)=>scrollToDiv(e,formik)} method="post">
                    <Row>
                      <Col md="12 ">
                        <Card className="my-1 mb-3 p-3 my-bg-info">
                          {t("partnerregistration:headerInfo")}
                          <p className="mt-3">
                            {t("partnerregistration:moreHeaderInfo")}
                          </p>
                        </Card>
                        <Form.Label
                          className="d-flex field"
                          htmlFor="businessName"
                        >
                          {/* {t("partnerregistration:mandoryFields")}
                          <span className="field">*</span> */}
                        </Form.Label>
                      </Col>
                      <Col md="6" className="mb-3">
                        <Form.Label className="d-flex" htmlFor="businessName">
                          {t("partnerregistration:businessName")}
                          <span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            name="businessName"
                            type="text"
                            maxLength={BUSINESSNAME_MAXLENGTH}
                            id="businessName"
                            placeholder={t("partnerregistration:businessName")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.businessName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.businessName &&
                              formik.errors.businessName
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col md="6" className="mb-3">
                        <Form.Label className="d-flex" htmlFor="businessType">
                          {t("partnerregistration:businessType")}
                          <span className="field">*</span>
                        </Form.Label>
                        <Select
                          name="businessType"                     
                          options={PARTNER_BUSSINESS_TYPES}
                          value={formik.values.businessType}
                          onChange={(value) =>
                            formik.setFieldValue("businessType", value)
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.businessType &&
                            formik.errors.businessType
                              ? "error my-0 rounded"
                              : "my-0 rounded"
                          }
                        />
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="street">
                          {t('partnerregistration:street')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            name="street"
                            type="text"
                            maxLength={ADDRESS_MAXLENGTH}
                            id="street"
                            placeholder={t("partnerregistration:street")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.street}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.street && formik.errors.street
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="city">
                          {t('partnerregistration:city')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            name="city"
                            type="text"
                            maxLength={ADDRESS_MAXLENGTH}
                            id="city"
                            placeholder={t("partnerregistration:city")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.city && formik.errors.city
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="stateProvince">
                          {t('partnerregistration:stateProvince')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            name="stateProvince"
                            type="text"
                            maxLength={ADDRESS_MAXLENGTH}
                            id="stateProvince"
                            placeholder={t("partnerregistration:stateProvince")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.stateProvince}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.stateProvince &&
                              formik.errors.stateProvince
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="country">
                          {t('country')}<span className="field">*</span>
                        </Form.Label>
                        <Select
                          name="countryName"
                          options={COUNTRY_NAME_ARRAY}
                          value={formik.values.countryName}
                          onChange={(value) =>
                            formik.setFieldValue("countryName", value)
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.countryName &&
                            formik.errors.countryName
                              ? "error my-0 rounded"
                              : "my-0 rounded"
                          }
                        />
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationCustomFirstName">
                          {t('firstName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            name="firstName"
                            type="text"
                            maxLength={FIRST_NAME_MAXLENGTH}
                            id="validationCustomFirstName"
                            placeholder={t("firstName")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.firstName &&
                              formik.errors.firstName
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationCustomLastName">
                          {t('lastName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="lastName"
                            maxLength={LAST_NAME_MAXLENGTH}
                            id="validationCustomLastName"
                            placeholder={t("lastName")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.lastName && formik.errors.lastName
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationCustomEmail">
                          {t('email')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            @
                          </InputGroup.Text>
                          <Form.Control
                            name="emailAddress"
                            type="email"
                            id="validationCustomEmail"
                            placeholder={t("emailAddress")}
                            aria-describedby="inputGroupPrepend"
                            maxLength={EMAIL_MAXLENGTH}
                            minLength={1}
                            value={formik.values.emailAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.emailAddress &&
                              formik.errors.emailAddress
                                ? "error"
                                : ""
                            }
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {t('chooseEmailAddress')}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="validationphone">
                          {t('phone')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="phone"
                            maxLength={CONTACT_NUMBER_MAXLENGTH}
                            id="validationphone"
                            placeholder={t("phone")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.phone}
                            onChange={(e)=> formik.setFieldValue("phone",e.target.value.replace(REGEX_NUMERIC,""))}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.phone && formik.errors.phone
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="registrationLocation">
                          {t('partnerregistration:registrationLocation')}
                          <span className="field">*</span>
                        </Form.Label>
                        <Select
                          name="registrationLocation"
                          options={REGISTRATION_PLACE}
                          value={formik.values.registrationLocation}
                          onChange={(value) =>
                            formik.setFieldValue("registrationLocation", value)
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.registrationLocation &&
                            formik.errors.registrationLocation
                              ? "error rounded my-0 mb-3"
                              : "rounded my-0 mb-3"
                          }
                        />
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="iataNo">
                          {t('partnerregistration:iataNo')}
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            id="iataNo"
                            name="iataNo"
                            maxLength={IATA_NO_MAXLENGTH}
                            placeholder={t("partnerregistration:iataNo")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.iataNo}
                            onChange={(e)=>formik.setFieldValue("iataNo",e.target.value.replace(REGEX_NUMERIC, ""))}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.iataNo && formik.errors.iataNo
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      {isProspect ? null :
                        <Col md="12">
                          <Row className="d-flex">
                            <Col xs={"auto"} className="d-flex">
                            <Switch
                              onChange={(nextChecked) => {
                                formik.setFieldValue("affiliate", nextChecked);
                              }}
                              checked={true}
                              value={true}
                              disabled ={true}
                              className="react-switch me-2 mb-3"
                              height={23}
                            />
                            <OverlayTooltip
                              title={t("partnerregistration:affiliate")}
                              msg={t('partnerregistration:affiliateInfo')}
                            ></OverlayTooltip>
                            </Col>
                            <Col xs={"auto"} className="d-flex">
                            <Switch
                              onChange={(nextChecked) => {
                                formik.setFieldValue("partner", nextChecked);
                              }}
                              checked={true}
                              value={true}
                              disabled ={true}
                              className="react-switch me-2 mb-3"
                                height={23}
                            />
                            <OverlayTooltip
                              title={t("partnerregistration:partner")}
                              msg={t("partnerregistration:partnerInfo")}
                            ></OverlayTooltip>
                            </Col>
                          </Row>
                          <Col md="12 ">
                            <Card className="p-3 bg-primary my-bg-info">
                              <h5>{t('partnerregistration:affiliate')}</h5>
                              <p>{t('partnerregistration:affiliateInfo')}</p>
                              <h5>{t('partnerregistration:partner')}</h5>
                              <p>{t('partnerregistration:partnerInfo')}</p>
                            </Card>
                          </Col>
                        </Col>
                      }
                      <Col
                        md="12"
                        className="my-3"
                      >
                        <Form.Label className="d-flex" htmlFor="chequeName">
                          {t('partnerregistration:chequeName')}<span className="field">*</span>
                        </Form.Label>

                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="chequeName"
                            maxLength={FIRST_NAME_MAXLENGTH}
                            id="chequeName"
                            placeholder={t("partnerregistration:chequeName")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.chequeName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.chequeName &&
                              formik.errors.chequeName
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <h5>{t("partnerregistration:mailingAddress")} </h5>
                      <Col md="12" className="d-flex my-3">
                        <h6>{t('partnerregistration:addressMessage')}</h6>
                        <Switch
                          onChange={handleToggleChange}
                          checked={checked}
                          className="react-switch mx-3"
                          height={23}
                        />
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="mailingstreet">
                          {t('partnerregistration:street')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            maxLength={ADDRESS_MAXLENGTH}
                            name="mailingStreet"
                            id="mailingstreet"
                            placeholder={t("partnerregistration:street")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.mailingStreet}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.mailingStreet &&
                              formik.errors.mailingStreet
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col><Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="mailingcity">
                          {t('partnerregistration:city')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            maxLength={ADDRESS_MAXLENGTH}
                            name="mailingCity"
                            id="mailingCity"
                            placeholder={t("partnerregistration:city")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.mailingCity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.mailingCity &&
                              formik.errors.mailingCity
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="mailingcitystateProvince">
                          {t('partnerregistration:stateProvince')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            name="mailingState"
                            maxLength={ADDRESS_MAXLENGTH}
                            id="mailingcitystateProvince"
                            placeholder={t("partnerregistration:state")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.mailingState}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.mailingState &&
                              formik.errors.mailingState
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col><Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="mailingcountry">
                          {t('country')}<span className="field">*</span>
                        </Form.Label>
                        <Select
                          options={COUNTRY_NAME_ARRAY}
                          name="mailingCountry"
                          value={formik.values.mailingCountry}
                          onChange={(value) =>
                            formik.setFieldValue("mailingCountry", value)
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.mailingCountry &&
                            formik.errors.mailingCountry
                              ? "error rounded my-0"
                              : "my-0 rounded"
                          }
                        />
                      </Col>
                      <Col
                        md="12"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="websiteInfo">
                          {t('partnerregistration:websiteInfo')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            maxLength={ADDRESS_MAXLENGTH}
                            id="websiteInfo"
                            name="websiteInfo"
                            placeholder={t("partnerregistration:websiteInfo")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.websiteInfo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.websiteInfo &&
                              formik.errors.websiteInfo
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="contactName">
                          {t('partnerregistration:contactName')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            maxLength={FIRST_NAME_MAXLENGTH}
                            id="contactName"
                            name="contactName"
                            placeholder={t("partnerregistration:contactName")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.contactName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.contactName &&
                              formik.errors.contactName
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="mailingcontactPhone">
                          {t('partnerregistration:contactPhone')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup className="d-flex">
                          <Form.Control
                            type="text"
                            maxLength={CONTACT_NUMBER_MAXLENGTH}
                            id="mailingcontactPhone"
                            name="mailingContactPhone"
                            placeholder={t("partnerregistration:contactPhone")}
                            aria-describedby="inputGroupPrepend"
                            value={formik.values.mailingContactPhone}
                            onChange={(e)=>formik.setFieldValue("mailingContactPhone",e.target.value.replace(REGEX_NUMERIC, ""))}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.mailingContactPhone &&
                              formik.errors.mailingContactPhone
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        md="6"
                        className="mb-3"
                      >
                        <Form.Label className="d-flex" htmlFor="contactEmail">
                          {t('contactEmail')}<span className="field">*</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            @
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            id="contactEmail"
                            name="contactEmail"
                            placeholder={t("emailAddress")}
                            aria-describedby="inputGroupPrepend"
                            maxLength={EMAIL_MAXLENGTH}
                            minLength={1}
                            value={formik.values.contactEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.contactEmail &&
                              formik.errors.contactEmail
                                ? "error"
                                : ""
                            }
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="d-flex justify-content-start mb-2">
                        <ReCAPTCHA
                          sitekey={CAPTCHA_SITEKEY}
                          onChange={(value) =>
                            formik.setFieldValue("recaptcha", value)
                          }
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.recaptcha && formik.errors.recaptcha
                              ? "error rounded"
                              : "rounded"
                          }
                          // size="compact"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        {/* <Form.Check inline id="switch1" className="pl-5">
                          <Form.Check.Input
                            checked={isAgreeToTermsConditions}
                            onChange={(e) => { setIsAgreeToTermsConditions(e.target.checked) }}
                          />
                          <Form.Check.Label>
                            {t('iAgree')}
                            <Link to="#" className=" text-decoration-none" onClick={setIsVisibleTermsConditionsModal}> {t('termsConditions')}</Link>
                          </Form.Check.Label>
                        </Form.Check> */}
                        <Form.Label className="d-flex">
                          <Switch
                            checked={isAgreeToTermsConditions}
                            onChange={(nextChecked) => { setIsAgreeToTermsConditions(nextChecked) }}
                            className="react-switch me-2"
                            height={25}
                          />
                          {/* {t('iAgree')}&nbsp;<Link to="#" className=" text-decoration-none" onClick={setIsVisibleTermsConditionsModal}> {t('termsConditions')}</Link> */}
                          <Link to="#" className=" text-decoration-none" onClick={setIsVisibleTermsConditionsModal}> {t('iAgree')}</Link>
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <CommonButton
                          type="submit"
                          classname="gradient-btn "
                          size="md"
                          title={t('partnerregistration:register')}
                          disabled={!isAgreeToTermsConditions}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
