import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill1Wave,
  faTicket, faTag
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HtmlToReactConverter from "./HtmlToReactConverter";
import { PRODUCTID_ARRIVAL, PRODUCTID_ARRIVALBUNDLE, PRODUCTID_DEPARTURE } from "../../config/commonConstants";
import { getAdultDisplayAge, getChildDisplayAge, getDistributorSubDistributorProfile, getInfantDisplayAge } from "../commonComponents/commonFunctions";
import { selectedMarket as currentMarket, selectedMarketCurrency } from "../../redux/marketRedux";

function PriceModal(props) {
  const selectedMarket = useSelector(currentMarket);
  const currencyCode = useSelector(selectedMarketCurrency);
  const distributorProfile = getDistributorSubDistributorProfile();
  const infantallowed = distributorProfile?.infantallowed;

  const priceList = selectedMarket?.pricelist;
  const cancelHandler = () => {
    props.cancelModal();
  };

  const { t } = useTranslation(["common", "pricemodal"]);
  const getDirectionTranslationKey = () => {
    let translationKey = "departure";
    if(props.productid === PRODUCTID_ARRIVALBUNDLE) {
        translationKey = "arrivalDeparture";
    } else if(props.productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else { // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }

    return translationKey;
  }

  return (
    <div>
      <Modal
        show={props.visible}
        onHide={cancelHandler}
        size="xl"
        // dialogClassName="modal-90w"
        // fullscreen={true}
        style={{ zIndex: "9998" }}
      >
        <Modal.Header closeButton><h3>{t('priceModalHeading', {product: t(getDirectionTranslationKey())})}</h3></Modal.Header>
        <Modal.Body>
          <div style={{ padding: "0 30px" }}>
            {priceList
              ?.filter((item) => item?.productid === props?.productid)
              .map((item,key) => (
                <div key={key}>
                  <h4>{item?.lounge}</h4>
                  <hr className="" />
                  <Row >
                    <Col md={3}>
                      <h5 className="">
                        <FontAwesomeIcon
                          icon={faTicket}
                          size="sm"
                          className="mx-2 icon-color"
                        />
                        {t("pricemodal:ticket")}
                      </h5>
                      <hr />
                      <ul className="list-unstyled mx-2 text">
                        <li>{t("pricemodal:adultTicket")} {getAdultDisplayAge()}</li>
                        <li>{t("pricemodal:childTicket")} { getChildDisplayAge() }</li>
                        {(infantallowed !== "N") && <li> {t("pricemodal:infant")} {getInfantDisplayAge()}</li>}
                      </ul>
                    </Col>

                    <Col md={3}>
                      <h5 className="">
                        <FontAwesomeIcon
                          icon={faMoneyBill1Wave}
                          size="sm"
                          className="mx-2 icon-color"
                        />
                        {item?.productid === PRODUCTID_DEPARTURE
                          ? t("pricemodal:normalPrice")
                          : t("price")}
                      </h5>
                      <hr />
                      <ul className="list-unstyled mx-2" key={item?.lounge}>
                        <li>{currencyCode} {item?.normaladultrate.toFixed(2)}</li>
                        <li>{currencyCode} {item?.normalchildrate.toFixed(2)}</li>
                        {(infantallowed !== "N") && <li> {currencyCode} {item?.normalinfantrate.toFixed(2)} </li>}
                      </ul>
                    </Col>

                    {item?.promotionprice === "Y" &&
                      item?.normaladultrate - item?.adultrate !== 0 ? (
                      <>
                        <Col md={3}>
                          <h5 className="">
                            <FontAwesomeIcon
                              icon={faMoneyBill1Wave}
                              size="sm"
                              className="mx-2 icon-color"
                            />
                            {t("pricemodal:preBookedPrice")}
                          </h5>
                          <hr />
                          <ul className="list-unstyled mx-2" key={item.lounge}>
                            <li>{currencyCode} {item?.adultrate.toFixed(2)}</li>
                            <li>{currencyCode} {item?.childrate.toFixed(2)}</li>
                            {(infantallowed !== "N") && <li>{currencyCode} {item?.infantrate.toFixed(2)}</li>}
                          </ul>
                        </Col>
                        <Col md={3}>
                          <h5 className="">
                            <FontAwesomeIcon
                              icon={faTag}
                              size="sm"
                              className="mx-2 icon-color"
                            />
                            {t("pricemodal:Discount")}
                          </h5>
                          <hr />
                          <ul className="list-unstyled mx-2" key={item?.lounge}>
                            <li>{currencyCode}{" "}{(item?.normaladultrate - item?.adultrate).toFixed(2)}</li>
                            <li>{currencyCode}{" "}{(item?.normalchildrate - item?.childrate).toFixed(2)}</li>
                            {(infantallowed !== "N") && <li>{currencyCode}{" "}{(item?.normalinfantrate - item?.infantrate).toFixed(2)}</li>}
                          </ul>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              ))}

            <Row>
              <Col md={"12"}>
              {priceList
                ?.filter((item) => item?.productid === props?.productid)
                .map(
                  (value, index, elements) =>
                    !elements[index + 1] && (
                      <Row key={index}>
                        <Col md={"12"}>
                          <ul className="list-unstyled mx-2 text">
                            <li className="mb-3">
                              <div className="justifyContent">{value?.line1}</div>
                            </li>
                            <li className="mb-3">
                              <div className="justifyContent"> {value?.line2}</div>
                            </li>
                            <li>
                              <div className="justifyContent">
                                <HtmlToReactConverter value={value?.line3} />
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    )
                )}
                </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PriceModal;
