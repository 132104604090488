import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import range from "lodash/range";
import {
  DOB_YEARS,
  EMAIL_MAXLENGTH,
  FIRST_NAME_MAXLENGTH,
  LAST_NAME_MAXLENGTH,
  REGEX_ALPHABET,
  SALUTATION
} from "../../config/commonConstants";
import DatepickerComponent from "./Date-Picker";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { dateDifferenceInYears, getAdultMinimumAge, translateArrayKeyValue } from "./commonFunctions";
import { showConfirm } from "./Swal";
import { getYear } from "date-fns";

function AdultDetails(props) {
  const [adultDob, setadultDob] = useState();
  const { t } = useTranslation(["common"]);

  const validateDateOfBirth = (val) => {
    const years = dateDifferenceInYears(props.bookingDate, val);
    let adultMinimum = getAdultMinimumAge();
    if (years < (adultMinimum + 1)) {
      const confirmed = showConfirm(t('confirm'), t('adultDobValidationError', { adultMinimum: (adultMinimum + 1) }))
      if (confirmed.isConfirmed) {
        props.onChange("dob", "")
      }
      else {
        props.onChange("dob", "")
      }
    }
    else
      props.onChange("dob", val)
  }
  const [title,setTitle]= useState(translateArrayKeyValue(SALUTATION, "SALUTATION")[0]);

  return (
    <div>
      <Row className="mt-3">
        <Col md="auto" controlid="validationCustom01">
          {props.isMandatory === true ? (
            <Form.Label className="d-flex">
              <FontAwesomeIcon
                icon={faUser}
                className="me-2 icon-color"
                size="lg"
              />
              {t('adult')} <span className="field">*</span>
            </Form.Label>
          ) : (
            <Form.Label ></Form.Label>
          )}
          {/* <Form.Select
            value={props.title}
            className={props.index === 0 ? "" : "mt-2"}
            onChange={(e) => props.onChange?.("title", e.target.value)}
            aria-label="Default select example"
          >
            <option value="MR">Mr.</option>
            <option value="MRS">Mrs.</option>
            <option value="MISS">Miss</option>
            <option value="MASTER">Master</option>
          </Form.Select> */}

          <Select
            name={`adults.${props.index}.title`}
            options={translateArrayKeyValue(SALUTATION, "SALUTATION")}
            className={props.isMandatory === true ? "my-0 select_width" : "mt-2 select_width"}
            autosize={true}
            value={props.title}
            onChange={(d) =>{props.onChange("title", d);setTitle(d)}}
            onBlur={(e)=>props.onBlur("title", e,title)}
            getOptionValue={(v) => v.value}
            getOptionLabel={(v) => v.label}
          />

        </Col>
        <Col
          md="2"
          controlid="validationCustom01"
          className="mt-2"
        >
          <Form.Label></Form.Label>
          <Form.Control
            type="text"
            name={`adults.${props.index}.firstName`}
            id="validationCustom01"
            placeholder={t('firstName')}
            maxLength={FIRST_NAME_MAXLENGTH}
            onChange={(e) => {
              props.onChange("firstName", e.target.value)
            }}
            onBlur={(e) => props.onBlur("firstName", e)}
            //  onBlur={props.onBlur}
            minLength={1}
            value={props.formik?.values?.adults?.[props.index]?.firstName.replace(REGEX_ALPHABET,"")}
            className={props?.formik?.touched["adults"]?.[props.index]?.["firstName"] && props?.formik?.errors["adults"]?.[props.index]?.["firstName"] ? "error rounded-2" : ""}
          />

        </Col>
        <Col
          md="2"
          controlid="validationCustom02"
          className="mt-2"
        >
          <Form.Label></Form.Label>
          <Form.Control
            type="text"
            name={`adults.${props.index}.lastName`}
            placeholder={t('lastName')}
            onChange={(e) => props.onChange("lastName", e.target.value)}
            onBlur={(e) => props.onBlur("lastName", e)}
            maxLength={LAST_NAME_MAXLENGTH}
            minLength={1}
            value={props.formik?.values?.adults?.[props.index]?.lastName.replace(REGEX_ALPHABET,"")}
            className={props?.formik?.touched["adults"]?.[props.index]?.["lastName"] && props?.formik?.errors["adults"]?.[props.index]?.["lastName"] ? "error rounded-2" : ""}
          />
          {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please Enter Last Name.
          </Form.Control.Feedback> */}
        </Col>
        <Col
          md="3"
          controlid="validationCustomUsername"
          className="mt-2"
        >
          <Form.Label></Form.Label>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              type="email"
              placeholder={t('emailAddress')}
              name={`adults.${props.index}.email`}
              aria-describedby="inputGroupPrepend"
              onChange={(e) => props.onChange("email", e.target.value)}
              onBlur={(e) => props.onBlur("email", e)}
              maxLength={EMAIL_MAXLENGTH}
              minLength={1}

              value={props.formik?.values?.adults?.[props.index]?.email}
              className={props?.formik?.touched["adults"]?.[props.index]?.["email"] && props?.formik?.errors["adults"]?.[props.index]?.["email"] ? "error rounded-2" : ""}

            />
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please choose a Email Address.
            </Form.Control.Feedback> */}
          </InputGroup>
        </Col>
        <Col md="3" controlid="validationFormikadultDate" className="mt-4 mt-md-0 d-flex flex-column">
          <Form.Label>{t('dateOfBirthOptional')}</Form.Label>
          {/* <DatePicker
            onChange={(d) => props.onChange("dob", d)}
            value={props.dob}
          /> */}
          <DatepickerComponent
            range={range(getYear(new Date()) - DOB_YEARS, getYear(new Date())+1, 1)}
            variableName="dob"
            onChangeProps={false}
            name={`adults.${props.index}.dob`}
            onBlur={(e) => props.onBlur("dob", e)}
            showClearIcon={true}
            // onBlur={(e) => props.onBlur("lastName", e.target.value)}
            maxLength={LAST_NAME_MAXLENGTH}
            minLength={1}
            value={props.formik?.values?.adults?.[props.index]?.dob}
            //  className={props?.formik?.touched["adults"]?.[props.index]?.["dob"] && props?.formik?.errors["adults"]?.[props.index]?.["dob"] ? "date_invalid_border_color" : ""}
            maxDate={new Date()}
            onchange={async (val) => {
              await validateDateOfBirth(val);
              //  props.onChange("dob", val)
            }}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Col>
      </Row>
    </div >
  );
}

export default AdultDetails;
