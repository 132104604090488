import { Card, Col, Form, Row } from "react-bootstrap";
import OverlayTooltip from "./OverlayTooltip";
import CommonButton from "./CommonButton";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { CARD_NUMBER_MAXLENGTH, MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_CARD_VALUE_LABEL, NAME_LENGTH, REGEX_ALPHABET,REGEX_NUMERIC } from "../../config/commonConstants";
import { useEffect, useState } from "react";
import { getDuplicatesIndex, translateConstantText } from "./commonFunctions";

function TopUpMemberCardInformation(props) {
  const { onRemove, cardIndex } = props;
  const { t } = useTranslation(["common", "memberandcardinformation"]);
  const [cardNumberClassName,setCardNumberClassName] = useState("")
  useEffect(()=>{
    if(props?.formik?.touched["memberShipCards"]?.[props.index]?.["cardNumber"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["cardNumber"]=== " " && getDuplicatesIndex(props?.formik?.values["memberShipCards"]).includes(props?.index)){
      setCardNumberClassName("error rounded-2")
    }
    if(props?.formik?.touched["memberShipCards"]?.[props.index]?.["cardNumber"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["cardNumber"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["cardNumber"] !== " " && !getDuplicatesIndex(props?.formik?.values["memberShipCards"]).includes(props?.index)){
      setCardNumberClassName("error rounded-2")
    }
    
    if(props?.formik?.touched["memberShipCards"]?.[props.index]?.["cardNumber"] && !props?.formik?.errors["memberShipCards"]?.[props.index]?.["cardNumber"]){
      setCardNumberClassName("")
    }

    if(props?.formik?.touched["memberShipCards"]?.[props.index]?.["cardNumber"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["cardNumber"] === " " && !getDuplicatesIndex(props?.formik?.values["memberShipCards"]).includes(props?.index)){
      setCardNumberClassName("")
    }
  

  },[props?.formik?.touched["memberShipCards"] , props?.formik?.errors["memberShipCards"],props?.formik?.values["memberShipCards"]])

  return (
    <div>
      <Card className="rounded-3 px-0 mb-2 mx-2">
        {cardIndex !== 0 && (
          <Card.Header className=" d-flex justify-content-end">
            <CommonButton
              classname="gradient-btn d-flex justify-content-end"
              size="md"
              title={t('memberandcardinformation:removeCard')}
              onclick={onRemove}
            />
          </Card.Header>
        )}
        <Card.Body>
          <Row className="g-3 mb-3">
            <Col md="4">
              <Form.Label className="d-flex" htmlFor="memberinformation_cardvalue">
                {t('cardType',{membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL")})} <span className="field">*</span>
              </Form.Label>
              <Select
              onChange={(e) => {
                props.onChange("cardType", e);
              }}
              options={props.cardTypes}
              name={`memberShipCards.${props.index}.cardType`}
              onBlur={(e) => props.onBlur("cardType", e)}
              value={props.formik?.values?.memberShipCards?.[props.index]?.cardType}
            //  className={props?.formik?.touched["memberShipCards"]?.[props.index]?.["cardType"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["cardType"] ? "error rounded-2" : ""}
              />
            </Col>
            <Col md="8">
              <Form.Label className="d-flex" htmlFor="memberinformation_cardvalue">
              {t('cardValue',{membership: translateConstantText("MEMBERCARD_CARD_VALUE_LABEL")})} <span className="field">*</span>
              </Form.Label>
              <Row className="d-flex ">
                <Col md="6">
                  <Select   
                    onChange={(d) => props.onChange("cardValue", d)}
                    options={props.cardValues}   
                    name={`memberShipCards.${props.index}.cardValue`}
                    onBlur={(e) => props.onBlur("cardValue", e)}
                    value={props.formik?.values?.memberShipCards?.[props.index]?.cardValue} 
                  />
                </Col>
                <Col md="6">
                  <Form.Label  >
                    <span className="ms-1 mt-2"> {props.formik?.values?.memberShipCards?.[props.index]?.cardValueLabel}</span>
                  </Form.Label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-3">
            <Col md="4">
              <Form.Label className="d-flex" htmlFor="validationCustomCardNumber">
                <OverlayTooltip
                  title={t('memberandcardinformation:cardNumber',{membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")})}
                  for="memberinformation_membername"
                  msg={t('memberandcardinformation:cardNumberTooltipTopUpMember')}
                  mandatory={true}
                ></OverlayTooltip>
              </Form.Label>
              <Form.Control
                required
                id="validationCardNumber"
                type="text"
                placeholder={t('cardNumber',{membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL")})}
                minLength={CARD_NUMBER_MAXLENGTH}
                maxLength={CARD_NUMBER_MAXLENGTH}
                value={props.formik?.values?.memberShipCards?.[props.index]?.cardNumber}
                onChange={(e) => props.onChange("cardNumber", e.target.value.replace(REGEX_NUMERIC, ""))}
                onBlur={(e) => props.onBlur("cardNumber", e)}
                name={`memberShipCards.${props.index}.cardNumber`}
                className={cardNumberClassName}
  
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

            </Col >
            <Col md="4">
              <Form.Label className="d-flex" >
                <OverlayTooltip
                  title={t('memberandcardinformation:memberName')}
                  for="memberinformation_membername"
                  msg={t('memberandcardinformation:memberNameTooltip')}
                  mandatory={true}
                ></OverlayTooltip>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t('memberandcardinformation:memberNamePlaceholder')}
                id="memberinformationfgfg_membername"
                value={props.formik?.values?.memberShipCards?.[props.index]?.name}
                // onChange={(e) => props.onChange("name", e.target.value)}
                onChange={(e) => {
                  e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
                  props.onChange("name", e.target.value)
                }}
                maxLength={NAME_LENGTH}
                onBlur={(e) => props.onBlur("name", e)}
                name={`memberShipCards.${props.index}.name`}
                className={props?.formik?.touched["memberShipCards"]?.[props.index]?.["name"] && props?.formik?.errors["memberShipCards"]?.[props.index]?.["name"] ? "error rounded-2" : ""}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TopUpMemberCardInformation;