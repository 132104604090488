import { Card, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import CommonButton from "../components/commonComponents/CommonButton";
import { useState } from "react";
import card1 from "../images/gift-cards/card1.png";
import card2 from "../images/gift-cards/card2.png";
import card3 from "../images/gift-cards/card3.png";
import card4 from "../images/gift-cards/card4.png";
import card5 from "../images/gift-cards/card5.png";
import card6 from "../images/gift-cards/card6.png";
import card7 from "../images/gift-cards/card7.png";
import card8 from "../images/gift-cards/card8.png";
import card9 from "../images/gift-cards/card9.png";
import card10 from "../images/gift-cards/card10.png";
import card11 from "../images/gift-cards/card11.png";
import card12 from "../images/gift-cards/card12.png";
import {
  EMAIL_MAXLENGTH,
  EMAIL_POLICY,
  FIRST_NAME_MAXLENGTH,
  GIFTCARD_MESSAGE_TEXTAREA_MAXLENGTH,
  GIFT_CARD_VALID_UPTO_MONTHS,
  GIFT_FIRST_NAME_MAXLENGTH,
  GIFT_LAST_NAME_MAXLENGTH,
  LAST_NAME_MAXLENGTH,
  PEOPLE_COUNT,
  REGEX_ALPHABET,
  SALUTATION,
} from "../config/commonConstants";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay, faChild,
  faMoneyBill1Wave,
  faHotel,
  faMessage,
  faPersonArrowDownToLine,
  faPersonArrowUpFromLine, faUser
} from "@fortawesome/free-solid-svg-icons";
import ArrivalProductImg from "../images/pic2_resized.jpg";
import DepartureProductImg from "../images/pic3_resized.jpg";
import BundleProductImg from "../images/pic1_resized.jpg";
import { PRODUCTID_ARRIVALBUNDLE } from "../config/commonConstants";
import { PRODUCTID_ARRIVAL } from "../config/commonConstants";
import { PRODUCTID_DEPARTURE } from "../config/commonConstants";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import DatepickerComponent from "../components/commonComponents/DatepickerComponent";
import { useTranslation } from "react-i18next";
import { autoLogin, checkCartItemUse, dateDifferenceInMonth, divRef, getDistributorSubDistributorProfile, getMarketLoungenamesWithAirport, getProductName, renderErrorMessages, scrollToDiv, translateArrayKeyValue } from "../components/commonComponents/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { selectedMarket as currentMarket, selectedMarketCurrency, availableMarkets as AM } from "../redux/marketRedux";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { giftCartFetchSuccess } from "../redux/giftCartRedux";
import { giftCartData as GCD } from "../redux/giftCartRedux";
import { giftPackages as GP, logindetails as LD } from "../redux/signInRedux";
import { getDistributorRates } from "../redux/apiCalls";
import { loggedInUserDetails as LOGGED_USER } from "../redux/loggedInUserDetailRedux";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ExitButton from "../components/commonComponents/ExitButton";
import PriceModal from "../components/commonComponents/PriceModal";
import useToggle from "../hooks/useToggle";
import { showError } from "../components/commonComponents/Swal";
import { subDistributorProfile as SDP } from "../redux/signInRedux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import OverlayTooltip from "../components/commonComponents/OverlayTooltip";
import { addMonths } from "date-fns";
import CommonTextArea from "../components/commonComponents/CommonTextArea";
import Loader from "../components/commonComponents/Loader";


function GiftCard() {
  const { t } = useTranslation(["common", "giftcard"]);
  const loginDetails = useSelector(LD);
  const [key, setKey] = useState(null);
  const sendBroadcastMessage = useOutletContext();
  const [amount, setAmount] = useState(0);
  const [product, setProduct] = useState("");
  const [clubType, setClubType] = useState([]);
  const [distributorRatesData, setDistributorRatesData] = useState([]);
  const [regularRates, setRegularRates] = useState([]);
  const [distributorRates, setDistributorRates] = useState([]);
  const [isVisiblePriceModal, setIsVisiblePriceModal] = useToggle();
  const dispatch = useDispatch();
  const giftCartData = useSelector(GCD);
  const navigate = useNavigate();
  const location = useLocation();
  const giftPackages = useSelector(GP);
  const selectedMarket = useSelector(currentMarket);
  const subDistributorProfile = useSelector(SDP);
  const priceList = selectedMarket?.pricelist;
  const currencyCode = useSelector(selectedMarketCurrency);
  let editKey = location.state?.giftCartItemId ? location.state?.giftCartItemId : null;
  let giftCardForEdit = giftCartData?.find((c) => c.giftCartItemId === editKey);
  const [dob, setDob] = useState(new Date());
  const cards = [card1, card2, card3, card4, card5, card6, card7, card8, card9, card10, card11, card12];
  const [giftCardDetail, setGiftCardDetail] = useState([]);
  const loggedInUserDetails = useSelector(LOGGED_USER);
  const airports = selectedMarket?.airports;
  let loginType = "";
  const [btnClicked, setBtnClicked] = useState('');
  const [salutation, setSalutation] = useState(translateArrayKeyValue(SALUTATION, "SALUTATION"))
  const availableMarkets = useSelector(AM);
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    loungeType: Yup.string().required(t("giftcard:errors.loungeType.required")),
    productid: Yup.string().required(t("giftcard:errors.productid.required")),
    recipient_email: Yup.string()
    .required(t("giftcard:errors.recipient_email.required"))
    .matches(EMAIL_POLICY, t("giftcard:errors.recipient_email.valid")),
    purchase_firstname: Yup.string().required(
      t("giftcard:errors.purchase_firstname.required")
    ),
    purchase_lastname: Yup.string().required(t("giftcard:errors.purchase_lastname.required")),
    recipient_firstname: Yup.string().required(
      t("giftcard:errors.recipient_firstname.required")
    ),
    recipient_lastname: Yup.string().required(t("giftcard:errors.recipient_lastname.required")),
    delivery_date: Yup.string().required(t("giftcard:errors.delivery_date.required")),
    message: Yup.string().required(t("giftcard:errors.message.required")),
    adult_count: Yup.object().required(t("giftcard:errors.recipient_lastname.required"))
      .test(
        'infatn-count-validate',
        t("giftcard:errors.adult.count"),
        adult_count => {
          let count = (parseInt(adult_count.value) + parseInt(formik.values.child_count.value));
          if (count === 0) {
            return false
          }
          return true
        }
      ),
    child_count: Yup.object().test(
      'child-count-validate',
      t(""),
      child_count => {
        let count = (parseInt(formik.values.adult_count.value) + parseInt(child_count.value));
        if (count === 0) {
          return false
        }
        return true
      }
    ),
  });

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
      * (max - min + 1)) + min;
  };

  const resetForm = () => {
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues: editKey === null ? {
      key: "",
      loungeType: getMarketLoungenamesWithAirport(selectedMarket?.airports)[0]?.value,
      selectedCard: "",
      adult_count: { value: "1", label: "1" },
      child_count: { value: "0", label: "0" },
      // infant_count: { value: "0", label: "0" },
      purchase_firstname_title: salutation[0]?.label,
      purchase_firstname: "",
      purchase_lastname: "",
      recipient_firstname_title: salutation[0]?.label,
      recipient_firstname: "",
      recipient_lastname: "",
      recipient_email: "",
      delivery_date: "",
      message: "",
      amount: 0,
      productid: "",
    } : { ...giftCardForEdit },
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  const showPriceModal = async (productid) => {
    setIsVisiblePriceModal(true);
    await setProduct(productid);
  };


  const onClickHandle = async (a, i) => {
    await setKey(a);
    formik.setFieldValue("key", i);
    formik.setFieldValue("selectedCard", a);
    const selectedGiftCard = document.getElementById('selected-gift-card');
    if (selectedGiftCard) {
      selectedGiftCard.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  };

  const addAnotherGiftCardHandler = () => {
    dispatch(giftCartFetchSuccess({ ...formik.values, giftCartItemId: editKey === null ? randomNumberInRange(1, 100) : editKey }));
    setGiftCardDetail({ ...formik.values });
    setKey("");
    giftCardForEdit = "";
    resetForm();
  };

  const scrollIntoGiftInfoDiv = () => {
    const giftInfoDiv = document.getElementById('gift-info-div');
    if (giftInfoDiv) {
      giftInfoDiv.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }

  const updateAmount = (productId, adult_count, child_count, loungeType) => {
    let a = [];
    let b = [];

    distributorRatesData?.forEach(e => {
      if (productId === PRODUCTID_ARRIVALBUNDLE && e.productid === productId) {
        a.push({ airportid: e.airportid, adult: e.adultbundled, child: e.childbundled });
        b.push({ airportid: e.airportid, adult: e.distributoradultrate, child: e.distributorchildrate });
      }
      if (productId === PRODUCTID_ARRIVAL && e.productid === productId) {
        a.push({ airportid: e.airportid, adult: e.adultarrivalrate, child: e.childarrivalrate });
        b.push({ airportid: e.airportid, adult: e.distributoradultrate, child: e.distributorchildrate });
      }
      if (productId === PRODUCTID_DEPARTURE && e.productid === productId) {
        a.push({ airportid: e.airportid, adult: e.adultdeparturerate, child: e.childdeparturerate });
        b.push({ airportid: e.airportid, adult: e.distributoradultrate, child: e.distributorchildrate });
      }
    });

    setRegularRates(a);
    setDistributorRates(b);

    let adultrate = a.find(i => i.airportid === loungeType)?.adult;
    let childrate = a.find(i => i.airportid === loungeType)?.child;

    let adultamount = (adult_count * adultrate);
    let childamount = (child_count * childrate);

    let giftcardAmount = 0;
    if (isNaN(adultamount) || isNaN(childamount)) {
      giftcardAmount = 0;
    } else {
      giftcardAmount = (adultamount + childamount);
    }

    formik.setFieldValue("amount", giftcardAmount);
  }

  const displayRates = (productId) => {
    let a = [];
    let b = [];

    distributorRatesData?.forEach(e => {
      if (e.airportid === getMarketLoungenamesWithAirport(selectedMarket?.airports)[0]?.value || e.airportid === getMarketLoungenamesWithAirport(selectedMarket?.airports)[1]?.value) {
        if (productId === PRODUCTID_ARRIVALBUNDLE && e.productid === productId) {
          a.push({ airportid: e.airportid, adult: e.adultbundled, child: e.childbundled });
          b.push({ airportid: e.airportid, adult: e.distributoradultrate, child: e.distributorchildrate });
        }
        if (productId === PRODUCTID_ARRIVAL && e.productid === productId) {
          a.push({ airportid: e.airportid, adult: e.adultarrivalrate, child: e.childarrivalrate });
          b.push({ airportid: e.airportid, adult: e.distributoradultrate, child: e.distributorchildrate });
        }
        if (productId === PRODUCTID_DEPARTURE && e.productid === productId) {
          a.push({ airportid: e.airportid, adult: e.adultdeparturerate, child: e.childdeparturerate });
          b.push({ airportid: e.airportid, adult: e.distributoradultrate, child: e.distributorchildrate });
        }
      }

    });
    return a;
  }

  const getDistributorRatesService = async () => {
    // if (Object.keys(loggedInUserDetails).length !== 0) {
    if (loginDetails?.sessionid) {
      const rates = await getDistributorRates();
      setDistributorRatesData(rates?.data?.distributorrates);
      // }
    }
  }
  const distributorProfile = getDistributorSubDistributorProfile();

  const [maxAdultCount, setMaxAdultCount] = useState([]);
  const [maxChildCount, setMaxChildCount] = useState([]);
  const [maxInfantCount, setMaxInfantCount] = useState([]);
  const [maxAllowed, setMaxAllowed] = useState();

  const getMaxSeats = () => {
    let adultMaxCount = 0;
    let childMaxCount = 0;
    let infantMaxCount = 0;
    let maxCount = 0;
    let productid = formik?.values?.productid;

    if (productid === PRODUCTID_DEPARTURE ) {
      adultMaxCount = distributorProfile.departuremaxseats;
      childMaxCount = distributorProfile.departuremaxseats;
      infantMaxCount = distributorProfile.departuremaxseats;
      maxCount = distributorProfile.departuremaxseats;
    } else {
      adultMaxCount = distributorProfile.arrivalmaxseats;
      childMaxCount = distributorProfile.arrivalmaxseats;
      infantMaxCount = distributorProfile.arrivalmaxseats;
      maxCount = distributorProfile.arrivalmaxseats;
    }

    let objMaxSeats = {
      adult: adultMaxCount,
      child: childMaxCount,
      infant: infantMaxCount,
      max: maxCount,
    };

    return objMaxSeats;
  };

  const setMaxSeats = () => {
    const objMaxSeats = getMaxSeats();

    const updatedAdultCount = Array.from(
      { length: objMaxSeats.adult + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    const updatedChildCount = Array.from(
      { length: objMaxSeats.child + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    const updatedInfantCount = Array.from(
      { length: objMaxSeats.infant + 1 },
      (_, i) => ({
        value: i.toString(),
        label: i.toString(),
      })
    );

    setMaxAdultCount(updatedAdultCount);
    setMaxChildCount(updatedChildCount);
    setMaxInfantCount(updatedInfantCount);
    setMaxAllowed(objMaxSeats.max);
  };

  const maxChecker = async (value, type) => {
    let returnValue = true;
    let tmpAdultCount = 0;
    let tmpChildCount = 0;

    if (type === "child_count") {
      tmpAdultCount = parseInt(formik.values.adult_count.value);
      tmpChildCount = parseInt(value);
    } else if (type === "adult_count") {
      tmpAdultCount = parseInt(value);
      tmpChildCount = parseInt(formik.values.child_count.value);
    }

    let tmpPaxCount = tmpAdultCount + tmpChildCount;

    if (tmpPaxCount > maxAllowed) {
      returnValue = false;
      await showError(
        t("error"),
        t("common:maxSeats", { maxAllowed: maxAllowed })
      );

      if (type === "child_count") {
        formik.setFieldValue("child_count", { value: "0", label: "0" });
      } else if (type === "adult_count") {
        formik.setFieldValue("adult_count", { value: "0", label: "0" });
      }
    } else {
      formik.setFieldValue("adult_count", {
        value: tmpAdultCount.toString(),
        label: tmpAdultCount.toString(),
      });
      formik.setFieldValue("child_count", {
        value: tmpChildCount.toString(),
        label: tmpChildCount.toString(),
      });
    }
    return returnValue;
  };
  useEffect(() => {
   availableMarkets && setLoading(false);
  }, [availableMarkets]);

  useEffect(() => {
    setMaxSeats();
  }, [formik?.values?.productid, distributorProfile]);

  useEffect(() => {
    setMaxSeats();
  }, [distributorProfile]);

  const loginService = async () => {
    let response = "";
    await autoLogin(dispatch, loginType, sendBroadcastMessage).then((res) => {
      if (res.status === 0) {
        response = res;
      }
    })
      .catch((e) => console.log(e?.message))
      .finally(() => {
      });
    return response;
  }

  useEffect(() => {
    loginService();
    setClubType(getMarketLoungenamesWithAirport(selectedMarket?.airports));
    setKey(giftCardForEdit?.selectedCard);
    getDistributorRatesService();
  }, [loginDetails, availableMarkets]);


  const DateChangeHandler = (value) => {
    formik.setFieldValue("delivery_date", value);
  };

  const handleSubmit = (formik) => {
    let isFormValid = true; // Add a variable to track form validity
    if (isFormValid) {
      if (formik.productid === "") {
        showError(t("error"), t("selectProduct"));
      }
      else {
        if (btnClicked === "add") {
          addAnotherGiftCardHandler();
        } else {
          dispatch(giftCartFetchSuccess({ ...formik, giftCartItemId: editKey === null ? randomNumberInRange(1, 100) : editKey }));
          navigate("/giftSummary");
        }
      }
    }
  };

  const getDirectionTranslationKey = (productid) => {
    let translationKey = "";
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
      translationKey = "arrivalDeparture";
    } else if (productid === PRODUCTID_ARRIVAL) {
      translationKey = "arrival";
    } else { // DEPARTURE, PRIORITY PASS, DIGICEL EXECUTIVE CARD
      translationKey = "departure";
    }
    return translationKey;
  }

  useEffect(() => {
    if (loginDetails?.sessionid) {
      let cartItemId = editKey
      cartItemId && checkCartItemUse(giftCartData, editKey, navigate, true);
    }
  }, [giftCartData, editKey, navigate, loginDetails]);

  return (
    <div>
      <PriceModal
        visible={isVisiblePriceModal}
        cancelModal={() => setIsVisiblePriceModal(false)}
        productid={product}
      ></PriceModal>
      <section>
      <Loader loading={loading} message="" />
        <Container className="my-4">
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">{t('giftcard:giftPackages')}</span>
          </h3>
          {/* <div className="my-3 d-flex justify-content-end">
            <Link
              to="/giftSummary"
              state={giftCardDetail}
              className="d-flex justify-content-center text-decoration-none"
            >
              <FontAwesomeIcon className="my-2" icon={faCartShopping} size="2xl" />
              <Badge className="h-50 my-0" bg="danger">
                {giftCartData.length}
              </Badge>
            </Link>
          </div > */}
          <h3 className=" my-3 d-flex justify-content-center">
            {t('giftcard:selectCardBelow')}
          </h3>
          <Row className="my-3 d-flex justify-content-around align-content-left">
            <Col md={6}></Col>
            <Col md={6} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <Row>
                <Col sm={12} md={12}>
                  <h3>{t('giftcard:pickGiftCard')}</h3>
                </Col>
              </Row>
              <Row>
                {/* {cards?.map((a, i) => (
                  <Col key={i} md={3} className="gift-card-image my-2 d-flex justify-content-center">
                    <Card.Img
                      onClick={() => onClickHandle(a, i)}
                      key={i}
                      variant="top"
                      src={a}
                      className=" img-responsive center-block"
                      alt=""
                    />
                  </Col>
                ))} */}

                <Carousel
                  infinite={true}
                  partialVisible={true}
                  centerMode={false}
                  responsive={{
                    superLargeDesktop: {
                      // the naming can be any, depends on you.
                      breakpoint: { max: 4000, min: 3000 },
                      items: 5
                    },
                    desktop: {
                      breakpoint: { max: 3000, min: 1024 },
                      items: 3
                    },
                    tablet: {
                      breakpoint: { max: 1024, min: 464 },
                      items: 2
                    },
                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1
                    }
                  }}
                >
                  {cards?.map((a, i) => (
                    <Col key={i} md={3} className="gift-card-image">
                      <Image
                        onClick={() => onClickHandle(a, i)}
                        key={i}
                        variant="top"
                        src={a}
                        className="img-responsive"
                        alt=""
                        style={{ "width": "auto" }}
                      />
                    </Col>
                  ))}
                </Carousel>
              </Row>

              {key && (
                <div>
                  <hr className="horizontalBar" />
                  <Form onSubmit={(e) => scrollToDiv(e, formik)}>
                    <Row className="my-3 ">
                      <Col md={"12"}>
                        <h3 className=" d-flex justify-content-center">
                          {t('giftcard:enterCustomerInfo')}
                        </h3>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col md={7}>
                        <div className="my-bg-info">
                          <ul>
                            <li>
                              {t('giftcard:giftCardTermsConditions1',{GIFT_CARD_VALID_UPTO_MONTHS:GIFT_CARD_VALID_UPTO_MONTHS})}
                            </li>
                            <li>
                              {t('giftcard:giftCardTermsConditions2')}
                            </li>
                            <li>
                              {t('giftcard:giftCardTermsConditions3')}
                            </li>
                            <li>
                              <b className="my-1">{t('giftcard:emailDelivery')}: </b> {t('giftcard:giftCardTermsConditions4')}
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md={5}>
                        {key && (
                          <Card.Img
                            variant="top"
                            src={key}
                            className="img-fluid center-block"
                            style={{ "width": "auto" }}
                            alt=""
                            id="selected-gift-card"
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4 className="my-3 d-flex justify-content-center">
                          {t('giftcard:selectYourProduct')}
                        </h4>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center my-1" ref={divRef}>
                      {/* bundle product */}
                      {
                        selectedMarket?.arrivalbundleallowed === "Y" ? subDistributorProfile?.distributorprofile?.arrivalbundle === "N" ? "" :
                          <Col className="col-md-4 d-flex mb-2 mb-md-0">
                            <Card className="cardBackground">

                              <CommonButton
                                onclick={async () => {
                                  formik.setFieldValue("productid", PRODUCTID_ARRIVALBUNDLE);
                                  updateAmount(PRODUCTID_ARRIVALBUNDLE, formik.values.adult_count.value, formik.values.child_count.value, formik.values.loungeType);
                                  scrollIntoGiftInfoDiv();
                                }}
                                classname="my-2 mx-2 gradient-btn"
                                size="md"
                                title={t('bookProductTitle', { product: t(getDirectionTranslationKey(PRODUCTID_ARRIVALBUNDLE)) })}
                              />

                              <Card.Img
                                className="img-responsive bottom-block"
                                variant="middle"
                                src={BundleProductImg}
                              />
                              <Card.Body>
                                <Card.Text className="mb-0">
                                  {" "}
                                  {t("bundleCardDescription")}
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer className="align-items-center text-center p-3">
                                {/* {displayRates(PRODUCTID_ARRIVALBUNDLE)
                              .map((item, key) => (
                                <div key={key} className="bg-dark text-light justify-content-end p-2">
                                  {getClubName(item.airportid)} <br />
                                  <b>{t('adult')}:  {currencyCode} {item?.adult} / {t('child')}:  {currencyCode} {item?.child}</b>
                                </div>
                              ))} */}
                                <Link
                                  to="#"
                                  className="btn p-0 no-btn-border"
                                  onClick={() => { showPriceModal(PRODUCTID_ARRIVALBUNDLE); }}
                                >
                                  {t('giftcard:viewPrices')}
                                </Link>
                              </Card.Footer>
                            </Card>
                          </Col> : ""}
                      {/* arrival product */}
                      {
                        selectedMarket?.arrivalonlyallowed === "Y" ? subDistributorProfile?.distributorprofile?.arrivalonly === "N" ? "" :
                          <Col className="col-md-4 d-flex mb-2 mb-md-0">

                            <Card className="cardBackground">

                              <CommonButton
                                onclick={() => {
                                  formik.setFieldValue("productid", PRODUCTID_ARRIVAL);
                                  updateAmount(PRODUCTID_ARRIVAL, formik.values.adult_count.value, formik.values.child_count.value, formik.values.loungeType);
                                  scrollIntoGiftInfoDiv();
                                }}
                                classname="my-2 mx-2 gradient-btn"
                                size="md"
                                title={t('bookProductTitle', { product: t(getDirectionTranslationKey(PRODUCTID_ARRIVAL)) })}
                              />

                              <Card.Img
                                className="img-responsive bottom-block"
                                variant="middle"
                                src={ArrivalProductImg}
                              />
                              <Card.Body>
                                <Card.Text className="mb-0">
                                  {t("arrivalCardDescription")}
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer className="align-items-center text-center p-3">
                                {/* {displayRates(PRODUCTID_ARRIVAL)
                              .map((item, key) => (
                                <div key={key} className="bg-dark text-light justify-content-end p-2">
                                  {getClubName(item.airportid)} <br />
                                  <b>{t('adult')}:  {currencyCode} {item?.adult} / {t('child')}:  {currencyCode} {item?.child}</b>
                                </div>
                              ))} */}
                                <Link
                                  to="#"
                                  className="btn p-0 no-btn-border"
                                  onClick={() => { showPriceModal(PRODUCTID_ARRIVAL); }}
                                >
                                  {t('giftcard:viewPrices')}
                                </Link>
                              </Card.Footer>
                            </Card>
                          </Col> : ""}
                      {/* departure product */}
                      {
                        selectedMarket?.departureloungeallowed === "Y" ? subDistributorProfile?.distributorprofile?.departurelounge === "N" ? "" :
                          <Col className="col-md-4 d-flex">

                            <Card className="cardBackground">

                              <CommonButton
                                onclick={() => {
                                  formik.setFieldValue("productid", PRODUCTID_DEPARTURE);
                                  updateAmount(PRODUCTID_DEPARTURE, formik.values.adult_count.value, formik.values.child_count.value, formik.values.loungeType);
                                  scrollIntoGiftInfoDiv();
                                }}
                                classname="my-2 mx-2 gradient-btn"
                                size="md"
                                title={t('bookProductTitle', { product: t(getDirectionTranslationKey(PRODUCTID_DEPARTURE)) })}
                              />

                              <Card.Img
                                className="img-responsive bottom-block"
                                variant="middle"
                                src={DepartureProductImg}
                              />
                              <Card.Body>
                                <Card.Text className="mb-0">
                                  {t("departureCardDescription")}
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer className="align-items-center text-center p-3">
                                {/* {displayRates(PRODUCTID_DEPARTURE)
                              .map((item, key) => (
                                <div key={key} className="bg-dark text-light justify-content-end p-2">
                                  {getClubName(item.airportid)} <br />
                                  <b>{t('adult')}:  {currencyCode} {item?.adult} / {t('child')}:  {currencyCode} {item?.child}</b>
                                </div>
                              ))} */}
                                <Link
                                  to="#"
                                  className="btn p-0 no-btn-border"
                                  onClick={() => { showPriceModal(PRODUCTID_DEPARTURE); }}
                                >
                                  {t('giftcard:viewPrices')}
                                </Link>

                              </Card.Footer>
                            </Card>
                          </Col> : ""}
                    </Row>
                    <Row className="my-3">
                      <Col md={6} sm={12}>
                        <h6 className="field mb-2 mt-2">
                          {t('fillAllFields')}
                        </h6>
                        <h6 className="field mb-2">
                          <ErrorMessages formik={formik} />
                        </h6>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col md={4} sm={12} id="gift-info-div">
                        <Form.Group className="mb-3">
                          <Form.Label className="d-flex"> {t('giftcard:product')}<span className="field">*</span></Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            id="productid"
                            name="productid"
                            value={getProductName(formik.values.productid)}
                            //  onChange={formik.handleChange}
                            //  onBlur={formik.handleBlur}
                            className={
                              formik.touched.productid && formik.values.productid === "" &&
                                formik.errors.productid
                                ? "error"
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Label className="d-flex">
                          <FontAwesomeIcon
                            icon={faHotel}
                            size="lg"
                            className="me-2 icon-color"
                          />
                          {t('selectLounge')}{" "}
                          <span className="field">*</span>
                        </Form.Label>
                        <Form.Group>
                          <Select
                            //  className="my-0"
                            value={clubType.find(
                              (x) => x.value === formik.values.loungeType
                            )}
                            onChange={(d) => {
                              if (formik.values.productid === "") {
                                showError(t("error"), t("selectProduct"));
                              }
                              else {
                                formik.setFieldValue("loungeType", d.value);
                                updateAmount(formik.values.productid, formik.values.adult_count.value, formik.values.child_count.value, d.value);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.loungeType &&
                                formik.errors.loungeType
                                ? "error"
                                : ""
                            }
                            getOptionValue={(v) => v.value}
                            getOptionLabel={(v) => v.label}
                            options={clubType}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col md={2} sm={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="icon-color"
                              size="lg"
                            />{" "}
                            {t('giftcard:noOfAdult')}
                          </Form.Label>
                          <Select
                            name="adult_count"
                            options={maxAdultCount}
                            defaultValue={maxAdultCount[1]}
                            // options={PEOPLE_COUNT}
                            // defaultValue={PEOPLE_COUNT[1]}
                            value={formik?.values?.adult_count}
                            onChange={async (d) => {
                              await formik.setFieldValue("adult_count", d);
                              maxChecker(d.value, "adult_count");
                              updateAmount(formik.values.productid, d.value, formik.values.child_count.value, formik.values.loungeType);
                            }}
                            onBlur={formik.handleBlur}
                            className={formik.touched.adult_count && formik.errors.adult_count ? " error rounded-2" : ""}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2} sm={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="d-flex">
                            <FontAwesomeIcon
                              icon={faChild}
                              size="lg"
                              className="mx-1 mt-1 icon-color"
                            />
                            {t('giftcard:noOfChildren')}
                          </Form.Label>
                          <Select
                            name="child_count"
                            options={maxChildCount}
                            defaultValue={maxChildCount[1]}
                            value={formik.values.child_count}
                            onChange={async (d) => {
                              await formik.setFieldValue("child_count", d);
                              maxChecker(d.value, "child_count");
                              updateAmount(formik.values.productid, formik.values.adult_count.value, d.value, formik.values.loungeType);
                            }}
                            onBlur={formik.handleBlur}
                            className={formik.touched.child_count && formik.errors.adult_count ? " error rounded-2" : ""}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2} sm={12}>
                        <Form.Group className="">
                          <Form.Label className="d-flex">
                            <FontAwesomeIcon
                              icon={faMoneyBill1Wave}
                              size="lg"
                              className="mx-1 mt-1 icon-color"
                            />
                            {t('amount')} ({currencyCode})
                          </Form.Label>
                          <Form.Control
                            id="amount"
                            name="amount"
                            disabled
                            type="text"
                            value={formik?.values?.amount?.toFixed(2)}
                            className={

                              formik.errors.amount
                                ? "error"
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faPersonArrowUpFromLine}
                          className="me-2 icon-color"
                          size="lg"
                        />
                        {t('from')}
                      </Form.Label>
                      <Form.Label className="d-flex">
                        {t('giftcard:purchaserName')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Col
                        md={2}
                        controlid="validationCustom01"
                        className="mb-3"
                      >
                        <Select
                          value={salutation.filter(
                            (x) => x.label === formik.values.purchase_firstname_title
                          )[0]}
                          onChange={(d) => {
                            formik.setFieldValue("purchase_firstname_title", d.label);
                          }}
                          getOptionValue={(v) => v.value}
                          getOptionLabel={(v) => v.label}
                          options={salutation}
                        />

                      </Col>
                      <Col
                        md="2"
                        controlid="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Control

                          type="text"
                          //  className="position-relative "
                          placeholder={t('firstName')}
                          id="purchase_firstname"
                          name="purchase_firstname"
                          value={formik.values.purchase_firstname?.replace(REGEX_ALPHABET,"")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.purchase_firstname &&
                              formik.errors.purchase_firstname
                              ? "error"
                              : ""
                          }
                          maxLength={GIFT_FIRST_NAME_MAXLENGTH}
                          minLength={1}
                        />

                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {t('enterFirstName')}
                        </Form.Control.Feedback>
                      </Col>
                      <Col
                        md="2"
                        controlid="validationCustom02"
                      >
                        <Form.Control

                          type="text"
                          // className="position-relative "
                          placeholder={t('lastName')}
                          id="purchase_lastname"
                          name="purchase_lastname"
                          value={formik.values.purchase_lastname?.replace(REGEX_ALPHABET,"")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.purchase_lastname &&
                              formik.errors.purchase_lastname
                              ? "error"
                              : ""
                          }
                          maxLength={GIFT_LAST_NAME_MAXLENGTH}
                          minLength={1}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {t('enterLastName')}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faPersonArrowDownToLine}
                          className="me-2 icon-color"
                          size="lg"
                        />
                        {t('to')}
                      </Form.Label>
                      <Form.Label className="d-flex mb-3">
                        {t('giftcard:receiptName')}
                        <span className="field">*</span>
                      </Form.Label>
                      <Col
                        md="2"
                        controlid="validationCustom01"
                        className="mb-3"
                      >
                        <Select
                          value={salutation.filter(
                            (x) => x.label === formik.values.recipient_firstname_title
                          )[0]}
                          onChange={(d) =>
                            formik.setFieldValue("recipient_firstname_title", d.label)
                          }
                          getOptionValue={(v) => v.value}
                          getOptionLabel={(v) => v.label}
                          options={salutation}
                        />
                      </Col>
                      <Col
                        md="2"
                        controlid="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Control

                          type="text"
                          // className="position-relative"
                          placeholder={t('firstName')}
                          id="recipient_firstname"
                          name="recipient_firstname"
                          value={formik.values.recipient_firstname?.replace(REGEX_ALPHABET,"")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.recipient_firstname &&
                              formik.errors.recipient_firstname
                              ? "error"
                              : ""
                          }
                          maxLength={GIFT_FIRST_NAME_MAXLENGTH}
                          minLength={1}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {t('enterFirstName')}
                        </Form.Control.Feedback>
                      </Col>
                      <Col
                        md="2"
                        controlid="validationCustom02"
                        className="mb-3"
                      >
                        <Form.Control

                          type="text"

                          placeholder={t('lastName')}
                          id="recipient_lastname"
                          name="recipient_lastname"
                          value={formik.values.recipient_lastname?.replace(REGEX_ALPHABET,"")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.recipient_lastname &&
                              formik.errors.recipient_lastname
                              ? "error"
                              : ""
                          }
                          maxLength={GIFT_LAST_NAME_MAXLENGTH}
                          minLength={1}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          {t('enterLastName')}
                        </Form.Control.Feedback>
                      </Col>
                      <Col
                        md="3"
                        controlid="validationCustomUsername"
                        className=""
                      >
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            @
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            placeholder={t('emailAddress')}
                            aria-describedby="inputGroupPrepend"

                            id="recipient_email"
                            name="recipient_email"
                            value={formik.values.recipient_email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.recipient_email &&
                                formik.errors.recipient_email
                                ? "error"
                                : ""
                            }
                            maxLength={EMAIL_MAXLENGTH}
                            minLength={1}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {t('chooseEmailAddress')}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faCalendarDay}
                          className="me-2 icon-color"
                          size="lg"
                        />
                        {t('giftcard:deliverOn')}
                      </Form.Label>
                      <Form.Label className="d-flex">
                        <OverlayTooltip
                          title={t('giftcard:deliveryDate')}
                          msg={t("giftcard:giftCardPurchaseUpto",{GIFT_CARD_VALID_UPTO_MONTHS:GIFT_CARD_VALID_UPTO_MONTHS})}
                          mandatory={true}
                        >
                        </OverlayTooltip>
                      </Form.Label>
                      <Col
                        md="2"
                        controlid="validationFormikadultDate"
                        className=""
                      >
                        <DatepickerComponent
                          onChangeProps={false}
                          onchange={DateChangeHandler}
                          // value={giftCardDetail.delivery_date}
                          id="delivery_date"
                          name="delivery_date"
                          value={formik.values.delivery_date}
                          onBlur={formik.handleBlur}
                          minDate={new Date()}
                          maxDate={addMonths(new Date(), GIFT_CARD_VALID_UPTO_MONTHS)}
                          className={
                            formik.touched.delivery_date &&
                              formik.errors.delivery_date
                              ? "date_invalid_border_color"
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    {/* <Row className="my-3">
                      <Col
                        md={11}
                        controlid="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>
                          {" "}
                          <FontAwesomeIcon
                            icon={faMessage}
                            size="lg"
                            className="me-2 icon-color"
                          />
                          {t('giftcard:personalizedMessage')}
                          <span className="field">*</span>
                        </Form.Label>
                        <Form.Control
                          id="message"
                          name="message"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          maxLength={GIFTCARD_MESSAGE_TEXTAREA_MAXLENGTH}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.message &&
                              formik.errors.message
                              ? "error"
                              : ""
                          }

                          as="textarea"
                          rows={3}
                        />
                      </Col>
                    </Row> */}
                    <CommonTextArea
                      id="message"
                      name="message"
                      mandatory={true}
                      heading={t('giftcard:personalizedMessage')}
                      icon={faMessage}
                      md={11}
                      text={formik.values.message}
                      onBlur={(key, e) => {
                        formik.handleBlur(e);
                      }}
                      onChange={(key, e) => {
                        formik.handleChange(e);
                      }}
                      className={formik.touched.message && formik.errors.message ? "error" : ""}
                      maxLength={GIFTCARD_MESSAGE_TEXTAREA_MAXLENGTH}
                    />

                    <Row className="d-flex justify-content-end">
                      <Col
                        md={"auto"}
                        className="d-flex justify-content-start mb-2 ps-md-1"
                      >
                        {/* <Link
                          to="/"
                          state={giftCardDetail}
                          className="d-flex justify-content-center text-decoration-none"
                        > */}
                        <CommonButton
                          type="submit"
                          classname="gradient-btn"
                          title={t('giftcard:confirmPackage')}
                          onMouseOver={(event) => {
                            setBtnClicked('submit');
                          }}
                        // onclick={() => {
                        //   let flag = "false";
                        //   formik.handleSubmit(flag);
                        // }}
                        />
                        {/* </Link> */}
                      </Col>
                      <Col
                        md={"auto"}
                        className="d-flex justify-content-start mb-2 ps-md-1"
                      >
                        {/* <Link
                          to="#"

                          className="d-flex justify-content-center text-decoration-none"
                        > */}
                        <CommonButton
                          type="submit"
                          classname="gradient-btn"
                          title={t('giftcard:confirmPackageAddAnother')}
                          onMouseOver={(event) => {
                            setBtnClicked('add');
                          }}

                        // onclick={() => {
                        //   let flag = "true";
                        //   formik.handleSubmit(flag);
                        // }}
                        // onclick={addAnotherGiftCardHandler}
                        />
                        {/* </Link> */}
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </section>
    </div>
  );
}

export default GiftCard;
