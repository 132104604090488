import { useEffect, useState } from "react";
import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Select from "react-select";
import {
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_ARRIVAL,
  PRODUCTID_DEPARTURE,
  EMAIL_MAXLENGTH,
  CONTACT_NUMBER_MAXLENGTH,
  LAST_NAME_MAXLENGTH,
  FIRST_NAME_MAXLENGTH,
  SALUTATION,
  PRODUCTNAME_ARRIVALBUNDLE,
  PRODUCTNAME_DEPARTURE,
  PRODUCTNAME_ARRIVAL,
  GROUP_BOOKING_ADULT_COUNT,
  GROUP_BOOKING_CHILD_COUNT,
  GROUP_BOOKING_INFANT_COUNT,
  EMAIL_POLICY,
  GROUP_BOOKING_MINIMUM_PAXCOUNT,
  GROUP_BOOKING_MAXLENGTH,
  REGEX_ALPHANUMERIC_WITHOUT_SPACE,
  REGEX_ALPHANUMERIC_WITH_SPACE,
  REGEX_ALPHABET
} from "../config/commonConstants";
import OverlayTooltip from "../components/commonComponents/OverlayTooltip";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChild,
  faUsers,
  faFileImport,
  faPeopleRoof,
  faPersonBreastfeeding,
  faUser,
  faAddressCard
} from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../components/commonComponents/CommonButton";
import { useTranslation } from "react-i18next";
import { HandleAPIError, dateFormatForDisplay, divRef, getAdultDisplayAge, getAirportNamesArrayForGroupBoooking, getChildDisplayAge, getInfantDisplayAge, isObjectEmpty, renderErrorMessages, scrollToDiv, stripBlankNamesArray, translateArrayKeyValue } from "../components/commonComponents/commonFunctions";
import Loader from "../components/commonComponents/Loader";
import readXlsxFile from 'read-excel-file'
import { showError } from "../components/commonComponents/Swal";
import { addGroupBooking, getFlightsCache } from "../redux/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { VIPER_CONST } from "../config/commonConstants";
import { useFormik } from "formik";
import { mixed, object, string } from "yup";
import { useSelector } from "react-redux";
import { logindetails as LD } from "../redux/signInRedux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import * as Yup from "yup";

function GroupBookingForm() {
  const { t } = useTranslation(["common", "groupbooking"]);
  const navigate = useNavigate();
  const loginDetails = useSelector(LD);
  const [loading, setLoading] = useState(false);
  const [adultCount, setAdultCount] = useState({});
  const [childCount, setChildCount] = useState({});
  const [infantCount, setInfantCount] = useState({});
  const [flights, setflights] = useState({});
  const [selectedAdultCount, setSelectedAdultCount] = useState(null);
  const [selectedChildCount, setSelectedChildCount] = useState(null);
  const [selectedInfantCount, setSelectedInfantCount] = useState(null);

  const [salutation] = useState(translateArrayKeyValue(SALUTATION,"SALUTATION"));
  const AIRPORTS = getAirportNamesArrayForGroupBoooking();
  const [booking, setBooking] = useState([]);
  let cardDetailsValid = false
  const setAdultSeats = () => {
    let tmpAdultCount = [];
    for (let i = GROUP_BOOKING_ADULT_COUNT; i <= 1000; i++) {
      tmpAdultCount.push({ "value": i, "label": i });
    }
    setAdultCount(tmpAdultCount);
    // setSelectedAdultCount(tmpAdultCount[0]); //setting default value
    formik.setFieldValue("adultCount",tmpAdultCount[0]);
  }

  const setChildSeats = () => {
    let tmpChildCount = [];
    for (let i = 0; i <= GROUP_BOOKING_CHILD_COUNT; i++) {
      tmpChildCount.push({ "value": i, "label": i });
    }
    setChildCount(tmpChildCount);
    //setSelectedChildCount(tmpChildCount[0]); //setting default value
    formik.setFieldValue("childCount",tmpChildCount[0]);
  }

  const setInfantSeats = () => {
    let tmpInfantCount = [];
    for (let i = 0; i <= GROUP_BOOKING_INFANT_COUNT; i++) {
      tmpInfantCount.push({ "value": i, "label": i });
    }
    setInfantCount(tmpInfantCount);
    //setSelectedInfantCount(tmpInfantCount[0]); //setting default value
    formik.setFieldValue("infantCount",tmpInfantCount[0]);
  }

  const getFlightsArr = async () => {
    try {
      setLoading(true);
      const response = await getFlightsCache();
      if (response?.status === 0) {
        setflights(response?.data?.flights)
      } else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const [validationResults, setValidationResults] = useState([]);

  const ExcelCols = ({
    product: 0,
    arrivalAirport: 1,
    arrivalDate: 2,
    arrivalTime: 3,
    arrivalFlightId: 4,
    departureAirport: 5,
    departureDate: 6,
    departureTime: 7,
    departureFlightId: 8,
    adultPaxCount: 9,
    childPaxCount: 10,
    infantPaxCount: 11,
    adultNames: 12,
    childNames: 13,
    infantNames: 14,
    primaryContact: 15,
    contactEmail: 16,
    cardHolder: 17,
    authorization: 18,
    card: 19,
    chargedAmount: 20,
    paymentDate: 21
  })

  const handleValidation =  async (rowData, rowNo) => {
    let tmpRowNo = rowNo - 1
    let valid = true;
    let arrErrors = [];
    const primary_email = rowData[ExcelCols?.contactEmail];
    const card_holder = rowData[ExcelCols?.cardHolder]
    const authorization = rowData[ExcelCols?.authorization]
    const card = rowData[ExcelCols?.card]
    const charged_amount = rowData[ExcelCols?.chargedAmount]

    let adult_names = rowData[ExcelCols?.adultNames]
    let child_names = rowData[ExcelCols?.childNames]
    let infant_names = rowData[ExcelCols?.infantNames]
    let adultNamesArr = []
    let childNamesArr = []
    let infantNamesArr = []

    const adultCount = rowData[ExcelCols?.adultPaxCount] ? rowData[ExcelCols?.adultPaxCount] : 0;
    const childCount = rowData[ExcelCols?.childPaxCount] ? rowData[ExcelCols?.childPaxCount] : 0;
    const infantCount = rowData[ExcelCols?.infantPaxCount] ? rowData[ExcelCols?.infantPaxCount] : 0;

    let product = rowData[ExcelCols?.product]
    let arrivalAirportName = rowData[ExcelCols?.arrivalAirport];

    let arrivalDate = null;
    let arrivalTime = null
    if ((rowData[ExcelCols?.arrivalDate] !== null) || (rowData[ExcelCols?.arrivalTime] !== null)) {
      arrivalDate = dateFormatForDisplay(rowData[ExcelCols?.arrivalDate], "yyyyMMdd");
      arrivalTime = dateFormatForDisplay(rowData[ExcelCols?.arrivalTime], "HHmm");
    }
    let arrivalFlight = rowData[ExcelCols?.arrivalFlightId]
    let departureAirportName = rowData[ExcelCols?.departureAirport];
    let departureDate = null;
    let departureTime = null
    if ((rowData[ExcelCols?.departureDate] !== null) || (rowData[ExcelCols?.departureTime] !== null)) {
      departureDate = dateFormatForDisplay(rowData[ExcelCols?.departureDate], "yyyyMMdd");
      departureTime = dateFormatForDisplay(rowData[ExcelCols?.departureTime], "HHmm");
    }
    let departureFlight = rowData[[ExcelCols?.departureFlightId]]
    const currentDate = dateFormatForDisplay(new Date(), "yyyyMMdd");

    // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (primary_email === null) {
      valid = false;
      arrErrors.push(t('groupbooking:errors.primaryEmail', { tmpRowNo: tmpRowNo }));
    } else if (!EMAIL_POLICY.test(primary_email)) {
      valid = false;
      arrErrors.push(t('groupbooking:errors.validPrimaryEmail', { tmpRowNo: tmpRowNo }));
    }
   
    if (charged_amount === null) {
      valid = false;
      arrErrors.push(
        t("groupbooking:errors.chargedAmount", { tmpRowNo: tmpRowNo })
      );
    } else {
      if (charged_amount <= 0) {
        valid = false;
        arrErrors.push(t("groupbooking:errors.correctChargedAmout", { tmpRowNo: tmpRowNo }));
      }
      // if (charged_amount.includes("=")) {
      //   valid = false;
      //   arrErrors.push(t("groupbooking:errors.chargedAmountWithoutFormula", { tmpRowNo: tmpRowNo }));
      // }
    }

    if (rowNo === 2 && card_holder !== null) {
      cardDetailsValid = true;
    }

    if (cardDetailsValid) {
      if (card_holder === null || authorization === null || card === null) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.missingCardDetails', { tmpRowNo: tmpRowNo }));
      }
    } else {
      if (card_holder !== null || authorization !== null || card !== null) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.notAllowedCardDetails', { tmpRowNo: tmpRowNo }));
      }
    }

    if (adult_names) {
      if (adult_names !== null) {
        adultNamesArr = adultNamesArr.concat(await stripBlankNamesArray(adult_names));
      }
    }
    if (child_names) {
      if (child_names !== null) {
        childNamesArr = childNamesArr.concat(await stripBlankNamesArray(child_names));
        // childNamesArr.push(child_names);
      }
    }
    if (infant_names) {
      if (infant_names !== null) {
        infantNamesArr = infantNamesArr.concat(await stripBlankNamesArray(infant_names));
        // infantNamesArr.push(infant_names);
      }
    }

    if (adultCount !== adultNamesArr.length) {
      valid = false;
      arrErrors.push(t('groupbooking:errors.adultCountMismatch', { tmpRowNo: tmpRowNo }));
    }

    if (childCount !== childNamesArr.length) {
      valid = false;
      arrErrors.push(t('groupbooking:errors.childCountMismatch', { tmpRowNo: tmpRowNo }));
    }

    if (infantCount !== infantNamesArr.length) {
      valid = false;
      arrErrors.push(t('groupbooking:errors.infantCountMismatch', { tmpRowNo: tmpRowNo }));
    }

    if (product === PRODUCTNAME_ARRIVALBUNDLE) {
      if ((arrivalAirportName === null) || (arrivalFlight === null) || (arrivalDate === null) || (arrivalTime === null)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.missingArrivalInfo', { tmpRowNo: tmpRowNo }));
      }
      if ((departureAirportName === null) || (departureFlight === null) || (departureDate === null) || (departureTime === null)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.missingDepartureInfo', { tmpRowNo: tmpRowNo }));
      }

      if (!AIRPORTS.find(item => item.name === arrivalAirportName)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.invalidArrivalAirport', { tmpRowNo: tmpRowNo }));
      }
      if (!AIRPORTS.find(item => item.name === departureAirportName)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.invalidDepartureAirport', { tmpRowNo: tmpRowNo }));
      }

      if (!flights.find(item => item.flightid === arrivalFlight.trim())) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.invalidArrivalFlightId', { tmpRowNo: tmpRowNo }));
      }
      if (!flights.find(item => item.flightid === departureFlight.trim())) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.invalidDepartureFlightId', { tmpRowNo: tmpRowNo }));
      }

      if (arrivalDate !== null) {
        if (arrivalDate < currentDate) {
          valid = false;
          arrErrors.push(t('groupbooking:errors.invalidArrivalDate', { tmpRowNo: tmpRowNo }));
        }
      }
      if (departureDate !== null) {
        if (departureDate < currentDate) {
          valid = false;
          arrErrors.push(t('groupbooking:errors.invalidDepartureDate', { tmpRowNo: tmpRowNo }));
        }
      }
    } else if (product === PRODUCTNAME_ARRIVAL) {
      if ((arrivalAirportName === null) || (arrivalFlight === null) || (arrivalDate === null) || (arrivalTime === null)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.missingArrivalInfo', { tmpRowNo: tmpRowNo }));
      }

      if ((departureAirportName !== null) || (departureFlight !== null) || (departureDate !== null) || (departureTime !== null)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.arrivalProductDepartureInfo', { tmpRowNo: tmpRowNo }));
      }

      if (!flights.find(item => item.flightid === arrivalFlight.trim())) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.invalidArrivalFlightId', { tmpRowNo: tmpRowNo }));
      }

      if (!AIRPORTS.find(item => item.name === arrivalAirportName)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.invalidArrivalAirport', { tmpRowNo: tmpRowNo }));
      }

      if (arrivalDate !== null) {
        if (arrivalDate < currentDate) {
          valid = false;
          arrErrors.push(t('groupbooking:errors.invalidArrivalDate', { tmpRowNo: tmpRowNo }));
        }
      }

    } else if (product === PRODUCTNAME_DEPARTURE) {
      if ((departureAirportName === null) || (departureFlight === null) || (departureDate === null) || (departureTime === null)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.missingDepartureInfo', { tmpRowNo: tmpRowNo }));
      }

      if ((arrivalAirportName !== null) || (arrivalFlight !== null) || (arrivalDate !== null) || (arrivalTime !== null)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.departureProductArrivalInfo', { tmpRowNo: tmpRowNo }));
      }

      if (!flights.find(item => item.flightid === departureFlight.trim())) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.invalidDepartureFlightId', { tmpRowNo: tmpRowNo }));
      }

      if (!AIRPORTS.find(item => item.name === departureAirportName)) {
        valid = false;
        arrErrors.push(t('groupbooking:errors.invalidDepartureAirport', { tmpRowNo: tmpRowNo }));
      }

      if (departureDate !== null) {
        if (departureDate < currentDate) {
          valid = false;
          arrErrors.push(t('groupbooking:errors.invalidDepartureDate', { tmpRowNo: tmpRowNo }));
        }
      }
    }

    return { valid, arrErrors };
  };

  const processExcelFile = async (file) => {
    try {
      let arrErrors = [];
      // const file = event.target.files[0];
      let totalAdultCount = 0;
      let totalChildCount = 0;
      let totalInfantCount = 0;
      const tmpArr = [];
      const rows = await readXlsxFile(file, { sheet: 1, skipRows: 1 });
      const dataFromSecondLine = rows.slice(1); // Skip the first line
      setValidationResults([]);
      for (const [index, row] of dataFromSecondLine.entries()) {
        const rowNo = index + 2;
        let obj = {}
        let adultCount = row[ExcelCols?.adultPaxCount]
        let childCount = row[ExcelCols?.childPaxCount]
        let infantCount = row[ExcelCols?.infantPaxCount]
        let product = row[ExcelCols?.product]
        let passengerArr = []
        let paymentDate = dateFormatForDisplay(row[ExcelCols?.paymentDate], "yyyyMMdd");
        const tmpAdultCount = row[ExcelCols?.adultPaxCount] ? parseInt(row[ExcelCols?.adultPaxCount]) : 0;
        const tmpChildCount = row[ExcelCols?.childPaxCount] ? parseInt(row[ExcelCols?.childPaxCount]) : 0;
        const tmpInfantCount = row[ExcelCols?.infantPaxCount] ? parseInt(row[ExcelCols?.infantPaxCount]) : 0;
        totalAdultCount += tmpAdultCount;
        totalChildCount += tmpChildCount;
        totalInfantCount += tmpInfantCount;
        let arrivalAirportName = AIRPORTS.find(item => item.name === row[ExcelCols?.arrivalAirport]);
        let departureAirportName = AIRPORTS.find(item => item.name === row[ExcelCols?.departureAirport]);

        const validationData = await handleValidation(row, rowNo);
        if (!validationData.valid) {
          arrErrors.push(...validationData.arrErrors);
          // setValidationResults((prevResults) => [
          //   ...prevResults,
          //   ...validationData.arrErrors,
          // ]);
        } else {
          if (adultCount > 0) {
            const [adultFirstName, adultLastName] = row[ExcelCols?.adultNames].split(' ');
            passengerArr = [{
              "firstname": adultFirstName,
              "lastname": adultLastName,
              "passengertype": "A"
            }]
          } else if (childCount > 0) {
            const [childFirstName, childLastName] = row[ExcelCols?.childNames].split(' ');
            passengerArr = [{
              "firstname": childFirstName,
              "lastname": childLastName,
              "passengertype": "C"
            }]
          } else if (infantCount > 0) {
            const [infantFirstName, infantLastName] = row[ExcelCols?.infantNames].split(' ');
            passengerArr = [{
              "firstname": infantFirstName,
              "lastname": infantLastName,
              "passengertype": "I"
            }]
          }

          if (product === PRODUCTNAME_ARRIVALBUNDLE) {
            let productid = PRODUCTID_ARRIVALBUNDLE
            let arrivalDate = dateFormatForDisplay(row[ExcelCols?.arrivalDate], "yyyyMMdd");
            let arrivalTime = dateFormatForDisplay(row[ExcelCols?.arrivalTime], "HHmm");
            let arrivalFlightDateTime = arrivalDate + arrivalTime;
            let departureDate = dateFormatForDisplay(row[ExcelCols?.departureDate], "yyyyMMdd");
            let departureTime = dateFormatForDisplay(row[ExcelCols?.departureTime], "HHmm");
            let departureFlightDateTime = departureDate + departureTime;
            obj = {
              "contactname": row[ExcelCols?.primaryContact],
              "contactphone": "",
              "contactemail": row[ExcelCols?.contactEmail],
              "productid": productid,
              "arrivaldate": arrivalFlightDateTime,
              "arrivalflightid": row[ExcelCols?.arrivalFlightId],
              "arrivalairport": arrivalAirportName.key,
              "departuredate": departureFlightDateTime,
              "departureflightid": row[ExcelCols?.departureFlightId],
              "departureairport": departureAirportName.key,
              "adulttickets": adultCount,
              "childtickets": childCount,
              "infanttickets": infantCount,
              "cardholder": row[ExcelCols?.cardHolder],
              "authorizationnumber": row[ExcelCols?.authorization],
              "cardnumber": row[ExcelCols?.card],
              "chargedamount": row[ExcelCols?.chargedAmount],
              "passengers": passengerArr,
              "paymentdate": paymentDate
            }

          } else if (product === PRODUCTNAME_ARRIVAL) {
            let productid = PRODUCTID_ARRIVAL
            let arrivalDate = dateFormatForDisplay(row[ExcelCols?.arrivalDate], "yyyyMMdd");
            let arrivalTime = dateFormatForDisplay(row[ExcelCols?.arrivalTime], "HHmm");
            let arrivalFlightDateTime = arrivalDate + arrivalTime;
            obj = {
              "contactname": row[ExcelCols?.primaryContact],
              "contactphone": "",
              "contactemail": row[ExcelCols?.contactEmail],
              "productid": productid,
              "arrivaldate": arrivalFlightDateTime,
              "arrivalflightid": row[ExcelCols?.arrivalFlightId],
              "arrivalairport": arrivalAirportName.key,
              "adulttickets": adultCount,
              "childtickets": childCount,
              "infanttickets": infantCount,
              "cardholder": row[ExcelCols?.cardHolder],
              "authorizationnumber": row[ExcelCols?.authorization],
              "cardnumber": row[ExcelCols?.card],
              "chargedamount": row[ExcelCols?.chargedAmount],
              "passengers": passengerArr,
              "paymentdate": paymentDate
            }
          } else if (product === PRODUCTNAME_DEPARTURE) {
            let productid = PRODUCTID_DEPARTURE
            let departureDate = dateFormatForDisplay(row[ExcelCols?.departureDate], "yyyyMMdd");
            let departureTime = dateFormatForDisplay(row[ExcelCols?.departureTime], "HHmm");
            let departureFlightDateTime = departureDate + departureTime;
            obj = {
              "contactname": row[ExcelCols?.primaryContact],
              "contactphone": "",
              "contactemail": row[ExcelCols?.contactEmail],
              "productid": productid,
              "departuredate": departureFlightDateTime,
              "departureflightid": row[ExcelCols?.departureFlightId],
              "departureairport": departureAirportName.key,
              "adulttickets": adultCount,
              "childtickets": childCount,
              "infanttickets": infantCount,
              "cardholder": row[ExcelCols?.cardHolder],
              "authorizationnumber": row[ExcelCols?.authorization],
              "cardnumber": row[ExcelCols?.card],
              "chargedamount": row[ExcelCols?.chargedAmount],
              "passengers": passengerArr,
              "paymentdate": paymentDate
            }
          }

          tmpArr.push({ ...obj });
        }
      };  

      if (formik.values.adultCount.value !== totalAdultCount) {
        arrErrors.push(t("groupbooking:errors.totalAdultCountMismatch"));
      }
      if (formik.values.childCount.value !== totalChildCount) {
        arrErrors.push(t("groupbooking:errors.totalChildCountMismatch"));
      }
      if (formik.values.infantCount.value !== totalInfantCount) {
        arrErrors.push(t("groupbooking:errors.totalInfantCountMismatch"));
      }
      setBooking(tmpArr)
      setValidationResults(arrErrors);
      return arrErrors;
    } catch (error) {
      showError(t("groupbooking:errors.fileModified.title"),t("groupbooking:errors.fileModified.message"))
      console.error("Error reading the Excel file:", error); // show Error msg box
    }
  };


  useEffect(() => {
    if (loginDetails?.sessionid) { 
      getFlightsArr();
      setAdultSeats();
      setChildSeats();
      setInfantSeats();
    }
  }, [loginDetails])

  

  const handleSubmit = async (formik) => {
    try {
      setLoading(true);
      let processExcelFileResponse = await processExcelFile(formik.file);
      // event.preventDefault();
      if (processExcelFileResponse===undefined || processExcelFileResponse.length > 0) {
          // Do nothing 
      }else{

        
        const data = {
          "manifest": {
            "groupname": formik.groupName,
            "coordinatorname": formik.firstName + "" + formik.lastName,
            "coordinatorphone": formik.contactNumber,
            "coordinatoremail": formik.email,
            "bookings": booking
          }
        };
        const coordinatorname = formik.firstName + " " + formik.lastName
        const response = await addGroupBooking(data);
        if (response?.status === 0) {
          navigate("/group-booking-success", { state: { response, coordinatorname } });
        } else {
          showError(t("error"), response?.statusMessage);
        }
      }
      
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  }

  const validationSchema =  object().shape({
    groupName:string().required(t("groupbooking:errors.groupName.required")),
    firstName:string().required(t("groupbooking:errors.firstName.required")),
    lastName:string().required(t("groupbooking:errors.lastName.required")),
    email:string().required(t("groupbooking:errors.email.required")).email(t("groupbooking:errors.invalidEmail")),
    contactNumber:string().required(t("groupbooking:errors.contactNumber.required")).matches(/^[0-9]+$/, t("groupbooking:errors.contactNumber.match")),
    file: mixed().required(t("groupbooking:errors.manifest.required")),
    adultCount: Yup.object().test(
        'infatn-count-validate',
        t("groupbooking:errors.minimumPaxCount.count", { paxCount: GROUP_BOOKING_MINIMUM_PAXCOUNT }),
        adultCount => {
          let count = (parseInt(adultCount.value) + parseInt(formik.values.childCount.value));
          if (count < GROUP_BOOKING_MINIMUM_PAXCOUNT) {
            return false
          }
          return true
        }
      ),
    childCount: Yup.object().test(
        'child-count-validate',
        t(""),
        childCount => {
          let count = (parseInt(formik.values.adultCount.value) + parseInt(childCount.value));
          if (count < GROUP_BOOKING_MINIMUM_PAXCOUNT) {
            return false
          }
          return true
        }
      ),
  })

  const formik = useFormik({
    initialValues:{
      title:salutation[0],
      groupName:"",
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infantCount,
      firstName:"",
      lastName:"",
      email:"",
      contactNumber:"",
      file:null
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  })

  useEffect(()=>{ 
  },[validationResults])

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} />
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">
              {t('groupbooking:groupbookingHeading')}
            </span>
          </h3>
          <Card className="my-3">
            <Card.Body>
              <h6 className="field">{t("fillAllFields")}</h6>
              {isObjectEmpty(formik.errors) &&
              (validationResults.length > 0 && (
                <div className="bg-danger bg-opacity-10 rounded-1 mb-3 p-2">
                  <h5 className="text-danger">{t("tryAgain")}</h5>
                  <ul>
                    {validationResults.map((error, index) => (
                      <li className="text-danger ps-2 m-0 p-0" key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              ))}
              <Form onSubmit={(e)=>scrollToDiv(e,formik)}>
                <ErrorMessages formik={formik} />
                <Row>
                  <h5 className="my-3 d-flex">
                    <FontAwesomeIcon
                      icon={faUsers}
                      size="sm"
                      className="me-2 icon-color"
                    />
                    {t("groupbooking:groupName")}
                    <span className="field">*</span>
                  </h5>
                  <Col md={5} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        name="groupName"
                        type="text"
                        id="GroupName"
                        placeholder={t("groupbooking:groupName")}
                        aria-describedby="inputGroupPrepend"
                        value={formik.values.groupName?.replace(REGEX_ALPHANUMERIC_WITH_SPACE, "")}
                        onChange={formik.handleChange}
                        maxLength={GROUP_BOOKING_MAXLENGTH}
                        onBlur={formik.handleBlur}
                        className={formik.touched.groupName && formik.errors.groupName ? "error" : ""}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5 className="my-3 d-flex">
                    <FontAwesomeIcon
                      icon={faPeopleRoof}
                      size="sm"
                      className="me-2 icon-color"
                    />
                    {t("numberOfGuests")}
                    <span className="field">*</span>
                  </h5>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <FontAwesomeIcon
                          icon={faUser}
                          className="icon-color"
                          size="lg"
                        />{" "}
                        {t("adult")} {getAdultDisplayAge()}
                      </Form.Label>
                      <Select
                        name="adultCount"
                        options={adultCount}
                        defaultValue={formik.values.adultCount}
                        value={formik.values.adultCount}
                        onChange={async (d) => {
                          await formik.setFieldValue('adultCount', d)
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        className={formik.touched.adultCount && formik.errors.adultCount ? " error rounded-2" : ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={"auto"} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faChild}
                          size="lg"
                          className="mx-1 mt-1 icon-color"
                        />{" "}
                        <OverlayTooltip title={t("child") + getChildDisplayAge()} msg={t("childAgeMessage")}></OverlayTooltip>
                      </Form.Label>
                      <Select
                        name="childCount"
                        options={childCount}
                        defaultValue={formik.values.childCount}
                        value={formik.values.childCount}
                        onChange={async (d) => {
                          await formik.setFieldValue('childCount', d)
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        className={formik.touched.adultCount && formik.errors.adultCount ? " error rounded-2" : ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={"auto "} sm={12}>
                    <Form.Group>
                      <Form.Label className="d-flex">
                        <FontAwesomeIcon
                          icon={faPersonBreastfeeding}
                          size="lg"
                          className="mx-1 mt-1 icon-color"
                        />
                        <OverlayTooltip
                          title={t("infant") + getInfantDisplayAge()}
                          msg={t("infantAgeMessage")}
                        ></OverlayTooltip>
                      </Form.Label>
                      <Select
                        name="infantCount"
                        options={infantCount}
                        defaultValue={formik.values.infantCount}
                        value={formik.values.infantCount}
                        onChange={async (d) => {
                          await formik.setFieldValue('infantCount', d)
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        // className={formik.touched.adultCount && formik.errors.adultCount ? " error rounded-2" : ""}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5 className="my-3 d-flex">
                    <FontAwesomeIcon
                      icon={faAddressCard}
                      size="sm"
                      className="me-2 icon-color"
                    />
                    {t("groupbooking:coordinatorInfo")}
                    <span className="field">*</span>
                  </h5>

                  <Col
                    md="auto"
                    controlid="validationCustom01"
                    className="position-relative mb-4"
                  >
                    <Select
                      options={translateArrayKeyValue(SALUTATION, "SALUTATION")}
                      className="my-0 select_width"
                      value={formik.values.title}
                      name="title"
                      onChange={(e) =>
                        formik.setFieldValue("title",e)
                      }
                    />
                  </Col>
                  <Col md="2" controlid="validationCustom01" className="mb-4">
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder={t('firstName')}
                      maxLength={FIRST_NAME_MAXLENGTH}
                      minLength={1}
                      value={formik.values.firstName.replace(REGEX_ALPHABET, "")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.touched.firstName && formik.errors.firstName ? "error position-relative" : "position-relative"}
                    />
                  </Col>
                  <Col md="2" controlid="validationCustom02" className="mb-4">
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder={t('lastName')}
                      maxLength={LAST_NAME_MAXLENGTH}
                      minLength={1}
                      value={formik.values.lastName.replace(REGEX_ALPHABET, "")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.touched.lastName && formik.errors.lastName ? "error position-relative" : "position-relative"}
                    />
                  </Col>
                  <Col
                    md="3"
                    controlid="validationCustomUsername"
                    className="position-relative mb-4"
                  >
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder={t('emailAddress')}
                        aria-describedby="inputGroupPrepend"
                        maxLength={EMAIL_MAXLENGTH}
                        minLength={1}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={formik.touched.email && formik.errors.email ? "error position-relative" : "position-relative"}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="2" controlid="validationCustom02">
                    <Form.Control
                      type="text"
                      name="contactNumber"
                      placeholder={t('contactNumber')}
                      maxLength={CONTACT_NUMBER_MAXLENGTH}
                      value={formik.values.contactNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.touched.contactNumber && formik.errors.contactNumber ? "error mb-4" : "mb-4"}
                    />
                  </Col>
                </Row>
                <Row>
                  <h5 className="my-3 d-flex">
                    <FontAwesomeIcon
                      icon={faFileImport}
                      size="sm"
                      className="me-2 icon-color"
                    />
                    {t("groupbooking:manifestfile")}
                    <span className="field">*</span>
                  </h5>
                  <Col md="5" controlid="formFile">
                    <Form.Control
                      type="file"
                      id="input"
                      name="file"
                      // value={formik.values.file}
                      onClick={(e)=> e.target.value = null}
                      onChange={(e)=> {formik.setFieldValue("file",e.target.files[0]);setValidationResults([])}}
                      onBlur={formik.handleBlur}
                      className={formik.touched.file && formik.errors.file ? "error mb-4" : "mb-4"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-start gap-5 align-items-center">
                    <CommonButton
                      type="button"
                      classname="gradient-btn "
                      size="md"
                      title={t('submit')}
                      onclick={formik.handleSubmit}
                    />
                    <a href="../../static/tmp-group-booking-template.xlsx">{t("downloadHere")}</a>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default GroupBookingForm;
