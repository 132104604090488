import { createSlice } from "@reduxjs/toolkit";

const affiliatesSlice = createSlice({
  name: "affiliates",
  initialState: {
    affiliates: null,
    error: false,
    affiliateDetails:{}
  },
  reducers: {
    affiliatesFetchSuccess: (state, action) => {
      // state.affiliates = action.payload;
    },
    affiliatesFetchFailure: (state) => {
      state.error = true;
    },
    saveAffiliateDetails: (state, action) => {
      state.affiliateDetails = action.payload;
    }
  },
});

export const { affiliatesFetchSuccess, affiliatesFetchFailure, saveAffiliateDetails } = affiliatesSlice.actions;
export const affiliates = (state) => state.affiliatesRedux?.affiliates;
export const affiliateDetails = (state) => state.affiliatesRedux?.affiliateDetails;
export default affiliatesSlice.reducer;