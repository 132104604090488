import { Container } from "react-bootstrap";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  PRODUCTID_ARRIVAL,
  PRODUCTID_ARRIVALBUNDLE,
  PRODUCTID_DEPARTURE,
  ROUTENAME_ARRIVAL,
  ROUTENAME_ARRIVALBUNDLE,
  ROUTENAME_DEPARTURE,
} from "../config/commonConstants";
import Loader from "../components/commonComponents/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  availableMarkets as AM,
  selectedMarket as currentMarket,
  selectedMarketId as SM,
  setSelectedMarket,
} from "../redux/marketRedux";
// import { selectedMarketId as SM } from "../redux/marketRedux";
import {
  HandleAPIError,
  checkClubExistorNot,
  checkMarketIDExistorNot,
  dateFormatForDisplay,
  externalLogin,
  getAirpotId,
  getDistributorSubDistributorProfile,
  getMarketIdFromAirpotId,
  getMarketLoungenamesWithAirport,
  getTmpSelectedMarkets,
  isObjectEmpty,
  marketChangeBroadcast,
} from "../components/commonComponents/commonFunctions";
import { clearVIPERCart, setMarketId } from "../redux/apiCalls";
import { clearCart } from "../redux/cartRedux";
import { giftCartData as GCD, giftCartclear } from "../redux/giftCartRedux";
import { store } from "../store/store";
import { showConfirm, showError, showInfo } from "../components/commonComponents/Swal";
import { useTranslation } from "react-i18next";
import { paxCountClear } from "../redux/increasePaxCountRedux";
import { clearSubDistributorProfile } from "../redux/loggedInUserDetailRedux";
import LoadingSkeleton from "../components/commonComponents/LoadingSkeleton";
import { logindetails as LD } from "../redux/signInRedux";

function VipBookingReffer() {
  const navigate = useNavigate();
  const selectedMarketId = useSelector(SM)
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(Date());
  const location = useLocation();
  const { t } = useTranslation("common", "login");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const selectedMarket = useSelector(currentMarket);
  const availableMarkets = useSelector(AM);
  const state = store.getState();
  const cartData = state?.cartRedux?.cartData;
  var doLogin = true;
  // let club = query.get("club");
  const distributorProfile = getDistributorSubDistributorProfile();
  let maxCount = 0;
  let productid = query.get("productid") && query.get("productid").toUpperCase();
  if (productid === PRODUCTID_DEPARTURE) {
    maxCount = distributorProfile.departuremaxseats;
  } else {
    maxCount = distributorProfile.arrivalmaxseats;
  }

  let arrivaldate = query.get("arrivaldate");
  let tmparrivaldate = (new Date(dateFormatForDisplay(currentDate, "MM/dd/yyyy")) < new Date(dateFormatForDisplay(arrivaldate, "MM/dd/yyyy"))) ? true : false
  arrivaldate = tmparrivaldate === true ? arrivaldate : dateFormatForDisplay(currentDate, "MM/dd/yyyy");
  let departuredate = query.get("departuredate");
  let tmpdeparturedate = (new Date(dateFormatForDisplay(currentDate, "MM/dd/yyyy")) < new Date(dateFormatForDisplay(departuredate, "MM/dd/yyyy"))) ? true : false
  departuredate = tmpdeparturedate === true ? departuredate : dateFormatForDisplay(currentDate, "MM/dd/yyyy");
  let arrivalclub;
  let departureclub;

  if(productid === PRODUCTID_ARRIVALBUNDLE){
    arrivalclub = query.get("arrivalclub") && query.get("arrivalclub").toLowerCase();
    arrivalclub = arrivalclub === "" ? selectedMarket?.airports[0]?.lounge : checkClubExistorNot(arrivalclub)
    departureclub = query.get("departureclub") && query.get("departureclub").toLowerCase();
    departureclub = departureclub === "" ? selectedMarket?.airports[0]?.lounge : checkClubExistorNot(departureclub)
  } else if (productid === PRODUCTID_ARRIVAL) {
    arrivalclub = query.get("arrivalclub") && query.get("arrivalclub").toLowerCase();
    arrivalclub = arrivalclub === "" ? selectedMarket?.airports[0]?.lounge : checkClubExistorNot(arrivalclub)
  } else if (productid === PRODUCTID_DEPARTURE) {
    departureclub = query.get("departureclub") && query.get("departureclub").toLowerCase();
    departureclub = departureclub === "" ? selectedMarket?.airports[0]?.lounge : checkClubExistorNot(departureclub)
  }

  let adulttickets = query.get("adulttickets") ? query.get("adulttickets") && parseInt(query.get("adulttickets")) : 0;
  let childtickets = query.get("childtickets") ? query.get("childtickets") && parseInt(query.get("childtickets")) : 0;
  let infanttickets = query.get("infanttickets") ? query.get("infanttickets") && parseInt(query.get("infanttickets")) : 0;
  let showError = false;
  infanttickets =  infanttickets > maxCount ? maxCount : infanttickets 
  if (adulttickets || childtickets ) {
    let tmpPaxCount = adulttickets + childtickets 
    if (tmpPaxCount > maxCount) {
      showError = true
      adulttickets = 1;
      childtickets = 0;
    } else {
      adulttickets = adulttickets;
      childtickets = childtickets;
    }
  }

  let marketid = query.get("marketid") && query.get("marketid").toUpperCase();
  if(location?.pathname === "/market" && marketid === null){
    marketid = availableMarkets?.data?.defaultmarket;
  } else {
    marketid = marketid === "" ? availableMarkets?.data?.defaultmarket : checkMarketIDExistorNot(marketid);
  }
  const dispatch = useDispatch();
  const sendBroadcastMessage = useOutletContext()
  const giftCartData = useSelector(GCD)
  const loginDetail = useSelector(LD)
  const id = getMarketIdFromAirpotId(arrivalclub || departureclub);
  const tmpSelectedMarkets = getTmpSelectedMarkets(arrivalclub || departureclub);
  const loginDetails = useSelector(LD)

  const changeMarket = async (val, label, cartClear) => {
    let selectedMarketLanguage = availableMarkets?.data?.markets?.find((id) => id.marketid === val)?.language;
    let request = {
      "marketid": val,
      "languageid": selectedMarketLanguage
    }
    if (val === selectedMarketId) {
      // Do nothing
    } else {
      if (cartClear === true) {
        await clearVIPERCart();
        dispatch(clearCart());
        dispatch(giftCartclear());
      }
      dispatch(setSelectedMarket(val));
      try {
        setLoading(true);
        await setMarketId(request);
        await marketChangeBroadcast(sendBroadcastMessage, val, cartClear);
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }    
    }
  }
   
  const clearAllCart = async () => {
    dispatch(clearCart());
    dispatch(giftCartclear());
    dispatch(paxCountClear());
  };

  const navigateToHome = async () => { 
      var doNavigate = false
      let showConfirmresponse = await showInfo(t("warning"), t("productNotAllowed"));   
      doNavigate = showConfirmresponse?.isConfirmed;
      if(doNavigate) {
        await marketChangeBroadcast(sendBroadcastMessage, id, false)
          await changeMarket(id, "", false);
          setLoading(false);
          setTimeout(() => {
            navigate("/");
        }, 1000);
      } else {
        setLoading(false)
        navigate("/");
      }
  };

  const callProductService =  async () => {
    const marketLoungenameswithairport =  getMarketLoungenamesWithAirport(
      selectedMarket?.airports
    );
    let route;
    let request; 
    
    if (productid === PRODUCTID_ARRIVALBUNDLE) {
        request = [
          {
            lounge: {
              value: getAirpotId(arrivalclub),
              label: marketLoungenameswithairport?.find(
                (a) => a?.value === getAirpotId(arrivalclub)
              )?.label,
            },
            flightDate: arrivaldate,
            airlineName: "",
            airlineId: "",
            flightNumber: "",
            flightTime_hour: "",
          },
          {
            lounge: {
              value: getAirpotId(departureclub),
              label: marketLoungenameswithairport?.find(
                (a) => a?.value === getAirpotId(departureclub)
              )?.label,
            },
            flightDate: departuredate,
            airlineName: "",
            airlineId: "",
            flightNumber: "",
            flightTime_hour: "",
          },
        ];
    }

    if (productid === PRODUCTID_DEPARTURE) {
        request = {
          lounge: {
            value: getAirpotId(departureclub),
            label: marketLoungenameswithairport?.find(
              (a) => a?.value === getAirpotId(departureclub)
            )?.label,
          },
          flightDate: departuredate,
          airlineName: "",
          airlineId: "",
          flightNumber: "",
          flightTime_hour: "",
        };
    }
    if (productid === PRODUCTID_ARRIVAL) {
        request = {
          lounge: {
            value: getAirpotId(arrivalclub),
            label: marketLoungenameswithairport?.find(
              (a) => a?.value === getAirpotId(arrivalclub)
            )?.label,
          },
          flightDate: arrivaldate,
          airlineName: "",
          airlineId: "",
          flightNumber: "",
          flightTime_hour: "",
        };
    }
    
    route = productid === PRODUCTID_ARRIVALBUNDLE ? ROUTENAME_ARRIVALBUNDLE : productid === PRODUCTID_ARRIVAL ? ROUTENAME_ARRIVAL : ROUTENAME_DEPARTURE;
    
    if (tmpSelectedMarkets?.arrivalbundleallowed === "N" && productid === PRODUCTID_ARRIVALBUNDLE) {   
        navigateToHome();
    } else if (tmpSelectedMarkets?.departureloungeallowed === "N" && productid === PRODUCTID_DEPARTURE) {
        navigateToHome();
    } else if (tmpSelectedMarkets?.arrivalonlyallowed === "N" && productid === PRODUCTID_ARRIVAL) {
        navigateToHome();
    } else {   
      if(doLogin){ 
        clearAllCart();
        dispatch(clearSubDistributorProfile());
        setLoading(false)     
        changeMarket(id, "", false); 
        !isObjectEmpty(availableMarkets) &&
            navigate("/" + route, {
              state: {
                cartItemId: undefined,
                productid: productid,
                adultCount: adulttickets,
                childCount: childtickets,
                infantCount: infanttickets,
                bookingDetail: request,
                showMaxPaxError: showError,
                urlMaxCount: maxCount,
              },
        }); 
      } else {
        setLoading(false)
        navigate("/");
      }
    } 
  };

  const callRedirectToHomeService = async () => {
    if(marketid){
      await changeMarket(marketid, "", false);
      navigate("/");
      setLoading(false);  
    }
  };
  const [isFirstComplete,setIsFirstComplete] = useState(false)
  useEffect(()=>{
    externalLogin(location,sendBroadcastMessage,navigate,dispatch,setIsFirstComplete)
  },[])


  useEffect(() => {
    if(isFirstComplete && loginDetails?.sessionid){
      if (marketid !== null) {
        callRedirectToHomeService();
      } else {
        availableMarkets && callProductService();
      }
    }
  }, [selectedMarket, availableMarkets,loginDetails, isFirstComplete,selectedMarket, marketid]);

  return (
    <>
      <section>
      {loading ? (
        <>
          <Loader loading={loading} />
          <LoadingSkeleton />
        </>
        ) : (
      <Container className="my-4">
      </Container>
      )}
      </section>
    </>
  );
}
export default VipBookingReffer;
