import React, { useState } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import { useSelector } from "react-redux";
import { dateFormatForDisplay } from "./commonFunctions";
import { getMonth, getYear } from "date-fns";

function DatepickerComponent2(props) {
  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState();
  const years = props?.range;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const selectedMarket = useSelector(currentMarket);
  const locale = selectedMarket?.language;

  const DateChange = (value) => {
    const dt = value ? dateFormatForDisplay(value, "MM/dd/yyyy") : "";
    setDate(dt);
    props.onchange(dt);
  };

  return (
    <DatePicker
    showIcon
    popperPlacement="bottom-end"
    locale={locale} //"en-EN"
    onChange={ (d) => {
      // DateChange(d)
        setStartDate(d)
        props.onChangeProps ? (props.onchange(props.variableName, (d ? dateFormatForDisplay(d, "MM/dd/yyyy") : ""))) : DateChange(d)
      }
    }
    selected={startDate}
    value={props.value}
    className={props.className}
      format="MM/dd/yyyy"
      placeholderText="--/--/----"
    minDate={props.minDate}
    maxDate={props.maxDate}
    onKeyDown={(e) => {
        e.preventDefault();
    }}
    renderCustomHeader={({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    }) => (
      <div
        style={{
          margin: 10,
          display: "flex",
          justifyContent: "space-between",
          }}
      >
          <button onClick={(e) => {
            decreaseMonth()
            e.preventDefault();
          }}
            disabled={prevMonthButtonDisabled}
            className="btn border-0"
          >
          {"<"}
        </button>

        <select
          className="form-select form-select-sm me-1"
          style={{
            width: "120px"
          }}
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select
          className="form-select form-select-sm"
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

          <button onClick={(e) => {
            increaseMonth()
            e.preventDefault();
          }}
            disabled={nextMonthButtonDisabled}
            className="btn border-0"
          >
          {">"}
        </button>
      </div>
      )}
    />
  );
}

export default DatepickerComponent2;
