import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authed: false,
  logindetails: {},
  distributorProfile: {},
  giftPackages: {},
  userDefault: {},
  subDistributorProfile: {},
  sessionStartTime:null,
  promotion:false,
  error: null,
}

const signInSlice = createSlice({
  name: "signIn",
  initialState: initialState,
  reducers: {
    loginInServiceSuccess: (state, action) => {
      state.authed = true;
      state.error = null;
      state.logindetails = action.payload;
      state.distributorProfile = action.payload.distributorprofile;
      state.giftPackages = action.payload.giftpackages;
      state.userDefault = action.payload.userdefault;
      state.sessionStartTime= new Date();
      state.promotion = action.payload.promotion || false;
    },
    logInServicePending: (state, action) => {
      state.error = null;
    },
    logInServiceError: (state, action) => {
      state.error = action.payload;
      state.authed = false;
    },
    setIsAuthenicated: (state, action) => {
      state.authed = action.payload;
    },
    logout: state => {
      return initialState;
    },
    setSubDistributorProfile: (state, action) => {
      state.subDistributorProfile = action.payload;
    },
    // clearSessionId: state => {
    // },
    removeSession:state=>{
      state.sessionStartTime = null;
      if(Object.values(state.logindetails).length !== 0)
        state.logindetails = {...state.logindetails,sessionid:""};
    },
    resetSignInRedux:state=>{
      return initialState
    },
    updateSubscriberInfo :(state, action )=>{
      state.userDefault = {...state.userDefault , firstname : action.payload.firstname, lastname : action.payload.lastname, displayname : action.payload.displayname, email : action.payload.email, phone : action.payload.phone }
    }
  },
});

export const {
  loginInServiceSuccess,
  logInServicePending,
  logInServiceError,
  setIsAuthenicated,
  logout,
  setSubDistributorProfile,
  removeSession,
  resetSignInRedux,
  updateSubscriberInfo
} = signInSlice.actions;
export const distributorProfile = (state) => state.signInRedux?.distributorProfile;
export const userDefault = (state) => state.signInRedux?.userDefault;
export const authed = (state) => state.signInRedux?.authed;
export const error = (state) => state.signInRedux?.error;
export const giftPackages = (state) => state.signInRedux?.giftPackages;
export const subDistributorProfile = (state) => state.signInRedux?.subDistributorProfile;
export const logindetails = (state) => state.signInRedux?.logindetails;
export const sessionStartTime = (state) => state.signInRedux?.sessionStartTime;

export default signInSlice.reducer;
