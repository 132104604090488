import Jem from "../../images/jem.jpg";
import Je from "../../images/JE-pop-up-window.jpg";
import { Col, Row } from "react-bootstrap";

function Promotions(props) {
  const { jemPromotion, jePromotion } = props;
  return (
    <>
      <Row className="d-flex justify-content-center">
        {jemPromotion && (
          <Col md={12}>
            <>
              <img id="image-map-image-jem" src={Jem} className="img-fluid border border-2 border-secondary-subtle" useMap="#image-maps-jem" alt="Jamaica Experiences Marketplace" />
              <map name="image-maps-jem" id="image-maps-jem">
                <area alt="Jamaica Experiences Marketplace" title="Jamaica Experiences Marketplace" href="https://marketplace.jamaicaexperiences.com/" shape="rect" coords="0,0,866,407" style={{ outline: "none" }} target="_blank" />
                <area alt="" title="" href="https://play.google.com/store/apps/details?id=com.reliable.jem" shape="rect" coords="23,422,115,457" style={{ outline: "none" }} target="_blank" />
                <area alt="" title="" href="https://apps.apple.com/us/app/je-marketplace/id1624821578" shape="rect" coords="162,424,249,455" style={{ outline: "none" }} target="_blank" />
                <area alt="" title="" href="https://www.facebook.com/jamaicaexperiences" shape="rect" coords="318,430,348,458" style={{ outline: "none" }} target="_blank" />
                <area alt="" title="" href="https://www.instagram.com/jamaicaexperiences/" shape="rect" coords="349,430,378,458" style={{ outline: "none" }} target="_blank" />
              </map>
            </>
          </Col>
        )}
        {jePromotion && (
          <Col md={7} className="mt-3 d-flex justify-content-center">
            <a href="http://www.jamaicaexperiences.com/" className="border border-2 border-secondary-subtle" target="_blank" rel="noreferrer">
              <img src={Je} className="img-fluid" alt="Jamaica Experiences" />
            </a>
          </Col>
        )}
      </Row>
    </>
  );
}

export default Promotions;
