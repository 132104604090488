import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
// import { ARR_PRODUCTS } from "../config/commonConstants";
import CommonButton from "../components/commonComponents/CommonButton";
import InputGroup from "react-bootstrap/InputGroup";
import ExitButton from "../components/commonComponents/ExitButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { addUpdateLinkQueue } from "../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import Loader from "../components/commonComponents/Loader";
import { showError } from "../components/commonComponents/Swal";
import { selectedMarket as currentMarket, selectedMarketId as SMID } from "../redux/marketRedux";
import {
  HandleAPIError,
  divRef,
  getProductDropdownAccordingMarket,
  scrollToDiv,
} from "../components/commonComponents/commonFunctions";
import * as Yup from "yup";
import { useFormik } from "formik";
import ErrorMessages from "../components/commonComponents/ErrorMessages";
import { UPDATE_FLIGHT_CONFIRMATIONID_MAXLENGTH } from "../config/commonConstants";

function GetUpdateFlightDetailsLink() {
  const [Product, setProduct] = useState({ value: "", error: "" });
  const [ConfirmationNumber, setConfirmationNumber] = useState({
    value: "",
    error: "",
  });
  const selectedMarket = useSelector(currentMarket);
  const { t } = useTranslation(["common", "getupdateflightdetailslink"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const selectedMarketId = useSelector(SMID);
  const ARR_PRODUCTS = getProductDropdownAccordingMarket(selectedMarketId)

  const handleSubmit = async (formik) => {
    let isFormValid = true;
    if (isFormValid) {
      const data = {
        "productid": formik.product,
        "confirmationnumber": formik.confirmationNumber,
      };

      const successInfo = {
        messageHeading: t(
          "getupdateflightdetailslink:successInfoMessageHeading"
        ),
        successMessage: t(
          "getupdateflightdetailslink:successInfoSuccessMessage",
          {
            phone: selectedMarket?.contact?.phone,
            email: selectedMarket?.contact?.email,
            skype: selectedMarket?.contact?.skype,
          }
        ),
        jemPromotion: true,
        jePromotion: true,
      };
      try {
        setLoading(true);
        const response = await addUpdateLinkQueue(data);
        if (response?.status === 0)
          navigate("/common-success", { state: successInfo });
        else {
          showError(t("error"), response?.statusMessage);
        }
      } catch (e) {
        HandleAPIError(e);
      } finally {
        setLoading(false);
      }
    }
  };
  const validationSchema = Yup.object().shape({
    product: Yup.string().required(
      t("getupdateflightdetailslink:errors.product.required")
    ),
    confirmationNumber: Yup.string().required(
      t("getupdateflightdetailslink:errors.confirmationNumber.required")
    ),
  });

  const formik = useFormik({
    initialValues: {
      product: "",
      confirmationNumber: "",
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit(formik.values);
    },
  });

  useEffect(() => {}, []);

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader
            loading={loading}
            message={t("getupdateflightdetailslink:loaderMessage")}
          />

          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">
              {t("getupdateflightdetailslink:updateFlightDetails")}
            </span>
          </h3>
          <Row className="my-3 d-flex justify-content-around align-content-left">
            <Col md={6}></Col>
            <Col md={6} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Card className="my-3">
            <Card.Body>
              <Form onSubmit={(e)=>scrollToDiv(e,formik)}>
              <Row className="d-flex justify-content-start">
                  <Col md={3}>
                    <h6 className="field">{t('fillAllFields')}</h6>
                  </Col>
                </Row>
                <h5 className="d-flex justify-content-center">
                  {t("getupdateflightdetailslink:updateBookingHeading")}
                </h5>
                <ErrorMessages formik={formik} />
                <Row className="d-flex justify-content-center">
                  <Col md={3} sm={12}>
                    <Form.Group className="my-2">
                      <Form.Label className="d-flex">
                        {t("getupdateflightdetailslink:product")}
                        <span className="field">*</span>
                      </Form.Label>
                      <Select
                        name="product"
                        options={ARR_PRODUCTS}
                        value={formik.values.product.Label}
                        onChange={(d) => {
                          formik.setFieldValue("product", d.value);
                        }}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.product && formik.errors.product
                            ? "error rounded-2 my-0 mb-3"
                            : "my-0 mb-3"
                        }
                      />
                      {formik.touched.product}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col md={3} className="mb-3">
                    <Form.Label className="d-flex" htmlFor="ConfirmationNumber">
                      {t("getupdateflightdetailslink:confirmationNumber")}
                      <span className="field">*</span>
                    </Form.Label>
                    <InputGroup className="d-flex">
                      <Form.Control
                        name="confirmationNumber"
                        type="text"
                        id="ConfirmationNumber"
                        placeholder={t("getupdateflightdetailslink:confirmationNumber")}
                        aria-describedby="inputGroupPrepend"
                        value={formik.values.confirmationNumber}
                        maxLength={UPDATE_FLIGHT_CONFIRMATIONID_MAXLENGTH}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.touched.confirmationNumber &&
                          formik.errors.confirmationNumber
                            ? "error my-0 mb-3"
                            : "my-0 mb-3"
                        }
                      />
                      {formik.touched.confirmationNumber}
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-center">
                    <CommonButton
                      type="submit"
                      classname="gradient-btn "
                      size="md"
                      title={t("submit")}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default GetUpdateFlightDetailsLink;
