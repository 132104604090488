import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import mastercard from "../../images/mastercard.svg";
import visa from "../../images/visa.svg";
import CommonButton from "./CommonButton";
import { useState } from "react";
import {
  CARD_CVV_LENGTH, CARD_NUMBER_MAXLENGTH,
  EMAIL_MAXLENGTH,
  CARDHOLDERNAME_MAXLENGTH,
  REGEX_ALPHABET,
  REGEX_NUMERIC
} from "../../config/commonConstants";
import { getMonthDropdownList, getCCExpiryYearDropdownList } from "./commonFunctions";
import { t } from "i18next";
import { Link } from "react-router-dom";
import useToggle from "../../hooks/useToggle";
import OverlayTooltip from "./OverlayTooltip";
import Switch from "react-switch";

function CreditCard(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [isVisibleTermsAndConditionModal, setIsVisibleTermsAndConditionModal] = useToggle();
  return (
    <div className="mb-2 mx-2">
      <h4 className=" py-3 px-2  ">{t('cardholderDetails')}</h4>
      <Card className="bg-light  bg-opacity-10 rounded-3 px-0 mb-2">
        <Card.Body>
          <div className="d-sm-flex justify-content-sm-between align-items-center mb-3">
            <h5 className="mb-2 mb-sm-0">{t('paymentcard:weAccept')}:</h5>
            <ul className="list-inline mb-0">
              <li className="list-inline-item">{" "}<img src={visa} height="30px" alt="visa" /></li>
              <li className="list-inline-item">{" "}<img src={mastercard} height="30px" alt="mastercard" /></li>
            </ul>
          </div>
          <hr className="horizontalBar" />

          <Row className="g-3">
            <Col
              md="6"
            >
              <Form.Label className="d-flex" htmlFor="validationCustomCardUserName">
                {t('cardHolderName')}<span className="field">*</span>
              </Form.Label>
              <Form.Control
                name="name"
                id="validationCustomCardUserName"
                type="text"
                value={props?.formik?.values?.name}
                // onChange={(e) => props.onChange("name", e.target.value)}
                onBlur={props.formik.handleBlur}
                // onChange={props.formik.handleChange}
                // onChange={(e) => props.onChange("name", e.target.value)}
                onChange={(e) => {
                  e.target.value = e.target.value?.replace(REGEX_ALPHABET,"");
                  props.onChange("name", e.target.value)
                }}
                placeholder={t('cardHolderName')}
                maxLength={CARDHOLDERNAME_MAXLENGTH}
                minLength={1}
                className={props.formik.touched.name && props.formik.errors.name ? "error" : ""}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

            </Col >

            <Col md="6">
              <Form.Label className="d-flex" htmlFor="validationCustomcardemail">
                {" "}
                {t('cardholderEmail')}<span className="field">*</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t('emailAddress')}
                  value={props.formik.values.email}
                  //  onChange={(e) => props.onChange("email", e.target.value)}
                  className={props.formik.touched.email && props.formik.errors.email ? "error" : ""}
                  aria-describedby="inputGroupPrepend"
                  onBlur={props.formik.handleBlur}
                  // onChange={props.formik.handleChange}
                  onChange={(e) => props.onChange("email", e.target.value)}
                  maxLength={EMAIL_MAXLENGTH}
                  minLength={1}

                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

              </InputGroup>
            </Col >

            <Col md="6">
              <Form.Label className="d-flex" htmlFor="validationCustomCardType">
                {t('creditCardType')} <span className="field">*</span>
              </Form.Label>
              <Form.Select aria-label="Default select example" id="validationCustomCardType" value={props.formik.values.cardType}
                onChange={(e) => props.onChange("cardType", e.target.value)} >
                <option value="MASTERCARD">{t('mastercard')}</option>
                <option value="VISA">{t('visa')}</option>
              </Form.Select>
            </Col >
            {/* {props.cardProfileValue && props.cardProfileValue.length > 1 && <Col md="6">
              <Form.Label className="d-flex" htmlFor="validationCustomCardType">
                {t('cardProfile')} <span className="field">*</span>
              </Form.Label>
              <Select
                options={props.cardProfileValue}
                className="my-0"
                autosize={true}
                value={props.cardProfile}
                onChange={(d) => props.onChange("cardProfile", d)}
                getOptionValue={(v) => v.value}
                getOptionLabel={(v) => v.label}
              />
            </Col >} */}
            <Col md="6">
              <Form.Label className="d-flex" htmlFor="validationCustomCardNumber">
                {t('creditCardNumber')} <span className="field">*</span>
              </Form.Label>
              <Form.Control
                name="cardNumber"
                id="validationCustomCardNumber"
                type="text"
                placeholder={t('creditCardNumber')}
                minLength={CARD_NUMBER_MAXLENGTH}
                maxLength={CARD_NUMBER_MAXLENGTH}
                value={props.formik.values.cardNumber}
                onBlur={props.formik.handleBlur}
                // onChange={props.formik.handleChange}
                onChange={(e) => {
                  e.target.value = e.target.value?.replace(REGEX_NUMERIC, "");
                  props.onChange("cardNumber", e.target.value)
                }
                }
                className={props.formik.touched.cardNumber && props.formik.errors.cardNumber ? "error" : ""}


              // value={cardNumber}
              //  onChange={cardnumberChange}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>

            </Col >
            <Col md="3">
              <Form.Label className="d-flex" htmlFor="validationCustomCardMonthAndYear">
                {t('expiryDate')} <span className="field">*</span>
              </Form.Label>
              <InputGroup className="my-group-input">
                <Form.Select aria-label="Default select example" id="validationCustomCardMonthAndYear"
                  name="cardMonth"
                  value={props.formik.values.cardMonth}
                  onChange={(e) => props.onChange("cardMonth", e.target.value)}
               //   onBlur={(e) => props.onBlur("cardMonth", e)}
                  className={props.formik.touched.cardMonth && props.formik.errors.cardMonth ? "error" : ""}
                >
                  {getMonthDropdownList()}
                </Form.Select>
                <Form.Select aria-label="Default select example" 
                  id="validationCustomCardYear"
                  name="cardYear"
                  value={props.formik.values.cardYear}
                  onChange={(e) => props.onChange("cardYear", e.target.value)}
                //  onBlur={(e) => props.onBlur("cardYear", e)}
                  className={props.formik.touched.cardYear && props.formik.errors.cardMonth ? "error" : ""}

                >
                  {getCCExpiryYearDropdownList()}
                </Form.Select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {t('enterCardExpiryMonthYear')}
                </Form.Control.Feedback>
              </InputGroup>
            </Col >

            <Col md="2" className="offset-md-3" >
              <Form.Label className="d-flex" htmlFor="validationCustomCardCvv">
                <OverlayTooltip
                  title={t('cvv')}
                  for="validationCustomCardCvv"
                  msg={t('cvvCardSecrity')}
                  mandatory={true}
                />
              </Form.Label>
              <Form.Control
                id="validationCustomCardCvv"
                type="password"
                autoComplete="off"
                name="cvv"
                maxLength={CARD_CVV_LENGTH}
                minLength={CARD_CVV_LENGTH}
                value={props.formik.values.cvv}
                placeholder="XXX"
                onBlur={props.formik.handleBlur}
                // onChange={props.formik.handleChange}
                onChange={(e) => {
                  e.target.value = e.target.value?.replace(REGEX_NUMERIC, "");
                  props.onChange("cvv", e.target.value)
                }
                }
                className={props.formik.touched.cvv && props.formik.errors.cvv ? "error" : ""}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Col >

            {props.isTermsAndConditionRequired &&
              <props.modalTermsAndConditions
                visible={isVisibleTermsAndConditionModal}
                cancelModal={() => setIsVisibleTermsAndConditionModal(false)}
                onclick={setIsVisibleTermsAndConditionModal}
              />
            }

            {props.isTermsAndConditionRequired && <Col md="12" >
              {/* <Form.Check inline id="switch1" className="pl-5">
                <Form.Check.Input
                  checked={isChecked}
                  onChange={() => setIsChecked((prev) => !prev)}
                />
                <Form.Check.Label>
                  {t('iAgree')}
                  <Link to="#" className=" text-decoration-none" onClick={setIsVisibleTermsAndConditionModal}> {t('termsConditions')}</Link>
                </Form.Check.Label>
              </Form.Check> */}
              <Form.Label className="d-flex">
                <Switch
                  checked={isChecked}
                  onChange={() => setIsChecked((prev) => !prev)}
                  className="react-switch me-2"
                  height={25}
                />
                {/* {t('iAgree')}&nbsp;<Link to="#" className=" text-decoration-none" onClick={setIsVisibleTermsAndConditionModal}> {t('termsConditions')}</Link> */}
                <Link to="#" className=" text-decoration-none" onClick={setIsVisibleTermsAndConditionModal}> {t('iAgree')}</Link>
              </Form.Label>
            </Col>}

            <Col md="12 text-center">
              <CommonButton
                classname=" gradient-btn"
                title={t('submit')}
                size="md"
                disabled={props.isSubmitDisabled ? props.isSubmitDisabled : (props.isTermsAndConditionRequired && !isChecked)}
                onclick={props.onClick}
              />
            </Col >
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CreditCard;
