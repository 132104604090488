import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SwalTitleComponent = (props) => {
  return (
    <>
      <div className='row'>
        <div className='col-md-1'>
          <FontAwesomeIcon
            icon={props?.icon}
            size="lg"
            className={`me-2 text-${props?.color}`}
          />
        </div>
        <div className='col-md-10 d-flex justify-content-start mx-2 align-items-center'>
            <h3>{props?.title}</h3>
        </div>
      </div>
    </>
  )
}

export default SwalTitleComponent