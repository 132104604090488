import { Card, Col, Form, Row, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import CommonButton from "../components/commonComponents/CommonButton";
import { useTranslation } from "react-i18next";
import Loader from "../components/commonComponents/Loader";
import { resetMemberCardPin, processMemberCard } from "../redux/apiCalls";
import { showError, showSuccess } from "../components/commonComponents/Swal";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import { HandleAPIError, divRef, getMemberCardsArray, renderErrorMessages, scrollToDiv, translateConstantText } from "../components/commonComponents/commonFunctions";
import { MEMBERCARD_CARD_NUMBER_LABEL, MEMBERCARD_CARD_TYPE_LABEL, MEMBERCARD_NUMBER_MAXLENGTH, NAME_LENGTH, REGEX_ALPHABET, REGEX_NUMERIC } from "../config/commonConstants";
import ExitButton from "../components/commonComponents/ExitButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { memberCards as MC } from "../redux/memberCardRedux";
import { useSelector } from "react-redux";
import ErrorMessages from "../components/commonComponents/ErrorMessages";

function ResetMemberPin() {
  const [MemberCardTypeOptions, setMemberCardTypeOptions] = useState([]);
  const memberCards = useSelector(MC);
  const { t } = useTranslation(["common", "memberandcardinformation"]);
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    cardType: Yup.object().required(t("memberandcardinformation:errors.cardType.required", { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })),
    cardNumber: Yup.string()
      .required(t("memberandcardinformation:errors.cardNumber.required", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
      .matches(/^[0-9]+$/, t("memberandcardinformation:errors.cardNumber.match", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") }))
      .length(16, t("memberandcardinformation:errors.cardNumber.length", { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })),
    memberName: Yup.string().required(t("memberandcardinformation:errors.memberName.required"))
  });

  const initial = {
    cardType: "",
    cardNumber: "",
    memberName: ""
  }
  const formik = useFormik({
    initialValues: initial,
    validationSchema,
    onSubmit: () => { handleSubmit(formik.values) },
  });

  const handleSubmit = async (formiks) => {

    //event.preventDefault();
    const data = {
      "memberid": formiks.cardType.value + formiks.cardNumber, /// hardcode
    };
    const verifyNameMembeCard = {
      "action": "VERIFYNAME",
      "memberid": data.memberid,
      "name": formiks.memberName
    }
    try {
      setLoading(true);
      const response = await processMemberCard(verifyNameMembeCard);
      if (response?.status === 0) {
        try {
          const response = await resetMemberCardPin(data);
          if (response?.status === 0) {
            formik.resetForm();
            showSuccess(response.statusMessage, t('memberandcardinformation:pinResetMessage'))
          }
          else {
            formik.setValues({ ...initial })
            showError(t("error"), response?.statusMessage);
          }
        } catch (e) {
          HandleAPIError(e);
        } finally {
          setLoading(false);
        }
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }

  };

  const setMemberCardArray = async () => {
    const memberCardsArray = getMemberCardsArray(memberCards);
    setMemberCardTypeOptions(memberCardsArray);
  }

  useEffect(() => {
    setMemberCardArray();
  }, [memberCards]);

  return (
    <>
      <section>
        <Container className="my-4" ref={divRef}>
          <Loader loading={loading} message={t("loaderMessage")} />
          <h3 className="my-3 d-flex justify-content-center heading">
            <span className="heading">{t("memberandcardinformation:resetMemberPinHeader")}</span>
          </h3>

          <Row className="my-3 d-flex justify-content-end align-content-right">
            <Col md={12} className="d-flex justify-content-end">
              <ExitButton />
            </Col>
          </Row>
          <Form onSubmit={(e) => scrollToDiv(e, formik)}>
            <Col md={12}>
              <Card className="bg-light bg-opacity-10 rounded-3 ">
                <Card.Body>
                  <Row className="d-flex justify-content-start">
                    <Col md={3}>
                      <h6 className="field">{t('fillAllFields')}</h6>
                    </Col>
                  </Row>
                  <ErrorMessages formik={formik} />
                  <Row className="d-flex justify-content-center">
                    <Col
                      md={3}
                      className="mb-3"
                    >
                      <Form.Group>
                        <Form.Label className="d-flex">
                          {t('memberandcardinformation:cardType', { membershipType: translateConstantText("MEMBERCARD_CARD_TYPE_LABEL") })}
                          <span className="field">*</span>
                        </Form.Label>
                        <Select
                          options={MemberCardTypeOptions}
                          name="cardType"
                          id="cardType"
                          value={formik.values.cardType}
                          onChange={(d) => formik.setFieldValue('cardType', d)}
                          onBlur={(d) => formik.handleBlur}
                          className={formik.touched.cardType && formik.errors.cardType ? " error rounded-2" : ""}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center">
                    <Col
                      md={3}
                      className="mb-3"
                    >
                      <Form.Label className="d-flex" htmlFor="cardNumber">
                        {t('memberandcardinformation:cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}<span className="field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="cardNumber"
                        name="cardNumber"
                        placeholder={t('memberandcardinformation:cardNumber', { membershipNumber: translateConstantText("MEMBERCARD_CARD_NUMBER_LABEL") })}
                        value={formik.values.cardNumber.replace(REGEX_NUMERIC, "")}
                        maxLength={MEMBERCARD_NUMBER_MAXLENGTH}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={formik.touched.cardNumber && formik.errors.cardNumber ? "error" : ""}
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center">
                    <Col
                      md={3}
                      className="mb-3"
                    >
                      <Form.Label className="d-flex" htmlFor="MemberName">
                        {t('memberandcardinformation:memberName')}<span className="field">*</span>
                      </Form.Label>
                      <InputGroup className="d-flex">
                        <Form.Control
                          type="text"
                          id="memberName"
                          name="memberName"
                          placeholder={t('memberandcardinformation:memberNamePlaceholder')}
                          value={formik.values.memberName}
                          aria-describedby="inputGroupPrepend"
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            e.target.value = e.target.value?.replace(REGEX_ALPHABET, "");
                            formik.handleChange(e)
                          }}
                          maxLength={NAME_LENGTH}
                          onBlur={formik.handleBlur}
                          className={formik.touched.memberName && formik.errors.memberName ? "error" : ""}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={12} className="d-flex justify-content-center">
                      <CommonButton
                        type="submit"
                        classname="gradient-btn "
                        size="md"
                        title={t('memberandcardinformation:resetPin')}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Form>

        </Container>
      </section>
    </>
  );
}
export default ResetMemberPin;
