import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import CommonButton from "../components/commonComponents/CommonButton";
import HtmlToReactConverter from "../components/commonComponents/HtmlToReactConverter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

function PartnerRegistrationTermsConditionsModal(props) {
  const { t } = useTranslation(["common", "partnerregistration"]);

  const printableContentRef = useRef(null);
  const handlePrint = () => {
    const contentToPrint = printableContentRef.current;

    if (contentToPrint) {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);

      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(contentToPrint.innerHTML);
      iframeDoc.close();

      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      document.body.removeChild(iframe);
    }
  };

  

  return (
    <div>
      <Modal
        show={props.visible}
        onHide={props.onclick}
        size="xl"
        // dialogClassName="modal-90w"
        // fullscreen={true}
        style={{ zIndex: "9998" }}
      >
        <Modal.Header closeButton><h3>{t("partnerregistration:termsConditionsTitle")}</h3></Modal.Header>
        <Modal.Body>
          <Row >
            <Col md={12} className="p-2 px-5">
            <div ref={printableContentRef}>
              <HtmlToReactConverter value={t('partnerregistration:termsConditions')} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CommonButton
            classname="gradient-btn "
            title={t('close')}
            onclick={props.onclick}
          />
          <CommonButton
            classname="btn-md gradient-btn me-2"
            title={t('print')}
            onclick={handlePrint}
            icon={
              <FontAwesomeIcon
                icon={faPrint}
                className="mx-1"
                size="sm"
                position="right"
              />
            }
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PartnerRegistrationTermsConditionsModal;
