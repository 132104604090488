import React from 'react'

const SwalHtmlComponent = (props) => {
    return (
        <>
            <div className='text-start ms-5'>
                <h5>{props?.message}</h5>
            </div>
        </>
    )
}

export default SwalHtmlComponent