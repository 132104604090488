import React, { useEffect, useState } from 'react';
import Card from "react-bootstrap/Card";
import { PRODUCTID_DIGICELBLACKCARD, PRODUCTID_PRIORITYPASS } from '../../config/commonConstants';
import ProductCard from './ProductCard';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/esm/Container";
import { useSelector } from "react-redux";
import { selectedMarket as currentMarket } from "../../redux/marketRedux";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PriorityPassCardImg from "../../images/Priority-Pass-3.jpg";
import DigicelBlackCardImg from "../../images/Digicel-black-card.jpg";
import DragonCardImg from "../../images/Dragon-Card.jpg";

function RenderProductCarousel(props) {
  const { t } = useTranslation(["home", "common"]);
  const [tmpData, setTmpData] = useState([])
  const [description, setDescription] = useState([])
  const selectedMarket = useSelector(currentMarket);

  useEffect(() => {
    props?.priceList
      ?.filter((item) => item?.productid === PRODUCTID_PRIORITYPASS)
      .map((value, index, elements) => {
        let id;
        let data = []
        setDescription(value?.description);

        if (!elements[index + 1] && selectedMarket?.prioritypassallowed === "Y") {
          id = PRODUCTID_PRIORITYPASS;
          data.push({
            productid: id,
            heading_1: t("home:priorityPass"),
            heading_2: t("home:selectYourService"),
            title: t("home:priorityPassCard"),
            image: PriorityPassCardImg,
            description: value?.description
          })
        }

        if (!elements[index + 1] && selectedMarket?.digicelcardallowed === "Y") {
          id = PRODUCTID_DIGICELBLACKCARD;
          data.push({
            productid: id,
            heading_1: t("home:digicel"),
            heading_2: t("home:selectYourService"),
            title: t("home:digicelCard"),
            image: DigicelBlackCardImg,
            description: value?.description
          })
        }

        if (!elements[index + 1] && selectedMarket?.digicelcardallowed === "Y") {
          data.push({
            productid: "",
            heading_1: t("home:dragon"),
            heading_2: t("home:selectYourService"),
            title: t("home:dragonCard"),
            image: DragonCardImg,
            description: value?.description
          })
        }
        if (!elements[index + 1] && selectedMarket?.digicelcardallowed === "Y") {
          data.push({
            productid: "",
            heading_1: t("home:dragon"),
            heading_2: t("home:selectYourService"),
            title: t("home:dragonCard"),
            image: DragonCardImg,
            description: value?.description
          })
        }
        setTmpData(data);
      });
  }, [selectedMarket])

  return (
    <>
      {
        tmpData.length > 0 ?
          <>
            <section>
              <Container className='my-4'>
                <Card>
                  <Card.Body>
                    <h3 className="text-center mb-4"><span className="heading">{t("home:loungePartners")}</span></h3>
                    <Row>
                      <Carousel
                        partialVisible={true}
                        centerMode={false}
                        responsive={{
                          superLargeDesktop: {
                            breakpoint: { max: 4000, min: 3000 },
                            items: 5
                          },
                          desktop: {
                            breakpoint: { max: 3000, min: 1024 },
                            items: 3
                          },
                          tablet: {
                            breakpoint: { max: 1024, min: 464 },
                            items: 2
                          },
                          mobile: {
                            breakpoint: { max: 464, min: 0 },
                            items: 1
                          }
                        }}
                        className='px-0'
                      >
                        {tmpData.map((data, index) => (
                          <Col md={12} key={index} className="px-2">
                            <div className='mb-4 mx-2'>
                              <Row>
                                <Col className="col-md-12">
                                  <ProductCard
                                    productid={data?.productid}
                                    image={data?.image}
                                    viewPrice={t("home:viewPrices")}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        ))}
                      </Carousel>
                    </Row>
                    <Card.Text className='p-1'>
                      {description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Container>
            </section>
          </>
          : null
      }
    </>
  );
}

export default RenderProductCarousel;
