import { Card, Container, Row, Col } from "react-bootstrap";
import { getOrderDetails } from "../redux/apiCalls";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { showError, showSuccess } from "../components/commonComponents/Swal";
import BookingSummaryComponent from "../components/commonComponents/BookingSummaryComponent";
import { useTranslation } from "react-i18next";
import Loader from "../components/commonComponents/Loader";
import { HandleAPIError } from "../components/commonComponents/commonFunctions";
import CommonButton from "../components/commonComponents/CommonButton";
import ExitButton from "../components/commonComponents/ExitButton";

export default function UpdateContactDetails() {
  const { t } = useTranslation(["common", "orderreport"]);
  const [orderDetail, setOrderDetail] = useState([]);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const orderDetails = location.state.data
  const startDate = location.state.startDate
  const endDate = location.state.endDate
  const filterBy = location.state.filterBy

  const fetchOrderDetails = async () => {
    try {
      setLoading(true);
      const data = {
        "reservationid": orderDetails.reservationid,
        "productid": orderDetails.productid,
        "getschedule": "N",
      };
      const response = await getOrderDetails(data);
      if (response?.status === 0) {
        let tmpOrderDetail = response.data.orderdetail;
        setOrderDetail(tmpOrderDetail);
      }
      else {
        showError(t("error"), response?.statusMessage);
      }
    } catch (e) {
      HandleAPIError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  return (
    <section>
      <Container className="my-4">
        <div className="my-3">
          <h3 className="d-flex justify-content-center heading heading">
            <span className="heading">{t("orderreport:orderDetails")}</span>
          </h3>
        </div>
        <div className="my-3 px-1 d-flex justify-content-end">
          <Link to="/order-report" state={{ startDate, endDate, filterBy }} className=" mx-1 text-decoration-none">
            <CommonButton classname="back" title={t("back")} />
          </Link>
          <ExitButton />
        </div>
        <Card className="p-2 my-3">
          <Card.Body>
            <Loader loading={loading} message={t("loaderMessage")} />
            <BookingSummaryComponent
              renderType={"UPDATE-CONTACT-DETAILS"}
              orderDetail={orderDetail}
              productid={orderDetail.productid}
            />
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}